<template>
  <v-card>
    <v-row justify="center">
      <h1 class="ml-9 mb-7 mt-7" id="myfont">*ແກ້ໄຂຂໍ້ມູນສ່ວນຕົວ*</h1>
    </v-row>
    <v-divider></v-divider>
    <div>
      <v-row cols="2" sm="6" class="mt-3">
        <v-col lg="6" cols="12" sm="6">
          <h1 class="ml-9" id="myfont">ຊື່ເຕັມ*</h1>
          <v-text-field
            id="textfield"
            class="mt-2 mr-9 ml-9"
            outlined
            placeholder="Enter Your Full Name"
            v-model="full_name"
            :error-messages="NameErrors"
            :counter="100"
            required
            @input="$v.full_name.$touch()"
            @blur="$v.full_name.$touch()"
          ></v-text-field>
          <h1 class="ml-9" id="myfont">ເພດ*</h1>
          <v-row dense justify="space-around">
            <v-radio-group
              required
              :error-messages="GenderErrors"
              @change="$v.gender.$touch()"
              @blur="$v.gender.$touch()"
              v-model="gender"
              row
            >
              <v-radio
                label="ຊາຍ/Male"
                value="ຊາຍ"
                class="mr-15 radio"
              ></v-radio>
              <v-radio
                label="ຍິງ/Female"
                value="ຍິງ"
                class="ml-15 radio"
              ></v-radio>
            </v-radio-group>
          </v-row>
          <h1 class="ml-9 mt-7" id="myfont">ເບີໂທຕິດຕໍ່*</h1>
          <v-text-field
            id="textfield"
            class="mt-1 mr-9 ml-9"
            outlined
            placeholder="Enter Your Phone Number"
            v-model="tel"
            :error-messages="TelErrors"
            :counter="12"
            required
            @keypress="isNumber($event)"
            @input="$v.tel.$touch()"
            @blur="$v.tel.$touch()"
          ></v-text-field>
          <h1 class="ml-9" id="myfont">ແຟສບຸກ*</h1>
          <v-text-field
            id="textfield"
            class="mt-1 mr-9 ml-9"
            outlined
            placeholder="Enter Your Facebook"
            v-model="facebook"
            :error-messages="FacebookErrors"
            :counter="100"
            required
            @input="$v.facebook.$touch()"
            @blur="$v.facebook.$touch()"
          ></v-text-field>
          <h1 class="ml-9" id="myfont">ອີແມວ*</h1>
          <v-text-field
            id="textfield"
            class="mt-1 mr-9 ml-9"
            outlined
            readonly
            placeholder="Enter Your Email"
            v-model="email"
            :error-messages="emailErrors"
            :counter="100"
            required
          ></v-text-field>
          <h1 class="ml-9" id="myfont">ສະຖານະ*</h1>
          <v-text-field
            id="textfield"
            class="mt-1 mr-9 ml-9"
            outlined
            readonly
            v-model="status"
            placeholder="Status"
            :counter="100"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <h1 class="ml-9" id="myfont">ບ້ານຢູ່ປັດຈຸບັນ*</h1>
          <v-text-field
            id="textfield"
            class="mt-1 mr-9 ml-9"
            outlined
            placeholder="Enter Your Village"
            v-model="village"
            :error-messages="VillageErrors"
            :counter="100"
            required
            @input="$v.village.$touch()"
            @blur="$v.village.$touch()"
          ></v-text-field>
          <h1 class="ml-9" id="myfont">ເມືອງ*</h1>
          <v-text-field
            id="textfield"
            class="mt-1 mr-9 ml-9"
            outlined
            placeholder="Enter Your District"
            v-model="district"
            :error-messages="DistrictErrors"
            :counter="100"
            required
            @input="$v.district.$touch()"
            @blur="$v.district.$touch()"
          ></v-text-field>
          <h1 class="ml-9" id="myfont">ແຂວງ*</h1>
          <v-text-field
            id="textfield"
            class="mt-1 mr-9 ml-9"
            outlined
            placeholder="Enter Your Province"
            v-model="province"
            :error-messages="ProvinceErrors"
            :counter="100"
            required
            @input="$v.province.$touch()"
            @blur="$v.province.$touch()"
          ></v-text-field>
          <h1 class="ml-9" id="myfont">ປະເທດ*</h1>
          <v-select
            id="textfield"
            :items="items"
            outlined
            placeholder="Select Your Country"
            class="mt-1 mr-9 ml-9"
            v-model="country"
            :error-messages="CountryErrors"
            required
            @change="$v.country.$touch()"
            @blur="$v.country.$touch()"
          ></v-select>
          <h1 class="ml-9" id="myfont">ຕັ້ງລະຫັດຜ່ານ*</h1>
          <v-text-field
            id="textfield"
            class="mt-1 mr-9 ml-9"
            outlined
            placeholder="Create Password"
            type="password"
            v-model="password"
            :error-messages="PasswordErrors"
            counter
            required
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          ></v-text-field>
          <h1 class="ml-9" id="myfont">ຢືນຢັນລະຫັດຜ່ານ*</h1>
          <v-text-field
            id="textfield"
            class="mt-1 mr-9 ml-9"
            outlined
            v-model="comfirmpassword"
            placeholder="Comfirm Password"
            type="password"
            :error-messages="ComfirmPasswordErrors"
            counter
            required
            @input="$v.comfirmpassword.$touch()"
            @blur="$v.comfirmpassword.$touch()"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-row justify="end">
      <v-btn
        class="mt-10 mb-16 mr-10"
        color="#01BEFE"
        @click="Submit_Edit"
        id="btntext"
      >
        ບັນທຶກ
      </v-btn>
    </v-row>
           <v-alert dense type="info" class="ml-5 mr-5" id="Alert_info">
            {{status_Text}}
          </v-alert>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    full_name: { required, maxLength: maxLength(100) },
    gender: { required },
    tel: { required, maxLength: maxLength(12) },
    facebook: { required, maxLength: maxLength(100) },
    email: { required, email },
    village: { required, maxLength: maxLength(100) },
    district: { required, maxLength: maxLength(100) },
    province: { required, maxLength: maxLength(100) },
    country: { required },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
    },
    comfirmpassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
    },
  },
  data() {
    return {
      items: ["Laos", "Thailand", "America", "Vietname"],
      url: null,
      profile: "",
      CustomerStatus: "ລູກຄ້າໃໝ່",
      //Customer Info
      full_name: "",
      gender: "",
      tel: "",
      facebook: "",
      email: "",
      village: "",
      district: "",
      province: "",
      country: "",
      password: "",
      comfirmpassword: "",
      status_Text:"",
    };
  },
  mounted() {
    this.$store.dispatch("Customer_List");
    // alert((localStorage.getItem('Customer_Info')))
    this.full_name = JSON.parse(
      localStorage.getItem("Customer_Info")
    ).full_name;
    this.gender = JSON.parse(localStorage.getItem("Customer_Info")).gender;
    this.tel = JSON.parse(localStorage.getItem("Customer_Info")).tel;
    this.facebook = JSON.parse(localStorage.getItem("Customer_Info")).facebook;
    this.email = JSON.parse(localStorage.getItem("Customer_Info")).email;

    this.status = JSON.parse(localStorage.getItem("customer_status")).status;

    this.village = JSON.parse(localStorage.getItem("Customer_Info")).village;
    this.district = JSON.parse(localStorage.getItem("Customer_Info")).district;
    this.province = JSON.parse(localStorage.getItem("Customer_Info")).province;
    this.country = JSON.parse(localStorage.getItem("Customer_Info")).country;
    this.password = JSON.parse(localStorage.getItem("Customer_Info")).password;
    this.comfirmpassword = JSON.parse(
      localStorage.getItem("Customer_Info")
    ).password;
    if(this.status=="Comfirm"){
     this.status_Text="ທ່ານໄດ້ສະມັກເປັນຕົວແທນກັບຮ້ານ ດາດ້າແຟຊັ່ນແລ້ວ...ແລະ"
     +"ປັດຈຸບັນນີ້ຂໍ້ມູນຂອງທ່ານກໍາລັງຢູ່ໃນສະຖານະກວດສອບຄວາມຖຶກຕ້ອງ ແລະ ຮັບຮອງຈາກເຈົ້າຂອງຮ້ານເພື່ອຮັບທ່ານເປັນຕົວແທນທີ່ສົມບູນ....< ຂໍຂອບໃຈ >"
    }else if(this.status=="ຕົວແທນ"){
      this.status_Text="ທ່ານໄດ້ເປັນຕົວແທນກັບຮ້ານດາດ້າແຟຊັ່ນແລ້ວ....ທຸກຄັ້ງທີ່ທ່ານຊື້ສິນຄ້າຈາກຮ້ານຂອງພວກເຮົາແມ່ນທ່ານຈະໄດ້ຮັບສ່ວນຫຼຸດໃນລາຄາພິເສດ"
      +" ແລະ ທ່ານຍັງມີໂອກາດໄດ້ຮັບໂປຣໂມຊັ່ນອິກຫຼາກຫຼາຍຈາກຮ້ານຂອງພວກເຮົາ....< ຂໍຂອບໃຈ >"
    }else{
      this.status_Text="ປັດຈຸບັນນີ້ ທ່ານເປັນລູກຄ້າທົ່ວໄປ ຖ້າທ່ານຕ້ອງການຊື້ສິນຄ້າຈາກຮ້ານຂອງພວກເຮົາໃນລາຄາພິເສດ ແລະ ທຸກຄັ້ງທີ່ຊື້ສິນຄ້າຈາກຮ້ານຂອງພວກເຮົາໃຫ້ໄດ້ຮັບສ່ວນຫຼຸດ"
      +"ແມ່ນທ່ານສາມາດສະມັກເປັນຕົວແທນ ຫຼື ເປັນສະມາຊິກກັບຮ້ານຂອງພວກເຮົາໄດ້ທີ່ເມນູຈັດການໂປຣໄຟ໌ລຂອງທ່ານ....< ຂໍຂອບໃຈ >"
    }
  },
  computed: {
    customerInfo() {
      // alert(JSON.stringify(this.$store.state.customerInfo));
      return this.$store.state.customerInfo;
    },
    Customer_detail() {
      // alert(this.$store.state.Customer_detail)
      return this.$store.state.Customer_detail;
    },

    NameErrors() {
      const errors = [];
      if (!this.$v.full_name.$dirty) return errors;
      !this.$v.full_name.maxLength &&
        errors.push("Name must be at most 100 characters long");
      !this.$v.full_name.required && errors.push("Name is required.");
      return errors;
    },
    GenderErrors() {
      const errors = [];
      if (!this.$v.gender.$dirty) return errors;
      !this.$v.gender.required && errors.push("");
      return errors;
    },
    TelErrors() {
      const errors = [];
      if (!this.$v.tel.$dirty) return errors;
      !this.$v.tel.maxLength &&
        errors.push("Tel must be at most 12 characters long");
      !this.$v.tel.required && errors.push("Tel is required.");
      return errors;
    },
    FacebookErrors() {
      const errors = [];
      if (!this.$v.facebook.$dirty) return errors;
      !this.$v.facebook.maxLength &&
        errors.push("Facebook must be at most 100 characters long");
      !this.$v.facebook.required && errors.push("Facebook is required.");
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },

    VillageErrors() {
      const errors = [];
      if (!this.$v.village.$dirty) return errors;
      !this.$v.village.maxLength &&
        errors.push("Village must be at most 100 characters long");
      !this.$v.village.required && errors.push("Village is required.");
      return errors;
    },

    DistrictErrors() {
      const errors = [];
      if (!this.$v.district.$dirty) return errors;
      !this.$v.district.maxLength &&
        errors.push("District must be at most 100 characters long");
      !this.$v.district.required && errors.push("District is required.");
      return errors;
    },

    ProvinceErrors() {
      const errors = [];
      if (!this.$v.province.$dirty) return errors;
      !this.$v.province.maxLength &&
        errors.push("Province must be at most 100 characters long");
      !this.$v.province.required && errors.push("Province is required.");
      return errors;
    },
    CountryErrors() {
      const errors = [];
      if (!this.$v.country.$dirty) return errors;
      !this.$v.country.required && errors.push("Country is required");
      return errors;
    },
    PasswordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Password must be at less 6 characters short");
      !this.$v.password.maxLength &&
        errors.push("Password must be at most 20 characters long");
      !this.$v.password.required && errors.push("Password is required.");
      return errors;
    },
    ComfirmPasswordErrors() {
      const errors = [];
      if (!this.$v.comfirmpassword.$dirty) return errors;
      !this.$v.comfirmpassword.minLength &&
        errors.push("Comfirmpassword must be at less 6 characters short");
      !this.$v.comfirmpassword.maxLength &&
        errors.push("Comfirmpassword must be at most 20 characters long");
      !this.$v.comfirmpassword.required &&
        errors.push("Comfirmpassword is required.");

      if (this.comfirmpassword != this.password) {
        errors.push("Comfirm Password Again...!");
      }
      return errors;
    },
  },
  methods: {
    isNumber: function ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    Submit_Edit() {
      if (
        this.full_name == "" ||
        this.tel == "" ||
        this.facebook == "" ||
        this.email == "" ||
        this.village == "" ||
        this.district == "" ||
        this.province == "" ||
        this.password == "" ||
        this.gender == "" ||
        this.country == ""
      ) {
        this.$v.$touch();
        return;
      }
      if (this.password != this.comfirmpassword) {
        return;
      }
      this.$store.dispatch("Edit_Customer_Info", {
        cus_id: JSON.parse(localStorage.getItem("Customer_Info")).cus_id,
        full_name: this.full_name,
        gender: this.gender,
        tel: this.tel,
        facebook: this.facebook,
        email: this.email,
        village: this.village,
        district: this.district,
        province: this.province,
        country: this.country,
        password: this.password,
        status: this.status,
      });
      //   alert((localStorage.getItem('Customer_Info')))
      window.location.reload();
    },
    onFileSelected(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.url = e.target.result;
      };
      this.card_capture = event.target.files[0].name;
      // alert(JSON.stringify(this.url));
    },
  },
};
</script>

<style>
#cssstyle {
  font-size: 30px;
  font-family: "Noto Sans Lao";
  text-align: left;
  margin-right: 30px;
  margin-top: 7px;
  color: black;
  font-weight: normal;
}
#myfont {
  font-size: 25px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: black;
}
#dialog {
  font-size: 25px;
  font-family: "Noto Sans Lao";
  font-weight: bold;
  align-content: center;
  margin-left: 135px;
  margin-top: 10px;
  color: black;
}
#btntext {
  font-size: 18px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: white;
}
.radio {
  font-size: 40px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: black;
}
#Alert_info{
  font-size: 20px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
}
</style>