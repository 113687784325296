<template>
  <div>
    <v-carousel
      cycle
      height="450"
      hide-delimiter-background
      show-arrows-on-hover
      show-arrows
    >
      <v-carousel-item v-for="img in items" :src="img.src" :key="img.id">
        <div id="title">ຮ້ານ ດາດ້າແຟຊັ່ນ</div>
      </v-carousel-item>
    </v-carousel>
    <v-card flat tile>
      <v-container
        v-for="type in types"
        :key="type"
        class="grey lighten-4"
        fluid
      >
        <v-subheader id="css">{{ type }}</v-subheader>
        <v-progress-linear
          class="mb-2"
          color="black"
          value="100"
          height="1px"
        ></v-progress-linear>
        <v-row>
          <v-col
            v-for="(pro, idx) in TopProduct"
            :key="idx"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-hover v-slot="{ hover }" open-delay="100">
              <v-card
                :elevation="hover ? 10 : 0"
                :class="{ 'on-hover': hover }"
              >
                <v-carousel cycle height="400" show-arrows-on-hover hide-delimiter-background>
                  <v-carousel-item v-for="(img, i) in pro.Image" :key="i">
                    <v-card
                      class="mx-auto"
                      height="100%"
                      aspect-ratio="1.7"
                      :img="fectImg(img.img_url)"
                      @click="ViewDetail(pro.proID)"
                    >
                    </v-card>
                  </v-carousel-item>
                </v-carousel>
                <v-card-actions class="white justify-center">
                  <v-col>
                    <h1 id="name">ຊື່ສິນຄ້າ: {{ pro.proName }}</h1>
                    <h1 id="name">ເບີ້/ຂະໜາດ: {{ pro.Size }}</h1>
                    <h1 id="name">ລາຄາ: {{String(pro.Price).replace(/(.)(?=(\d{3})+$)/g,'$1 ') }} K</h1>
                    <v-row align="center" justify="space-around">
                      <v-text-field
                        class="mt-10 ml-15 mr-15 text-center tcenter"
                        filled
                        dense
                        rounded
                        prepend-inner-icon="mdi-minus"
                        append-icon="mdi-plus"
                        v-model="pro.quantity"
                        @keypress="isNumber($event)"
                        @keyup="CheckNumber(pro)"
                      >
                        <template slot="append">
                          <v-btn
                            style="margin-top: -7px; margin-right: -20px"
                            color="green darken-4"
                            icon
                          >
                            <v-icon large @click="IncrementQty(pro)"
                              >mdi-plus</v-icon
                            >
                          </v-btn>
                        </template>
                        <template slot="prepend-inner">
                          <v-btn
                            style="margin-top: -7px; margin-left: -20px"
                            color="green darken-4"
                            icon
                          >
                            <v-icon large @click="DecrementQty(pro)"
                              >mdi-minus</v-icon
                            >
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-row>
                    <v-row justify="end">
                      <v-row justify="start">
                        <v-btn
                          rounded
                          color="#ffffff"
                          id="btnAdd"
                          class="mt-3 ml-4"
                          @click="ViewDetail(pro.proID)"
                        >
                          <v-icon> mdi-eye-settings </v-icon>
                        </v-btn>
                      </v-row>
                      <v-btn
                        rounded
                        color="#ffffff"
                        id="btnAdd"
                        justify-space-around
                        @click="
                          AddToCart(
                            pro.proID,
                            pro.proName,
                            pro.quantity,
                            pro.Price,
                            pro.Image
                          )
                        "
                      >
                        <v-icon> mdi-cart-plus </v-icon>
                        ເພີ່ມໃສ່ກະຕ່າ
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
      <div class="text-end mb-3 mt-3 mr-7">
        <v-btn id="btntext" text rounded color="primary" dark @click="ViewMore">
          ເບີ່ງທັງໝົດ
        </v-btn>
      </div>
    </v-card>

    <v-card flat tile>
      <v-container
        v-for="type in types1"
        :key="type"
        class="grey lighten-4"
        fluid
      >
        <v-subheader id="css">{{ type }} </v-subheader>
        <v-progress-linear
          class="mb-2"
          color="black"
          value="100"
          height="1px"
        ></v-progress-linear>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            v-for="(pro, idx) in products"
            :key="idx"
          >
            <v-hover v-slot="{ hover }" open-delay="100">
              <v-card
                :elevation="hover ? 10 : 0"
                :class="{ 'on-hover': hover }"
              >
                <v-carousel cycle height="400" show-arrows-on-hover hide-delimiter-background>
                  <v-carousel-item v-for="(img, i) in pro.Image" :key="i">
                    <v-card
                      class="mx-auto"
                      height="100%"
                      aspect-ratio="1.7"
                      :img="fectImg(img.img_url)"
                      @click="ViewDetail(pro.proID)"
                    >
                    </v-card>
                  </v-carousel-item>
                </v-carousel>
                <v-card-actions class="white justify-center">
                  <v-col>
                    <h1 id="name">ຊື່ສິນຄ້າ: {{ pro.proName }}</h1>
                    <h1 id="name">ເບີ້/ຂະໜາດ: {{ pro.Size }}</h1>
                    <h1 id="name">ລາຄາ: {{String(pro.Price).replace(/(.)(?=(\d{3})+$)/g,'$1 ') }} K</h1>
                    <v-row align="center" justify="space-around">
                      <v-text-field
                        class="mt-10 ml-15 mr-15 text-center tcenter"
                        filled
                        dense
                        rounded
                        prepend-inner-icon="mdi-minus"
                        append-icon="mdi-plus"
                        @keypress="isNumber($event)"
                        @keyup="CheckNumber(pro)"
                        v-model="pro.quantity"
                      >
                        <template slot="append">
                          <v-btn
                            style="margin-top: -7px; margin-right: -20px"
                            color="green darken-4"
                            icon
                          >
                            <v-icon large @click="IncrementQty(pro)"
                              >mdi-plus</v-icon
                            >
                          </v-btn>
                        </template>
                        <template slot="prepend-inner">
                          <v-btn
                            style="margin-top: -7px; margin-left: -20px"
                            color="green darken-4"
                            icon
                          >
                            <v-icon large @click="DecrementQty(pro)"
                              >mdi-minus</v-icon
                            >
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-row>
                    <v-row justify="end">
                      <v-row justify="start">
                        <v-btn
                          rounded
                          color="#ffffff"
                          id="btnAdd"
                          class="mt-3 ml-4"
                          @click="ViewDetail(pro.proID)"
                        >
                          <v-icon> mdi-eye-settings </v-icon>
                        </v-btn>
                      </v-row>
                      <v-btn
                        rounded
                        color="#ffffff"
                        id="btnAdd"
                        justify-space-around
                        @click="
                          AddToCart(
                            pro.proID,
                            pro.proName,
                            pro.quantity,
                            pro.Price,
                            pro.Image
                          )
                        "
                      >
                        <v-icon> mdi-cart-plus </v-icon>
                        ເພີ່ມໃສ່ກະຕ່າ
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
      <div class="text-end mb-3 mt-3 mr-7">
        <v-btn id="btntext" text rounded color="primary" dark @click="ViewMore">
          ເບີ່ງທັງໝົດ
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        src: require("@/assets/images/dada3.jpg"),
      },
      {
        src: require("@/assets/images/dada.jpg"),
      },
      {
        src: require("@/assets/images/dada2.jpg"),
      },
      {
        src: require("@/assets/images/Kungfu.jpg"),
      },
    ],
    types: ["ສິນຄ້າຍອດນິຍົມ"],
    types1: ["ສິນຄ້າໃໝ່"],
  }),
  // props: ["id"],
  computed: {
    products() {
      // alert(JSON.stringify(this.$store.state.products));
      return this.$store.state.products;
    },
    TopProduct(){
       return this.$store.state.TopProduct;
    }
  },
  mounted() {
    // this.SelectNewProduct();
    this.$store.dispatch("getProducts");
    this.$store.dispatch('getTop_products')
  },
  methods: {
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/images/D (32).jpg");
      }
      return imageUrl;
    },
    ViewDetail(proID) {
      if (
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id != undefined
      ) {
        this.$router.push(`/Customer/ProductDetail/${proID}`);
      } else {
        this.$router.push(`/ProductDetail/${proID}`);
        // this.$store.state.productID = proID;
      }
    },
    ViewMore() {
      if (
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id != undefined
      ) {
        this.$router.push(`/Customer/Product`);
      } else {
        this.$router.push(`/Product`);
        // this.$store.state.productID = proID;
      }
    },

    AddToCart(proID, proName, quantity, price, Image) {
       if (
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == undefined ||
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == null ||
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == ""
      ) {
        this.$router.push('/Customer_Login')
        return
      }
      this.$store.dispatch("AddProductToCart", {
        proID,
        proName,
        quantity,
        price,
        total: price * quantity,
        Image,
      });
      // alert(JSON.stringify(this.quantity));
    },
    isNumber: function ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    CheckNumber(pro) {
      if (
        (typeof pro.quantity == "string" && pro.quantity == "") ||
        pro.quantity == null ||
        pro.quantity < 1
      ) {
        pro.quantity = 1;
      }
      pro.quantity = parseInt(pro.quantity, 10);
    },
    DecrementQty(pro) {
      if (pro.quantity <= 1) return;
      pro.quantity--;
    },
    IncrementQty(pro) {
      pro.quantity++;
    },
  },
};
</script>

<style>
#css {
  font-size: 20px;
  font-family: "Noto Sans Lao";
  text-align: left;
  margin-left: 7px;
  margin-top: 7px;
  color: black;
}
#textfield {
   font-size: 20px;
  font-family: "Noto Sans Lao";
}
</style>
<style scoped>
.tcenter >>> input {
  text-align: center;
  color: black;
}
</style>