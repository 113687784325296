import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from "moment";
import { header } from '../../config/constant'

Vue.use(Vuex)

const ImportModule = {
    namespaced: true,
    state: {
        import_history:[],
        orderid: [],
        importList: [],
        bills:[]
    },
    mutations: {
        setMaterialHistory(state, list) {
            state.import_history = list
        },
        setOrderID(state, id) {
            state.orderid = id
        },
        setImport(state, pro) {
            state.importList = pro
        },
        setBillMaterialImport(state, b) {
            state.bills = b
        },
    },

    actions: {
        async getMaterialHistory(context) {
            var dada = await axios.get(
              `http://localhost:2021/api/dadabackend/materialimport/getall`,
              {
                headers: header
                }
            );
            var materiallist = [],
              detailList = [];
            for (const key in dada.data) {
              var dd = await axios.get(
                `http://localhost:2021/api/dadabackend/materialimport/detail/getById/${dada.data[key].imp_id}`,
                {
                  headers: header
                }
              );
              detailList = [];
      
              for (const i in dd.data) {
                var datadetail = {
                    idx:parseInt(i)+1,
                  pro_name: dd.data[i].pro_name,
                  category: dd.data[i].category,
                  unit: dd.data[i].unit,
                  color: dd.data[i].color,
                  size: dd.data[i].size,
                  qty: dd.data[i].qty,
                  price: dd.data[i].price,
                  image: dd.data[i].image
                };
                detailList.push(datadetail);
              }
              var mater = {
                idx:parseInt(key)+1,
                id: dada.data[key].imp_id,
                employee: dada.data[key].emp_name + " " + dada.data[key].emp_surname,
                date: moment(String(dada.data[key].imp_date)).format("YYYY-MM-DD"),
                time: dada.data[key].imp_time,
                detail: detailList
              };
              materiallist.push(mater);
            }
            context.commit('setMaterialHistory', materiallist)
          },
        async getOrderID(context) {
            var id = [];
            var oid = await axios.get(
                `http://localhost:2021/api/dadabackend/materialorder/getnew`,
                {
                    headers: header
                }
            );
            for (const key in oid.data) {
                if (Object.hasOwnProperty.call(oid.data, key)) {
                    const el = oid.data[key];
                    id.push(el.m_order_id);
                }
            }
            context.commit('setOrderID', id)
        },
        async getImport(context, id) {
            try {
                var list = [];
                var oid = await axios.get(
                    `http://localhost:2021/api/dadabackend/materialorder/detail/getById/${id}`,
                    {
                        headers: header
                    }
                );
                for (const key in oid.data) {
                    if (Object.hasOwnProperty.call(oid.data, key)) {
                        const el = oid.data[key];
                        var editedItem = {
                            m_id:el.m_id,
                            pro_name: el.pro_name,
                            category: el.category,
                            unit: el.unit,
                            color: el.color,
                            size: el.size,
                            qty: el.qty,
                            price: 0,
                            image: el.image
                        };
                        list.push(editedItem);
                    }
                }
                context.commit('setImport', list)
            } catch (e) {
                this.errors.push(e);
            }
        },
        async SaveImport(context, importby) {
            await axios.post(
                `http://localhost:2021/api/dadabackend/materialimport/insert`,
                importby,
                {
                    headers: header
                }
            );
           
        },
        async SaveImportDetail(context,importList,) {
            
            await axios.post(
                `http://localhost:2021/api/dadabackend/materialimport/detail/insert`,
                importList,
                {
                    headers: header
                }
            );
        },
        async UpdateStatus(context,  select) {
           
            var m = {
                id: select
            }
            await axios.post(
                `http://localhost:2021/api/dadabackend/materialorder/update`, m,
                {
                    headers: header
                }
            );
            context.dispatch("getOrderID")
        },
        async getBillMaterialImport(context,id) {
            var data = await axios.get(
              `http://localhost:2021/api/dadabackend/materialimport/getById/${id}`,
              {
                headers: header
              }
            );
            var detailList = []
              var dd = await axios.get(
                `http://localhost:2021/api/dadabackend/materialimport/detail/getById/${id}`,
                {
                  headers: header
                }
              );
              for (const i in dd.data) {
               var datadetail = {
                 idx:parseInt(i)+1,
                 pro_name: dd.data[i].pro_name,
                  category: dd.data[i].category,
                  color: dd.data[i].color,
                  size: dd.data[i].size,
                  qty: dd.data[i].qty,
                  unit: dd.data[i].unit,
                  price: dd.data[i].price,
                  image: dd.data[i].image,
                };
                detailList.push(datadetail);
              }
              var mater = {
                imp_id: data.data[0].imp_id,
                employee: data.data[0].emp_name + " " + data.data[0].emp_surname,
                supplier: data.data[0].supplier,
                date: moment(String(data.data[0].imp_date)).format("DD-MM-YYYY"),
                time: data.data[0].imp_time,
                detail: detailList
              };
            context.commit("setBillMaterialImport",mater)
          },
        CrearData(context) {
           var list=[]
            context.commit('setImport', list)
            context.dispatch('getOrderID')
        }
    },
    getters: {
        import_history: state => state.import_history,
        importList: state => state.importList,
        orderid: state => state.orderid,
        bills: state => state.bills
    }
}
export default ImportModule;
