<template >
  <div id="chart">
    <v-row no-gutters class="justify-center">
      <p style="font-size: 2rem">ລາຍງານຂໍ້ມູນນໍາເຂົ້າວັດຖຸດິບ</p>
    </v-row>
    <v-toolbar flat>
      <v-spacer></v-spacer>
      <h3>ນໍາເຂົ້າທັງໝົດ: {{ importcount }} ຄັ້ງ</h3>
      <v-spacer></v-spacer>
      <h3>ລວມຍອດນໍາເຂົ້າວັດຖຸດິບທັງໝົດ: {{ importtodal }} ກີບ</h3>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-row class="justify-center my-3">
      <v-col cols="4">
        <v-card height="250">
          <v-card-title>
            <v-row class="justify-center">
              <h4>ລວມຍອດນໍາເຂົ້າວັດຖຸດິບລະຫວ່າງວັນທີ່</h4>
            </v-row>
          </v-card-title>
          <v-row class="justify-center ma-5">
            <v-toolbar dense flat class="mt-5">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-1"
                    v-model="picker"
                    label="YYYY-MM-DD"
                  ></v-text-field>
                  <v-btn
                    class="mr-8"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    color="black"
                  >
                    <v-icon color large>mdi-alarm</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="picker"></v-date-picker>
              </v-menu>
              <p class="mr-16">ຫາ</p>
            </v-toolbar>
            <v-toolbar dense flat>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="YYYY-MM-DD"
                    v-model="picker2"
                  ></v-text-field>
                  <v-btn color="black" icon v-bind="attrs" v-on="on">
                    <v-icon color large>mdi-alarm</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="picker2"></v-date-picker>
              </v-menu>
              <v-btn
                @click="dateReport"
                color="primary"
                style="font-size: 18px"
              >
                <v-icon color="white" left>mdi-air-filter</v-icon>ລາຍງານ
              </v-btn>
            </v-toolbar>
          </v-row>
          <v-row class="justify-center ma-2 mx-5">
            <h3>
              ລວມເປັນມູນຄ່າ:
              {{ String(importdate).replace(/(.)(?=(\d{3})+$)/g, "$1,") }} ກິບ
            </h3>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card height="250">
          <v-card-title style="font-weight: bold">
            ວັດຖຸດິບທີນິຍົມນໍາເຂົ້າ
          </v-card-title>
          <apexchart
            type="bar"
            height="200"
            :options="topImport.chartOptions"
            :series="topImport.series"
          ></apexchart>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-center my-3">
      <v-card width="100%" class="mx-3 mb-5 pt-2">
        <v-toolbar flat>
          <h3>ປະຫວັດຍອດນໍາເຂົ້າວັດຖຸດິບ</h3>
          <v-spacer></v-spacer>
          <v-card class="elevation-0 mt-1" color="transparent" width="100">
            <v-select
              v-model="selectDate"
              :items="date_list"
              single-line
              placeholder="Date"
              hint="Date"
            ></v-select>
          </v-card>
        </v-toolbar>
        <apexchart
          type="line"
          height="500"
          :options="reportImport.chartOptions"
          :series="reportImport.series"
        ></apexchart>
        <v-row class="justify-center my-3">
          <h3
            >ລວມຍອດນໍາເຂົ້າ:
            {{
              String(reportImport.todal).replace(/(.)(?=(\d{3})+$)/g, "$1,") ||
              0
            }}
            ກິບ</h3
          >
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      date_list: ["ວັນທີ່", "ເດືອນ", "ເປັນປີ"],
      selectDate: "ວັນທີ່",
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      picker2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  computed: {
    ...mapGetters({
      topImport: "reportMod/topImport",
      reportImport: "reportMod/reportImport",
      importtodal: "reportMod/importtodal",
      importcount: "reportMod/importcount",
      importdate: "reportMod/importdate",
    }),
  },
  watch: {
    selectDate(date) {
      if (date == "ວັນທີ່") {
        this.getImportDay();
      }
      if (date == "ເດືອນ") {
        this.getImportMonth();
      }
      if (date == "ເປັນປີ") {
        this.getImportYear();
      }
    },
  },
  mounted() {
    this.getTopImport();
    this.getImportDay();
    this.getTodalImport();
    var dates = { first_date: this.picker, end_date: this.picker2 };
    this.getdatesImport(dates);
  },
  methods: {
    ...mapActions("reportMod", [
      "getTopImport",
      "getImportDay",
      "getImportMonth",
      "getImportYear",
      "getTodalImport",
      "getdatesImport",
    ]),
    dateReport() {
      var dates = { first_date: this.picker, end_date: this.picker2 };
      this.getdatesImport(dates);
      let routeData = this.$router.resolve({
        name: "bills_report_material_import",
        query: dates,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style scoped>
p {
  font-size: 1.2rem;
}
</style>