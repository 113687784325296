import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import { header } from '../../config/constant';

Vue.use(Vuex)

const shopModule = {
    namespaced: true,
    state: {
        shopdata: [],
        shop_edit: {
            id: "",
            shop_name: "",
            tel: "",
            whatsapp: "",
            email: "",
            bank_account_no: "",
            bank_account_name: "",
            paypal_email:"",
            register_pay: "",
            discount: ""
        },
    },
    mutations: {
        SETSHOP_INFO(state, shop) {
            state.shopdata = shop
        },
        SET_SHOP_INFO(state, shop) {
            state.shop_edit = {
                id: shop.shop_id,
                shop_name: shop.shop_name,
                tel:shop.tel,
                whatsapp: shop.whatsapp,
                email: shop.email,
                bank_account_no: shop.bank_account_no,
                bank_account_name: shop.bank_account_name,
                paypal_email:shop.paypal_email,
                register_pay: shop.register_pay,
                discount: shop.discount
            }
        }
    },

    actions: {
        async getShop_Info(context) {
            var response = await axios.get(`http://localhost:2021/api/dadabackend/shop-info/get`, {
                headers: header
            })
            context.commit('SETSHOP_INFO', response.data)
        },

        async getShopBy_ID(context, id) {
         //   alert(JSON.stringify("H"))
            var res = await axios.get(
                `http://localhost:2021/api/dadabackend/shop-info/getby-id/${id}`, {
                headers: header
            }
            );
            context.commit('SET_SHOP_INFO', res.data[0])
          //  alert(JSON.stringify(res))
        },
        // async delEmployee(context, delItem) {
        //     try {
        //         await axios.post(`http://localhost:2021/api/dadabackend/employee/delete`, delItem, {
        //             headers: header
        //         })
        //         context.dispatch('getEmployee')
        //     } catch (e) {
        //         this.errors.push(e)
        //     }
        // },
        // async InsertEmployee(context, insertItem) {
        //     try {

        //         await axios.post(
        //             `http://localhost:2021/api/dadabackend/employee/insert`,
        //             insertItem, {
        //             headers: header
        //         }
        //         );
        //         context.dispatch('getEmployee')
        //     } catch (e) {
        //         this.errors.push(e)
        //     }
        // },
        async EditShop_Info(context, updateItem) {
            try {
              //  alert(JSON.stringify(updateItem))
                await axios.post(
                    `http://localhost:2021/api/dadabackend/shop-info/update`,
                    updateItem, {
                    headers: header
                }
                );
                context.dispatch('getShop_Info')
            } catch (e) {
                this.errors.push(e)
            }
        },

    },
    getters: {
        shop_info: state => state.shopdata,
        shop_edit: state => state.shop_edit,
    }
}

export default shopModule;

