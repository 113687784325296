<template>
  <v-app class="font">
    <v-card class="ma-2 elevation-0">
      <v-row>
          <div class="d-flex mt-9">
            <v-avatar size="100" color="#fafafafa" class="pa-2">
              <v-img src="@/assets/images/DaDa_LoGo.png" />
            </v-avatar>
          </div>
        <v-col cols="9">
          <v-row no-gutters class="justify-center mt-5">
            <p style="font-size: 1.2rem">ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</p>
          </v-row>
          <v-row no-gutters class="justify-center">
            <p style="font-size: 1.2rem">
              ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດຖະນາຖາວອນ
            </p>
          </v-row>
          <v-row no-gutters class="justify-center mt-3">
            <p style="font-size: 2rem; text-decoration: bold">
              ໃບສັ່ງຊື້ວັດຖຸດິບ
            </p>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class>
        <v-col cols="6">
          <p style="font-size: 1.4rem">ຮ້ານ ດາດ້າເເຟຊັນ</p>
          <p>ໂທລະສັບ: 020 92602326</p>

          <p>ອີເມວ: houyang0087@gmail.com</p>
          <p>ຕັ້ງຢູ່: ບ້ານ ຫົວຂົວ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ</p>
        </v-col>
        <v-col cols="6" class="align-end align-content-end">
          <v-row class="justify-end">
            <v-card class="mr-5 elevation-0">
              <p>ບິນເລກທີ່: {{ bills.m_order_id }}</p>
              <p>ວັນທີ່: {{ bills.date }}</p>
              <p>ເວລາ: {{ bills.time }}</p>
              <p>ຜູ້ສະໜອງ: {{ bills.name }}</p>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class>
          <v-progress-linear value="100" height="1"></v-progress-linear>
      </v-row>
      <v-row no-gutters class>
        <v-card width="100%" class="mt-2 elevation-0">
          <v-data-table
            :headers="headers"
            :items="bills.detail"
            :search="search"
            item-key="id"
            sort-by="idx"
            class="elevation-0"
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr v-ripple="{ class: `white--text` }">
                <td>{{ item.idx }}</td>
                <td>
                  <v-card
                    height="100"
                    width="100"
                    elevation="0"
                    :img="fectImg(item.image)"
                  >
                  </v-card>
                </td>
                <td>{{ item.product }}</td>
                <td>{{ item.category }}</td>
                <td>{{ item.color }}</td>
                <td>{{ item.size }}</td>
                <td>
                  {{ String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }}
                </td>
                <td>{{ item.unit }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-divider class></v-divider>
        </v-card>
      </v-row>
       <v-progress-linear value="100" height="1"></v-progress-linear>
      <v-row no-gutters class="mt-2 ">
        <v-spacer></v-spacer>
        <div style="height: 100px; margin-top: 20px; margin-right:20px">
          <p>ຜູ້ສັ່ງຊື້</p>
          <p>{{ bills.employee }}</p>
        </div>
      </v-row>
    </v-card>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "ລໍາດັບ", value: "idx" },
        {
          text: "ຮູບ",
          sortable: false,
          value: "image",
        },
        { text: "ຊື່ວັດຖຸດິບ", value: "pruduct" },
        { text: "ປະເພດວັດຖຸດິບ", value: "category" },
        { text: "ສີ", value: "color" },
        { text: "ຂະໜາດ", value: "size" },
        { text: "ຈໍານວນ", value: "qty" },
        { text: "ຫົວໜ່ວຍ", value: "unit" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      bills: "materialOrderMod/bills",
    }),
  },

  created() {
    this.getBillMaterialOrder(this.$route.query.m_order_id);
  },
  methods: {
    ...mapActions("materialOrderMod", ["getBillMaterialOrder"]),
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
  },
};
</script>
<style lang="css">
.font {
  font-family: "NotoSansLao";
}
p {
  font-size: 1rem;
}
</style>