<template>
  <v-card flat>
    <v-toolbar>
      <template v-slot:extension>
        <v-tabs   v-model="tabs"
          fixed-tabs
          slider-color="#00BFA5"
          large
          color="#00BFA5" >
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="i in 2" :key="i" :value="'mobile-tabs-5-' + i">
            <v-col>
              <div v-if="i == 1">
                <span id="csss">ສັ່ງຕັດເຄື່ອງທົ່ວໄປ</span>
              </div>
              <div v-if="i == 2">
                <span id="csss">ສັ່ງຕັດເຄື່ອງຈາກສິນຄ້າຕົວແບບ</span>
              </div>
            </v-col>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-divider></v-divider>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-card flat>
          <div>
            <v-col>
              <v-row justify="center">
                <h1 id="myfont" class="mt-16 mb-5 text-md-center">
                  ......ເພີ່ມຂໍ້ມູນທີ່ຕ້ອງການສັ່ງຕັດ.......
                </h1>
              </v-row>
              <v-row justify="start">
                <v-col cols="12" sm="12" md="8" lg="5">
                  <h1 class="ml-9" id="myfont">ຊື່ສິນຄ້າ*</h1>
                  <v-text-field
                  id="textfield"
                    class="mt-2 mr-9 ml-9"
                    placeholder="Enter Product Name"
                    v-model="txtproductname"
                    outlined
                    :counter="50"
                    required
                    :error-messages="NameError"
                    @input="$v.txtproductname.$touch()"
                    @blur="$v.txtproductname.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="2">
                  <h1 class="ml-9" id="myfont">ຂະໜາດ/ເບີ້*</h1>
                  <v-text-field
                  id="textfield"
                    class="mt-2 mr-9 ml-9"
                    placeholder="Enter Size"
                    v-model="txtsize"
                    outlined
                    :counter="10"
                    required
                    :error-messages="SizeError"
                    @input="$v.txtsize.$touch()"
                    @blur="$v.txtsize.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <h1 class="ml-9" id="myfont">ສີ*</h1>
                  <v-text-field
                  id="textfield"
                    class="mt-2 mr-9 ml-9"
                    placeholder="Enter Color"
                    outlined
                    v-model="txtcolor"
                    :counter="20"
                    required
                    :error-messages="ColorError"
                    @input="$v.txtcolor.$touch()"
                    @blur="$v.txtcolor.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="2">
                  <h1 class="ml-9" id="myfont">ຈໍານວນ*</h1>
                  <v-text-field
                  id="textfield"
                    class="mt-2 mr-9 ml-9 tcenter"
                    placeholder="Enter Quantity"
                    outlined
                    v-model="txtqty"
                    :counter="10"
                    required
                    :error-messages="QtyError"
                    @input="$v.txtqty.$touch()"
                    @blur="$v.txtqty.$touch()"
                    @keypress="isNumber($event)"
                    @keyup="CheckNumber()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h1 class="ml-9" id="myfont">ລາຍລະອຽດເພີ່ມເຕີມ*</h1>
                  <v-textarea
                  id="textfield"
                    placeholder="Enter More Information Detail"
                    class="mt-2 mr-9 ml-9"
                    outlined
                    :counter="500"
                    v-model="txtdetail"
                    :error-messages="DetailError"
                    @input="$v.txtdetail.$touch()"
                    @blur="$v.txtdetail.$touch()"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-col>
                <h1 class="mr-7 ml-9" id="myfont">ເລຶອກຮູບສິນຄ້າ*</h1>
                <input
                  prepend-icon="mdi-camera"
                  type="file"
                  class="file-upload ml-7 mt-5"
                  @change="onFileSelected"
                  show-size
                  label="Image input"
                  required
                />
                <v-divider class="ml-7"></v-divider>
                <v-img
                  :src="url"
                  v-show="image_url"
                  max-height="450"
                  aspect-ratio="1.7"
                  contain
                ></v-img>
              </v-col>
            </v-col>
            <v-row justify="end" class="mb-15 mr-10 mt-10">
              <v-btn
                depressed
                id="btntext"
                color="error"
                class="mr-5"
                @click="Clear"
              >
                <v-icon>mdi-close</v-icon> ຍົກເລິກ
              </v-btn>
              <v-btn
                depressed
                id="btntext"
                color="primary"
                class="ml-5"
                @click="AddToCart"
              >
                <v-icon>mdi-cart-plus</v-icon>
                ເພີ່ມໃສ່ກະຕ່າ
              </v-btn>
            </v-row>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <div class="mt-15 mb-15 ml-3 mr-3">
            <v-card flat class="grey lighten-4">
              <v-row>
                <v-col
                  v-for="(pro, idx) in products"
                  :key="idx"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-hover v-slot="{ hover }" open-delay="100">
                    <v-card
                      :elevation="hover ? 20 : 7"
                      :class="{ 'on-hover': hover }"
                    >
                      <v-carousel cycle height="400"  show-arrows-on-hover
                hide-delimiter-background>
                        <v-carousel-item v-for="(img, i) in pro.Image" :key="i">
                          <v-card
                            @click="ViewDetail(pro.proID)"
                            class="mx-auto"
                            height="100%"
                            aspect-ratio="1.7"
                            :img="fectImg(img.img_url)"
                          >
                          </v-card>
                        </v-carousel-item>
                      </v-carousel>
                      <v-card-actions class="white justify-center">
                        <v-col>
                          <h1 id="name">ຊື່ສິນຄ້າ/Name: {{ pro.proName }}</h1>
                          <h1 id="name">ລາຄາ/Price: {{ String(pro.Price).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }} ກິບ</h1>
                          <v-row justify="end" class="mt-7">
                            <v-btn
                              rounded
                              color="#ffffff"
                              justify-space-around
                              id="btnAdd"
                              @click="ViewDetail(pro.proID)"
                            >
                              <v-icon> mdi-check-bold </v-icon>
                              ສັ່ງຕັດ
                            </v-btn>
                          </v-row>
                        </v-col>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    txtproductname: { required, maxLength: maxLength(50) },
    txtsize: { required, maxLength: maxLength(10) },
    txtcolor: { required, maxLength: maxLength(20) },
    txtqty: { required, maxLength: maxLength(10) },
    txtdetail: { required, maxLength: maxLength(500) },
  },
  data() {
    return {
      tabs: null,
      txtproductname: "",
      txtsize: "",
      txtcolor: "",
      txtqty: 1,
      txtdetail: "ສັ່ງຕັດເຄື່ອງ",
    };
  },
  computed: {
    products() {
      // alert(JSON.stringify(this.$store.state.products));
      return this.$store.state.products;
    },

    NameError() {
      const errors = [];
      if (!this.$v.txtproductname.$dirty) return errors;
      !this.$v.txtproductname.maxLength &&
        errors.push("Name must be at most 50 characters long");
      !this.$v.txtproductname.required &&
        errors.push("ປ້ອນຊື່ສິນຄ້າທີ່ຕ້ອງການສັ່ງຕັດ");
      return errors;
    },
    SizeError() {
      const errors = [];
      if (!this.$v.txtsize.$dirty) return errors;
      !this.$v.txtsize.maxLength &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.txtsize.required &&
        errors.push("ປ້ອນຂະໜາດ ຫຼື ເບີ້ທີ່ຕ້ອງການສັ່ງຕັດ");
      return errors;
    },
    ColorError() {
      const errors = [];
      if (!this.$v.txtcolor.$dirty) return errors;
      !this.$v.txtcolor.maxLength &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.txtcolor.required && errors.push("ປ້ອນສີທີ່ຕ້ອງການ");
      return errors;
    },
    QtyError() {
      const errors = [];
      if (!this.$v.txtqty.$dirty) return errors;
      !this.$v.txtqty.maxLength &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.txtqty.required && errors.push("ປ້ອນຈໍານວນທີ່ຕ້ອງການສັ່ງຕັດ");
      return errors;
    },
    DetailError() {
      const errors = [];
      if (!this.$v.txtdetail.$dirty) return errors;
      !this.$v.txtdetail.maxLength &&
        errors.push("Detail must be at most 500 characters long");
      !this.$v.txtdetail.required && errors.push("ປ້ອນລາຍລະອຽດເພີ່ມເຕີມ");
      return errors;
    },
  },
  mounted() {
    // this.SelectNewProduct();
    this.$store.dispatch("getProducts_Prototype");
  },
  methods: {
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/images/D (32).jpg");
      }
      return imageUrl;
    },
    ViewDetail(proID) {
       if (
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == undefined ||
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == null ||
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == ""
      ) {
        this.$router.push('/Customer_Login')
        return
      }
      if (
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id != undefined
      ) {
        this.$router.push(`/Customer/ProductOrderCutDetail/${proID}`);
      } else {
        this.$router.push(`/ProductOrderCutDetail/${proID}`);
        // this.$store.state.productID = proID;
      }
    },
    onFileSelected(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.url = e.target.result;
      };
      this.image_url = event.target.files[0].name;
      // alert(JSON.stringify(this.url));
    },
    AddToCart() {
      if (
        this.txtsize == "" ||
        this.txtcolor == "" ||
        this.txtdetail == "" ||
        this.txtproductname == "" ||
        this.txtqty == ""
      ) {
        this.$v.$touch();
        return;
      }
       if (
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == undefined ||
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == null ||
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == ""
      ) {
        this.$router.push('/Customer_Login')
        return
      }
      this.$store.dispatch("OrderCutCart", {
        proName: this.txtproductname,
        Category:"",
        Color: this.txtcolor,
        Size: this.txtsize,
        Quantity: this.txtqty,
         Unit:"",
        Price:0,
        Detail: this.txtdetail,
        image: this.image_url,
      });
      //alert(JSON.stringify(this.image_url));
    },
    isNumber: function ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    CheckNumber() {
      if (
        (typeof this.txtqty == "string" && this.txtqty == "") ||
        this.txtqty == null ||
        this.txtqty < 1
      ) {
        this.txtqty = 1;
      }
      this.txtqty = parseInt(this.txtqty, 10);
    },
    Clear() {
      this.$v.$reset();
      this.txtsize = "";
      this.txtcolor = "";
      this.txtdetail = "";
      this.txtproductname = "";
      this.txtqty = 1;
    },
  },
};
</script>

<style>
.csss {
  font-size: 20px;
  font-family: "Noto Sans Lao";
  text-align: left;
  color: black;
}
</style>
<style scoped>
.tcenter >>> input {
  text-align: center;
}
</style>


