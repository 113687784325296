import { render, staticRenderFns } from "./ProductOrderCutDetail.vue?vue&type=template&id=e8e30a70&scoped=true&"
import script from "./ProductOrderCutDetail.vue?vue&type=script&lang=js&"
export * from "./ProductOrderCutDetail.vue?vue&type=script&lang=js&"
import style0 from "./ProductOrderCutDetail.vue?vue&type=style&index=0&lang=css&"
import style1 from "./ProductOrderCutDetail.vue?vue&type=style&index=1&lang=css&"
import style2 from "./ProductOrderCutDetail.vue?vue&type=style&index=2&id=e8e30a70&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8e30a70",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCarousel,VCarouselItem,VCol,VDivider,VIcon,VRow,VTextField,VTextarea})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
