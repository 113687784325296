import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { header } from '../../config/constant'
Vue.use(Vuex)

const ReportPDF_Module = {
    namespaced: true,
    state: {
      products:[],
      pro_bestsell:[],
      pro_best_orderbuy:[],
      pro_best_ordercut:[],
      customer_member:[],
      
    importreport:[],
    cutreport:[],
    orderreport:[],
    sellreport:[],
    },
    mutations: {
        setProduct(state, products) {
            state.products = products
        },
        setPro_BestSell(state, pro_bestsell) {
            state.pro_bestsell = pro_bestsell
        },
        setPro_Best_Orderbuy(state, pro_best_orderbuy) {
            state.pro_best_orderbuy = pro_best_orderbuy
        },
        setPro_Best_OrderCut(state, pro_best_ordercut) {
            state.pro_best_ordercut = pro_best_ordercut  
        },
        setCustomerMember(state, customer_member) {
            state.customer_member = customer_member
        },
        
    setBillImport(state, pro) {
      state.importreport = pro
    },
    setBillCut(state, pro) {
      state.cutreport = pro
    },
    setBillOrder(state, pro) {
      state.orderreport = pro
    },
    setBillSell(state, pro) {
      state.sellreport = pro
    },
    },

    actions: {
      async getProductStock(context) {
        try {
            var productList = []
            const response = await axios.get(
              `http://localhost:2021/api/dadabackend/report/getproduct-stock`,{
                headers:header
              }
            );
          // alert(JSON.stringify(response.data))
            for (const key in response.data) {
              const element = response.data[key];
              var imglist = []
                  const img = await axios.get(
                    `http://localhost:2021/api/dadabackend/product/image/getById/${element.pro_id}`,{
                      headers:header
                    }
                  );
                  imglist = img.data;
              var products =  {
                idx: parseInt(key, 10)+1,
                id: element.pro_id,
                name: element.pro_name,
                qty:element.qty+" "+element.unit_name,
                price: element.price,
                status: element.status,
                image: imglist,
             };
              productList.push(products);
              
            }
            context.commit('setProduct', productList)
          } catch (e) {
            this.errors.push(e);
          }
      },

      async getProductBestSell(context) {
        try {
            var productList = []
            const response = await axios.get(
              `http://localhost:2021/api/dadabackend/report/getproduct-bestseller`,{
                headers:header
              }
            );
          // alert(JSON.stringify(response.data))
            for (const key in response.data) {
              const element = response.data[key];
              var imglist = []
                  const img = await axios.get(
                    `http://localhost:2021/api/dadabackend/product/image/getById/${element.pro_id}`,{
                      headers:header
                    }
                  );
                  imglist = img.data;
              var products =  {
                idx: parseInt(key, 10)+1,
                name: element.pro_name,
                qty:element.qty+" "+element.unit_name,
                image: imglist,
             };
              productList.push(products);
              
            }
            context.commit('setPro_BestSell', productList)
          } catch (e) {
            this.errors.push(e);
          }
      },
      async getProductBest_Orderbuy(context) {
        try {
            var productList = []
            const response = await axios.get(
              `http://localhost:2021/api/dadabackend/report/getproduct-best-orderbuy`,{
                headers:header
              }
            );
          // alert(JSON.stringify(response.data))
            for (const key in response.data) {
              const element = response.data[key];
              var imglist = []
                  const img = await axios.get(
                    `http://localhost:2021/api/dadabackend/product/image/getById/${element.pro_id}`,{
                      headers:header
                    }
                  );
                  imglist = img.data;
              var products =  {
                idx: parseInt(key, 10)+1,
                name: element.pro_name,
                qty:element.qty+" "+element.unit_name,
                image: imglist,
             };
              productList.push(products);
              
            }
            context.commit('setPro_Best_Orderbuy', productList)
          } catch (e) {
            this.errors.push(e);
          }
      },

      async getProductBest_OrderCut(context) {
        try {
            var productList = []
            const response = await axios.get(
              `http://localhost:2021/api/dadabackend/report/getproduct-best-ordercut`,{
                headers:header
              }
            );
          // alert(JSON.stringify(response.data))
            for (const key in response.data) {
              const element = response.data[key];
              var products =  {
                idx: parseInt(key, 10)+1,
                name: element.pro_name,
                qty:element.qty+" "+element.unit,
                image: element.image,
             };
              productList.push(products);
              
            }
            context.commit('setPro_Best_OrderCut', productList)
          } catch (e) {
            this.errors.push(e);
          }
      },
      
      async getCustomerMember(context) {
        try {
            var customers=[]
          var response = await axios.get(`http://localhost:2021/api/dadabackend/customer/member/getall`, {
            headers: header
          })
             for (const key in response.data) {
                     const element = response.data[key];
                     var customerlist={
                         idx:parseInt(key,10)+1,
                         full_name:element.full_name,
                         gender:element.gender,
                         tel:element.tel,
                         facebook:element.facebook,
                         email: element.email,
                         address:element.village+","+element.district+", "+element.province+", "+element.country
                     }
                     customers.push(customerlist)
             }
          context.commit('setCustomerMember', customers)
        } catch (e) {
          console.log(e)
        }
    },
    async getBillImport(context, date) {
      try {
        const response = await axios.post(
          `http://localhost:2021/api/dadabackend/materialimport/getreport`, date, {
          headers: header
        },
        );
        var products=[]
        for (const key in response.data) {
          const el = response.data[key];
          var product={
            idx: parseInt(key)+1,
            m_id:el.m_id,
            pro_name:el.pro_name,
            category:el.category,
            color:el.color,
            size:el.size,
            qty:el.qty,
            unit:el.unit,
            price:el.price,
            sum:el.sum,
            image:el.image
          }
          products.push(product)
        }

        context.commit('setBillImport', products)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getBillCut(context, date) {
      try {
        const response = await axios.post(
          `http://localhost:2021/api/dadabackend/order/cut/getreport`, date, {
          headers: header
        },
        );
        var products=[]
        for (const key in response.data) {
          const el = response.data[key];
          var product={
            idx: parseInt(key)+1,
            pro_name:el.pro_name,
            category:el.category,
            color:el.color,
            size:el.size,
            qty:el.qty,
            unit:el.unit,
            price:el.price,
            sum:el.sum,
            image:el.image
          }
          products.push(product)
        }

        context.commit('setBillCut', products)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getBillOrder(context, date) {
      try {
        const response = await axios.post(
          `http://localhost:2021/api/dadabackend/order/buy/getreport`, date, {
          headers: header
        },
        );
        var products=[]
        for (const key in response.data) {
          const el = response.data[key];
          var imglist = []
          const img = await axios.get(
            `http://localhost:2021/api/dadabackend/product/image/getById/${el.pro_id}`, {
            headers: header
          }
          );
          imglist = img.data;
          var product={
            idx: parseInt(key)+1,
            pro_id:el.pro_id,
            pro_name:el.pro_name,
            category:el.category,
            color:el.color,
            size:el.size,
            qty:el.qty,
            unit:el.unit,
            price:el.price,
            sum:el.sum,
            image:imglist
          }
          products.push(product)
        }

        context.commit('setBillOrder', products)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getBillSell(context, date) {
      try {
        const response = await axios.post(
          `http://localhost:2021/api/dadabackend/sale/getreport`, date, {
          headers: header
        },
        );
        var products=[]
        for (const key in response.data) {
          const el = response.data[key];
          const img = await axios.get(
            `http://localhost:2021/api/dadabackend/product/image/getById/${el.pro_id}`, {
            headers: header
          }
          );
         var imglist = img.data;
          var product={
            idx: parseInt(key)+1,
            pro_id:el.pro_id,
            pro_name:el.pro_name,
            category:el.category,
            color:el.color,
            size:el.size,
            qty:el.qty,
            unit:el.unit,
            price:el.price,
            sum:el.sum,
            image:imglist
          }
          products.push(product)
        }

        context.commit('setBillSell', products)
      } catch (e) {
        this.errors.push(e);
      }
    },

    },
    getters: {
        products: state => state.products,
        pro_bestsell:state => state.pro_bestsell,
        pro_best_orderbuy:state=>state.pro_best_orderbuy,
        pro_best_ordercut:state=>state.pro_best_ordercut,
        customer_member:state=>state.customer_member,
        
    importreport:state=>state.importreport,
    cutreport:state=>state.cutreport,
    orderreport:state=>state.orderreport,
    sellreport:state=>state.sellreport,
    }
}
export default ReportPDF_Module;
