<template>
  <div style="background:#333">
    <v-card
      color="#fafafa"
      elevation="0"
      v-if="
        this.$store.state.authMod.token.status == 'Admin' ||
          this.$store.state.authMod.token.status == 'admin'"
    >
      <div class="d-flex justify-center hidden-sm-and-down mb-2 px-1 x">
        <v-card
          v-ripple="{ class: `green--text` }"
          elevation="2"
          class="card"
          style="background:linear-gradient(to right , #1B5E20, #00E676)"
        >
          <div class="d-flex">
            <v-icon color="white" size="40">mdi-medical-bag</v-icon>
            <v-divider vertical class></v-divider>
            <div class="pa-2 white--text">
              <h3 style="color:#E0F7FA">ຍອດຂາຍລວມທັງໝົດ</h3>
              <span>{{ selltodal }} ກິບ</span>
            </div>
          </div>
        </v-card>
        <v-spacer></v-spacer>
        <v-card
          v-ripple="{ class: `green--text` }"
          elevation="2"
          class="card"
          style="background:linear-gradient(120deg, #880E4F, #FF80AB)"
        >
          <div class="d-flex">
            <v-icon color="white" size="40">mdi-table-arrow-left</v-icon>
            <v-divider vertical class></v-divider>
            <div class="pa-2 white--text">
              <h3 style="color:#FBE9E7">ຍອດນໍາເຂົ້າວັດຖຸດິບ</h3>
              <span>{{importtodal}} ກິບ</span>
            </div>
          </div>
        </v-card>
        <v-spacer></v-spacer>
        <v-card
          v-ripple="{ class: `green--text` }"
          elevation="2"
          class="card"
             style="background:linear-gradient(120deg, #1A237E, #82B1FF)"
        >
          <div class="d-flex">
            <v-icon color="white" size="40">mdi-tshirt-crew</v-icon>
            <v-divider vertical class></v-divider>
            <div class="pa-2 white--text">
              <h3 style="color:#F3E5F5">ສິນຄ້າ</h3>
              <span>{{ protodal }} ລາຍການ</span>
            </div>
          </div>
        </v-card>
        <v-spacer></v-spacer>
        <v-card
          v-ripple="{ class: `cyan--text` }"
          elevation="2"
          class="card"
          style="background:linear-gradient(to right, #E65100, #FFCC80)"
        >
          <div class="d-flex">
            <v-icon color="white" size="40">mdi-account-tie</v-icon>
            <v-divider vertical class></v-divider>
            <div class="pa-2 white--text">
              <h3>ພະນັກງານ</h3>
              <span>{{emptodal}} ຄົນ</span>
            </div>
          </div>
        </v-card>
        <v-spacer></v-spacer>
        <v-card
          v-ripple="{ class: `cyan--text` }"
          elevation="2"
          class="card"
          style="background:linear-gradient(to right, #263238, #4DD0E1 )"
        >
          <div class="d-flex">
            <v-icon color="white" size="40">mdi-account</v-icon>
            <v-divider vertical class></v-divider>
            <div class="pa-2 white--text">
              <h3>ຕົວແທນ</h3>
              <span>{{ custtodal }} ຄົນ</span>
            </div>
          </div>
        </v-card>
      </div>
      <div>
        <v-row>
          <v-col cols="12" md="9">
            <v-row no-gutter class="justify-center ma-1">
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-card
                  :elevation="hover ? 20 : 2"
                  :class="{ 'on-hover': hover }"
                  width="100%"
                  height="400"
                >
                  <v-toolbar dense flat>
                    <v-spacer></v-spacer>
                    <p>{{ title }}</p>
                    <v-spacer></v-spacer>
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="d-flex"
                          v-bind="attrs"
                          v-on="on"
                          v-ripple="{ class: `green--text` }"
                        >
                          <v-icon color>mdi-filter-variant</v-icon>

                          <h3>ລາຍການ</h3>
                        </div>
                      </template>

                      <v-list>
                        <v-list-item link v-for="(item, index) in items" :key="index">
                          <v-list-item-title @click="SwitchMenu(item.title)">
                            {{
                            item.title
                            }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <v-spacer></v-spacer>
                    <v-card class="elevation-0 mt-1" color="transparent" width="100">
                      <v-select
                        v-model="selectDate"
                        :items="date_list"
                        single-line
                        placeholder="Date"
                        hint="Date"
                      ></v-select>
                    </v-card>
                  </v-toolbar>
                  <line-chart width="100%" height="300" v-if="list != null" :chart-data="list"></line-chart>
                  <v-row v-if="title == 'ກຣາຟສະແດງຍອດລວມ'" class="justify-space-around mt-3">
                    <span style="font-weight:bold;font-size:15px">ຍອດສັ່ງຕັດສິນຄ້າລວມ:{{ String(parseInt(list.datasets[0].todal)).replace(/(.)(?=(\d{3})+$)/g, '$1,') || 0 }} ກິບ</span>
                    <span style="font-weight:bold;font-size:15px">ຍອດສັ່ງຊື້ສິນຄ້າລວມ:{{ String(parseInt(list.datasets[1].todal)).replace(/(.)(?=(\d{3})+$)/g, '$1,') || 0 }} ກິບ</span>
                    <span style="font-weight:bold;font-size:15px">ຍອດຂາຍສິນຄ້າລວມ:{{ String(parseInt(list.datasets[2].todal)).replace(/(.)(?=(\d{3})+$)/g, '$1,') || 0 }} ກິບ</span>
                  </v-row>
                  <v-row v-else class="justify-center mt-3">
                    <span style="font-weight:bold;font-size:25px">ຍອດລວມ:{{ String(list.datasets[0].todal).replace(/(.)(?=(\d{3})+$)/g, '$1,') || 0 }} ກິບ</span>
                  </v-row>
                </v-card>
              </v-hover>
            </v-row>
           
          </v-col>
          <v-col cols="12" md="3">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card
                class="ma-1"
                :elevation="hover ? 20 : 2"
                :class="{ 'on-hover': hover }"
                width="100%"
                height="400"
              >
                <v-card-title primary-title>
                 <v-row class="mx-2 justify-space-between">
                        <p style="font-weight:bold">ຕົວແທນຕົວແບບ</p>
                       <router-link to="/report_customer">
                          <p color="green" text>View More</p>
                      </router-link>
                      </v-row>
                </v-card-title>
                <CustBarChart />
              </v-card>
            </v-hover>
            
          </v-col>
        </v-row>
        

           <v-row>
          <v-col cols="12" class="justify-center">
         
            <v-row no-gutter class="justify-center">
              <v-col cols="12" md="4">
                <v-hover v-slot="{ hover }" open-delay="200">
                  <v-card
                    :elevation="hover ? 20 : 2"
                    :class="{ 'on-hover': hover }"
                    width="100%"
                    height="400"
                  >
                    <v-card-title primary-title>
                      <v-row class="mx-2 justify-space-between"><p style="font-weight:bold">ສິນຄ້າທີນິຍົມສັ່ງຕັດ</p>
                       <router-link to="/report_production">
                          <p color="green" text>View More</p>
                      </router-link></v-row>
                    </v-card-title>
                    <div>
                      <PolarChart />
                    </div>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="12" md="4" class="align-center">
                <v-hover v-slot="{ hover }" open-delay="200">
                  <v-card
                    :elevation="hover ? 20 : 2"
                    :class="{ 'on-hover': hover }"
                    width="100%"
                    height="400"
                  >
                    <v-card-title primary-title>
                      <v-row class="mx-2 justify-space-between">
                        <p style="font-weight:bold">ສິນຄ້າທີ່ນິຍົມສັ່ງຊື້</p>
                       <router-link to="/report_production">
                          <p color="green" text> View More</p>
                      </router-link></v-row>
                    </v-card-title>
                    <pie-chart
                      height="330"
                      v-if="orderproducts != null"
                      :chart-data="orderproducts"
                    ></pie-chart>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="12" md="4">
                <v-hover v-slot="{ hover }" open-delay="200">
                  <v-card
                    :elevation="hover ? 20 : 2"
                    :class="{ 'on-hover': hover }"
                    width="100%"
                    height="400"
                  >
                    <v-card-title > <v-row class="mx-2 justify-space-between">
                        <p style="font-weight:bold">ສິນຄ້າທີ່ໄດ້ຂາຍດີ</p>
                       <router-link to="/report_production">
                          <p color="green" text> View More</p>
                      </router-link></v-row>
                    </v-card-title>
                    <v-card-subtitle>5 ລາຍການ</v-card-subtitle>
      <apexchart type="bar" height="300" :options="topproducts.chartOptions" :series="topproducts.series"></apexchart>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        
      </div>
    </v-card>
    <!-- __________________________________________________________user____________________________________ -->
    <v-card color="#fafafa" elevation="0" v-else>
      <v-card
        :img="fectEmpImg(this.$store.state.authMod.token.image)"
        color="#fafafa"
        elevation="0"
        v-ripple="{ class: `white--text` }"
      >
        <v-row class="justify-center ma-2">
          <v-avatar size="400" outline color="green">
            <img class="ma-2" :src="fectEmpImg(this.$store.state.authMod.token.image)" alt="Riam" />
          </v-avatar>
        </v-row>
      </v-card>
      <v-row class="justify-space-around ma-2">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <h3>ຊື່ ແລະ ນານສະກຸນ: {{this.$store.state.authMod.token.emp_name}} {{this.$store.state.authMod.token.emp_surname}}</h3>
        <v-spacer></v-spacer>
        <v-divider vertical></v-divider>
        <v-spacer></v-spacer>
        <h3>ເບີ້ໂທ: {{this.$store.state.authMod.token.tel}}</h3>
        <v-spacer></v-spacer>
        <v-divider vertical></v-divider>
        <v-spacer></v-spacer>
        <h3>ອີມິວ: {{this.$store.state.authMod.token.email}}</h3>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
      </v-row>
        <v-divider
          class=""
        ></v-divider>
        <v-divider
          class=""
        ></v-divider>
      <v-row no-gutter class="justify-center mt-5">
        <v-col cols="12" md="3" class="align-center">
          <v-hover v-slot="{ hover }" open-delay="200" 
          v-ripple="{ class: `white--text` }">
            <v-card
              :elevation="hover ? 20 : 2"
              :class="{ 'on-hover': hover }"
              width="100%"
              height="400"
            >
              <v-card-title primary-title>
                <p>ປະຫວັດຍອດຂາຍສິນຄ້າລວມ</p>
              </v-card-title>
               <apexchart type="radialBar" height="380" :options="empSellTodal.chartOptions" :series="empSellTodal.series"></apexchart>
            </v-card>
          </v-hover>
        </v-col>

        <v-col cols="12" md="3">
          <v-hover v-slot="{ hover }" open-delay="200" v-ripple="{ class: `white--text` }">
            <v-card
              :elevation="hover ? 20 : 2"
              :class="{ 'on-hover': hover }"
              width="100%"
              height="400"
            >
              <v-card-title primary-title>
                <p>ປະຫວັດຍອດຮັບສັ່ງຊື້ສິນຄ້າລວມ</p>
              </v-card-title>
              <div>
                 <apexchart type="radialBar" height="380" :options="empBuyTodal.chartOptions" :series="empBuyTodal.series"></apexchart>
              </div>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" md="3">
          <v-hover v-slot="{ hover }" open-delay="200" v-ripple="{ class: `white--text` }">
            <v-card
              :elevation="hover ? 20 : 2"
              :class="{ 'on-hover': hover }"
              width="100%"
              height="400"
            >
              <v-card-title primary-title>
                <p>ປະຫວັດຍອດຮັບສັ່ງຕັດສິນຄ້າລວມ</p>
              </v-card-title>
             <apexchart type="radialBar" height="380" :options="empCutTodal.chartOptions" :series="empCutTodal.series"></apexchart>
            </v-card>
          </v-hover>
        </v-col>
        <!-- <v-col cols="12" md="3">
          <v-hover v-slot="{ hover }" open-delay="200" v-ripple="{ class: `white--text` }">
            <v-card
              :elevation="hover ? 20 : 2"
              :class="{ 'on-hover': hover }"
              width="100%"
              height="400"
            >
              <v-card-title primary-title>
                <p>ປະຫວັດຮັບສະໝັກຕົວແທນ</p>
              </v-card-title>
               <apexchart type="radialBar" height="380" :options="empCustTodal.chartOptions" :series="empCustTodal.series"></apexchart>
            </v-card>
          </v-hover>
        </v-col> -->
      </v-row>
    </v-card>
  </div>
</template>
<script>
import LineChart from "@/components/chart/LineChart";
import CustBarChart from "@/components/chart/CustBarChart";
import PieChart from "@/components/chart/PieChart";
import PolarChart from "@/components/chart/PolarChart";
import VueApexCharts from 'vue-apexcharts'

import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      model: 0,
      datacollection: null,
      items: [
        { title: "ກຣາຟສະແດງຍອດລວມ" },
        { title: "ກຣາຟສະແດງຍອດຂາຍ" },
        { title: "ກຣາຟສະແດງຍອດສັ່ງຊື້" },
        { title: "ກຣາຟສະແດງຍອດສັ່ງຕັດ" },
      ],
      title: "ກຣາຟສະແດງຍອດລວມ",
      date_list: ["ວັນທີ່", "ເດືອນ", "ເປັນປີ"],
      selectDate: "ວັນທີ່"
    };
  },
  components: {
    LineChart,
    CustBarChart,
    PieChart,
    PolarChart,
          apexchart: VueApexCharts,
  },
  computed: {
    ...mapGetters({
      topproducts: "dashMod/products",
      protodal: "dashMod/protodal",
      selltodal: "dashMod/selltodal",
      emptodal: "dashMod/emptodal",
      custtodal: "dashMod/custtodal",
      cuttodal: "dashMod/ordcuttodal",
      empCutTodal:"dashMod/empCutTodal",
      empBuyTodal:"dashMod/empBuyTodal",
      empSellTodal:"dashMod/empSellTodal",
      empCustTodal:"dashMod/empCustTodal",
      orderproducts: "dashMod/orderproducts",
      list: "dashMod/list",
      importtodal: "reportMod/importtodal"
    })
  },
  watch: {
    title(title) {
      if (title == "ກຣາຟສະແດງຍອດລວມ") {
        if (this.selectDate == "ວັນທີ່") {
          this.getAllDay();
        }
        if (this.selectDate == "ເດືອນ") {
          this.getAllMonth();
        }
        if (this.selectDate == "ເປັນປີ") {
          this.getAllYear();
        }
      }
      if (title == "ກຣາຟສະແດງຍອດຂາຍ") {
        if (this.selectDate == "ວັນທີ່") {
          this.getSellDay();
        }

        if (this.selectDate == "ເດືອນ") {
          this.getSellMonth();
        }
        if (this.selectDate == "ເປັນປີ") {
          this.getSellYear();
        }
      }
      if (title == "ກຣາຟສະແດງຍອດສັ່ງຕັດ") {
        if (this.selectDate == "ວັນທີ່") {
          this.getCutDay();
        }

        if (this.selectDate == "ເດືອນ") {
          this.getCutMonth();
        }
        if (this.selectDate == "ເປັນປີ") {
          this.getCutYear();
        }
      }
      if (title == "ກຣາຟສະແດງຍອດສັ່ງຊື້") {
        if (this.selectDate == "ວັນທີ່") {
          this.getOrderDay();
        }

        if (this.selectDate == "ເດືອນ") {
          this.getOrderMonth();
        }
        if (this.selectDate == "ເປັນປີ") {
          this.getOrderYear();
        }
      }
    },
    selectDate(date) {
      if (date == "ວັນທີ່") {
        if (this.title == "ກຣາຟສະແດງຍອດລວມ") {
          this.getAllDay();
        }
        if (this.title == "ກຣາຟສະແດງຍອດຂາຍ") {
          this.getSellDay();
        }
        if (this.title == "ກຣາຟສະແດງຍອດສັ່ງຊື້") {
          this.getOrderDay();
        }
        if (this.title == "ກຣາຟສະແດງຍອດສັ່ງຕັດ") {
          this.getCutDay();
        }
      }
      if (date == "ເດືອນ") {
        if (this.title == "ກຣາຟສະແດງຍອດລວມ") {
          this.getAllMonth();
        }
        if (this.title == "ກຣາຟສະແດງຍອດຂາຍ") {
          this.getSellMonth();
        }
        if (this.title == "ກຣາຟສະແດງຍອດສັ່ງຊື້") {
          this.getOrderMonth();
        }
        if (this.title == "ກຣາຟສະແດງຍອດສັ່ງຕັດ") {
          this.getCutMonth();
        }
      }
      if (date == "ເປັນປີ") {
        if (this.title == "ກຣາຟສະແດງຍອດລວມ") {
          this.getAllYear();
        }
        if (this.title == "ກຣາຟສະແດງຍອດຂາຍ") {
          this.getSellYear();
        }
        if (this.title == "ກຣາຟສະແດງຍອດສັ່ງຊື້") {
          this.getOrderYear();
        }
        if (this.title == "ກຣາຟສະແດງຍອດສັ່ງຕັດ") {
          this.getCutYear();
        }
      }
    }
  },
  mounted() {
    this.fillData();
    this.getTodalImport();
    this.getTopPro();
    this.getTopOrderPro();
    this.getTodalPro();
    this.getTodalSell();
    this.getTodalEmp();
    this.getTodalCust();
    this.getTodalCut();
    this.getTodalCutEmp(this.$store.state.authMod.token.emp_id)
    this.getTodalBuyEmp(this.$store.state.authMod.token.emp_id)
    this.getTodalSellEmp(this.$store.state.authMod.token.emp_id)
    this.getTodalCustEmp(this.$store.state.authMod.token.emp_id)
    this.getAllDay();
    // this.getBestProFucture()

    this.getNewOrder();
    this.getCustnewMember();
  },
  methods: {
    ...mapActions("dashMod", [
      "getTopPro",
      "getTopOrderPro",
      "getTodalPro",
      "getTodalSell",
      "getTodalEmp",
      "getTodalCust",
      "getTodalCut",
      "getTodalCutEmp",
      "getTodalBuyEmp",
      "getTodalSellEmp",
      "getTodalCustEmp",
      "getSellMonth",
      "getOrderMonth",
      "getCutMonth",
      "getAllMonth",
      "getSellYear",
      "getOrderYear",
      "getCutYear",
      "getAllYear",
      "getSellWeek",
      "getSellDay",
      "getOrderDay",
      "getCutDay",
      "getAllDay",
      "getBestProFucture"
    ]),
    ...mapActions("orderMod", ["getNewOrder"]),
    ...mapActions("customerMod", ["getCustnewMember"]),
    ...mapActions("reportMod", ["getTodalImport"]),

    SwitchMenu(title) {
      this.title = title;
    },
    fectEmpImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/Employees/" + img);
      } else {
        imageUrl = require("@/assets/logo.png");
      }
      return imageUrl;
    },
    fillData() {
      this.datacollection = {
        labels: [
          "January",
          "Feebruary",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "Octorber",
          "November",
          "December"
        ],
        datasets: [
          {
            label: "ຍອດສັ່ງຕັດ",
            pointBackgroundColor: "#99f",
            borderWidth: 1,
            pointBorderColor: "#0ff",
            data: this.getRandomInt(),
            borderColor: ["#1f9"],
            // borderWidth:2,
            backgroundColor: ["rgba(00,250,250,0.7)"]
          },
          {
            label: "ຍອດສັ່ງຊີ້",
            pointBackgroundColor: "#99f",
            borderWidth: 1,
            pointBorderColor: "#0ff",
            data: this.getRandomInt(),
            borderColor: ["#1f9"],
            // borderWidth:2,
            backgroundColor: ["rgba(00,250,25,0.7)"]
          },
          {
            label: "ຍອດຂາຍ",
            pointBackgroundColor: "#99f",
            borderWidth: 1,
            pointBorderColor: "#0ff",
            data: this.getRandomInt(),
            borderColor: ["#1f9"],
            // borderWidth:2,
            backgroundColor: ["rgba(250,250,25,0.7)"]
          }
        ]
      };
    },
    getRandomInt() {
      let ramdoms = [];
      for (let index = 0; index < 12; index++) {
        ramdoms.push(Math.floor(Math.random() * (50000 - 5 + 1)) + 5);
      }
      return ramdoms;
    }
  }
};
</script>
<style scoped>
p{
  font-size:1.1rem;
}
.small {
  max-width: 600px;
  margin: 150px auto;
}
.card {
  max-width: 100%;
  width: 100%;
  min-width: 240px;
  margin: 7px 2px;
  padding: 5px 3px;
}
.x {
  width: auto;
  flex-wrap: nowrap;
  display: inline-flex;
  overflow-y: hidden;
}
a:link{
     text-decoration: none;
  color: green;
  }
   a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: blue;
}

a:active {
  color: green;
}
</style>