var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.Headers,"items":_vm.import_history,"show-expand":"","search":_vm.search,"single-expand":"","expanded":_vm.expanded,"item-key":_vm.import_history.imp_id},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[_c('h2',{staticClass:"mt-5 mb-7"},[_vm._v("ປະຫວັດນໍາເຂົ້າວັດຖຸດິບ")])]),_c('v-divider'),_c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("ປະຫວັດນໍາເຂົາວັດຖຸດິບ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"ຄົ້ນຫາ","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('router-link',{attrs:{"to":"material_import"}},[_c('v-btn',{staticClass:"mx-2",staticStyle:{"font-size":"20px","font-weight":"bold"},attrs:{"color":"#ffffff","rounded":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-plus ")]),_vm._v(" ນໍາເຂົ້າວັດຖຸດິບ ")],1)],1)],1),_c('v-divider'),_c('v-divider')],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 ",attrs:{"color":"blue"},on:{"click":function($event){return _vm.editeItem(item)}}},[_vm._v(" mdi-cart-check ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.id != '' || item.id != null)?_c('v-data-table',{staticClass:"elevation-0 my-2",staticStyle:{"background":"#fafafaff"},attrs:{"headers":_vm.headersdetail,"items":item.detail,"search":_vm.search,"hide-default-footer":"","sort-by":"calories"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: "white--text" }),expression:"{ class: `white--text` }"}]},[_c('td',[_vm._v(_vm._s(item.idx))]),_c('td',[_c('v-img',{attrs:{"src":_vm.fectImg(item.image),"contain":"","height":"100","width":"70"}})],1),_c('td',[_vm._v(_vm._s(item.pro_name))]),_c('td',[_vm._v(_vm._s(item.category))]),_c('td',[_vm._v(_vm._s(item.unit))]),_c('td',[_vm._v(_vm._s(item.color))]),_c('td',[_vm._v(_vm._s(item.size))]),_c('td',[_vm._v(_vm._s(String(item.qty).replace(/(.)(?=(\d{3})+$)/g,'$1 ')))]),_c('td',[_vm._v(_vm._s(String(item.price).replace(/(.)(?=(\d{3})+$)/g,'$1 ')))])])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }