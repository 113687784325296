<template >
    <div id="chart">
        <apexchart type="bar" height="300" :options="customertop.chartOptions" :series="customertop.series"></apexchart>
      </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
          apexchart: VueApexCharts,
        },
       data() {
    return  {
          
    }
        },
         computed: {
     ...mapGetters({
         customertop:'dashMod/customertop',
       })
         },
mounted() {
    this.getTopCustomer()
  },
  methods: {
    ...mapActions('dashMod', [
      'getTopCustomer',
    ]),
  }
}
</script>