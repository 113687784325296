import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from "moment";

Vue.use(Vuex)

const ordercutModule = {
  namespaced: true,
  state: {
    OrderCut_Bill: [],
   OrderCutHistory:[],
  },
  mutations: {
    Set_OrderCuthistory(state, OrderCutHistory) {
      state.OrderCutHistory = OrderCutHistory
    },
    Set_Order_CutBill(state, OrderCut_Bill) {
      state.OrderCut_Bill = OrderCut_Bill
    },
  },
  actions: {
    async getOrderCut_History(context) {
      var cus_id = JSON.parse(localStorage.getItem("Customer_Info")).cus_id;
      var data = await axios.get(
        `http://localhost:2021/api/ordercut/get-ordercut-history/${cus_id}`
      );
      var items = [],
      money=0.0;
      for (const key in data.data) {
        if (Object.hasOwnProperty.call(data.data, key)) {
          const el = data.data[key];
          var dd = await axios.get(
            `http://localhost:2021/api/ordercut/get-ordercutdetail-history/${el.cut_id}`
          );
          var detailarray = []
          money=0.0;
          for (const key in dd.data) {
            const list = dd.data[key];
            var detailList = {
              No: parseInt(key) + 1,
              pro_name: list.pro_name,
              qty: list.qty,
              price:list.price,
              color: list.color,
              size: list.size,
              unit:list.unit,
              discount:list.discount,
              detail: list.detail,
              image: list.image,
            };
            detailarray.push(detailList);
            money=money+(parseInt(list.qty)*parseFloat(list.price)-parseInt(list.qty)*parseFloat(list.price)*parseFloat(list.discount)/100)
          }
          this.item = {
            OrderNO: parseInt(key) + 1,
            OrderID: el.cut_id,
            OrderDate: moment(String(el.order_cut_date)).format("DD-MM-YYYY"),
            FinishDate: moment(String(el.Enddate)).format("DD-MM-YYYY"),
            OrderStatus: el.status,
            money_pay: el.money_pay,
            discount:detailarray[0].discount,
            OrderCut_List: detailarray,
            todalMoney:money-parseFloat(el.money_pay)
          };
          items.push(this.item);
        }
      }
      context.commit('Set_OrderCuthistory',items)
     // alert(JSON.stringify(items))
    },

    async getOrderCutBill(context, orderID) {
      try {
      //  alert(JSON.stringify(orderID))
        var data = await axios.get(
          `http://localhost:2021/api/ordercut/get-ordercut-bill/${orderID}`
        );
      //  alert(JSON.stringify(data.data))
        var items = [],
        money=0.0;
        for (const key in data.data) {
          if (Object.hasOwnProperty.call(data.data, key)) {
            const el = data.data[key];
            var dd = await axios.get(
              `http://localhost:2021/api/ordercut/get-ordercutdetail-history/${el.cut_id}`
            );
            var detailarray = []
            money=0.0;
            var sum=0
            for (const key in dd.data) {
              const list = dd.data[key];
              var detailList = {
                No: parseInt(key) + 1,
                pro_name: list.pro_name,
                qty: list.qty,
                price:list.price,
                color: list.color,
                size: list.size,
                unit:list.unit,
                discount:list.discount,
                detail: list.detail,
                image: list.image,
              };
              detailarray.push(detailList);
              money=money+(parseInt(list.qty)*parseFloat(list.price)-parseInt(list.qty)*parseFloat(list.price)*parseFloat(list.discount)/100)
              sum=sum+parseInt(list.qty)*parseFloat(list.price)
            }
            this.item = {
              OrderID: el.cut_id,
              OrderDate: moment(String(el.order_cut_date)).format("DD-MM-YYYY"),
              order_cut_time: el.order_cut_time,
              OrderStatus: el.status,
              money_pay: String(el.money_pay).replace(/(.)(?=(\d{3})+$)/g, "$1,"),
              discount:detailarray[0].discount,
              details: detailarray,
              todalMoney:String(money-parseFloat(el.money_pay)).replace(/(.)(?=(\d{3})+$)/g, "$1,"),
              total:String(sum).replace(/(.)(?=(\d{3})+$)/g, "$1,")
            };
            items.push(this.item);
          }
        }
     //  alert(JSON.stringify(items))
        context.commit('Set_Order_CutBill',items)
      } catch (e) {
        alert(JSON.stringify(e));
      }
    },
    
  },
  getters: {
   OrderCutHistory: state => state.OrderCutHistory,
   OrderCut_Bill:state => state.OrderCut_Bill,
  }
}

export default ordercutModule;

