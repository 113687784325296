import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import { header } from '../../config/constant'

Vue.use(Vuex)

const productModule = {
  namespaced: true,
  state: {
    category: [],
    cate_names:[],
    unit: [],
    unit_names:[],
    products: [],
    proEdit:[],
    proImg:[]
  },
  mutations: {
    setCategory(state, cate) {
      var cateList = []
      for (const key in cate) {
        var el = {
          idx: parseInt(key, 10)+1,
          id: cate[key].cate_id,
          name: cate[key].cate_name,
        }
        cateList.push(el)
      }
      state.category = cateList
    },
    setCategoryName(state, cate) {
      state.cate_names = cate
    },
    setUnit(state, unit) {
      var unitList = []
      for (const key in unit) {
        var el = {
          idx: parseInt(key, 10) + 1,
          id: unit[key].unit_id,
          name: unit[key].unit_name,
        }
        unitList.push(el)
      }
      state.unit = unitList
    },
    setUnitName(state, unit) {
      state.unit_names = unit
    },
    setProduct(state, pro) {
      state.products = pro
    },
    setProByone(state, pro) {
      state.proEdit = pro
    },
    setProImage(state, img) {
      state.proImg = img
    }
  },

  actions: {
    // _______________________________________________________Category_________________________________________________________
    async getCategory(context) {
      var response = await axios.get(`http://localhost:2021/api/dadabackend/category/getall`,{
        headers:header
      })
      var cateList=[]
      for (const key in response.data) {
        const element = response.data[key];
        cateList.push(element.cate_name);
      }
      context.commit('setCategory', response.data)
      context.commit('setCategoryName',cateList)
    },
    
    async delCategory(context, delItem) {
      try {
        await axios.post(`http://localhost:2021/api/dadabackend/category/delete`, delItem,{
          headers:header
        })
        context.dispatch('getCategory')
      } catch (e) {
        this.errors.push(e)
      }
    },
    async InsertCategory(context, insertItem) {
      try {
//  alert(JSON.stringify(insertItem))
        await axios.post(
          `http://localhost:2021/api/dadabackend/category/insert`,
          insertItem,{
            headers:header
          }
        );
        context.dispatch('getCategory')
      } catch (e) {
        this.errors.push(e)
      }
    },
    async UpdateCategory(context, updateItem) {
      try {

        await axios.post(
          `http://localhost:2021/api/dadabackend/category/update`,
          updateItem,{
            headers:header
          }
        );
        context.dispatch('getCategory')
      } catch (e) {
        this.errors.push(e)
      }
    },

    // _________________________________________________Unit________________________________________________________
    async getUnit(context) {
      var response = await axios.get(`http://localhost:2021/api/dadabackend/unit/getall`,{
        headers:header
      })
      var unitList=[]
      for (const key in response.data) {
        const element = response.data[key];
        unitList.push(element.unit_name);
      }
      context.commit('setUnit', response.data)
      context.commit('setUnitName', unitList)
     // alert(JSON.stringify(unitList))
    },
    async delUnit(context, delItem) {
      try {
        await axios.post(`http://localhost:2021/api/dadabackend/unit/delete`, delItem,{
          headers:header
        })
        context.dispatch('getUnit')
      } catch (e) {
        this.errors.push(e)
      }
    },
    async InsertUnit(context, insertItem) {
     // alert(JSON.stringify(insertItem))
      try {

        await axios.post(
          `http://localhost:2021/api/dadabackend/unit/insert`,
          insertItem,{
            headers:header
          }
        );
        context.dispatch('getUnit')
      } catch (e) {
        this.errors.push(e)
      }
    },
    async UpdateUnit(context, updateItem) {
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/unit/update`,
          updateItem,{
            headers:header
          }
        );
        context.dispatch('getUnit')
      } catch (e) {
        this.errors.push(e)
      }
    },

    // __________________________________________________________product___________________________________________________________________
    async getProduct(context) {
      try {
        var productList = []
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/product/getall`,{
            headers:header
          }
        );
        for (const key in response.data) {
          const element = response.data[key];
          var imglist = []
              const img = await axios.get(
                `http://localhost:2021/api/dadabackend/product/image/getById/${element.pro_id}`,{
                  headers:header
                }
              );
              imglist = img.data;
          var products = {
            idx: parseInt(key, 10)+1,
            id: element.pro_id,
            name: element.pro_name,
            category: element.cate_name,
            unit: element.unit_name,
            color: element.color,
            size: element.size,
            qty: element.qty,
            price: element.price,
            status: element.status,
            create_date: moment(String(element.create_date)).format('YYYY-MM-DD'),
            image: imglist
          };
          productList.push(products);
        }
        context.commit('setProduct', productList)
      } catch (e) {
        this.errors.push(e);
      }
    },

    async getProByone(context,id) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/product/getById/${id}`,{
            headers:header
          }
        );
          const element = response.data[0];
           var products = {
            id: element.pro_id,
            name: element.pro_name,
            category: element.cate_name,
            unit: element.unit_name,
            color: element.color,
            size: element.size,
            qty: element.qty,
            price: element.price,
            status: element.status,
            create_date: moment(String(element.create_date)).format('YYYY-MM-DD'),
          };
        context.commit('setProByone', products)

        const img = await axios.get(
          `http://localhost:2021/api/dadabackend/product/image/getById/${id}`,{
            headers:header
          }
        );
        var list=[]
        img.data.forEach(el => {
          list.push(el.img_url)
        });
        context.commit('setProImage', list)
      } catch (e) {
        this.errors.push(e);
      }
    },

    async delProduct(context, delItem) {
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/product/delete`,
          delItem,{
            headers:header
          }
        );
        await axios.post(
          `http://localhost:2021/api/dadabackend/product/images/delete`, delItem,{
            headers:header
          });
        context.dispatch('getProduct')
      } catch (e) {
        this.errors.push(e)
      }
    },
    async InsertProduct(context, insertItem) {
      try {
       // alert(JSON.stringify(insertItem))
        await axios.post(
          `http://localhost:2021/api/dadabackend/product/insert`,
          insertItem,{
            headers:header
          }
        );
        context.dispatch('getProduct')
      } catch (e) {
        this.errors.push(e)
      }
    },
    
    async InsertImage(context, img) {
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/product/image/insert`,
          img,{
            headers:header
          }
        );
        context.dispatch('getProduct')
      } catch (e) {
        this.errors.push(e)
      }
    },
    //********************************************Insert Image When Receive Cut***********************/
    async Insert_Image(context, img) {
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/product/image-insert`,
          img,{
            headers:header
          }
        );
        context.dispatch('getProduct')
      } catch (e) {
        this.errors.push(e)
      }
    },

    async UpdateProduct(context, updateItem) {
     // alert(JSON.stringify(updateItem))
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/product/update`,
          updateItem,{
            headers:header
          }
        );
        context.dispatch('getProduct')
      } catch (e) {
        this.errors.push(e)
      }
    },
     UpdateImage(context, img) {
      try {
         axios.post(
          `http://localhost:2021/api/dadabackend/product/images/delete`,
          img,{
            headers:header
          }
        ).then(res=>{
          if(res.length===0) alert("res.length===0")
          axios.post(
            `http://localhost:2021/api/dadabackend/product/image/insert`,
            img,{
              headers:header
            }).then(res=>{
              if(res.length===0) alert("res.length===0")
              context.dispatch('getProduct')
            })
        })
      } catch (e) {
        this.errors.push(e)
      }
    },
    async delImages(context, img) {
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/product/images/delete`,
          img,{
            headers:header
          }
        )
              context.dispatch('getProduct')
      } catch (e) {
        this.errors.push(e)
      }
    },
    async delImage(context, img) {
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/product/image/delete`,
          img,{
            headers:header
          }
        )
              context.dispatch('getProduct')
      } catch (e) {
        this.errors.push(e)
      }
    },

  },
  getters: {
    category: state => state.category,
    cate_names: state => state.cate_names,
    unit: state => state.unit,
    unit_names: state => state.unit_names,
    products: state => state.products,
    proEdit: state => state.proEdit,
    proImg: state =>state.proImg
  }
}

export default productModule;

