<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top:2px; padding-bottom:10px">
            <v-row no-gutters class="justify-center">
              <h2 style="margin-top:20px;">ປັບປຸງຂໍ້ມູນສິນຄ້າ</h2>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    v-model="products.id"
                    :error-messages="IDErrors"
                    readonly
                    :counter="100"
                    label="ລະຫັດສິນຄ້າ"
                    required
                    @input="$v.products.id.$touch()"
                    @blur="$v.products.id.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="products.name"
                    :error-messages="NameErrors"
                    :counter="100"
                    label="ຊື່ສິນຄ້າ"
                    required
                    @input="$v.products.name.$touch()"
                    @blur="$v.products.name.$touch()"
                  ></v-text-field>
                  <v-autocomplete
                    v-model="products.category"
                    :items="cate_names"
                    :error-messages="CategoryErrors"
                    label="ເລຶອກປະເພດສິນຄ້າ"
                    allow-overflow
                    required
                    @input="$v.products.category.$touch()"
                    @blur="$v.products.category.$touch()"
                  ></v-autocomplete>
                  <v-text-field
                    v-model="products.color"
                    :error-messages="ColorErrors"
                    :counter="100"
                    label="ສີ"
                    required
                    @input="$v.products.color.$touch()"
                    @blur="$v.products.color.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="products.size"
                    :error-messages="SizeErrors"
                    label="ຂະໜາດ"
                    counter
                    required
                    @input="$v.products.size.$touch()"
                    @blur="$v.products.size.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="products.qty"
                    :error-messages="QtyErrors"
                    label="ຈໍານວນສິນຄ້າ"
                    required
                    @input="$v.products.qty.$touch()"
                    @blur="$v.products.qty.$touch()"
                    @keypress="number($event)"
                  ></v-text-field>
                  <v-autocomplete
                    v-model="products.unit"
                    :error-messages="UnitErrors"
                    :items="unit_names"
                    label="ເລຶອກຫົວໜ່ວຍສິນຄ້າ"
                    required
                    @input="$v.products.unit.$touch()"
                    @blur="$v.products.unit.$touch()"
                  ></v-autocomplete>

                  <v-text-field
                    v-model="products.price"
                    :error-messages="PriceErrors"
                    label="ລາຄາສິນຄ້າ"
                    required
                    @input="$v.products.price.$touch()"
                    @blur="$v.products.price.$touch()"
                    @keypress="number($event)"
                  ></v-text-field>

                  <v-radio-group
                    v-model="products.status"
                    row
                    label="ສະຖານະສິນຄ້າ"
                    required
                    :error-messages="StatusErrors"
                    @change="$v.products.status.$touch()"
                    @blur="$v.products.status.$touch()"
                  >
                    <v-row class="justify-space-around">
                      <v-radio label="ສິນຄ້າເກົ່າ" value="Old"></v-radio>
                      <v-radio label="ສິນຄ້າໃໝ່" value="New"></v-radio>
                        <v-radio label="ສິນຄ້າຕົວແບບ" value="Prototype"></v-radio>
                    </v-row>
                  </v-radio-group>
                  <v-divider style="margin-top:-10px" class></v-divider>
                  <h4 class="mt-5" style="font-size: 17px; font-weight: normal;">ເລຶອກຮູບສິນຄ້າ</h4>
                  <input
                    type="file"
                    class="file-upload"
                    multiple
                    ref="file"
                    @change="onFileSelected"
                    label="Image input"
                  />
                  <v-carousel
                    v-if="upimages.length>0"
                    show-arrows-on-hover
                    hide-delimiters
                    height="auto"
                    class="grey lighten-3"
                    v-ripple="{ class: `white--text` }"
                  >
                    <v-carousel-item v-for="(img, i) in upimages" :key="i">
                      <v-card class="mx-auto grey lighten-5" height="100%" width="100%">
                        <v-img :src="fectImg(img)" contain height="450" aspect-ratio="1" transition>
                          <template v-slot>
                            <div>
                              <v-col class="align-end">
                                <v-row class="ma-1 align-end justify-end">
                                  <v-btn
                                    class
                                    @click="RemoveAll"
                                    large
                                    color="error"
                                    icon
                                  >
                                    <v-icon large color="error">mdi-delete</v-icon>
                                  </v-btn>
                                </v-row>
                                <v-row class="ma-1 align-end justify-end">
                                  <v-btn class large icon @click="RemoveOne(upimages[i])">
                                    <v-icon large color="red">mdi-close-box-multiple</v-icon>
                                  </v-btn>
                                </v-row>
                              </v-col>
                            </div>
                          </template>
                        </v-img>
                      </v-card>
                    </v-carousel-item>
                  </v-carousel>
                  <v-carousel
                    v-else
                    show-arrows-on-hover
                    hide-delimiters
                    height="auto"
                    class="grey lighten-3"
                    v-ripple="{ class: `white--text` }"
                  >
                    <v-carousel-item v-for="(img, i) in proImg" :key="i">
                      <v-card class="mx-auto grey lighten-5" height="100%" width="100%">
                        <v-img :src="fectImg(img)" contain height="450" aspect-ratio="1" transition>
                          <template v-slot>
                            <div>
                              <v-col class="align-end">
                                <v-row class="ma-1">
                                  <v-card class="imgtitle">
                                    <h2>{{ proImg[i] }}</h2>
                                  </v-card>
                                </v-row>
                                <v-row class="ma-1 align-end justify-end">
                                  <v-btn
                                    class
                                    @click="RemoveAll"
                                    large
                                    color="error"
                                    style="margin-top:-100px"
                                    icon
                                  >
                                    <v-icon large color="error">mdi-delete</v-icon>
                                  </v-btn>
                                </v-row>
                                <v-row class="ma-1 align-end justify-end">
                                  <v-btn class large icon @click="RemoveOne(proImg[i])">
                                    <v-icon large color="red">mdi-close-box-multiple</v-icon>
                                  </v-btn>
                                </v-row>
                              </v-col>
                            </div>
                          </template>
                        </v-img>
                      </v-card>
                    </v-carousel-item>
                  </v-carousel>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row class="ma-5">
              <v-btn @click="back" class="ma-2" style="font-size: 20px; font-weight: bold" color="deep-purple lighten-4" rounded>
                <v-icon left large>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າສິນຄ້າ
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn @click="clear" class="ma-2" style="font-size: 20px; font-weight: bold" color="#FF8A65" rounded>
                <v-icon left large>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn @click="submit" class="ma-2" style="font-size: 20px; font-weight: bold" color="#1DE9B6" rounded>
                <v-icon left large>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  mixins: [validationMixin],

  validations: {
    products: {
      id: { required, maxLength: maxLength(100) },
      name: { required, maxLength: maxLength(100) },
      category: { required, maxLength: maxLength(100) },
      unit: { required, maxLength: maxLength(100) },
      color: { required, maxLength: maxLength(20) },
      size: { required, maxLength: maxLength(100) },
      qty: { required, maxLength: maxLength(20) },
      price: { required, maxLength: maxLength(100) },
      status: { required }
    },
    checkbox: {
      checked(val) {
        return val;
      }
    }
  },

  data: () => ({
    CategoryDialog: false,
    CategoryDelete: false,
    CategoryShowEdit: false,
    Categorysearch: "",
    UnitDialog: false,
    UnitDelete: false,
    UnitShowEdit: false,
    Categoryheaders: [
      { text: "ລໍາດັບ", value: "cate_id" },
      { text: "Category", value: "cate_name" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    category: [],
    categoryEdit: [],
    CategoryDeleteItem: [],
    cateList: [],
    Unit: [],
    UnitEdit: [],
    UnitDeleteItem: [],
    unitList: [],
    upimages: []
  }),

  computed: {
    ...mapGetters({
      products: "productMod/proEdit",
      proImg: "productMod/proImg",
      cate_names: "productMod/cate_names",
      unit_names: "productMod/unit_names",
    }),

     
    IDErrors() {
      const errors = [];
      if (!this.$v.products.id.$dirty) return errors;
      !this.$v.products.id.maxLength &&
        errors.push("ID must be at most 100 characters long");
      !this.$v.products.id.required && errors.push("ID is required.");
      return errors;
    },
    NameErrors() {
      const errors = [];
      if (!this.$v.products.name.$dirty) return errors;
      !this.$v.products.name.maxLength &&
        errors.push("Name must be at most 100 characters long");
      !this.$v.products.name.required && errors.push("Name is required.");
      return errors;
    },
    CategoryErrors() {
      const errors = [];
      if (!this.$v.products.category.$dirty) return errors;
      !this.$v.products.category.maxLength &&
        errors.push("category must be at most 100 characters long");
      !this.$v.products.category.required &&
        errors.push("category is required.");
      return errors;
    },
    UnitErrors() {
      const errors = [];
      if (!this.$v.products.unit.$dirty) return errors;
      !this.$v.products.unit.maxLength &&
        errors.push("unit must be at most 20 characters long");
      !this.$v.products.unit.required && errors.push("unit is required.");
      return errors;
    },
    ColorErrors() {
      const errors = [];
      if (!this.$v.products.color.$dirty) return errors;
      !this.$v.products.color.maxLength &&
        errors.push("color must be at most 100 characters long");
      !this.$v.products.color.required && errors.push("color is required.");
      return errors;
    },

    SizeErrors() {
      const errors = [];
      if (!this.$v.products.size.$dirty) return errors;
      !this.$v.products.size.maxLength &&
        errors.push("Size Must be at most 100 characters long");
      !this.$v.products.size.required && errors.push("Size is required");
      return errors;
    },
    QtyErrors() {
      const errors = [];
      if (!this.$v.products.qty.$dirty) return errors;
      !this.$v.products.qty.maxLength &&
        errors.push("Qty must be at most 20 characters long");
      !this.$v.products.qty.required && errors.push("Qty is required.");
      return errors;
    },

    PriceErrors() {
      const errors = [];
      if (!this.$v.products.price.$dirty) return errors;
      !this.$v.products.price.maxLength &&
        errors.push("Price must be at most 100 characters long");
      !this.$v.products.price.required && errors.push("Price is required.");
      return errors;
    },
    StatusErrors() {
      const errors = [];
      if (!this.$v.products.status.$dirty) return errors;
      !this.$v.products.status.required &&
        errors.push("You must Check Status!");
      return errors;
    }
  },
  mounted() {
    this.getCategory()
    this.getUnit()
    this.getProByone(this.$route.params.id);
  },
  methods: {
    ...mapActions("productMod", [
      "getProByone",
      "UpdateProduct",
      "UpdateImage",
      "delImages",
      "delImage",
      "getCategory",
      "getUnit"
    ]),
    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
       // alert("Enter Only Number ? (0-9)");
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onFileSelected(event) {
      this.upimages = [];
      var list = [];
      if (event.target.files.length < 1) return;
      for (let idx = 0; idx < event.target.files.length; idx++) {
        const el = event.target.files[idx].name;
        list.push(el);
      }
      this.upimages = list;
    },
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
    async RemoveAll() {
      var images = {
        id: this.products.id,
        images: this.images
      };
      this.delImages(images);
      this.getProByone(this.$route.params.id);
    },
    RemoveOne(img) {
      var image = {
        id: this.products.id,
        image: img
      };
      this.delImage(image);
      this.getProByone(this.$route.params.id);
    },
    async submit() {
      this.$v.$touch();
      try {
        if (
          this.products.id == "" ||
          this.products.id == null ||
          this.products.id.length > 100
        ) {
          alert(this.products.create_date);
          return;
        }
        if (
          this.products.name == "" ||
          this.products.name == null ||
          this.products.name.length > 100
        ) {
          this.$v.$touch();
          return;
        }
        if (
          this.products.category == "" ||
          this.products.category == null ||
          this.products.category.length > 100
        ) {
          this.$v.$touch();
          return;
        }

          if (
          this.products.size == "" ||
          this.products.size == null ||
          this.products.size.length > 100
        ) {
          this.$v.$touch();
          return;
        }

        if (
          this.products.unit == "" ||
          this.products.unit == null ||
          this.products.unit.length > 20
        ) {
         this.$v.$touch();
          return;
        }
        if (
          this.products.color == "" ||
          this.products.color == null ||
          this.products.color.length > 20
        ) {
          this.$v.$touch();
          return;
        }
        if (this.products.status == "" || this.products.status == null) {
          this.$v.$touch();
          return;
        }
        var category_id = await axios.post(
          `http://localhost:2021/api/dadabackend/category/getByName`,
          this.products,
          {
            headers: {
              "Content-Type": "application/json",
              token: JSON.parse(window.localStorage.getItem('token'))
            }
          }
        );
        this.products.cate_id = category_id.data[0].cate_id;
        var unit_id = await axios.post(
          `http://localhost:2021/api/dadabackend/unit/getByName`,
          this.products,
          {
            headers: {
              "Content-Type": "application/json",
              token: JSON.parse(window.localStorage.getItem('token'))
            }
          }
        );
        this.products.unit_id = unit_id.data[0].unit_id;
        this.UpdateProduct(this.products);
       if(this.upimages.length>0){
          var imgs = {
          id: this.products.id,
          images: this.upimages
        };
        this.UpdateImage(imgs);
       }

        this.back();
      } catch (e) {
        this.errors.push(e);
      }
    },
    back() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.file-upload {
  margin: 7px 0px;
  font-size: 16px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #999;
}
.file-upload:hover {
  border-bottom: 1px solid #555;
}
::-webkit-file-upload-button {
  background: #eeeeee;
  border-radius: 0px 50px 50px 0px;
  padding: 7px;
  width: 180px;
  border: 1px solid #edffdd;
}
::-webkit-file-upload-button:hover {
  background: #dedede;
}

.imgtitle {
  padding: 7px;
  background: transparent;
  color: black;
  border-radius: 50px;
}

.imgtitle:hover {
  background: #44ff;
  color: white;
}
</style>