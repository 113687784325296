import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from "moment";
import { header } from '../../config/constant'
Vue.use(Vuex)

const OrderModule = {
    namespaced: true,
    state: {
      selectItemList: [],
      bills: [],
      material:[]
    },
    mutations: {
        setOrder(state, m) {
            state.material = m
        },
        setBillMaterialOrder(state, m) {
          state.bills = m
      },

    },

    actions: {
      async initialize(context) {
        var dada = await axios.get(
          `http://localhost:2021/api/dadabackend/materialorder/getall`,
          {
            headers: header
          }
        );
        var materiallist = [],
        detailList = []
        
        if(dada.data.length>0){
        for (const key in dada.data) {
          var dd = await axios.get(
            `http://localhost:2021/api/dadabackend/materialorder/detail/getById/${dada.data[key].m_order_id}`,
            {
              headers: header
            }
          );
          detailList = []
          if(dd.data.length>0){
          for (const i in dd.data) {
           var datadetail = {
             idx:parseInt(i)+1,
              product: dd.data[i].pro_name,
              category: dd.data[i].category,
              unit: dd.data[i].unit,
              color: dd.data[i].color,
              size: dd.data[i].size,
              qty: dd.data[i].qty,
              image: dd.data[i].image,
              ch: null
            };
            detailList.push(datadetail);
          }
        }
          var mater = {
            idx:parseInt(key)+1,
            id: dada.data[key].m_order_id,
            employee: dada.data[key].emp_name + " " + dada.data[key].emp_surname,
            sup_id: dada.data[key].sup_id,
            name: dada.data[key].name,
            date: moment(String(dada.data[key].order_date)).format("YYYY-MM-DD"),
            time: dada.data[key].order_time,
            detail: detailList
          };
          materiallist.push(mater);
        }
      }
        context.commit("setOrder",materiallist)
      },
      async getBillMaterialOrder(context,id) {
        var data = await axios.get(
          `http://localhost:2021/api/dadabackend/materialorder/getById/${id}`,
          {
            headers: header
          }
        );
        var detailList = []
          var dd = await axios.get(
            `http://localhost:2021/api/dadabackend/materialorder/detail/getById/${id}`,
            {
              headers: header
            }
          );
          for (const i in dd.data) {
           var datadetail = {
             idx:parseInt(i)+1,
              product: dd.data[i].pro_name,
              category: dd.data[i].category,
              unit: dd.data[i].unit,
              color: dd.data[i].color,
              size: dd.data[i].size,
              qty: dd.data[i].qty,
              image: dd.data[i].image,
            };
            detailList.push(datadetail);
          }
          var mater = {
            m_order_id: data.data[0].m_order_id,
            employee: data.data[0].emp_name + " " + data.data[0].emp_surname,
            sup_id: data.data[0].sup_id,
            name: data.data[0].name,
            date: moment(String(data.data[0].order_date)).format("DD-MM-YYYY"),
            time: data.data[0].order_time,
            detail: detailList
          };
        context.commit("setBillMaterialOrder",mater)
      },
     async SaveOrder(context,order){
        await axios.post(
          `http://localhost:2021/api/dadabackend/materialorder/insert`,order,{
              headers:header
            }
        )
      },
     async SaveOrderDetail(context,order){
        await axios.post(
          `http://localhost:2021/api/dadabackend/materialorder/detail/insert`,order,{
              headers:header
            }
        );
      },
      async DeleteOrder(context,order){
         await axios.post(
           `http://localhost:2021/api/dadabackend/materialorder/delete`,order,{
               headers:header
             }
         );
       },
      async DeleteOrderDetail(context,order){
         await axios.post(
           `http://localhost:2021/api/dadabackend/materialorder/detail/delete`,order,{
               headers:header
             }
         );
        context.dispatch('initialize')
       }
    },
    getters: {
      material: state => state.material,
      orderid: state => state.orderid,
      bills: state => state.bills,
    }
}
export default OrderModule;
