import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import { header } from '../../config/constant'

Vue.use(Vuex)

const OrderModule = {
  namespaced: true,
  state: {
    customer_order_bills: []
  },
  mutations: {
    setBillOrder_Buy(state, bill) {
      state.customer_order_bills = bill
    }
  },

  actions: {
    async getOrderBuyBill(context, orderID) {
      try {
       // alert(orderID)
        const order = await axios.get(
          `http://localhost:2021/api/order/get-orderbuy/${orderID}`
        );
        const details = await axios.get(
          `http://localhost:2021/api/order/get-orderbuydetail/${orderID}`
        );

        var productList = [],discount=0
        for (const key in details.data) {
          const element = details.data[key];
          var imglist = []
          const img = await axios.get(
            `http://localhost:2021/api/dadabackend/product/image/getById/${element.pro_id}`, {
            headers: header
          }
          );
          imglist = img.data;
          var products = {
            No: parseInt(key) + 1,
            pro_name: element.pro_name,
            qty: element.qty,
            size: element.size,
            unit_name:element.unit_name,
            price: element.price,
            discount:element.discount,
            image:imglist
          };
          discount=details.data[0].discount
          productList.push(products);
        }

        var bills = {
          order_id: order.data[0].order_id,
          order_date: moment(String(order.data[0].order_date)).format('DD-MM-YYYY'),
          order_time: order.data[0].order_time,
          discount:discount,
          detail: productList
        }
       // alert(JSON.stringify(bills))
        context.commit('setBillOrder_Buy', bills)
      } catch (e) {
        alert(JSON.stringify(e));
      }
    },

  },
  getters: {
    customer_order_bills: state => state.customer_order_bills
  }
}

export default OrderModule;

