
      <template>
  <v-card
    v-if="
      $store.state.authMod.token.status == 'Admin' ||
      $store.state.authMod.token.status == 'admin'
    "
    class="elevation-0"
  >
    <v-row justify="center">
      <h2 class="mt-5 mb-5">ຈັດການຂໍ້ມູນສິນຄ້າ</h2>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="product"
      :search="search"
      item-key="id"
      sort-by="idx"
      class="elevation-0 table"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ລາຍການຂໍ້ມູນສິນຄ້າ</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider
          ><v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ຄົ້ນຫາຂໍ້ມູນສິນຄ້າ"
            single-line
             dense
                  outlined
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <router-link to="/product_add">
            <v-btn
              class="mb-2"
              style="font-size: 20px; font-weight: bold"
              color="#ffffff"
              rounded
            >
              <v-icon large>mdi-plus</v-icon>
              ເພີ່ມສິນຄ້າ
            </v-btn></router-link
          >
        </v-toolbar>
        <v-divider></v-divider>
      </template>
      <template v-slot:item="{ item }">
        <tr v-ripple="{ class: `white--text` }">
          <td>{{ item.idx }}</td>
          <td align="center">
            <v-carousel
              v-if="item.image.length > 0"
              show-arrows-on-hover
              hide-delimiters
              height="100"
              width="70"
            >
              <v-carousel-item v-for="(img, i) in item.image" :key="i">
                <v-card class="mx-auto" height="100%" width="100">
                  <v-img :src="fectImg(img.img_url)"></v-img>
                </v-card>
              </v-carousel-item>
            </v-carousel>
            <v-card v-else height="100" width="100">
              <v-img
                src="@/assets/No_Product.png"
              ></v-img>
            </v-card>
          </td>
          <td>{{ item.name }}</td>
          <td>{{ item.category }}</td>
          <td>{{ item.color }}</td>
          <td>{{ item.size }}</td>
          <td>{{ String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }}</td>
          <td>{{ item.unit }}</td>
          <td>{{ String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.create_date }}</td>
          <td>
            <v-row>
              <v-icon @click="EditProduct(item)" class="mr-2" color="orange">
                mdi-pencil
              </v-icon>
              <v-icon color="red" @click="DeleteProduct(item)">
                mdi-delete
              </v-icon>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card rounded>
        <v-card-title
          class="ml-16"
          style="font-size: 25px; font-weight: bold; font-family: NotoSansLao"
          >ທ່ານຕ້ອງການລຶບສິນຄ້າລາຍການນີ້ບໍ...?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="closeDelete"
            style="font-size: 20px; font-family: NotoSansLao"
            >ຍົກເລິກ</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteEmpProductConfirm"
            style="font-size: 20px; font-family: NotoSansLao"
            >ຢຶນຢັນລຶບ</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    search: "",
    dialogDelete: false,
    deleteItem: [],
    headers: [
      { text: "ລໍາດັບ", value: "idx" },
      {
        text: "ຮູບ",
        align: "center",
        sortable: false,
        value: "image",
      },
      { text: "ສິນຄ້າ", value: "name" },
      { text: "ປະເພດ", value: "category" },
      { text: "ສີ", value: "color" },
      { text: "ຂະໜາດ/ເບີ້", value: "size" },
      { text: "ຈໍານວນ", value: "qty" },
      { text: "ຫົວໜ່ວຍ", value: "unit" },
      { text: "ລາຄາ(ກິບ)", value: "price" },
      { text: "ສະຖານະ", value: "status" },
      { text: "ວັນທີ່ນໍາເຂົ້າ", value: "create_date" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapGetters({
      product: "productMod/products",
    }),
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    this.getProduct();
  },
  methods: {
    ...mapActions("productMod", ["getProduct", "delProduct"]),
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
    EditProduct(item) {
      this.$router.push(`/product_edite/${item.id}`);
    },

    DeleteProduct(item) {
      this.deleteItem = item;
      this.dialogDelete = true;
    },

    deleteEmpProductConfirm() {
      this.delProduct(this.deleteItem);
      this.dialogDelete = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>
<style scoped>
.table {
  border-radius: 3px;
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
}
</style>