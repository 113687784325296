<template>
  <v-app class="font">
     <v-card class="ma-2 elevation-0">
      <v-row>
        <div class="d-flex mt-9">
          <v-avatar size="100" color="#fafafafa" class="pa-2">
            <v-img src="@/assets/images/DaDa_LoGo.png" />
          </v-avatar>
        </div>
        <v-col cols="9">
          <v-row no-gutters class="justify-center mt-5">
            <p style="font-size: 1.2rem">ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</p>
          </v-row>
          <v-row no-gutters class="justify-center">
            <p style="font-size: 1.2rem">
              ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດຖະນາຖາວອນ
            </p>
          </v-row>
          <v-row no-gutters class="justify-center mt-3">
            <p style="font-size: 2rem; text-decoration: bold">
              ລາຍງານຂໍ້ມູນສັ່ງຊື້ສິນຄ້າ
            </p>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class>
        <v-col cols="6">
          <p style="font-size: 1.4rem">ຮ້ານ ດາດ້າເເຟຊັນ</p>
          <p>ໂທລະສັບ: 020 92602326</p>

          <p>ອີເມວ: houyang0087@gmail.com</p>
          <p>ຕັ້ງຢູ່: ບ້ານ ຫົວຂົວ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ</p>
        </v-col>
        <v-col cols="6" class="align-end align-content-end">
          <v-row class="justify-end">
        <v-card class="mr-5 elevation-0">
             <p>ລາຍງານຂໍ້ມູນລະຫວ່າງວັນທີ່</p>
              <p>ວັນທີ່ເລິ່ມຕົ້ນ: {{$route.query.first_date }}</p>
              <p>ວັນທີ່ສິ້ນສຸດ: {{$route.query.end_date}}</p>
              <p>ຜູ້ລາຍງານ: {{$store.state.authMod.token.emp_name}} {{$store.state.authMod.token.emp_surname}}</p>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class>
        <v-progress-linear value="100" height="1"></v-progress-linear>
      </v-row>
      <v-row no-gutters class>
        <v-card width="100%" class="mt-2 elevation-0">
          <v-data-table
            :headers="headers"
            :items="orderreport"
            :search="search"
            item-key="id"
            sort-by="idx"
            class="elevation-0"
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr v-ripple="{ class: `white--text` }">
                <td>{{item.idx}}</td>
                <td>
                  <v-carousel class="carousel"
                    v-if="item.image.length>0"
                    show-arrows-on-hover
                    hide-delimiters
                    height="100"
                    width="70"
                  >
                    <v-carousel-item v-for="(img, i) in item.image" :key="i">
                      <v-card height="100%" width="100" :img="fectImg(img.img_url)">
                      </v-card>
                    </v-carousel-item>
                  </v-carousel>
                  <v-card v-else height="100" width="100">
                    <v-img
                      src="https://pbs.twimg.com/profile_images/1150780350932684800/LIJ11DWR.jpg"
                    ></v-img>
                  </v-card>
                </td>
                <td>{{ item.pro_name }}</td>
                <td>{{ String(parseInt(item.qty)).replace(/(.)(?=(\d{3})+$)/g,'$1,')}} {{item.unit}}</td>
                <td>{{ String(parseInt(item.price )).replace(/(.)(?=(\d{3})+$)/g,'$1,') }}</td>
                <td>{{ String(parseInt(item.sum)).replace(/(.)(?=(\d{3})+$)/g,'$1,') }}</td>
              </tr>
            </template>
          </v-data-table>
            <v-progress-linear value="100" height="1"></v-progress-linear>
          <v-divider class></v-divider>
        </v-card>
      </v-row>
       <v-row no-gutters class="mt-2 justify-end">
        <p style="font-size:1.5rem;margin-top:10px; margin-right:10px">ລວມເປັນມູນຄ່າ: {{String(parseInt(todal)).replace(/(.)(?=(\d{3})+$)/g,'$1,')}} ກິບ</p> 
      </v-row>
    </v-card>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "ລໍາດັບ", value: "idx" },
        {
          text: "ຮູບ",
          sortable: false,
          value: "image"
        },
        { text: "ສິນຄ້າ", value: "name" },
        { text: "ຈໍານວນ", value: "qty" },
        { text: "ລາຄາ(ກິບ)", value: "price" },
        { text: "ລາຄາລວມ(ກິບ)", value: "sum" }
      ]
    };
  },
  computed: {
   ...mapGetters({
      orderreport: "reportpdfMod/orderreport"
    }),
    todal(){
      var sum=0
        for (const key in this.orderreport) {
          const e = this.orderreport[key];
          sum=sum+(parseInt(e.sum))
      }
      return sum
    }
  },

  created() {
    this.getBillOrder(this.$route.query);
  },
  methods: {
    ...mapActions("reportpdfMod", ["getBillOrder"]),
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/logo.png");
      }
      return imageUrl;
    }
  }
};
</script>
<style lang="css">
.font {
  font-family: "NotoSansLao";
}
p {
  font-size: 1rem;
}
.carousel{
width: 100px;
}
</style>

    