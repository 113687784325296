
      <template>
  <v-card v-if="($store.state.authMod.token.status=='Admin') || ($store.state.authMod.token.status=='admin')">
    <v-row justify="center">
     <h2 class="mt-5 mb-7">ຈັດການຂໍ້ມູນວັດຖຸດິບ</h2>
   </v-row>
    <v-data-table
      :headers="headers"
      :items="material"
      :search="search"
      item-key="pro_name"
      sort-by="idex"
      class="elevation-2 table"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ຈັດການຂໍ້ມູນວັດຖຸດິບ</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider
          ><v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ຄົ້ນຫາ"
            single-line
               dense
                  outlined
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <router-link to="/material_add">
            <v-btn  class="mb-2" style="font-size: 20px; font-weight: bold" color="#ffffff" rounded>
               <v-icon color="black" large>mdi-plus</v-icon>
               ເພີ່ມຂໍ້ມູນວັດຖຸດິບ
            </v-btn></router-link>
        </v-toolbar>
        <v-divider
        ></v-divider>
      </template>
      <template v-slot:item="{ item }">
        <tr  v-ripple="{ class: `white--text` }" >
          <td>{{item.idx}}</td>
          <td align='center'>
            
            <v-card 
              height="100"
              width="100"
              :img="fectImg(item.image)">
            </v-card>
          </td>
          <td>{{ item.pro_name }}</td>
          <td>{{ item.category }}</td>
          <td>{{ item.color }}</td>
          <td>{{ item.size }}</td>
          <td>{{ item.unit }}</td>
          <td>
            <v-row>
              <v-icon @click="EditMaterial(item)" class="mr-2" color="orange">
                mdi-pencil
              </v-icon>
              <v-icon color="red" @click="DelMaterial(item)">
                mdi-delete
              </v-icon>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card rounded>
       <v-card-title  style="font-size: 25px; font-weight: bold;font-family:NotoSansLao">ທ່ານຕ້ອງການລຶບຂໍ້ມູນວັດຖຸດິບລາຍການນີ້ບໍ...?</v-card-title>
        <v-divider class=""></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text style="font-size: 20px;font-family:NotoSansLao" @click="dialogDelete = false">ຍົກເລິກ</v-btn>
          <v-btn color="primary" text style="font-size: 20px;font-family:NotoSansLao" @click="deleteMaterialConfirm"
            >ຢຶນຢັນລຶບ</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
export default {
  data: () => ({
    search: "",
    dialogDelete: false,
    deleteItem: [],
    headers: [
      { text: "ລໍາດັບ", value: "idex" },
      {
        text: "ຮູບ",
        align: "center",
        sortable: false,
        value: "image"
      },
      { text: "ສິນຄ້າ", value: "name" },
      { text: "ປະເພດ", value: "category" },
      { text: "ສີ", value: "color" },
      { text: "ຂະໜາດ/ເບີ້", value: "size" },
      { text: "ຫົວໜ່ວຍ", value: "unit" },
      { text: "Actions", value: "actions", sortable: false }
    ],
   
  }),

  computed: {
     ...mapGetters({
         material:'materialMod/material'
       }),

  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  mounted() {
    this.getMaterial()
  },
  methods: {
    ...mapActions('materialMod', [
      'getMaterial',
      'DeleteMaterial'
    ]),
fectImg(img){
          var imageUrl
          if (img) {
            imageUrl=require('@/assets/images/products/'+img)
          }else{
imageUrl=require('@/assets/No_Product.png')
          }
return imageUrl;
    },
    EditMaterial(item) {
      this.$router.push(`/material_edit/${item.m_id}`);
    },

    DelMaterial(item) {
      this.deleteItem = item;
      this.dialogDelete = true;
    },

    deleteMaterialConfirm() {
     this.DeleteMaterial(this.deleteItem)
      this.dialogDelete = false;
    },

  }
};
</script>
<style scoped>
.table {
  border-radius: 3px;
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
}
</style>