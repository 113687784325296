<template>
  <v-card class="mt-5 mb-5 ml-5 mr-5" v-if="this.OrderCutHistory.length > 0">
    <v-row justify="center" class="mt-3 mb-3">
      <h1 id="myfont">ປະຫວັດການສັ່ງຕັດສິນຄ້າ</h1>
    </v-row>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="OrderCutHistory"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="OrderID"
      show-expand
      class="elevation-3"
      id="head"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ປະຫວັດການສັ່ງຕັດ</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider
          ><v-spacer></v-spacer>
          <v-text-field
            id="textfield"
            dense
            outlined
            v-model="search"
            append-icon="mdi-magnify"
            label="ຄົ້ນຫາ"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            v-if="item.OrderID != '' || item.OrderID != null"
            :headers="detailHeader"
            :items="item.OrderCut_List"
            hide-default-footer
            class="elevation-2"
            id="bgColor"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.No }}</td>
                <td>
                  <v-card
                    class="mx-auto"
                    height="100"
                    width="100"
                    aspect-ratio="1.7"
                    :img="fectImg(item.image)"
                  >
                  </v-card>
                </td>
                <td>{{ item.pro_name }}</td>
                <td>{{ item.color }}</td>
                <td>{{ item.size }}</td>
                <td>{{ item.qty + " " + item.unit }}</td>
                <td>
                  {{ String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }}
                </td>
                <td>{{ item.detail }}</td>
              </tr>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>
              <v-row class="ma-5" align="center" justify="end">
                <p>
                  ລວມເປັນ:
                  {{
                    String(item.todalMoney).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                  }}
                  ກິບ
                </p>
              </v-row>
            </template>
          </v-data-table>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon large color="error" @click="PrintBill(item)">
          mdi-download
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
  <v-card v-else height="500" class="elevation-0">
    <v-row justify="center">
      <h1 id="myfont" class="mt-16">ບໍ່ມີຂໍ້ມູນ</h1></v-row
    >
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    search: "",
    expanded: [],
    singleExpand: true,
    headers: [
      {
        text: "ລໍາດັບ",
        align: "start",
        sortable: false,
        value: "OrderNO",
      },
      {
        text: "ເລກບິນສັ່ງຕັດ",
        align: "start",
        value: "OrderID",
      },
      { text: "ວັນທີ່ສັ່ງຕັດ", value: "OrderDate" },
      { text: "ວັນທີ່ຕັດສໍາເລັດ", value: "FinishDate" },
      { text: "ຄ່າມັດຈໍາ", value: "money_pay" },
      { text: "ສ່ວນຫຼຸດ", value: "discount" },
      { text: "ສະຖານະ", value: "OrderStatus" },
      { text: "ດາວໂຫຼດບິນ", value: "actions", sortable: false },
      { text: "ລາຍລະອຽດ", value: "data-table-expand" },
    ],
    detailHeader: [
      {
        text: "ລໍາດັບ",
        align: "start",
        sortable: false,
        value: "No",
      },
      { text: "ຮູບ", align: "start", value: "image" },
      {
        text: "ສິນຄ້າ",
        align: "start",
        value: "pro_name",
      },
      { text: "ສີ", value: "color" },
      { text: "ຂະໜາດ", value: "size" },
      { text: "ຈໍານວນ", value: "qty" },
      { text: "ລາຄາ", value: "price" },
      { text: "ລາຍລະອຽດ", value: "detail" },
    ],
  }),

  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters({
      OrderCutHistory: "OrderCutMod/OrderCutHistory",
    }),
  },
  mounted() {
    this.getOrderCut_History();
  },
  methods: {
    ...mapActions("OrderCutMod", ["getOrderCut_History"]),
    
    PrintBill(item) {
       if(item.OrderStatus=="Success"){
      var data = {
        OrderID: item.OrderID,
      };
      let routeData = this.$router.resolve({
        name: "bills_order_cut_product",
        query: data,
      });
      window.open(routeData.href, "_blank");
      }else{
        return
      }
    },

    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
  },
};
</script>