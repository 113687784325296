<template>
  <v-card class="mt-5 mb-5 ml-5 mr-5" v-if="this.Order_History_Item.length > 0">
    <v-row justify="center" class="mt-3 mb-3">
      <h1 id="myfont">ປະຫວັດການສັ່ງຊື້ສິນຄ້າຈາກ ຮ້ານດາດ້າແຟຊັ່ນ</h1>
    </v-row>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="Order_History_Item"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="OrderID"
      show-expand
      class="elevation-3"
      id="head"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ປະຫວັດການສັ່ງຊື້</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider
          ><v-spacer></v-spacer>
          <v-text-field
            id="textfield"
            v-model="search"
            dense
            outlined
            append-icon="mdi-magnify"
            label="ຄົ້ນຫາ"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            :headers="detailHeader"
            :items="item.OrderBuyDetail"
            hide-default-footer
            class="elevation-2"
            id="bgColor"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.No }}</td>
                <td>
                  <v-carousel hide-delimiter-background show-arrows-on-hover height="100">
                    <v-carousel-item v-for="(img, i) in item.image" :key="i">
                      <v-card
                        class="mx-auto"
                        height="100"
                        width="100"
                        aspect-ratio="1.7"
                        :img="fectImg(img.img_url)"
                      >
                      </v-card>
                    </v-carousel-item>
                  </v-carousel>
                </td>
                <td>{{ item.pro_name }}</td>
                <td>{{ item.color }}</td>
                <td>{{ item.size }}</td>
                <td>{{ item.qty }}</td>
                <td>{{ item.price }}</td>
              </tr>
            </template>
          </v-data-table>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
      <v-icon
        large
        color="error"
        @click="PrintBill(item)"
      >
        mdi-download
      </v-icon>
    </template>
    </v-data-table>
  </v-card>
  <v-card v-else height="500" class="elevation-0">
    <v-row justify="center">
      <h1 id="myfont" class="mt-16">ບໍ່ມີຂໍ້ມູນ</h1></v-row
    >
  </v-card>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  data: () => ({
    dialog: false,
    search: "",
    expanded: [],
    singleExpand: false,
    Order_History_Item: [],
    cutList: {
      OrderNO: 0,
      OrderID: 0,
      OrderDate: null,
      OrderTime: null,
      OrderStatus: "",
      OrderDetail_List: [],
    },
    headers: [
      {
        text: "ລໍາດັບ",
        align: "start",
        sortable: false,
        value: "OrderNO",
      },
      {
        text: "ເລກບິນສັ່ງຊື້",
        align: "start",
        value: "OrderID",
      },
      { text: "ວັນທີ່ສັ່ງຊື້", value: "OrderDate" },
      { text: "ເວລາສັ່ງຊື້", value: "OrderTime" },
      { text: "ສະຖານະ", value: "OrderStatus" },
      { text: "ຮູບແບບການຈ່າຍເງີນ", value: "pay_method" },
 { text: "ສ່ວນຫຼຸດ", value: "discount" },
  { text: "ດາວໂຫຼດບິນ", value: 'actions', sortable: false},
      { text: "ລາຍລະອຽດ", value: "data-table-expand" },
    ],
    detailHeader: [
      { text: "ລໍາດັບ", align: "start", value: "No" },
      {
        text: "ຮູບ",
        sortable: false,
        align: "start",
        value: "image",
      },
      { text: "ຊື່ສິນຄ້າ", value: "pro_name" },
      { text: "ສີ", value: "color" },
      { text: "ຂະໜາດ", value: "size" },
      { text: "ຈໍານວນ", value: "qty" },
      { text: "ລາຄາ", value: "price" },
    ],
  }),
  created() {
    this.initialize();
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      var cus_id = JSON.parse(localStorage.getItem("Customer_Info")).cus_id;
      var data = await axios.get(
        `http://localhost:2021/api/order/get-orderbuy-history/${cus_id}`
      );
      var items = [];
      for (const key in data.data) {
        if (Object.hasOwnProperty.call(data.data, key)) {
          const el = data.data[key];
          var dd = await axios.get(
            `http://localhost:2021/api/order/get-orderbuydetail-history/${el.order_id}`
          );
          var detailarray = [];
          var discount=0
          for (const keys in dd.data) {
            var imglist = [];
            const list = dd.data[keys];
            const img = await axios.get(
                  `http://localhost:2021/api/product/image/getById/${list.img_id}`
                );
                imglist = img.data;
            var detailList = {
              No: parseInt(keys) + 1,
              pro_name: list.pro_name,
              qty: list.qty,
              color: list.color,
              size: list.size,
              price: String(list.price).replace(/(.)(?=(\d{3})+$)/g, "$1 "),
              image: imglist,
            };
            discount=list.discount
            detailarray.push(detailList);
          }

          this.OrderList = {
            OrderNO: parseInt(key) + 1,
            OrderID: el.order_id,
            OrderDate: moment(String(el.order_date)).format("DD-MM-YYYY"),
            OrderTime: el.order_time,
            OrderStatus: el.status,
            pay_method: el.pay_method,
            discount:discount,
            OrderBuyDetail: detailarray,
          };
          items.push(this.OrderList);
        }
        this.Order_History_Item = items;
        // alert(JSON.stringify(imglist.data));
      }
    },
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
    PrintBill(item){
      if(item.OrderStatus=="Success"){
      var data = {
        orderID: item.OrderID,
      };
    //  alert(item.OrderID)
      let routeData = this.$router.resolve({
        name: "bills_order_products",
        query: data,
      });
      window.open(routeData.href, "_blank");
      }else{
        return
      }
    }
  },
};
</script>

<style>
#bgColor {
  font-size: 60px;
  font-family: "Noto Sans Lao";
  text-align: left;
  margin-right: 30px;
  margin-top: 7px;
  color: black;
  background: #edfbff;
  font-weight: normal;
}
#head {
  font-size: 60px;
  font-family: "Noto Sans Lao";
  text-align: left;
  margin-right: 30px;
  margin-top: 7px;
  color: black;
  font-weight: normal;
}
</style>