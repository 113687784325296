<template>
  <div
    v-if="
      $store.state.authMod.token.status == 'Admin' ||
      $store.state.authMod.token.status == 'admin'
    "
  >
    <v-row justify="center">

      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <h2 style="margin-top: 30px">ແກ້ໄຂຂໍ້ມູນຮ້ານ</h2>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    v-model="shop_info.shop_name"
                    :error-messages="ShoNameErrors"
                    :counter="100"
                    label="ຊື່ຮ້ານ"
                    required
                    @input="$v.shop_info.shop_name.$touch()"
                    @blur="$v.shop_info.shop_name.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="shop_info.tel"
                    :error-messages="TelErrors"
                    :counter="100"
                    label="ເບີໂທລະສັບ"
                    required
                    @input="$v.shop_info.tel.$touch()"
                    @blur="$v.shop_info.tel.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="shop_info.whatsapp"
                    :error-messages="WhatsappErrors"
                    :counter="100"
                    label="ເບີວອດແອັບ"
                    required
                    @input="$v.shop_info.whatsapp.$touch()"
                    @blur="$v.shop_info.whatsapp.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="shop_info.email"
                    :error-messages="EmailErrors"
                    :counter="100"
                    label="ອີແມວ"
                    required
                    @input="$v.shop_info.email.$touch()"
                    @blur="$v.shop_info.email.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="shop_info.bank_account_no"
                    :error-messages="BankNoErrors"
                    label="ເລກບັນຊີທະນາຄານການຄ້າ"
                    counter
                    required
                    @input="$v.shop_info.bank_account_no.$touch()"
                    @blur="$v.shop_info.bank_account_no.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="shop_info.bank_account_name"
                    :error-messages="BankNameErrors"
                    :counter="100"
                    label="ຊື່ບັນຊີ"
                    required
                    @input="$v.shop_info.bank_account_name.$touch()"
                    @blur="$v.shop_info.bank_account_name.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model="shop_info.paypal_email"
                    :error-messages="PaypalEmailErrors"
                    :counter="100"
                    label="PayPal ອີແມວ"
                    required
                    @input="$v.shop_info.paypal_email.$touch()"
                    @blur="$v.shop_info.paypal_email.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model="shop_info.register_pay"
                    :error-messages="RegisterPayErrors"
                    :counter="100"
                    label="ຄ່າສະມັກເປັນຕົວແທນ"
                     @keypress="isNumber($event)"
                    required
                    @input="$v.shop_info.register_pay.$touch()"
                    @blur="$v.shop_info.register_pay.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="shop_info.discount"
                    :error-messages="DiscountErrors"
                    label="ສ່ວນຫຼຸດ"
                     @keypress="isNumber($event)"
                    counter
                    required
                    @input="$v.shop_info.discount.$touch()"
                    @blur="$v.shop_info.discount.$touch()"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-5">
              <v-spacer></v-spacer>
              <v-btn
                @click="back"
                style="font-size: 20px; font-weight: bold"
                color="deep-purple lighten-4"
                rounded
                class="mt-2 mr-5"
              >
                <v-icon left large> mdi-arrow-left-circle </v-icon>
                ກັບຄືນ
              </v-btn>

              <v-btn
                style="font-size: 20px; font-weight: bold"
                color="#1DE9B6"
                rounded
                class="mr-5 mt-2"
                @click="submit"
              >
                <v-icon left large> mdi-check-circle </v-icon>
                ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  email,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    shop_info: {
      shop_name: { required, maxLength: maxLength(100) },
      tel: { required, maxLength: maxLength(20) },
      whatsapp: { required, maxLength: maxLength(20) },
      email: { required, email },
      bank_account_no: { required, maxLength: maxLength(100) },
      bank_account_name: { required, maxLength: maxLength(100) },
      paypal_email:  { required, email },
      register_pay: { required },
      discount: { required},
    },
  },

  computed: {
    ...mapGetters({
      shop_info: "shopMod/shop_edit",
    }),
    ShoNameErrors() {
      const errors = [];
      if (!this.$v.shop_info.shop_name.$dirty) return errors;
      !this.$v.shop_info.shop_name.maxLength &&
        errors.push("ຊື່ບໍ່ໃຫ້ເກີນ 100 ຕົວອັກສອນ");
      !this.$v.shop_info.shop_name.required && errors.push("ຊື່ຮ້ານຫວ່າງ...");
      return errors;
    },
    TelErrors() {
      const errors = [];
      if (!this.$v.shop_info.tel.$dirty) return errors;
      !this.$v.shop_info.tel.maxLength &&
        errors.push("ເບີໂທລະສັບບໍ່ໃຫ້ເກີນ 20 ຕົວເລກ");
      !this.$v.shop_info.tel.required && errors.push("ເບີໂທລະສັບຫວ່າງ...");
      return errors;
    },
      WhatsappErrors() {
      const errors = [];
      if (!this.$v.shop_info.whatsapp.$dirty) return errors;
      !this.$v.shop_info.whatsapp.maxLength &&
        errors.push("ເບີວອດແອັບບໍ່ໃຫ້ເກີນ 20 ຕົວເລກ");
      !this.$v.shop_info.whatsapp.required && errors.push("ເບີວອດແອັບຫວ່າງ...");
      return errors;
    },
    EmailErrors() {
      const errors = [];
      if (!this.$v.shop_info.email.$dirty) return errors;
      !this.$v.shop_info.email.email && errors.push("ບໍ່ຖຶກຕາມກົດຂອງອີແມວ");
      !this.$v.shop_info.email.required && errors.push("ອີແມວຫວ່າງ...");
      return errors;
    },
    BankNoErrors() {
      const errors = [];
      if (!this.$v.shop_info.bank_account_no.$dirty) return errors;
      !this.$v.shop_info.bank_account_no.maxLength &&
        errors.push("ເລກບັນຊີຍາວເກີນ");
      !this.$v.shop_info.bank_account_no.required &&
        errors.push("ເລກບັນຊີຫວ່າງ...");
      return errors;
    },

    BankNameErrors() {
      const errors = [];
      if (!this.$v.shop_info.bank_account_name.$dirty) return errors;
      !this.$v.shop_info.bank_account_name.maxLength &&
        errors.push("ຊື່ບັນຊີບໍ່ໃຫ້ຍາວເກີນ 100 ຕົວອັກສອນ");
      !this.$v.shop_info.bank_account_name.required &&
        errors.push("ຊື່ບັນຊີຫວ່າງ...");
      return errors;
    },

     PaypalEmailErrors() {
      const errors = [];
      if (!this.$v.shop_info.paypal_email.$dirty) return errors;
      !this.$v.shop_info.paypal_email.email && errors.push("ບໍ່ຖຶກຕາມກົດຂອງອີແມວ");
      !this.$v.shop_info.paypal_email.required && errors.push("ອີແມວຫວ່າງ...");
      return errors;
    },
    RegisterPayErrors() {
      const errors = [];
      if (!this.$v.shop_info.register_pay.$dirty) return errors;
      !this.$v.shop_info.register_pay.required &&
        errors.push("ຄ່າສະມັກຕົວແທນຫວ່າງ...");
      return errors;
    },
       DiscountErrors() {
      const errors = [];
      if (!this.$v.shop_info.discount.$dirty) return errors;
      !this.$v.shop_info.discount.required &&
        errors.push("ສ່ວນຫຼຸດຫວ່າງ...");
      return errors;
    },
  },

  mounted() {
    this.initail();
  },

  methods: {
    ...mapActions("shopMod", ["getShopBy_ID","EditShop_Info"]),
    async initail() {
      this.getShopBy_ID(this.$route.params.id);
    },
       isNumber: function ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
       // alert("ເບີໂທລະສັບຕ້ອງເປັນຕົວເລກ (0-9)");
        $event.preventDefault();
      }
    },

    async submit() {
      this.$v.$touch();
      try {
        if (
          this.shop_info.shop_name == "" ||
          this.shop_info.shop_name == null ||
          this.shop_info.shop_name.length > 100
        ) {
         this.$v.$touch();
          return;
        }
        if (
         this.shop_info.tel == "" ||
          this.shop_info.tel == null ||
          this.shop_info.tel.length > 20
        ) {
          this.$v.$touch();
          return;
        }
        if (
          this.shop_info.whatsapp == "" ||
          this.shop_info.whatsapp == null ||
          this.shop_info.whatsapp.length > 20
        ) {
         this.$v.$touch();
          return;
        }
        if (
          this.shop_info.email == "" ||
          this.shop_info.email == null ||
          this.shop_info.email.length > 100
        ) {
           this.$v.$touch();
          return;
        }
         if (
          this.shop_info.bank_account_no == "" ||
          this.shop_info.bank_account_no == null ||
          this.shop_info.bank_account_no.length > 100
        ) {
           this.$v.$touch();
          return;
        }
         if (
          this.shop_info.bank_account_name == "" ||
          this.shop_info.bank_account_name == null ||
          this.shop_info.bank_account_name.length > 100
        ) {
           this.$v.$touch();
          return;
        }
        if (
          this.shop_info.paypal_email == "" ||
          this.shop_info.paypal_email == null ||
          this.shop_info.paypal_email.length > 100
        ) {
          this.$v.$touch();
          return;
        }
          if (
          this.shop_info.register_pay == "" ||
          this.shop_info.register_pay == null ||
          this.shop_info.register_pay.length > 100
        ) {
          this.$v.$touch();
          return;
        }
            if (
          this.shop_info.discount == "" ||
          this.shop_info.discount == null ||
          this.shop_info.discount.length > 100
        ) {
          this.$v.$touch();
          return;
        }
      //  alert(JSON.stringify(this.shop_info))
        this.EditShop_Info(this.shop_info);

        this.clear();
        this.back();
      } catch (e) {
        this.errors.push(e);
      }
    },
    
    clear() {
      this.$v.$reset();
      this.shop_info.shop_name = "";
      this.shop_info.tel = "";
      this.shop_info.whatsapp = "";
      this.shop_info.email = "";
       this.shop_info.bank_account_no = "";
       this.shop_info.bank_account_name = "";
      this.shop_info.paypal_email = "";
      this.shop_info.register_pay = "";
      this.shop_info.discount = "";
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
