import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import { header } from '../../config/constant';

Vue.use(Vuex)

const customerModule = {
  namespaced: true,
  state: {
    commentdata:[]
  },
  mutations: {
    setCustomer(state, customer) {
      state.commentdata = customer
    },
  },

  actions: {
    async getComment(context) {
      try {
        var response = await axios.get(`http://localhost:2021/api/dadabackend/customer/getall`, {
          headers: header
        })
        context.commit('setCustomer', response.data)
      } catch (e) {
        console.log(e)
      }
    },
    async InsertCutComment(context, insertItem) {
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/comment/cut/insert`,
          insertItem, {
          headers: header
        }
        );
        context.dispatch('getComment')
      } catch (e) {
        console.log(e)
      }
    },
    async InsertComment(context, insertItem) {
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/comment/insert`,
          insertItem, {
          headers: header
        }
        );
        context.dispatch('getComment')
      } catch (e) {
        console.log(e)
      }
    },
    async UpdateComment(context, updateItem) {
      try {

        await axios.post(
          `http://localhost:2021/api/dadabackend/comment/update`,
          updateItem, {
          headers: header
        }
        );
        context.dispatch('getCustomer')
      } catch (e) {
        console.log(e)
      }
    },
  },
  getters: {
    commentdata: state => state.commentdata,
  }
}

export default customerModule;

