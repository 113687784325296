import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { header } from '../../config/constant'
import moment from 'moment'


Vue.use(Vuex)

const productModule = {
  namespaced: true,
  state: {

    proReport: [],
    topImport: [],
    cutproducts: [],
    orderproducts: [],
    sellproducts: [],
    customertop: [],

    sellIncome: [],
    orderIncome: [],
    cutIncome: [],

    bestlist: [],

    reportImport: [],
    importtodal: 0,
    importcount: 0,
    importdate: 0,

    protodal: 0,
    emptodal: 0,
    selltodal: [],
    ordertodal: [],
    cuttodal: [],

    selldate: 0.0,
    orderdate: 0.0,
    cutdate: 0.0,
    custtodal: 0,

  },
  mutations: {

    setReportPro(state, pro) {
      state.proReport = pro
    },
    setBestProFucture(state, list) {
      state.bestlist = list
    },
    setImport(state, list) {
      state.reportImport = list
    },
    setTopImport(state, pro) {
      state.topImport = pro
    },
    setTopCutPro(state, pro) {
      state.cutproducts = pro
    },
    setTopOrderPro(state, pro) {
      state.orderproducts = pro
    },
    setTopSalePro(state, pro) {
      state.sellproducts = pro
    },
    setTopCustomer(state, cust) {
      state.customertop = cust
    },


    setdatesImport(state, todal) {
      state.importdate = todal
    },
    setdatesSell(state, todal) {
      state.selldate = todal
    },
    setdatesOrder(state, todal) {
      state.orderdate = todal
    },
    setdatesCut(state, todal) {
      state.cutdate = todal
    },


    setTodalPro(state, todal) {
      state.protodal = todal
    },
    setTodalImport(state, todal) {
      state.importtodal = todal
    },
    setCountImport(state, count) {
      state.importcount = count
    },
    setTodalSell(state, todal) {
      state.selltodal = todal
    },
    setTodalOrder(state, todal) {
      state.ordertodal = todal
    },
    setTodalEmp(state, todal) {
      state.emptodal = todal
    },
    setTodalCust(state, todal) {
      state.custtodal = todal
    },
    setTodalCut(state, todal) {
      state.cuttodal = todal
    },


    setCutIncome(state, list) {
      state.cutIncome = list
    },
    setSellIncome(state, list) {
      state.sellIncome = list
    },
    setOrderIncome(state, list) {
      state.orderIncome = list
    },

  },

  actions: {

    async getTopImport(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/materialimport/gettop`, {
          headers: header
        }
        );
        var pro_name = [], count = [], sum = []
        for (const key in response.data) {
          const el = response.data[key];
          pro_name.push(el.pro_name)
          count.push(el.count)
          sum.push(el.sum)
        }
       
        var products = {
          series: [{
            data: sum
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              events: {
                click: function (chart, w, e) {
                  console.log(chart, w, e)
                }
              }, 
              toolbar: {
                show: false
              },
              zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: false,
                zoomedArea: {
                  fill: {
                    color: '#90CAF9',
                    opacity: 0.4
                  },
                  stroke: {
                    color: '#0D47A1',
                    opacity: 0.4,
                    width: 1
                  }
                }
              }
            },
            colors: ['#01579B','#880E4F','#00695C','#FF6D00','#2E7D32', '#43A047', '#00C853', '#69F0AE', '#76FF03','#C6FF00',
            '#76FF03','#C6FF00',],
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            xaxis: {
              categories: pro_name,
              labels: {
                style: {
                  colors: ['#01579B','#880E4F','#00695C','#FF6D00','#2E7D32', '#43A047', '#00C853', '#69F0AE','#76FF03','#C6FF00',
                  '#76FF03','#C6FF00', ],
                  fontSize: '18px',
                  fontFamily:'NoTo Sans Lao'
                }
              }
            },
            responsive: [
              {
                breakpoint: 1000,
                options: {
                  plotOptions: {
                    bar: {
                      horizontal: false
                    }
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ],

          }

        }
        context.commit('setTopImport', products)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getReportPro(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/product/done/getall`, {
          headers: header
        }
        );
        var pro_name = [], qty = []
        for (const key in response.data) {
          const el = response.data[key];
          pro_name.push(el.pro_name)
          qty.push(el.qty)
        }

        var products = {
          series: [{
            data: qty
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: true,
                dataLabels: {
                  position: 'bottom',
                  fontFamily:'NoTo Sans Lao'

                },
                rangeBarGroupRows: false,
                colors: {
                  ranges: [{
                      from: 0,
                      to: 0,
                      color: undefined
                  }],
              },
              },
            },
            colors: [
              '#6600ff', '#00796B', '#993399', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#ffb3ff', '#ff4dff', '#b30059',
              '#4dffc3', '#80d4ff', '#3399ff', '#9999ff', '#d9b3ff', '#40ff00', '#827717', '#b3ff99', '#993399', '#66ff99', '#80ffd4',
              '#d9ffb3', '#E65100', '#ffff4d', '#cccc00', '#ffbf80', '#fff2e6', '#ffb3b3', '#ffb3ff', '#ff4dff', '#b30059', '#c65353',
              '#cc8800', '#b38600', '#ac7339', '#cc6600', '#888844', '#77b300', '#00cc44', '#006600', '#527a7a', '#009999', '#003399',
              '#b3b3ff', '#333399', '#6600ff', '#bf00ff', '#ff00ff', '#993399', '#bf4080', '#cc0000', '#ff6600', '#996600', '#ffe6e6',
              '#cc8800', '#b38600', '#ac7339', '#cc6600', '#888844', '#77b300', '#00cc44', '#006600', '#527a7a', '#009999', '#003399',
              '#d9ffb3', '#E65100', '#ffff4d', '#cccc00', '#ffbf80', '#fff2e6', '#ffb3b3', '#ffb3ff', '#ff4dff', '#b30059', '#c65353',
              '#b3b3ff', '#333399', '#6600ff', '#bf00ff', '#ff00ff', '#993399', '#bf4080', '#cc0000', '#ff6600', '#996600', '#ffe6e6',
              '#4dffc3', '#80d4ff', '#3399ff', '#9999ff', '#d9b3ff', '#40ff00', '#827717', '#b3ff99', '#993399', '#66ff99', '#80ffd4',
              '#cc8800', '#b38600', '#ac7339', '#cc6600', '#888844', '#77b300', '#00cc44', '#006600', '#527a7a', '#009999', '#003399',
              '#b3ff99', '#993399', '#66ff99', '#80ffd4', '#b3b3ff', '#333399', '#6600ff', '#bf00ff', '#cc0000', '#ff6600', '#996600',
            ],
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#ffffff', '#993399', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#ffb3ff', '#ff4dff', '#b30059',
                '#4dffc3', '#80d4ff', '#3399ff', '#9999ff', '#d9b3ff', '#40ff00', '#827717', '#b3ff99', '#993399', '#66ff99', '#80ffd4',
                '#d9ffb3', '#E65100', '#ffff4d', '#cccc00', '#ffbf80', '#fff2e6', '#ffb3b3', '#ffb3ff', '#ff4dff', '#b30059', '#c65353',
                '#cc8800', '#b38600', '#ac7339', '#cc6600', '#888844', '#77b300', '#00cc44', '#006600', '#527a7a', '#009999', '#003399',
                '#b3b3ff', '#333399', '#6600ff', '#bf00ff', '#ff00ff', '#993399', '#bf4080', '#cc0000', '#ff6600', '#996600', '#ffe6e6',
                '#cc8800', '#b38600', '#ac7339', '#cc6600', '#888844', '#77b300', '#00cc44', '#006600', '#527a7a', '#009999', '#003399',
                '#d9ffb3', '#E65100', '#ffff4d', '#cccc00', '#ffbf80', '#fff2e6', '#ffb3b3', '#ffb3ff', '#ff4dff', '#b30059', '#c65353',
                '#b3b3ff', '#333399', '#6600ff', '#bf00ff', '#ff00ff', '#993399', '#bf4080', '#cc0000', '#ff6600', '#996600', '#ffe6e6',
                '#4dffc3', '#80d4ff', '#3399ff', '#9999ff', '#d9b3ff', '#40ff00', '#827717', '#b3ff99', '#993399', '#66ff99', '#80ffd4',
                '#cc8800', '#b38600', '#ac7339', '#cc6600', '#888844', '#77b300', '#00cc44', '#006600', '#527a7a', '#009999', '#003399',
                '#b3ff99', '#993399', '#66ff99', '#80ffd4', '#b3b3ff', '#333399', '#6600ff', '#bf00ff', '#cc0000', '#ff6600', '#996600',
              ],
                fontSize: '25px',
                fontFamily:'NoTo Sans Lao'
              },
              formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + " :  " + val+" ຈໍານວນ"
              },
              offsetX: 0,
              dropShadow: {
                enabled: true
              }
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            grid: {
              show: true,
              borderColor: '#d0d0d0',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                  lines: {
                      show: false
                  },
              },   
              yaxis: {
                  lines: {
                      show: true
                  },
              },   
            },
            xaxis: {
              categories: pro_name,
            },
            yaxis: {
              labels: {
                show: false
              },
            },
            legend: {
              show: true,
              position: 'top',
              horizontalAlign: 'center', 
              floating: false,
              fontSize: '14px',
              fontFamily:'NoTo Sans Lao',
              fontWeight: 400,
              labels: {
                  colors: undefined,
                  useSeriesColors: true
              },
              markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 0,
                  strokeColor: '#fff',
                  fillColors: undefined,
                  radius: 12,
                  customHTML: undefined,
                  onClick: undefined,
                  offsetX: 0,
                  offsetY: 0
              },
              itemMargin: {
                  horizontal: 5,
                  vertical: 0
              },
              onItemClick: {
                  toggleDataSeries: true
              },
              onItemHover: {
                  highlightDataSeries: true
              },
          },
            tooltip: {
              theme: 'dark',
              x: {
                show: false
              },
              y: {
                title: {
                  formatter: function () {
                    return ''
                  }
                }
              }
            }
          },
        }
        context.commit('setReportPro', products)
      } catch (e) {
        this.errors.push(e);
      }
    },
     async getTopSalePro(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/product/report/gettop`, {
          headers: header
        }
        );
        var pro_name = [], sum = []
        for (const key in response.data) {
          const el = response.data[key];
          pro_name.push(el.pro_name)
          sum.push(el.sum)
        }

        var products = {
          series: [{
            data: sum,
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              events: {
                click: function (chart, w, e) {
                  console.log(chart, w, e)
                }
              }, 
              toolbar: {
                show: false
              },
              zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: false,
                zoomedArea: {
                  fill: {
                    color: '#90CAF9',
                    opacity: 0.4
                  },
                  stroke: {
                    color: '#0D47A1',
                    opacity: 0.4,
                    width: 1
                  }
                }
              }
            },
            colors: ['#00695C','#1B5E20','#2E7D32', '#43A047', '#00C853', '#69F0AE', '#006064','#33691E','#9E9D24','#C0CA33'],
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            xaxis: {
              categories: pro_name,
              labels: {
                style: {
                  colors: ['#00695C','#1B5E20','#2E7D32', '#43A047', '#00C853', '#69F0AE', '#006064','#33691E','#9E9D24','#C0CA33'],
                  fontSize: '18px',
                  fontFamily:'NoTo Sans Lao'
                }
              }
            },
            responsive: [
              {
                breakpoint: 1000,
                options: {
                  plotOptions: {
                    bar: {
                      horizontal: false
                    }
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ],

          }

        }
        context.commit('setTopSalePro', products)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTopOrderPro(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/buy/report/gettop`, {
          headers: header
        }
        );
        var pro_name = [], sum = []
        for (const key in response.data) {
          const el = response.data[key];
          pro_name.push(el.pro_name)
          sum.push(el.sum)
        }
        var products = {
          series: [{
            data: sum
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              events: {
                click: function (chart, w, e) {
                  console.log(chart, w, e)
                }
              }, toolbar: {
                show: false
              },
              zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: false,
                zoomedArea: {
                  fill: {
                    color: '#90CAF9',
                    opacity: 0.4
                  },
                  stroke: {
                    color: '#0D47A1',
                    opacity: 0.4,
                    width: 1
                  }
                }
              }
            },
            colors: ['#BF360C','#F4511E','#FF8A65', '#FF6D00', '#FB8C00', '#FFA000', '#FBC02D','#FFD600','#FFFF00','#AEEA00'],
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            xaxis: {
              categories: pro_name,
              labels: {
                style: {
                  colors:  ['#BF360C','#F4511E','#FF8A65', '#FF6D00', '#FB8C00', '#FFA000', '#FBC02D','#FFD600','#FFFF00','#AEEA00'],
                  fontSize: '18px',
                  fontFamily:'NoTo Sans Lao'
                }
              }
            },
            responsive: [
              {
                breakpoint: 1000,
                options: {
                  plotOptions: {
                    bar: {
                      horizontal: false
                    }
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ],

          }

        }
        context.commit('setTopOrderPro', products)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTopCutPro(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/cut/report/gettop`, {
          headers: header
        }
        );
        var pro_name = [], sum = []
        for (const key in response.data) {
          const el = response.data[key];
          pro_name.push(el.pro_name)
          sum.push(String(el.sum).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
        }

        var products = {
          series: [{
            data: sum
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              events: {
                click: function (chart, w, e) {
                  console.log(chart, w, e)
                }
              }, toolbar: {
                show: false
              },
              zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: false,
                zoomedArea: {
                  fill: {
                    color: '#90CAF9',
                    opacity: 0.4
                  },
                  stroke: {
                    color: '#0D47A1',
                    opacity: 0.4,
                    width: 1
                  }
                }
              }
            },
            colors:  ['#01579B','#0288D1','#448AFF', '#4FC3F7', '#00E5FF', '#64FFDA', '#26C6DA','#26A69A','#006064','#004D40'],
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            xaxis: {
              categories: pro_name,
              labels: {
                style: {
                  colors:  ['#01579B','#0288D1','#448AFF', '#4FC3F7', '#00E5FF', '#64FFDA', '#26C6DA','#26A69A','#006064','#004D40'],
                  fontSize: '18px',
                  fontFamily: 'NoTo Sans Lao'
                }
              }
            },
            responsive: [
              {
                breakpoint: 1000,
                options: {
                  plotOptions: {
                    bar: {
                      horizontal: false
                    }
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ],

          }

        }
        context.commit('setTopCutPro', products)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTopCustomer(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/customer/report/gettop`
          , {
            headers: header
          }
        );
        var cust_name = [], count = []
        for (const key in response.data) {
          const el = response.data[key];
          cust_name.push(el.full_name)
          count.push(String(el.count).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
        }

        var customer = {
          series: [{
            data: count
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              events: {
                click: function (chart, w, e) {
                  console.log(chart, w, e)
                }
              }, toolbar: {
                show: false
              },
              zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: false,
                zoomedArea: {
                  fill: {
                    color: '#90CAF9',
                    opacity: 0.4
                  },
                  stroke: {
                    color: '#0D47A1',
                    opacity: 0.4,
                    width: 1
                  }
                }
              }
            },
            colors: ['#0288D1','#FF6F00','#EC407A', '#993399', '#00897B', '#FFFF00','#5D4037','#37474F', '#996600','#512DA8'],
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            grid: {
              show: true,
              borderColor: '#76FF03',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            xaxis: {
              categories: cust_name,
              labels: {
                style: {
                  colors:  ['#0288D1','#FF6F00','#EC407A', '#993399', '#00897B', '#FFFF00','#5D4037','#37474F', '#996600','#512DA8'],
                  fontSize: '18px',
                  fontFamily: 'NoTo Sans Lao'
                }
              }
            },
            responsive: [
              {
                breakpoint: 1000,
                options: {
                  plotOptions: {
                    bar: {
                      horizontal: false
                    }
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ],

          }

        }
        context.commit('setTopCustomer', customer)
      } catch (e) {
        this.errors.push(e);
      }
    },

    async getTodalImport(context) {
      try {
        const resptodal = await axios.get(
          `http://localhost:2021/api/dadabackend/materialimport/gettodal`, {
          headers: header
        }
        );
        const respcount = await axios.get(
          `http://localhost:2021/api/dadabackend/materialimport/getcount`, {
          headers: header
        }
        );
        context.commit('setTodalImport', String(resptodal.data[0].todal).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
        context.commit('setCountImport', String(respcount.data[0].count).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalPro(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/product/gettodal`, {
          headers: header
        }
        );
        context.commit('setTodalPro', String(response.data[0].todal).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalSell(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/sale/gettodal`, {
          headers: header
        }
        );
        var sum=0.0
        if (sum!=null) {
          sum=parseFloat(response.data[0].todal)
        }
        var todal = {
         series: [sum],
         // series: [String(parseFloat(sum)).replace(/(.)(?=(\d{3})+$)/g, "$1 ")],
          chartOptions: {
            chart: {
              height: 320,
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              type: 'radialBar',
              toolbar: {
                show: false
              }
            },
            colors: ['#00E676'],
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                  margin: 0,
                  size: '70%',
                  background: '#fff',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'front',
                  dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                  }
                },
                track: {
                  background: '#fff',
                  strokeWidth: '67%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: true,
                    top: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.35
                  }
                },

                dataLabels: {
                  show: true,
                  name: {
                    offsetY: -10,
                    show: true,
                    color: '#000000',
                    fontSize: '20px'
                  },
                  value: {
                    formatter: function (val) {
                      return parseInt(val);
                    },
                    color: '#ffffff',
                    fontSize: '20px',
                    show: true,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
              gradientToColors: ['#D81B60',],
              //  gradientToColors: ['#FF8F00', '#84FFFF',],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },
            labels: [String(parseInt(sum)).replace(/(.)(?=(\d{3})+$)/g, "$1,")+" ກິບ"],
          },


        }
        context.commit('setTodalSell', todal)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalOrder(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/buy/gettodal`, {
          headers: header
        }
        );
        var todal = {
          series: [response.data[0].todal],
          chartOptions: {
            chart: {
              height: 320,
              type: 'radialBar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              toolbar: {
                show: false
              }
            },
            colors: ["#76FF03"],
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                  margin: 0,
                  size: '70%',
                  background: '#fff',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'front',
                  dropShadow: {
                    enabled: true,
                    top: 1,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                  }
                },
                track: {
                  background: '#fff',
                  strokeWidth: '67%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: true,
                    top: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.35
                  }
                },

                dataLabels: {
                  show: true,
                  name: {
                    offsetY: -10,
                    show: true,
                    color: '#000000',
                    fontSize: '20px',
                    fontWeight:'bold'
                  },
                  value: {
                    formatter: function (val) {
                      return parseInt(val);
                    },
                    color: '#ffffff',
                    fontSize: '20px',
                    show: true,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                gradientToColors: ['#FF8F00', '#84FFFF',],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 80, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },
            labels: [String(parseInt(response.data[0].todal)).replace(/(.)(?=(\d{3})+$)/g, "$1,")+" ກິບ"],
          },


        }
        context.commit('setTodalOrder', todal)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalCut(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/cut/gettodal`, {
          headers: header
        }
        );
        var todal = {
          series: [response.data[0].todal],
          chartOptions: {
            chart: {
              height: 320,
              type: 'radialBar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              toolbar: {
                show: false
              }
            },
            colors: ['#EC407A'],
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                  margin: 0,
                  size: '70%',
                  background: '#fff',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'front',
                  dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                  }
                },
                track: {
                  background: '#fff',
                  strokeWidth: '67%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: true,
                    top: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.35
                  }
                },

                dataLabels: {
                  show: true,
                  name: {
                    offsetY: -10,
                    show: true,
                    color: '#000000',
                    fontSize: '20px'
                  },
                  value: {
                    formatter: function (val) {
                      return parseInt(val);
                    },
                    color: '#ffffff',
                    fontSize: '20px',
                    show: true,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horyzontal',
                shadeIntensity: 1,
                gradientToColors: ['#18FFFF',],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },
            labels: [String(parseInt(response.data[0].todal)).replace(/(.)(?=(\d{3})+$)/g, "$1,")+" ກິບ"],
          },


        }
        context.commit('setTodalCut', todal)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalEmp(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/employee/gettodal`, {
          headers: header
        }
        );
        context.commit('setTodalEmp', String(response.data[0].todal).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalCust(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/customer/gettodal`, {
          headers: header
        }
        );
        context.commit('setTodalCust', String(response.data[0].todal).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
      } catch (e) {
        this.errors.push(e);
      }
    },


    async getdatesImport(context, date) {
      try {
        const response = await axios.post(
          `http://localhost:2021/api/dadabackend/materialimport/getdates`, date, {
          headers: header
        },
        );
        var sum = 0
        for (const key in response.data) {
          const el = response.data[key];
          sum = sum + parseFloat(el.sum)
        }

        context.commit('setdatesImport', sum)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getdatesSell(context, date) {
      try {
        const response = await axios.post(
          `http://localhost:2021/api/dadabackend/sale/getdates`, date, {
          headers: header
        },
        );
        context.commit('setdatesSell', response.data[0].sum)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getdatesOrder(context, date) {
      try {
        const response = await axios.post(
          `http://localhost:2021/api/dadabackend/order/buy/getdates`, date, {
          headers: header
        },
        );
        context.commit('setdatesOrder', response.data[0].sum)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getdatesCut(context, date) {
      try {
        const response = await axios.post(
          `http://localhost:2021/api/dadabackend/order/cut/getdates`, date, {
          headers: header
        },
        );
        context.commit('setdatesCut', response.data[0].sum)
      } catch (e) {
        this.errors.push(e);
      }
    },

    async getImportDay(context) {
      try {
        const current = new Date();
        var todal = 0, data = [], date = []
        for (let index = 0; index < 30; index++) {
          const prior = new Date().setDate(current.getDate() - index);
          date.push(new Date(prior).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"))
        }
        date.sort();
        var end_date = new Date().toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
          first_date = new Date(new Date().setDate(new Date().getDate() - 30)).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
        const res = await axios.post(
          `http://localhost:2021/api/dadabackend/materialimport/getday`, { first_date: first_date.toString(), end_date: end_date.toString() }
          , {
            headers: header
          }
        );
        for (let i = 0; i < date.length; i++) {
          var sum = 0
          const e = date[i];
          for (const key in res.data) {
            const el = res.data[key];
            if (e == moment(String(el.imp_date)).format('YYYY-MM-DD')) {
              sum =el.sum
            }
          }
          data.push(sum)
          todal = todal + parseFloat(sum)
        }
        var datacollection = {
          series: [
            {
              name: "",
              data: data
            }
          ],
          chartOptions: {
            chart: {
              background: '#fff', foreColor: '#000',
              stacked: false,
              height: 350,
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              group: 'social',
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#EF6C00'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                enabled: false,
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: true,
                gradientToColors: ['#9C27B0',],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              },
            },
            markers: {
              size: 3,
              colors: ["#18FFFF"],
              strokeColor: "#0277BD",
              strokeWidth: 3
            },
            grid: {

              show: true,
              borderColor: '#0288D1',
              strokeDashArray: 0,
              position: 'back',
              xaxis: {
                lines: {
                  show: true
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              },
              lines: {
                show: true,
              },
              tickPlacement: 'on',
              borderColor: '#f2c2c2',
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
              lines: {
                show: true,
              }
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },
          noData: {
            text: 'Loading...'
          },
          todal: todal,
        };
        context.commit('setImport', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getSellDay(context) {
      try {

        const current = new Date();
        var todal = 0, data = [], date = []
        for (let index = 0; index < 30; index++) {
          const prior = new Date().setDate(current.getDate() - index);
          date.push(new Date(prior).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"))
        }
        date.sort();
        var end_date = new Date().toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
          first_date = new Date(new Date().setDate(new Date().getDate() - 30)).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
        const res = await axios.post(
          `http://localhost:2021/api/dadabackend/sale/getday`, { first_date: first_date.toString(), end_date: end_date.toString() }
          , {
            headers: header
          }
        );
        for (let i = 0; i < date.length; i++) {
          var sum = 0
          const e = date[i];
          for (const key in res.data) {
            const el = res.data[key];
            if (e == moment(String(el.sell_date)).format('YYYY-MM-DD')) {
              sum = el.sum
            }
          }
          data.push(sum)
          todal = todal + parseFloat(sum)
        }
        var datacollection = {
          series: [
            {
              name: "",
              data: data
            }
          ],
          chartOptions: {
            chart: {
              fontFamily: 'Noto Sans, Arial, sans-serif',
              height: 350,
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#039BE5'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            markers: {
              size: 3,
              colors: ["#FFFFFF"],
              strokeColor: "#AA00FF",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },
          todal: todal
        };
        context.commit('setSellIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getOrderDay(context) {

      const current = new Date();
      var todal = 0, data = [], date = []
      for (let index = 0; index < 30; index++) {
        const prior = new Date().setDate(current.getDate() - index);
        date.push(new Date(prior).toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"))
      }
      date.sort();
      var end_date = new Date().toJSON()
        .slice(0, 10)
        .replace(/-/g, "-"),
        first_date = new Date(new Date().setDate(new Date().getDate() - 30)).toJSON()
          .slice(0, 10)
          .replace(/-/g, "-")
      const res = await axios.post(
        `http://localhost:2021/api/dadabackend/order/buy/getday`, { first_date: first_date.toString(), end_date: end_date.toString() }
        , {
          headers: header
        }
      );
      for (let i = 0; i < date.length; i++) {
        var sum = 0
        const e = date[i];
        for (const key in res.data) {
          const el = res.data[key];
          if (e == moment(String(el.order_date)).format('YYYY-MM-DD')) {
            sum = parseInt(el.sum)
          }
        }
        data.push(sum)
        todal = todal + parseFloat(sum)
      }

      var datacollection = {
        series: [
          {
            name: "",
            data: data
          }
        ],
        chartOptions: {
          chart: { 
            fontFamily: 'Saysettha OT, Arial, sans-serif',
            stacked: false,
            height: 350,
            group: 'social',
            type: 'area',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: false
            }
          },
          colors: ['#66BB6A'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: '',
            align: 'left'
          },
          fill: {
            type: 'gradient',
            gradient: {
              enabled: false,
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 1,
              inverseColors: true,
              gradientToColors: ['#76FF03'],
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100]
            },
          },
          grid: {
            show: true,
            borderColor: '#76FF03',
            strokeDashArray: 1,
            position: 'back',
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis: {
              lines: {
                show: true
              }
            },
          },
          markers: {
            size: 3,
            colors: ["#FFEBEE"],
            strokeColor: "#FF9800",
            strokeWidth: 3
          },
          xaxis: {
            categories: date,
            title: {
              text: ''
            }
          },
          yaxis: {
            title: {
              text: 'ມູນຄ່າ'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
          }
        },
        todal: todal,
      };
      context.commit('setOrderIncome', datacollection)
    },
    async getCutDay(context) {

      const current = new Date();
      var todal = 0, data = [], date = []
      for (let index = 0; index < 30; index++) {
        const prior = new Date().setDate(current.getDate() - index);
        date.push(new Date(prior).toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"))
      }
      date.sort();
      var end_date = new Date().toJSON()
        .slice(0, 10)
        .replace(/-/g, "-"),
        first_date = new Date(new Date().setDate(new Date().getDate() - 30)).toJSON()
          .slice(0, 10)
          .replace(/-/g, "-")
      const res = await axios.post(
        `http://localhost:2021/api/dadabackend/order/cut/getday`, { first_date: first_date.toString(), end_date: end_date.toString() }
        , {
          headers: header
        }
      );
      for (let i = 0; i < date.length; i++) {
        var sum = 0
        const e = date[i];
        for (const key in res.data) {
          const el = res.data[key];
          if (e == moment(String(el.order_cut_date)).format('YYYY-MM-DD')) {
            sum = el.sum
          }
        }
        data.push(sum)
        todal = todal + parseFloat(sum)
      }

      var datacollection = {
        series: [
          {
            name: "",
            data: data
          }
        ],
        chartOptions: {
          chart: {
            stacked: false,
            height: 350,
            group: 'social',
            type: 'line',
            fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: false
            }
          },
          colors: ['#8E24AA', '#6D4C41'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: '',
            align: 'left'
          },
          fill: {
            type: 'gradient',
            gradient: {
              enabled: false,
              shade: 'light',
              type: 'horyzontal',
              shadeIntensity: 1,
              inverseColors: true,
              gradientToColors: ['#F8BBD0', '#84FFFF',],
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100]
            },
          },
          markers: {
            size: 3,
            colors: ["#E1F5FE"],
            strokeColor: "#1E88E5",
            strokeWidth: 3
          },
          xaxis: {
            categories: date,
            title: {
              text: ''
            }
          },
          yaxis: {
            title: {
              text: 'ມູນຄ່າ'
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
          }
        },
        todal: todal,
      };
      context.commit('setCutIncome', datacollection)
    },


    async getImportMonth(context) {

      try {
        var todal = 0.0, data = [], date = [], aday = 0, sday = 0, y = 0,
          arrmonth = [
            "ມັງກອນ",
            "ກຸມພາ",
            "ມີນາ",
            "ເມສາ",
            "ພຶດສະພາ",
            "ມິຖຸນາ",
            "ກໍລະກົດ",
            "ສິງຫາ",
            "ກັນຍາ",
            "ຕຸລາ",
            "ພະຈິກ",
            "ທັນວາ"
          ]
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/materialimport/getMonth`
          , {
            headers: header
          }
        );

        for (let indx = 0; indx < 12; indx++) {
          var sum = 0.0, month = '', mth = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
          if ((parseInt(mth.slice(5, 7)) - aday) > 0) {
            month = (parseInt(mth.slice(5, 7)) - aday).toString()
            aday = aday + 1
            y = new Date().getFullYear()
          } else {
            month = (12 - sday).toString()
            y = parseInt(new Date().getFullYear()) - 1
            sday = sday + 1
          }
          for (const key in response.data) {
            const el = response.data[key];
            var m = moment(String(el.imp_date)).format('YYYY-MM-DD').slice(5, 7)
            if (parseInt(m) == parseInt(month)) {
              sum += parseFloat(el.sum)
            }
          }
          todal = todal + sum
          data.push(sum)
          date.push(y + " " + arrmonth[month - 1])
        }
        date.reverse();
        data.reverse();
        var datacollection = {
          series: [
            {
              name: "",
              data: data
            }
          ],
          chartOptions: {
            chart: {
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              background: '#fff', foreColor: '#000',
              stacked: false,
              height: 350,
              group: 'social',
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#EF6C00'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                enabled: false,
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: true,
                gradientToColors: ['#9C27B0',],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              },
            },
            markers: {
              size: 3,
              colors: ["#18FFFF"],
              strokeColor: "#0277BD",
              strokeWidth: 3
            },
            grid: {

              show: true,
              borderColor: '#0288D1',
              strokeDashArray: 0,
              position: 'back',
              xaxis: {
                lines: {
                  show: true
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              },
              lines: {
                show: true,
              },
              tickPlacement: 'on',
              borderColor: '#f2c2c2',
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
              lines: {
                show: true,
              }
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },
          noData: {
            text: 'Loading...'
          },
          todal: todal,
        };
        context.commit('setImport', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getSellMonth(context) {
      try {
        var todal = 0.0, data = [], date = [], aday = 0, sday = 0, y = 0,
          arrmonth = [
            "ມັງກອນ",
            "ກຸມພາ",
            "ມີນາ",
            "ເມສາ",
            "ພຶດສະພາ",
            "ມິຖຸນາ",
            "ກໍລະກົດ",
            "ສິງຫາ",
            "ກັນຍາ",
            "ຕຸລາ",
            "ພະຈິກ",
            "ທັນວາ"
          ]
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/sale/getMonth`
          , {
            headers: header
          }
        );

        for (let indx = 0; indx < 12; indx++) {
          var sum = 0.0, month = '', mth = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
          if ((parseInt(mth.slice(5, 7)) - aday) > 0) {
            month = (parseInt(mth.slice(5, 7)) - aday).toString()
            aday = aday + 1
            y = new Date().getFullYear()
          } else {
            month = (12 - sday).toString()
            y = parseInt(new Date().getFullYear()) - 1
            sday = sday + 1
          }
          for (const key in response.data) {
            const el = response.data[key];
            var m = moment(String(el.sell_date)).format('YYYY-MM-DD').slice(5, 7)
            if (parseInt(m) == parseInt(month)) {
              sum += parseFloat(el.sum)
            }
          }
          todal = todal + sum
          data.push(sum)
          date.push(y + " " + arrmonth[month - 1])
        }
        date.reverse();
        data.reverse();
        var datacollection = {
          series: [
            {
              name: "",
              data: data
            }
          ],
          chartOptions: {
            chart: {
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              height: 350,
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#039BE5'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            markers: {
              size: 3,
              colors: ["#FFFFFF"],
              strokeColor: "#AA00FF",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },
          todal: todal,
        };
        context.commit('setSellIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getCutMonth(context) {
      try {
        var todal = 0.0, data = [], date = [], aday = 0, sday = 0, y = 0,
          arrmonth = [
            "ມັງກອນ",
            "ກຸມພາ",
            "ມີນາ",
            "ເມສາ",
            "ພຶດສະພາ",
            "ມິຖຸນາ",
            "ກໍລະກົດ",
            "ສິງຫາ",
            "ກັນຍາ",
            "ຕຸລາ",
            "ພະຈິກ",
            "ທັນວາ"
          ]
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/cut/getMonth`
          , {
            headers: header
          }
        );

        for (let indx = 0; indx < 12; indx++) {
          var sum = 0.0, month = '', mth = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
          if ((parseInt(mth.slice(5, 7)) - aday) > 0) {
            month = (parseInt(mth.slice(5, 7)) - aday).toString()
            aday = aday + 1
            y = new Date().getFullYear()
          } else {
            month = (12 - sday).toString()
            y = parseInt(new Date().getFullYear()) - 1
            sday = sday + 1
          }
          for (const key in response.data) {
            const el = response.data[key];
            var m = moment(String(el.order_cut_date)).format('YYYY-MM-DD').slice(5, 7)
            if (parseInt(m) == parseInt(month)) {
              sum += parseFloat(el.sum)
            }
          }
          todal = todal + sum
          data.push(sum)
          date.push(y + " " + arrmonth[month - 1])
        }
        date.reverse();
        data.reverse();

        var datacollection = {
          series: [
            {
              name: "",
              data: data
            }
          ],
          chartOptions: {
            chart: {
              stacked: false,
              height: 350,
              group: 'social',
              type: 'line',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#8E24AA', '#6D4C41'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                enabled: false,
                shade: 'light',
                type: 'horyzontal',
                shadeIntensity: 1,
                inverseColors: true,
                gradientToColors: ['#F8BBD0', '#84FFFF',],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              },
            },
            markers: {
              size: 3,
              colors: ["#E1F5FE"],
              strokeColor: "#1E88E5",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },
          todal: todal,
        };
        context.commit('setCutIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getOrderMonth(context) {
      try {
        var todal = 0.0, data = [], date = [], aday = 0, sday = 0, y = 0,
          arrmonth = [
            "ມັງກອນ",
            "ກຸມພາ",
            "ມີນາ",
            "ເມສາ",
            "ພຶດສະພາ",
            "ມິຖຸນາ",
            "ກໍລະກົດ",
            "ສິງຫາ",
            "ກັນຍາ",
            "ຕຸລາ",
            "ພະຈິກ",
            "ທັນວາ"
          ]
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/buy/getMonth`
          , {
            headers: header
          }
        );

        for (let indx = 0; indx < 12; indx++) {
          var sum = 0.0, month = '', mth = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
          if ((parseInt(mth.slice(5, 7)) - aday) > 0) {
            month = (parseInt(mth.slice(5, 7)) - aday).toString()
            aday = aday + 1
            y = new Date().getFullYear()
          } else {
            month = (12 - sday).toString()
            y = parseInt(new Date().getFullYear()) - 1
            sday = sday + 1
          }
          for (const key in response.data) {
            const el = response.data[key];
            var m = moment(String(el.order_date)).format('YYYY-MM-DD').slice(5, 7)
            if (parseInt(m) == parseInt(month)) {
              sum += parseFloat(el.sum)
            }
          }
          todal = todal + sum
          data.push(sum)
          date.push(y + " " + arrmonth[month - 1])
        }
        date.reverse();
        data.reverse();

        var datacollection = {
          series: [
            {
              name: "",
              data: data
            }
          ],
          chartOptions: {
            chart: { 
              stacked: false,
              height: 350,
              group: 'social',
              type: 'area',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#66BB6A'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                enabled: false,
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: true,
                gradientToColors: ['#76FF03'],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              },
            },
            grid: {
              show: true,
              borderColor: '#76FF03',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            markers: {
              size: 3,
              colors: ["#FFEBEE"],
              strokeColor: "#FF9800",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },
          todal: todal,
        };
        context.commit('setOrderIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },

    async getImportYear(context) {
      try {
        var todal = 0.0, data = [], date = []
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/materialimport/getyear`
          , {
            headers: header
          }
        );

        for (let index = 0; index < 5; index++) {
          var year = parseInt(new Date().getFullYear(), 10), sum = 0.0
          for (const key in response.data) {
            const el = response.data[key];
            var y = moment(String(el.imp_date)).format('YYYY-MM-DD').slice(0, 4)

            if (year - index == y) {
              sum += parseFloat(el.sum)
            }
          }
          todal = todal + sum
          data.push(sum)
          date.push(year -= index)
        }
        date.reverse();
        data.reverse();
        var datacollection = {
          series: [
            {
              name: "",
              data: data
            }
          ],
          chartOptions: {
            chart: {
              background: '#fff', foreColor: '#000',
              stacked: false,
              height: 350,
              group: 'social',
              type: 'line',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#EF6C00'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                enabled: false,
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: true,
                gradientToColors: ['#9C27B0',],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              },
            },
            markers: {
              size: 3,
              colors: ["#18FFFF"],
              strokeColor: "#0277BD",
              strokeWidth: 3
            },
            grid: {

              show: true,
              borderColor: '#0288D1',
              strokeDashArray: 0,
              position: 'back',
              xaxis: {
                lines: {
                  show: true
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              },
              lines: {
                show: true,
              },
              tickPlacement: 'on',
              borderColor: '#f2c2c2',
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
              lines: {
                show: true,
              }
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },
          noData: {
            text: 'Loading...'
          },
          todal: todal,
        };
        context.commit('setImport', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getSellYear(context) {
      try {
        var todal = 0.0, data = [], date = []
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/sale/getyear`
          , {
            headers: header
          }
        );

        for (let index = 0; index < 5; index++) {
          var year = parseInt(new Date().getFullYear(), 10), sum = 0.0
          for (const key in response.data) {
            const el = response.data[key];
            var y = moment(String(el.sell_date)).format('YYYY-MM-DD').slice(0, 4)

            if (year - index == y) {
              sum += parseFloat(el.sum)
            }
          }
          todal = todal + sum
          data.push(sum)
          date.push(year -= index)
        }
        date.reverse();
        data.reverse();

        var datacollection = {
          series: [
            {
              name: "",
              data: data
            }
          ],
          chartOptions: {
            chart: {
              height: 350,
              type: 'line',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#039BE5'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            markers: {
              size: 3,
              colors: ["#FFFFFF"],
              strokeColor: "#AA00FF",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },
          todal: todal,
        };
        context.commit('setSellIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getOrderYear(context) {
      try {
        var todal = 0.0, data = [], date = []
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/buy/getyear`
          , {
            headers: header
          }
        );

        for (let index = 0; index < 5; index++) {
          var year = parseInt(new Date().getFullYear(), 10), sum = 0.0
          for (const key in response.data) {
            const el = response.data[key];
            var y = moment(String(el.order_date)).format('YYYY-MM-DD').slice(0, 4)
            if (year - index == y) {
              sum += parseFloat(el.sum)
            }
          }
          todal = todal + sum
          data.push(sum)
          date.push(year -= index)
        }
        date.reverse();
        data.reverse();

        var datacollection = {
          series: [
            {
              name: "",
              data: data
            }
          ],
          chartOptions: {
            chart: { 
              stacked: false,
              height: 350,
              group: 'social',
              type: 'area',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#66BB6A'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                enabled: false,
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: true,
                gradientToColors: ['#76FF03'],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              },
            },
            grid: {
              show: true,
              borderColor: '#76FF03',
              strokeDashArray: 1,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            markers: {
              size: 3,
              colors: ["#FFEBEE"],
              strokeColor: "#FF9800",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },
          todal: todal,
        };
        context.commit('setOrderIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getCutYear(context) {
      try {
        var todal = 0.0, data = [], date = []
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/cut/getyear`
          , {
            headers: header
          }
        );

        for (let index = 0; index < 5; index++) {
          var year = parseInt(new Date().getFullYear(), 10), sum = 0.0
          for (const key in response.data) {
            const el = response.data[key];
            var y = moment(String(el.order_cut_date)).format('YYYY-MM-DD').slice(0, 4)
            if (year - index == y) {
              sum += parseFloat(el.sum)
            }
          }
          todal = todal + sum
          data.push(sum)
          date.push(year -= index)
        }
        date.reverse();
        data.reverse();

        var datacollection = {
          series: [
            {
              name: "",
              data: data
            }
          ],
          chartOptions: {
            chart: {
              stacked: false,
              height: 350,
              group: 'social',
              type: 'line',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#8E24AA', '#6D4C41'],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: '',
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                enabled: false,
                shade: 'light',
                type: 'horyzontal',
                shadeIntensity: 1,
                inverseColors: true,
                gradientToColors: ['#F8BBD0', '#84FFFF',],
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              },
            },
            markers: {
              size: 3,
              colors: ["#E1F5FE"],
              strokeColor: "#1E88E5",
              strokeWidth: 3
            },
            xaxis: {
              categories: date,
              title: {
                text: ''
              }
            },
            yaxis: {
              title: {
                text: 'ມູນຄ່າ'
              },
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },
          todal: todal,
        };
        context.commit('setCutIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },

  },
  getters: {
    proReport: state => state.proReport,
    importtodal: state => state.importtodal,
    importcount: state => state.importcount,
    protodal: state => state.protodal,
    selltodal: state => state.selltodal,
    sellproducts: state => state.sellproducts,
    selldate: state => state.selldate,
    emptodal: state => state.emptodal,
    custtodal: state => state.custtodal,
    ordertodal: state => state.ordertodal,
    cuttodal: state => state.cuttodal,
    orderdate: state => state.orderdate,
    cutdate: state => state.cutdate,
    cutproducts: state => state.cutproducts,
    orderproducts: state => state.orderproducts,
    customertop: state => state.customertop,
    sellIncome: state => state.sellIncome,
    orderIncome: state => state.orderIncome,
    cutIncome: state => state.cutIncome,
    bestlist: state => state.bestlist,
    reportImport: state => state.reportImport,
    topImport: state => state.topImport,
    importdate: state => state.importdate,
  }
}

export default productModule;

