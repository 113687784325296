
      <template>
  <v-card class="elevation-0">
    <v-data-table
      :headers="headers"
      :items="importList"
      :search="search"
      item-key="name"
      v-model="selected"
      sort-by="calories"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-row justify="center">
          <h2 class="mt-5 mb-7">ນໍາເຂົ້າວັດຖຸດິບ</h2>
        </v-row>
        <v-divider></v-divider>
        <div>
          <v-toolbar flat>
            <router-link to="material_import_history">
               <v-btn
            class="mt-4"
            text
            style="font-size: 20px; font-family: NotoSansLao"
          >
            <v-icon large left>mdi-history</v-icon>
            ປະຫວັດການນໍາເຂົ້າວັດຖຸດິບ
          </v-btn>
            </router-link>

            <v-divider class="mx-4" inset vertical> </v-divider>
            <v-toolbar-title>ລາຍການນໍາເຂົ້າວັດຖຸດິບ</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-autocomplete
              v-model="select"
              auto-select-first
              chips
              clearable
              outlined
              dense
              @change="Selected"
              deletable-chips
              :items="orderid"
              prepend-icon="mdi-file-find"
              label="ປ້ອນເລກໃບສັ່ງຊື້ວັດຖຸດິບ"
              single-line
              hide-details
            ></v-autocomplete>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title
                  style="
                    font-size: 25px;
                    font-weight: bold;
                    font-family: NotoSansLao;
                  "
                  >ທ່ານຕ້ອງການລຶບຂໍ້ມູນລາຍການນີ້ບໍ...?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    style="font-size: 20px; font-family: NotoSansLao"
                    @click="close"
                    >ຍົກເລິກ</v-btn
                  >
                  <v-btn
                    color="primary"
                    text
                    style="font-size: 20px; font-family: NotoSansLao"
                    @click="deleteItemConfirm"
                    >ຢຶນຢັນລຶບ</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-divider class=""></v-divider>
          <v-divider class=""></v-divider>
        </div>
      </template>
      <template v-slot:body="{ items, headers }">
        <tbody>
          <tr
            v-for="(item, idx) in items"
            :key="idx"
            v-ripple="{ class: `white--text` }"
          >
            <td v-for="(header, key) in headers" :key="key">
              <div v-if="headers[key] == headers[0]">
                {{ idx + 1 }}
              </div>
              <v-card
                v-if="headers[key] == headers[1]"
                width="70"
                height="100"
                :img="fectImg(item[header.value])"
              >
              </v-card>
              <v-edit-dialog
                v-else
                v-ripple="{ class: `error--text` }"
                :return-value.sync="item[header.value]"
                @save="save"
                @cancel="cancel"
                @open="open"
                @close="close"
                large
              >
                {{ item[header.value] }}
                <template v-slot:input>
                  <v-text-field
                    v-if="headers[key] == headers[6] || headers[key] == headers[8]"
                    v-model="item[header.value]"
                    label="Edit"
                    single-line
                    @keypress="CheckNumber($event)"
                  ></v-text-field>
                     <v-text-field
                     v-else
                    v-model="item[header.value]"
                    label="Edit"
                    single-line
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </td>
            <td>
              <v-icon color="red" large @click="deleteItem(idx)">
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-row class="mt-4 mr-3">
      <v-spacer></v-spacer>
      <v-btn
        @click="clear"
        style="font-size: 20px; font-weight: bold"
        color="#FF8A65"
        rounded
        class="mt-2 mr-7 mb-5"
      >
        <v-icon left large>mdi-close-circle</v-icon>ຍົກເລິກ
      </v-btn>

      <v-btn
        @click="saveOrder"
        style="font-size: 20px; font-weight: bold"
        color="#1DE9B6"
        rounded
        class="mt-2 mr-5 mb-5"
        :disabled="emty"
      >
        <v-icon large left>mdi-check-circle</v-icon>ຢຶນຢັນນໍາເຂົ້າວັດຖຸດິບ
      </v-btn>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    dialog: false,
    filter: {},
    sortDesc: false,
    dialogDelete: false,
    search: "",
    headers: [
      { text: "ລໍາດັບ", value: "idx", sortable: false },
      {
        text: "ຮູບ",
        align: "start",
        sortable: false,
        value: "image",
      },
      { text: "ຊື່ວັດຖຸດິບ", value: "pro_name" },
      { text: "ປະເພດວັດຖຸດິບ", value: "category" },
      { text: "ສີ", value: "color" },
      { text: "ຂະໜາດ", value: "size" },
      { text: "ຈໍານວນ", value: "qty" },
      { text: "ຫົວໜ່ວຍ", value: "unit" },
      { text: "ລາຄາ", value: "price" },
    ],
    selected: [],
    select: null,
    did: null,
    editedItem: {
      pro_name: "",
      category: "",
      unit: "",
      color: "",
      size: "",
      qty: 0,
      price: 0,
      image: null,
    },
    importby: {
      import_id: "",
      emp_id: "",
      detail: [],
    },
  }),

  computed: {
    ...mapGetters({
      orderid: "materialImportMod/orderid",
      importList: "materialImportMod/importList",
    }),
    emty() {
      if (this.importList == [] || this.importList.length < 1) return true;
      return false;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    select(val) {
      this.fectOrder(val);
    },
  },

  mounted() {
    this.getOrderID();
    this.fectOrder(this.select);
  },
  methods: {
    ...mapActions("materialImportMod", [
      "getOrderID",
      "getImport",
      "SaveImport",
      "SaveImportDetail",
      "UpdateStatus",
      "CrearData",
    ]),
    fectOrder(id) {
      if (id == "" || id == null) return;
      this.getImport(id);
    },
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
    deleteItem(id) {
      this.dialogDelete = true;
      this.did = id;
    },

    deleteItemConfirm() {
      this.importList.splice(this.did, 1);
      this.dialogDelete = false;
    },
    close() {
      this.dialogDelete = false;
    },

    async saveOrder() {
      for (const key in this.importList) {
        const element = this.importList[key];
        if (parseInt(element.price) < 1) {
          alert("Product price less then One");
          return;
        }
      }
      this.importby.emp_id = this.$store.state.authMod.token.emp_id;
      var date = new Date().toJSON().slice(0, 10).replace(/-/g, "");
      var time = new Date()
        .toTimeString()
        .replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1", "")
        .replace(":", "")
        .replace(":", "");
      this.importby.import_id = "MIM" + date.slice(2, date.length) + time;
      this.importby.detail = this.importList;
      this.SaveImport(this.importby);
      this.SaveImportDetail(this.importby);
      this.UpdateStatus(this.select);

      var data = {
        imp_id: "MIM" + date.slice(2, date.length) + time,
      };
      let routeData = this.$router.resolve({
        name: "bills_material_import",
        query: data,
      });
      window.open(routeData.href, "_blank");

      this.CrearData();
    },
    clear() {
      this.CrearData();
      this.select = null;
    },
    Selected(){
      if(this.select==""||this.select==null){
         this.CrearData();
      this.select = null;
      }
    },
     CheckNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
       // alert("Enter Only Number ? (0-9)");
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>