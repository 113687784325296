<template>
  <div>
    <v-row justify="center">
      <v-card class="elevation-1 mt-3" :width="breack">
        <v-card-title class=" justify-center">
          <h3>ຈັດການຂໍ້ມູນຫົວໜ່ວຍສິນຄ້າ</h3>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
              <v-text-field
                placeholder="ປ້ອນຫົວໜ່ວຍ"
                append-icon="mdi-close"
                v-model="unitEdit.name"
                @click:append="unitclear"
              ></v-text-field>
              <v-btn
                rounded
              color="#ffffff"
                v-if="unitShowEdit"
                @click="unitEditComfirm"
                       class="align-start ml-15 mt-4 mr-6"
                style="font-size: 20px; font-weight: bold"
              >
                <v-icon large color="orange accent-3">mdi-pencil</v-icon>
                ແກ້ໄຂ
              </v-btn>
              <v-btn v-else @click="unitAdd" style="font-size: 20px; font-weight: bold"
                rounded
              color="#ffffff"
                class="ml-15 mt-4 mr-6">
                <v-icon large>mdi-plus</v-icon>
                ບັນທຶກ
              </v-btn>
          </v-row>
          <v-data-table
            :headers="unitheaders"
            :search="unitsearch"
            :items="unit"
            sort-by="unit_id"
            class="elevation-0"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-text-field
                  dense
                  outlined
                  v-model="unitsearch"
                  append-icon="mdi-magnify"
                  label="ຄົ້ນຫາຂໍ້ມູນຫົວໜ່ວຍ"
                  single-line
                  hide-details
                ></v-text-field>
                <v-dialog v-model="unitDelete" max-width="500px">
                  <v-card>
                    <v-card-title  style="font-size: 20px; font-weight: bold;font-family:NotoSansLao"
                      >ທ່ານຕ້ອງການລຶບຂໍ້ມູນລາຍການນີ້ບໍ...?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="error" text @click="Closeunit" style="font-size: 20px;font-family:NotoSansLao"
                      >ຍົກເລິກ</v-btn
                      >
                      <v-btn
                        color="primary"
                        style="font-size: 20px;font-family:NotoSansLao"
                        text
                        @click="deleteunitConfirm"
                        >ຢຶນຢັນລຶບ</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>

              <v-divider class=""></v-divider>
              <v-divider class=""></v-divider>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-row no-gutters class="justify-center">
                <v-icon
                  large
                  color="orange"
                  class="mr-2"
                  @click="editeunit(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon color="error" large @click="deleteunit(item)">
                  mdi-delete
                </v-icon>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    unitDelete: false,
    unitShowEdit: false,
    unitsearch: "",
    unitheaders: [
      { text: "ລໍາດັບ", value: "idx" },
      { text: "ຊື່ຫົວໜ່ວຍ", value: "name" },
      { text: "Actions", align: "center", value: "actions", sortable: false }
    ],
    unitEdit: {
      id: "",
      name: ""
    },
    unitDeleteItem: []
  }),
  computed: {
    ...mapGetters({
      unit: "productMod/unit"
    }),
    breack() {
      var w = "";
      if (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      ) {
        w = "100%";
      } else if (this.$vuetify.breakpoint.name == "md") {
        w = "80%";
      } else if (this.$vuetify.breakpoint.name == "lg") {
        w = "70%";
      } else if (this.$vuetify.breakpoint.name == "xl") {
        w = "50%";
      }
      return w;
    }
  },
  mounted() {
    this.getUnit();
  },
  methods: {
    ...mapActions("productMod", [
      "getUnit",
      "InsertUnit",
      "UpdateUnit",
      "delUnit"
    ]),
    unitclear() {
      this.unitEdit = {
        id: "",
        name: ""
      };
      this.unitDelete = false;
    },
    unitAdd() {
      if (this.unitEdit.name == null || this.unitEdit.name == "") {
        return;
      }
      this.InsertUnit(this.unitEdit);
      this.unitclear();
    },
    editeunit(item) {
      this.unitEdit = item;
      this.unitShowEdit = true;
    },
    unitEditComfirm() {
      try {
        if (this.unitEdit.name == null || this.unitEdit.name == "") {
          return;
        }
        this.UpdateUnit(this.unitEdit);
        this.unitShowEdit = false;
        this.unitclear();
      } catch (error) {
        console.log(error);
      }
    },
    deleteunit(item) {
      this.unitDeleteItem = item;
      this.unitDelete = true;
    },
    deleteunitConfirm() {
      this.delUnit(this.unitDeleteItem);
      this.unitDelete = false;
    },
    Closeunit(){
this.unitDelete = false;
    }
  }
};
</script>