import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import { header } from '../../config/constant';

Vue.use(Vuex)

const employeeModule={
    namespaced: true,
  state: {
   employeedata:[],
   employeedit: {
    id: "",
    name: "",
    surename: "",
    gender: "",
    tel: "",
    facebook: "",
    email: "",
    password: "",
    village: "",
    district: "",
    province: "",
    status: "",
    profile: ""
  },
  },
  mutations: {
      setEmployee(state,employee){
          state.employeedata=employee
      },
      setEmployeeByone(state,emp){
        state.employeedit= {
          id: emp.emp_id,
          name: emp.emp_name,
          surename: emp.emp_surname,
          gender: emp.gender,
          tel:emp.tel,
          facebook: emp.facebook,
          email: emp.email,
          password: emp.password,
          village: emp.village,
          district: emp.district,
          province: emp.province,
          status: emp.status,
          profile: emp.image
        }
      }
  },

  actions: {
      async getEmployee(context){
        var employee_list=[]
        var response = await axios.get(`http://localhost:2021/api/dadabackend/employee/getall`,{
          headers:header
        })
        for (const key in response.data) {
            const element = response.data[key];
            var list={
              idx:parseInt(key,10)+1,
              emp_id:element.emp_id,
              full_name:element.emp_name+" "+element.emp_surname,
              gender:element.gender,
              tel:element.tel,
              facebook:element.facebook,
              email:element.email,
              village:element.village,
              district:element.district,
              province:element.province,
              password:element.password,
              status:element.status,
              image:element.image
            }
            employee_list.push(list)
        }
        context.commit('setEmployee',employee_list)
      },
     async getEmployeeByone(context,id){
      var res=  await axios.get(
          `http://localhost:2021/api/dadabackend/employee/getById/${id}`,{
            headers:header
          }
        );
            context.commit('setEmployeeByone', res.data[0])
    },
    async delEmployee(context,delItem){
      try {
        await axios.post(`http://localhost:2021/api/dadabackend/employee/delete`,delItem,{
          headers:header
        })
        context.dispatch('getEmployee')
      } catch (e) {
        this.errors.push(e)
      }
    },

    async InsertEmployee(context,insertItem){
      try {
        
    await axios.post(
      `http://localhost:2021/api/dadabackend/employee/insert`,
      insertItem,{
        headers:header
      }
    );
        context.dispatch('getEmployee')
      } catch (e) {
        this.errors.push(e)
      }
    },
    
    async UpdateEmployee(context,updateItem){
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/employee/update`,
          updateItem,{
            headers:header
          }
        );
        context.dispatch('getEmployee')
      } catch (e) {
        this.errors.push(e)
      }
    },
  },
  getters: {
    employees: state => state.employeedata,
    employeedit: state => state.employeedit,
  }
}

export default employeeModule;

