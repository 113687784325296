
      <template>
  <div>
    <v-row justify="center">
      <h2 class="mt-5 mb-7">ສັ່ງຊື້ວັດຖຸດິບ</h2>
    </v-row>
    <v-divider></v-divider>
    <v-stepper v-model="e1" class="elevation-0 grey lighten-5">
      <v-stepper-header>
        <router-link to="/material_order">
          <v-btn
            class="mt-4"
            text
            style="font-size: 20px; font-family: NotoSansLao"
          >
            <v-icon large left>mdi-history</v-icon>
            ປະຫວັດການສັ່ງຊື້ວັດຖຸດິບ
          </v-btn>
        </router-link>
        <v-spacer></v-spacer>
        <template v-for="n in steps">
          <v-stepper-step
            dense
            flat
            :key="`${n}-step`"
            :complete="e1 > n"
            :step="n"
            editable
          >
            <h2 v-if="n == 1">ເລືອກວັດຖຸດິບ</h2>
            <h2 v-if="n == 2">ຢຶນຢັນສັ່ງຊື້ວັດຖຸດິບ</h2>
          </v-stepper-step>

          <v-divider v-if="n !== steps" :key="n"></v-divider>
        </template>
        <v-spacer></v-spacer>
        <v-chip @click="steps2" class="ma-5" color="#004D40" outlined>
          <v-avatar left>
            <v-icon>mdi-cart</v-icon>
          </v-avatar>
          {{ orderList.length }}
        </v-chip>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content v-for="n in steps" :key="`${n}-content`" :step="n">
          <!-- __________________________________________________111____________________________________________________________ -->

          <v-card
            v-if="n == 1 || e1 == 1"
            class="grey lighten-5 elevation-2 ma-0"
          >
            <div>
              <v-data-table
                :headers="materialHeaders"
                :items="material"
                :search="search"
                item-key="pro_name"
                sort-by="idex"
                class="elevation-2 table"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>ລາຍການຂໍ້ມູນວັດຖຸດິບ</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="ຄົ້ນຫາ"
                      outlined
                      dense
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-divider></v-divider>
                </template>
                <template v-slot:item="{ item }">
                  <tr v-ripple="{ class: `white--text` }">
                    <td>{{ item.idx }}</td>
                    <td align="center">
                      <v-card
                        height="100"
                        width="100"
                        :img="fectImg(item.image)"
                      ></v-card>
                    </td>
                    <td>{{ item.pro_name }}</td>
                    <td>{{ item.category }}</td>
                    <td>{{ item.color }}</td>
                    <td>{{ item.size }}</td>
                    <td>{{ item.unit }}</td>
                    <td>
                      <v-row>
                        <v-btn
                          @click="AddItem(item)"
                          class="mx-2"
                          fab
                          dark
                          :color="item.check"
                        >
                          <v-icon large>{{ item.icon }}</v-icon>
                        </v-btn>
                      </v-row>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="steps2"
                style="font-size: 20px; font-weight: bold"
                color="#00E5FF"
                rounded
                class="mt-5"
              >
                ໄປໜ້າກະຕ່າສິນຄ້າ
                <v-icon large right
                  >mdi-arrow-right-bold-hexagon-outline</v-icon
                >
              </v-btn>
            </v-card-actions>
          </v-card>
          <!-- __________________________________________________222____________________________________________________________ -->
          <v-card v-if="n == 2" class="elevation-0 ma-0">
            <v-data-table
              :headers="headers"
              :items="orderList"
              :search="search"
              hide-default-footer
              item-key="name"
              v-model="selected"
              sort-by="calories"
              class="elevation-2"
            >
              <template v-slot:top>
                <div>
                  <v-toolbar flat>
                    <v-toolbar-title>ລາຍການສັ່ງຊື້ວັດຖຸດິບ</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                        <v-text-field
                      v-model="suplierItem.name"
                      readonly
                      prepend-icon="mdi-account"
                      @click:prepend="OpenSuplier"
                      label="Suppier"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>

                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title
                          style="
                            font-size: 25px;
                            font-weight: bold;
                            font-family: NotoSansLao;
                          "
                          >ທ່ານຕ້ອງການລຶບຂໍ້ມູນລາຍການນີ້ບໍ...?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            text
                            style="font-size: 20px; font-family: NotoSansLao"
                            @click="close"
                            >ຍົກເລິກ</v-btn
                          >
                          <v-btn
                            color="primary"
                            text
                            style="font-size: 20px; font-family: NotoSansLao"
                            @click="deleteItemConfirm"
                            >ຢຶນຢັນລຶບ</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </div>
              </template>

              <template v-slot:item="{ item }">
                <tr v-ripple="{ class: `white--text` }">
                  <td>{{ item.index }}</td>
                  <td>
                    <v-img
                      :src="fectImg(item.image)"
                      contain
                      height="100"
                      width="100"
                    ></v-img>
                  </td>
                  <td>{{ item.pro_name }}</td>
                  <td>{{ item.category }}</td>
                  <td>{{ item.color }}</td>
                  <td>{{ item.size }}</td>
                  <td>
                    <v-text-field
                      class="mt-5 tcenter"
                      filled
                      dense
                      rounded
                      prepend-inner-icon="mdi-minus"
                      append-icon="mdi-plus"
                      autofocus
                      v-model="item.qty"
                      hint="ຈໍານວນທີ່ຕ້ອງການ"
                      @click:append="Add(pro)"
                      @click:prepend-inner="Minus(item)"
                      @keypress="InputQty($event, item)"
                      @keyup="ZeroQty(item)"
                      v-ripple="{ class: `white--text` }"
                    >
                      <template slot="append">
                        <v-btn
                          style="margin-top: -7px; margin-right: -20px"
                          color="green"
                          icon
                          @click="Add(item)"
                        >
                          <v-icon large>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <template slot="prepend-inner">
                        <v-btn
                          style="margin-top: -7px; margin-left: -20px"
                          color="green"
                          icon
                          @click="Minus(item)"
                        >
                          <v-icon large>mdi-minus</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </td>
                  <td>{{ item.unit }}</td>
                  <td>
                    <v-row>
                      <v-icon color="red" large @click="deleteItem(item)"
                        >mdi-delete</v-icon
                      >
                    </v-row>
                  </td>
                </tr>
              </template>

              <template v-slot:footer>
                <v-row class="ma-5" align="center" justify="center">
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="clear"
                    style="font-size: 20px; font-weight: bold"
                    color="#FF8A65"
                    rounded
                    class="mt-2 mr-7 mb-5"
                  >
                    <v-icon left large>mdi-close-circle</v-icon>ຍົກເລິກ
                  </v-btn>

                  <v-btn
                    @click="saveOrder"
                    style="font-size: 20px; font-weight: bold"
                    color="#1DE9B6"
                    rounded
                    class="mt-2 mr-5 mb-5"
                    :disabled="emty"
                  >
                    <v-icon large left>mdi-check-circle</v-icon
                    >ຢຶນຢັນສັ່ງຊື້ວັດຖຸດິບ
                  </v-btn>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>   <v-dialog v-model="dialogSuplier" max-width="700px">
      <v-card class="ma-2">
        <v-data-table
          :headers="headersSuplier"
          :items="suplier"
          :search="sreachSuppier"
          item-key="idx"
          sort-by="name"
          class="elevation-2 table"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>ຂໍ້ມູນຜູ້ສະໜອງ</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
          </template>
          <template v-slot:item="{ item }">
            <tr v-ripple="{ class: `white--text` }">
              <td>
                <v-row class="justify-center">
                  <v-icon
                    @click="ChoiceSuplier(item)"
                    class="mr-2"
                    color="success"
                  >mdi-account-check</v-icon>
                </v-row>
              </td>
              <td>{{item.idx}}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.tel }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.address }}</td>
            </tr>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="CloseSuplier">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { mapGetters, mapActions } from "vuex";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    suppier: { required, maxLength: maxLength(100) },
  },
  data: () => ({
    e1: 1,
    steps: 2,
    dialog: false,
    dialogDelete: false,
    dialogSuplier: false,
    materialHeaders: [
      { text: "ລໍາດັບ", value: "idex" },
      {
        text: "ຮູບ",
        align: "center",
        sortable: false,
        value: "image",
      },
      { text: "ສິນຄ້າ", value: "name" },
      { text: "ປະເພດ", value: "category" },
      { text: "ສີ", value: "color" },
      { text: "ຂະໜາດ/ເບີ້", value: "size" },
      { text: "ຫົວໜ່ວຍ", value: "unit" },
      { text: "ເລຶອກ", value: "actions", sortable: false },
    ],
    headers: [
          { text: "ລໍາດັບ", value: "idex" },
      {

        text: "ຮູບ",
        align: "start",
        sortable: false,
        value: "image",
      },
      { text: "ສິນຄ້າ", value: "pro_name" },
      { text: "ປະເພດ", value: "category" },
      { text: "ສີ", value: "color" },
      { text: "ຂະໜາດ", value: "size" },
      { text: "ຈໍານວນ", value: "qty" },
      { text: "ຫົວໜ່ວຍ", value: "unit" },
      { text: "ລຶບ", value: "actions", sortable: false },
    ], 
    sreachSuppier: "",
    headersSuplier: [
      { text: "Actions", value: "actions", sortable: false },
      { text: "ລໍາດັບ", value: "idx" },
      { text: "ຊື່ຜູ້ສະໜອງ", value: "name" },
      { text: "ເບີ້ໂທ", value: "tel" },
      { text: "ອີເມວ", value: "email" },
      { text: "ທີ່ຢູ່", value: "address" }
    ],
    suplierItem: {
      sup_id: "",
      name: "",
      tel: "",
      email: "",
      address: ""
    },
    m_id: "",
    orderList: [],
    orderby: {
      m_order_id: null,
      emp_id: "",
      sup_id: "",
      orderdetail: [],
    },
  }),

  computed: {
    ...mapGetters({
      material: "materialMod/material",
      suplier: "supplierMod/suplier"
    }),

    emty() {
      if (this.orderList == [] || this.orderList.length < 1 ||
        this.suplierItem.sup_id == "") return true;
      return false;
    },
    SubplierError() {
      const errors = [];
      if (!this.$v.suppier.$dirty) return errors;
      !this.$v.suppier.maxLength &&
        errors.push("ຂໍ້ມູນຜູ້ສະໜອງຕ້ອງບໍ່ໃຫ້ຍາວເກີນ 100 ຕົວອັກສອນ");
      !this.$v.suppier.required && errors.push("ຂໍ້ມູນຜູ້ສະໜອງວ່າງ....");
      return errors;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getMaterial();
    this.getSuplier();
  },
  methods: {
    ...mapActions("materialOrderMod", ["SaveOrder", "SaveOrderDetail"]),
    ...mapActions("materialMod", ["getMaterial"]),
    ...mapActions("suplierMod", ["getSuplier"]),
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
      OpenSuplier() {
      this.dialogSuplier = true;
    },
    ChoiceSuplier(item) {
      this.suplierItem = item;
      this.CloseSuplier();
    },
    CloseSuplier() {
      this.dialogSuplier = false;
    },
    AddItem(item) {
      item.icon = "mdi-check";
      item.check = "green";
      if (this.orderList.length > 0) {
        for (const key in this.orderList) {
          const el = this.orderList[key];
          if (el.m_id == item.m_id) {
            this.orderList.splice(key, 1);
          }
        }
      }
      var mater = {
        index: item.idx,
        m_id: item.m_id,
        pro_name: item.pro_name,
        category: item.category,
        unit: item.unit,
        color: item.color,
        size: item.size,
        qty: 1,
        image: item.image,
      };
      this.orderList.push(mater);
    },

    Add(item) {
      if (typeof item.qty == "string" && item.qty == "") {
        item.qty = 1;
        return;
      }
      item.qty = parseInt(item.qty) + 1;
    },
    Minus(item) {
      if (parseInt(item.qty, 10) < 2) return;
      item.qty = parseInt(item.qty, 10) - 1;
    },
    ZeroQty(item) {
      if (
        parseInt(item.qty) < 1 ||
        (typeof item.qty == "string" && item.qty.length < 1) ||
        typeof item.qty == undefined
      ) {
        item.qty = 1;
        return;
      }
    },
    InputQty(evt, item) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        // alert("Enter Only Number ? (0-9)");
        evt.preventDefault();
      } else {
        if (
          (typeof item.qty == "string" && item.qty == "") ||
          typeof item.qty == undefined
        ) {
          item.qty = 1;
        } else {
          item.qty = parseInt(item.qty);
        }
        return true;
      }
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.m_id = item.m_id;
    },

    deleteItemConfirm() {
      for (const key in this.material) {
        const element = this.material[key];
        if (element.m_id == this.m_id) {
          element.icon = "mdi-plus";
          element.check = "#0288D1";
        }
      }
      for (const key in this.orderList) {
        const element = this.orderList[key];
        if (element.m_id == this.m_id) {
          this.orderList.splice(key, 1);
          this.dialogDelete = false;
          this.close();
        }
      }
    },

    close() {
      this.dialogDelete = false;
    },

    saveOrder() {
       if (this.suplierItem.sup_id == "" || this.suplierItem.sup_id == null) {
         this.$v.$touch();
        return;
      }
      if (this.orderList.length < 1) return;

      this.orderby.emp_id = this.$store.state.authMod.token.emp_id;
      this.orderby.sup_id = this.suplierItem.sup_id;
      this.orderby.orderdetail = this.orderList;
      var date = new Date().toJSON().slice(0, 10).replace(/-/g, "");
      var time = new Date()
        .toTimeString()
        .replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1", "")
        .replace(":", "")
        .replace(":", "");
      this.orderby.m_order_id = "MOR" + date.slice(2, date.length) + time;
      this.SaveOrder(this.orderby);
      this.SaveOrderDetail(this.orderby);
      var data = {
        m_order_id: "MOR" + date.slice(2, date.length) + time,
      };
      let routeData = this.$router.resolve({
        name: "bills_material_order",
        query: data,
      });
      window.open(routeData.href, "_blank");
      this.clear();
    },
    steps2() {
      this.e1 = 2;
    },
    clear() {
      this.orderby = {
        emp_id: "",
        sup_id: "",
        orderdetail: [],
      };
      this.orderList = [];
      this.suplierItem={
      sup_id: "",
      name: "",
      tel: "",
      email: "",
      address: ""
    }
      for (const key in this.material) {
        const element = this.material[key];
        element.icon = "mdi-plus";
        element.check = "#0288D1";
      }
      this.e1 = 1;
    },
  },
};
</script>
<style scoped>
.file-upload {
  margin: 7px 0px;
  font-size: 16px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #999;
}
.file-upload:hover {
  border-bottom: 1px solid #555;
}
::-webkit-file-upload-button {
  background: #eeeeee;
  border-radius: 0px 50px 50px 0px;
  padding: 7px;
  width: 100px;
  border: 1px solid #edffdd;
}
::-webkit-file-upload-button:hover {
  background: #dedede;
}
.tcenter {
  width: 200px;
  min-width: 100px;
}
.tcenter >>> input {
  text-align: center;
}
</style>