<template>
  <div>
    <v-card
      cols="12"
      sm="4"
      class="mb-7 mt-10"
      flat
      v-for="(item, index) in Shop_Info"
      :key="index"
    >
      <v-row justify="center">
        <h1 class="ml-9 mt-3 mb-7" id="myfont">*ສະໜັກເປັນຕົວແທນ*</h1>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" sm="6" md="5">
          <h1 class="ml-7 mt-8" id="myfont">*ໂອນຜ່ານບັນຊີທະນາຄານ</h1>
          <v-img
            class="ml-9"
            height="120"
            width="120"
            src="@/assets/images/bcelone.png"
          ></v-img>
          <v-row>
            <h1 class="ml-7 mt-7" id="myfont">ເລກບັນຊີ:</h1>
            <h1 class="ml-7 mt-7" id="myfont">
              {{ item.bank_account_no }}
            </h1>
          </v-row>
          <v-row>
            <h1 class="ml-7 mt-7" id="myfont">ຊື່ບັນຊີ:</h1>
            <h1 class="ml-7 mt-7" id="myfont">
              {{ item.bank_account_name }}
            </h1>
          </v-row>
          <v-row>
            <h1 class="ml-7 mt-7" id="myfont">ປະເພດບັນຊີ:</h1>
            <h1 class="ml-7 mt-7 mb-7" id="myfont">Saving</h1>
          </v-row>
          <h1 class="ml-7" id="myfont">*QR Code</h1>
          <v-img
            class="ml-9 mb-8"
            height="300"
            width="300"
            src="@/assets/images/houa.png"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="6" md="5">
          <h1 class="mt-9 mr-7 ml-9" id="myfont">ຄ່າສະມັກຕົວແທນ*</h1>
          <v-row>
            <v-text-field
            textfield
              class="mt-5 ml-12 mr-5"
              outlined
              :value="
                      String(item.register_pay).replace(
                        /(.)(?=(\d{3})+$)/g,
                        '$1 '
                      )
                    "
              readonly
            ></v-text-field>
            <h1 class="mt-9 mr-5" id="myfont">ກິບ</h1>
          </v-row>
          <v-divider class="ml-7 mt-15 mb-4"></v-divider>
          <h1 class="ml-9" id="myfont">ຮູບແບບການຈ່າຍເງີນ*</h1>
          <v-row dense justify="start" class="ml-8">
            <v-radio-group
              required
              :error-messages="Pay_MethodError"
              @change="$v.pay_method.$touch()"
              @blur="$v.pay_method.$touch()"
              v-model="pay_method"
              row
            >
              <v-col>
                <v-radio
                  label="ຈ່າຍຜ່ານ Bcel One"
                  value="ຈ່າຍຜ່ານ Bcel One"
                  class="radio"
                ></v-radio>
                <v-radio
                  label="ຈ່າຍຜ່ານ Paypal"
                  value="ຈ່າຍຜ່ານ Paypal"
                  class="mt-5 radio"
                ></v-radio>
                <v-radio
                  label="ຈ່າຍຜ່ານ Western Union"
                  value="ຈ່າຍຜ່ານ Western Union"
                  class="mt-5 radio"
                ></v-radio>
                <v-radio
                  label="ຈ່າຍຜ່ານ MoneyGram"
                  value="ຈ່າຍຜ່ານ MoneyGram"
                  class="mt-5 radio"
                ></v-radio>
                <v-radio
                  label="ຈ່າຍຮູບແບບອື່ນໆ"
                  value="ຈ່າຍຮູບແບບອື່ນໆ"
                  class="mt-5 radio"
                ></v-radio>
                <v-text-field
                textfield
                  v-if="pay_method == 'ຈ່າຍຮູບແບບອື່ນໆ'"
                  class="mt-5 mr-10"
                  outlined
                  placeholder="Pay Method"
                  :error-messages="Pay_ByError"
                  @change="$v.Pay_By.$touch()"
                  @blur="$v.Pay_By.$touch()"
                  v-model="Pay_By"
                ></v-text-field>
              </v-col>
            </v-radio-group>
          </v-row>
          <h1 class="mt-9 mr-7 ml-9" id="myfont">ຮູບອ້າງອິງການຈ່າຍເງີນ*</h1>
          <input
            prepend-icon="mdi-camera"
            type="file"
            class="file-upload ml-7 mt-5"
            @change="onFileSelected"
            show-size
            label="Image input"
            required
          />
          <v-divider class="ml-7"></v-divider>
          <v-img
            class="mt-4"
            :src="url"
            v-show="card_capture"
            max-height="450"
            aspect-ratio="1.7"
            contain
          ></v-img>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-btn id="btntext" class="mb-16 mr-10" color="error"> ຍົກເລິກ </v-btn>
        <v-btn
          id="btntext"
          color="#01BEFE"
          @click="Register(item.register_pay)"
          class="mb-16 mr-10"
        >
          ຢຶນຢັນ
        </v-btn>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    txtfullname: { required, maxLength: maxLength(100) },
    Pay_By: { required },
    pay_method: { required },
  },
  data() {
    return {
      url: null,
      profile: "",
      pay_method: "",
      Pay_By: "",
    };
  },
  mounted() {
    this.$store.dispatch("Shop_Info");
    this.$store.dispatch("Customer_List");
  },
  computed: {
    Shop_Info() {
      // alert(this.$store.state.Shop_Info)
      return this.$store.state.Shop_Info;
    },
    Customer_detail() {
      // alert(this.$store.state.Customer_detail)
      return this.$store.state.Customer_detail;
    },

    PasswordErrors() {
      const errors = [];
      if (!this.$v.txtpassword.$dirty) return errors;
      !this.$v.txtpassword.minLength &&
        errors.push("Password must be at less 6 characters short");
      !this.$v.txtpassword.maxLength &&
        errors.push("Password must be at most 20 characters long");
      !this.$v.txtpassword.required && errors.push("Password is required.");
      return errors;
    },
    Pay_ByError() {
      const errors = [];
      if (!this.$v.Pay_By.$dirty) return errors;
      !this.$v.Pay_By.required && errors.push("Pay Method is required.");
      return errors;
    },
    Pay_MethodError() {
      const errors = [];
      if (!this.$v.pay_method.$dirty) return errors;
      !this.$v.pay_method.required && errors.push("");
      return errors;
    },
  },
  methods: {
    Register(register_pay) {
      if (this.pay_method == "") {
        this.$v.$touch();
        return;
      }
      if (this.pay_method == "ຈ່າຍຮູບແບບອື່ນໆ" && this.Pay_By == "") {
        this.$v.$touch();
        return;
      }
      if (this.pay_method == "ຈ່າຍຮູບແບບອື່ນໆ") {
        this.pay_method = this.Pay_By;
      }
      var cusID = JSON.parse(localStorage.getItem("Customer_Info")).cus_id;
      var RegisterID = cusID;
      this.$store.dispatch("Add_Customer_member", {
        cus_id: cusID,
        register_pay: register_pay,
        register_date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        pay_method: this.pay_method,
        card_capture: this.card_capture,
      });
      this.$store.dispatch("Edit_Customer_Status", {
        cus_id: cusID,
        status: "Confirm",
      });
       this.$store.dispatch("Submit_Comment", {
          cus_id: cusID,
          coment_id: RegisterID,
          customer_content: "ສະມັກເປັນຕົວແທນກັບ ຮ້ານດາດ້າແຟຊັນ",
        });

      this.$v.$reset();
      this.$router.push(`/Customer/`);
    },
    onFileSelected(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.url = e.target.result;
      };
      this.card_capture = event.target.files[0].name;
      // alert(JSON.stringify(this.url));
    },
    BtnClear() {
      this.$v.$reset();
    },
  },
};
</script>

<style>
#btntext {
  font-size: 18px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: white;
}
.radio {
  font-size: 40px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: black;
}
</style>
<style lang="css">
.font {
  font-family: "NotoSansLao";
}
</style>