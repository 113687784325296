<template>
  <v-app class="font grey lighten-5">
    <!-- <NavigationDrawer/>
    <AppBar/> -->
    <v-app-bar app light clipped-left dense flat color="#E0E0E0">
      <v-app-bar-nav-icon @click.stop="mini = !mini"></v-app-bar-nav-icon>
      <v-avatar size="35" class="mr-1 ml-2 home" @click="DasboardPage">
        <v-img src="@/assets/images/DaDa_LoGo.png" />
      </v-avatar>
      <h2 @click="DasboardPage" class="home">DaDaFashion</h2>
      <v-spacer></v-spacer>
      <h2 style="color:#004D40">
        ລະບົບບໍລິການ ການຂາຍເຄື່ອງແຟຊັ່ນອອນລາຍຂອງຮ້ານ ດາດ້າແຟຊັ່ນ
      </h2>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, menu, attrs }">
          <v-chip class="py-2" outlined v-bind="attrs" v-on="on" icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon color="black" v-on="{ ...tooltip, ...menu }"
                  >mdi-bell</v-icon
                >
              </template>
              <span class="tooltip">ແຈ້ງເຕືອນ</span>
            </v-tooltip>
            <span>{{
              newmember.length + newOrders.length + new_cutItems.length || 0
            }}</span>
          </v-chip>
        </template>
        <v-list v-ripple="{ class: `error--text` }">
          <v-list-item
            link
            @click="onClickMenu('/recieve_customer')"
            v-show="newmember.length > 0"
          >
            <v-list-item-content>
              <v-list-item-title
                style="font-size: 17px;font-family:NotoSansLao"
              >
                <v-icon color="">mdi-account-plus</v-icon>
                ຕົວແທນໃໝ່
                <v-chip class="" color="">
                  <span style="color: #FF1744">{{ newmember.length }}</span>
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            @click="onClickMenu('/recieve_orderBuy')"
            v-show="newOrders.length > 0"
          >
            <v-list-item-content>
              <v-list-item-title
                style="font-size: 17px;font-family:NotoSansLao"
              >
                <v-icon color="">mdi-phone-return</v-icon>
                ສັ່ງຊື້ສິນຄ້າ
                <v-chip class="" color="">
                  <span style="color: #FF1744">{{ newOrders.length }}</span>
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            @click="onClickMenu('/recieve_cut')"
            v-show="new_cutItems.length > 0"
          >
            <v-list-item-content>
              <v-list-item-title
                style="font-size: 17px;font-family:NotoSansLao"
              >
                <v-icon color="">mdi-content-cut</v-icon>
                ສັ່ງຕັດສິນຄ້າ
                <v-chip class="" color="">
                  <span style="color: #FF1744">{{ new_cutItems.length }}</span>
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-avatar
        size="40"
        class="mr-5 ml-7"
        @click="dialog = !dialog"
        v-ripple="{ class: `error--text` }"
      >
        <v-img
          :src="fectEmpImg(this.$store.state.authMod.token.image)"
          alt="Riam"
        />
      </v-avatar>

      <router-link to="/Login">
        <v-btn icon @click="doLogout">
          <v-icon large>mdi-logout</v-icon>
        </v-btn>
      </router-link>
    </v-app-bar>

    <v-navigation-drawer
      app
      clipped
      :mini-variant.sync="mini"
      v-model="drawer"
      disable-resize-watcher
      v-ripple="{ class: `error--text` }"
      class="elevation-1"
    >
      <v-list>
        <v-list-item class="px-2">
          <v-row justify="center" no-gutters @click.stop="mini = !mini">
            <v-list-item-avatar
              @click.stop="mini = !mini"
              :size="mini ? '40' : '100'"
              v-ripple="{ class: `error--text` }"
            >
              <v-img src="@/assets/images/DaDa_LoGo.png"></v-img>
            </v-list-item-avatar>
          </v-row>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title style="font-size: 25px; font-weight: bold">
              ຮ້ານ ດາດ້າແຟຊັ່ນ
            </v-list-item-title>
            <v-list-item-subtitle>
              ບໍລິການຂາຍເຄື່ອງແຟຊັ່ນຕ່າງໆ</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense v-if="user.status == 'Admin' || user.status == 'admin'">
        <v-list-group
          v-ripple="{ class: `error--text` }"
          mandatory
          v-for="item in Admin_menus"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          :append-icon="item.append_icon"
          @click="onClickMenu(item.route)"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item-group
            v-for="child in item.items"
            :key="child.title"
            :mandatory="child.active"
          >
            <v-list-item
              @click="onClickMenu(child.route)"
              @mousedown="SelectMenu(item.title, child)"
              class="ml-3"
              v-model="child.active"
            >
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list-group>
      </v-list>

      <v-list nav dense v-else>
        <v-list-group
          v-ripple="{ class: `error--text` }"
          mandatory
          v-for="item in User_menus"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          :append-icon="item.append_icon"
          @click="onClickMenu(item.route)"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-avatar size="130" mt="2">
                <v-img
                  :src="fectEmpImg(this.$store.state.authMod.token.image)"
                  alt="Riam"
                />
              </v-avatar>
            </v-row>
            <v-row>
              <v-col cols="12" :sm="editdg">
                <v-text-field
                  v-model="user.emp_name"
                  label="name"
                  outlined
                  readonly
                ></v-text-field>

                <v-text-field
                  v-model="user.emp_surname"
                  label="surname"
                  outlined
                  readonly
                ></v-text-field>

                <v-text-field
                  v-model="user.tel"
                  label="tel"
                  outlined
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model="user.email"
                  label="email"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="editpass">
                <v-text-field
                  v-model="oldpass"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show ? 'text' : 'password'"
                  name="input-10-1"
                  label="old password"
                  hint="At least 8 characters"
                  counter
                  outlined
                  @click:append="show = !show"
                ></v-text-field>
                <v-text-field
                  v-model="newpass"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show ? 'text' : 'password'"
                  name="input-10-1"
                  label="Create new password"
                  hint="At least 8 characters"
                  counter
                  outlined
                  @click:append="show = !show"
                ></v-text-field>
                <v-text-field
                  v-model="cfpass"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show ? 'text' : 'password'"
                  name="input-10-1"
                  label="Comfirm password"
                  hint="At least 8 characters"
                  counter
                  outlined
                  @click:append="show = !show"
                ></v-text-field>
                <small>*indicates required field</small>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              editpass = true;
              editdg = 6;
            "
          >
            Edit
          </v-btn>
          <v-btn
            v-if="editpass"
            color="blue darken-1"
            text
            @click="
              dialog = false;
              editpass = false;
              editdg = 12;
            "
          >
            Save
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="
              dialog = false;
              editpass = false;
              editdg = 12;
            "
          >
            close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-app>
</template>
<script>
// import AppBar from '@/components/admin/admin.appbar.vue'
// import NavigationDrawer from '@/components/admin/admin.drawer.vue'

import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      editpass: false,
      editdg: 12,
      show: true,
      password: "Password",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      oldpass: "",
      newpass: "",
      cfpass: "",
      mini: true,
      user: [],
      Admin_menus: [
        {
          action: "mdi-view-dashboard",
          append_icon: "",
          title: "Dashboard",
          route: "/dashboard",
          active: true,
        },
        {
          action: "mdi-dots-triangle",
          append_icon: "mdi-menu-down",
          route: "/not",
          active: false,
          items: [
            {
              icon: "mdi-folder-information",
              title: "ຂໍ້ມູນຮ້ານ",
              route: "/manage_shop",
              active: false,
            },
            {
              icon: "mdi-material-design",
              title: "ຂໍ້ມູນວັດຖຸດິບ",
              route: "/manage_material",
              active: false,
            },
            {
              icon: "mdi-shape",
              title: "ປະເພດ",
              route: "/manage_category",
              active: false,
            },
            {
              icon: "mdi-unity",
              title: "ຫົວໜ່ວຍ",
              route: "/manage_unit",
              active: false,
            },
            {
              icon: "mdi-tshirt-crew",
              title: "ສິນຄ້າ",
              route: "/manage_product",
              active: false,
            },
            {
              icon: "mdi-account-tie",
              title: "ພະນັກງານ",
              route: "/manage_employee",
              active: false,
            },
            {
              icon: "mdi-account",
              title: "ລູກຄ້າ",
              route: "/manage_customer",
              active: false,
            },
            {
              icon: "mdi-account-cowboy-hat",
              title: "ຜູ້ສະໜອງ",
              route: "manage_supplier",
              active: false,
            },
          ],
          title: "ຈັດການຂໍ້ມູນພື້ນຖານ",
        },
        {
          action: "mdi-message-arrow-right",
          append_icon: "",
          title: "ສັ່ງຊື້ວັດຖຸດິບ",
          route: "/material_order_steper",
          active: false,
        },
        {
          action: "mdi-table-arrow-left",
          append_icon: "",
          route: "/material_import",
          title: "ນໍາເຂົ້າວັດຖຸດິບ",
          active: false,
        },
        {
          action: "mdi-account-plus",
          append_icon: "",
          route: "/recieve_customer",
          title: "ຮັບສະໝັກຕົວແທນ",
          active: false,
        },
        {
          action: "mdi-content-cut",
          append_icon: "",
          route: "/recieve_cut",
          title: "ຮັບສັ່ງຕັດເຄື່ອງ",
          active: false,
        },
        {
          action: "mdi-basket-plus",
          append_icon: "",
          route: "/recieve_orderBuy",
          title: "ຮັບສັ່ງຊື້ສິນຄ້າ",
          active: false,
        },
        {
          action: "mdi-widgets",
          append_icon: "",
          route: "/sale_page",
          title: "ຂາຍສິນຄ້າ",
          active: false,
        },
        {
          action: "mdi-chart-bar",
          append_icon: "mdi-menu-down",
          route: "/not",
          active: false,
          items: [
            {
              icon: "mdi-tshirt-crew",
              title: "ຂໍ້ມູນສິນຄ້າ",
              route: "/report_production",
              active: false,
            },
            {
              icon: "mdi-table-arrow-left",
              title: "ຂໍ້ມູນນໍາເຂົ້າວັດຖຸດິບ",
              route: "/report_import",
              active: false,
            },
            {
              icon: "mdi-chart-bell-curve",
              title: "ຂໍ້ມູນການຂາຍສິນຄ້າ",
              route: "/report_sale",
              active: false,
            },
            {
              icon: "mdi-scissors-cutting",
              title: "ຂໍ້ມູນການສັ່ງຕັດເຄື່ອງ",
              route: "/report_cut",
              active: false,
            },
            {
              icon: "mdi-baby-carriage",
              title: "ຂໍ້ມູນການສັ່ງຊື້ສິນຄ້າ",
              route: "/report_orderBuy",
              active: false,
            },
            {
              icon: "mdi-account-question",
              title: "ຂໍ້ມູນຕົວແທນ",
              route: "/report_customer",
              active: false,
            },
          ],
          title: "ລາຍງານ",
        },
      ],
      User_menus: [
        {
          action: "mdi-view-dashboard",
          append_icon: "",
          title: "Dashboard",
          route: "/dashboard",
          active: true,
        },

        {
          action: "mdi-content-cut",
          append_icon: "",
          route: "/recieve_cut",
          title: "ຮັບສັ່ງຕັດເຄື່ອງ",
          active: false,
        },
        {
          action: "mdi-phone-return",
          append_icon: "",
          route: "/recieve_orderBuy",
          title: "ຮັບສັ່ງຊື້ເຄື່ອງ",
          active: false,
        },
        {
          action: "mdi-widgets",
          append_icon: "",
          route: "/sale_page",
          title: "ຂາຍສິນຄ້າ",
          active: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      newOrders: "orderMod/new_orders",
      newmember: "customerMod/cust_newmember",
      new_cutItems: "cutMod/new_cutItems",
    }),
  },
  mounted() {
    this.initial();
    this.mapMenu();
  },
  watch: {
    $route(to) {
      if (this.user.status == "Admin" || this.user.status == "admin") {
        this.selectedMenu = this.Admin_menus.path(
          (menus) => menus.route == to.path
        );
      } else {
        this.selectedMenu = this.User_menus.path(
          (menus) => menus.route == to.path
        );
      }
    },
  },
  methods: {
    ...mapActions("orderMod", ["getNewOrder"]),
    ...mapActions("customerMod", ["getCustnewMember"]),
    ...mapActions("cutMod", ["getNewCut"]),
    initial() {
      this.getNewOrder();
      this.getCustnewMember();
      this.isLogin();
      this.getNewCut();
    },
    mapMenu() {
      if (this.user.status == "Admin" || this.user.status == "admin") {
        this.selectedMenu = this.Admin_menus.findIndex(
          (menus) => menus.route == this.$route.path
        );
      } else {
        this.selectedMenu = this.User_menus.findIndex(
          (menus) => menus.route == this.$route.path
        );
      }
    },

    isLogin() {
      if (this.$store.state.authMod.token.length === 0) {
        this.$router.push("/");
      } else {
        this.user = this.$store.state.authMod.token;
      }
    },
    fectEmpImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/Employees/" + img);
      } else {
        imageUrl = require("@/assets/logo.png");
      }
      return imageUrl;
    },
    onClickMenu(link) {
      if (link != "/not") {
        this.$router.push(link).catch((error) => {
          console.log(error);
        });
      }
    },
    SelectMenu(title, active) {
      if (title == "ຈັດການຂໍ້ມູນພື້ນຖານ") {
        this.Admin_menus[1].items.forEach((el) => {
          el.active = false;
        });
      } else {
        this.Admin_menus[8].items.forEach((el) => {
          el.active = false;
        });
      }
      active.active = true;
    },
    doLogout() {
      window.localStorage.removeItem("token");
    },
    DasboardPage() {
      this.$router.push("/dashboard");
    },
  },
};
</script>
<style lang="css">
.font {
  font-family: "NotoSansLao";
}
.home:hover {
  cursor: pointer;
}
</style>
