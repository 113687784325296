<template>
  <div
    v-if="
      $store.state.authMod.token.status == 'Admin' ||
      $store.state.authMod.token.status == 'admin'
    "
  >
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="2" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <h2 style="margin-top: 30px">ປັບປຸງຂໍ້ມູນພະນັກງານ</h2>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    readonly
                    v-model="employees.id"
                    :error-messages="IDErrors"
                    :counter="100"
                    label="ລະຫັດພະນັກງານ"
                    required
                    @input="$v.employees.id.$touch()"
                    @blur="$v.employees.id.$touch()"
                  ></v-text-field
                  ><v-text-field
                    v-model="employees.name"
                    :error-messages="NameErrors"
                    :counter="100"
                    label="ຊື່ພະນັກງານ"
                    required
                    @input="$v.employees.name.$touch()"
                    @blur="$v.employees.name.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="employees.surename"
                    :error-messages="SurenameErrors"
                    :counter="100"
                    label="ນານສະກຸນ"
                    required
                    @input="$v.employees.surename.$touch()"
                    @blur="$v.employees.surename.$touch()"
                  ></v-text-field>
                  <v-radio-group
                    label="ເພດ"
                    v-model="employees.gender"
                    row
                    required
                    :error-messages="GenderErrors"
                    @change="$v.employees.gender.$touch()"
                    @blur="$v.employees.gender.$touch()"
                  >
                    <v-row class="justify-space-around">
                      <v-radio label="ຊາຍ" value="ຊາຍ"></v-radio>
                      <v-radio label="ຍິງ" value="ຍິງ"></v-radio>
                    </v-row>
                  </v-radio-group>
                  <v-divider style="margin-top: -12px"></v-divider>
                  <v-text-field
                    v-model="employees.tel"
                    :error-messages="TelErrors"
                    :counter="100"
                    label="ເບີ້ໂທລະສັບ"
                    required
                    @keypress="isNumber($event)"
                    @input="$v.employees.tel.$touch()"
                    @blur="$v.employees.tel.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="employees.facebook"
                    :error-messages="FacebookErrors"
                    :counter="100"
                    label="ແຟ໌ສບຸກ"
                    required
                    @input="$v.employees.facebook.$touch()"
                    @blur="$v.employees.facebook.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="employees.email"
                    :error-messages="emailErrors"
                    label="ອີແມວ"
                    counter
                    required
                    @input="$v.employees.email.$touch()"
                    @blur="$v.employees.email.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="employees.village"
                    :error-messages="VillageErrors"
                    :counter="100"
                    label="ບ້ານຢູປັດຈຸບັນ"
                    required
                    @input="$v.employees.village.$touch()"
                    @blur="$v.employees.village.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model="employees.district"
                    :error-messages="DistrictErrors"
                    :counter="100"
                    label="ເມືອງ"
                    required
                    @input="$v.employees.district.$touch()"
                    @blur="$v.employees.district.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model="employees.province"
                    :error-messages="ProvinceErrors"
                    :counter="100"
                    label="ແຂວງ"
                    required
                    @input="$v.employees.province.$touch()"
                    @blur="$v.employees.province.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="employees.password"
                    :error-messages="PasswordErrors"
                    label="ລະຫັດຜ່ານ"
                    counter
                    required
                    @input="$v.employees.password.$touch()"
                    @blur="$v.employees.password.$touch()"
                  ></v-text-field>

                  <v-radio-group
                    v-model="employees.status"
                    row
                    label="ສະຖານະ"
                    required
                    :error-messages="StatusErrors"
                    @change="$v.employees.status.$touch()"
                    @blur="$v.employees.status.$touch()"
                  >
                    <v-row class="justify-space-around">
                      <v-radio label="Admin" value="Admin"></v-radio>
                      <v-radio label="User" value="User"></v-radio>
                    </v-row>
                  </v-radio-group>
                  <v-divider style="margin-top: -10px"></v-divider>
                  <h4 class="mt-5" style="font-size: 17px; font-weight: normal">
                    ເລຶອກຮູບພະນັກງານ
                  </h4>
                  <input
                    type="file"
                    class="file-upload"
                    @change="onFileSelected"
                    accept="image/*"
                    hide-details
                    :error-messages="ProfileErrors"
                    required
                    @input="$v.employees.profile.$touch()"
                    @click="$v.employees.profile.$touch()"
                    @blur="$v.employees.profile.$touch()"
                    v-ripple="{ class: `error--text` }"
                  /><br />
                  <v-img
                    :src="brow ? imgUrl : fectImg(employees.profile)"
                    max-height="450"
                    aspect-ratio="1.7"
                    contain
                    v-ripple="{ class: `white--text` }"
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-5">
              <v-btn
                @click="back"
                style="font-size: 20px; font-weight: bold"
                color="deep-purple lighten-4"
                rounded
                class="mt-2"
              >
                <v-icon left large> mdi-arrow-left-circle </v-icon>
                ກັບສູ່ໜ້າຂໍ້ມູນພະນັກງານ
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn
                @click="clear"
                style="font-size: 20px; font-weight: bold"
                color="#FF8A65"
                rounded
                class="mr-7 mt-2"
              >
                <v-icon left large> mdi-close-circle </v-icon>
                ຍົກເລິກ
              </v-btn>

              <v-btn
                @click="submit"
                style="font-size: 20px; font-weight: bold"
                color="#1DE9B6"
                rounded
                class="mr-5 mt-2"
              >
                <v-icon left large> mdi-check-circle </v-icon>
                ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    employees: {
      id: { required, maxLength: maxLength(100) },
      name: { required, maxLength: maxLength(100) },
      surename: { required, maxLength: maxLength(100) },
      gender: { required },
      tel: { required, maxLength: maxLength(20) },
      facebook: { required, maxLength: maxLength(100) },
      email: { required, email },
      password: { required, minLength: minLength(6), maxLength: maxLength(20) },
      village: { required, maxLength: maxLength(100) },
      district: { required, maxLength: maxLength(100) },
      province: { required, maxLength: maxLength(100) },
      status: { required },
      profile: { required, maxLength: maxLength(500) },
    },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },

  data: () => ({
    imgUrl: "",
    brow: false,
  }),

  computed: {
    ...mapGetters({
      employees: "employeeMod/employeedit",
    }),
    IDErrors() {
      const errors = [];
      if (!this.$v.employees.id.$dirty) return errors;
      !this.$v.employees.id.maxLength &&
        errors.push("ID must be at most 100 characters long");
      !this.$v.employees.id.required && errors.push("ID is required.");
      return errors;
    },
    NameErrors() {
      const errors = [];
      if (!this.$v.employees.name.$dirty) return errors;
      !this.$v.employees.name.maxLength &&
        errors.push("Name must be at most 100 characters long");
      !this.$v.employees.name.required && errors.push("Name is required.");
      return errors;
    },
    SurenameErrors() {
      const errors = [];
      if (!this.$v.employees.surename.$dirty) return errors;
      !this.$v.employees.surename.maxLength &&
        errors.push("Surename must be at most 100 characters long");
      !this.$v.employees.surename.required &&
        errors.push("Surename is required.");
      return errors;
    },
    GenderErrors() {
      const errors = [];
      if (!this.$v.employees.gender.$dirty) return errors;
      !this.$v.employees.gender.required &&
        errors.push("You must Check Gender!");
      return errors;
    },
    TelErrors() {
      const errors = [];
      if (!this.$v.employees.tel.$dirty) return errors;
      !this.$v.employees.tel.maxLength &&
        errors.push("Tel must be at most 20 characters long");
      !this.$v.employees.tel.required && errors.push("tel is required.");
      return errors;
    },
    FacebookErrors() {
      const errors = [];
      if (!this.$v.employees.facebook.$dirty) return errors;
      !this.$v.employees.facebook.maxLength &&
        errors.push("Facebook must be at most 100 characters long");
      !this.$v.employees.facebook.required &&
        errors.push("Facebook is required.");
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.employees.email.$dirty) return errors;
      !this.$v.employees.email.email && errors.push("Must be valid e-mail");
      !this.$v.employees.email.required && errors.push("E-mail is required");
      return errors;
    },
    PasswordErrors() {
      const errors = [];
      if (!this.$v.employees.password.$dirty) return errors;
      !this.$v.employees.password.minLength &&
        errors.push("Password must be at less 6 characters short");
      !this.$v.employees.password.maxLength &&
        errors.push("Password must be at most 20 characters long");
      !this.$v.employees.password.required &&
        errors.push("Password is required.");
      return errors;
    },

    VillageErrors() {
      const errors = [];
      if (!this.$v.employees.village.$dirty) return errors;
      !this.$v.employees.village.maxLength &&
        errors.push("Village must be at most 100 characters long");
      !this.$v.employees.village.required &&
        errors.push("Village is required.");
      return errors;
    },

    DistrictErrors() {
      const errors = [];
      if (!this.$v.employees.district.$dirty) return errors;
      !this.$v.employees.district.maxLength &&
        errors.push("District must be at most 100 characters long");
      !this.$v.employees.district.required &&
        errors.push("District is required.");
      return errors;
    },

    ProvinceErrors() {
      const errors = [];
      if (!this.$v.employees.province.$dirty) return errors;
      !this.$v.employees.province.maxLength &&
        errors.push("Province must be at most 100 characters long");
      !this.$v.employees.province.required &&
        errors.push("Province is required.");
      return errors;
    },
    StatusErrors() {
      const errors = [];
      if (!this.$v.employees.status.$dirty) return errors;
      !this.$v.employees.status.required &&
        errors.push("You must Check Status!");
      return errors;
    },
    ProfileErrors() {
      const errors = [];
      if (!this.$v.employees.profile.$dirty) return errors;
      !this.$v.employees.profile.maxLength &&
        errors.push("Profile must be at most 500 characters long");

      !this.$v.employees.profile.required &&
        errors.push("Frofile is required!");
      return errors;
    },
  },

  mounted() {
    this.initail();
  },

  methods: {
    ...mapActions("employeeMod", ["getEmployeeByone", "UpdateEmployee"]),
    async initail() {
      this.getEmployeeByone(this.$route.params.id);
    },
    onFileSelected(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.imgUrl = e.target.result;
      };
      this.employees.profile = event.target.files[0].name;
      this.brow = true;
    },
       isNumber: function ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        alert("ເບີໂທລະສັບຕ້ອງເປັນຕົວເລກ (0-9)");
        $event.preventDefault();
      }
    },
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/Employees/" + img);
      } else {
       imageUrl = require("@/assets/Emty_Profile.png");
      }
      return imageUrl;
    },
    async submit() {
      this.$v.$touch();
      try {
        if (
          this.employees.id == "" ||
          this.employees.id == null ||
          this.employees.id.length > 100
        ) {
          this.$v.$touch();
          return;
        }
        if (
          this.employees.name == "" ||
          this.employees.name == null ||
          this.employees.name.length > 100
        ) {
         this.$v.$touch();
          return;
        }
        if (
          this.employees.surename == "" ||
          this.employees.surename == null ||
          this.employees.surename.length > 100
        ) {
          this.$v.$touch();
          return;
        }
        if (this.employees.gender == "" || this.employees.gender == null) {
          this.$v.$touch();
          return;
        }
        if (
          this.employees.tel == "" ||
          this.employees.tel == null ||
          this.employees.tel.length > 20
        ) {
         this.$v.$touch();
          return;
        }
        if (
          this.employees.email == "" ||
          this.employees.email == null ||
          this.employees.email.length > 100
        ) {
           this.$v.$touch();
          return;
        }
         if (
          this.employees.village == "" ||
          this.employees.village == null ||
          this.employees.village.length > 100
        ) {
           this.$v.$touch();
          return;
        }
         if (
          this.employees.district == "" ||
          this.employees.district == null ||
          this.employees.district.length > 100
        ) {
           this.$v.$touch();
          return;
        }
        if (
          this.employees.password == "" ||
          this.employees.password == null ||
          this.employees.password.length < 6 ||
          this.employees.password.length > 20
        ) {
          this.$v.$touch();
          return;
        }
        if (this.employees.status == "" || this.employees.status == null) {
         this.$v.$touch();
          return;
        }

        this.UpdateEmployee(this.employees);

        this.clear();
        this.back();
      } catch (e) {
        this.errors.push(e);
      }
    },
    clear() {
      this.$v.$reset();
      this.employees.surename = "";
      this.employees.name = "";
      this.employees.email = "";
      this.employees.tel = "";
      this.employees.facebook = "";
      this.employees.gender = "";
      this.employees.password = "";
      this.employees.province = "";
      this.employees.district = "";
      this.employees.village = "";
      this.employees.status = "";
      this.employees.profile = "";
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.file-upload {
  margin: 7px 0px;
  font-size: 16px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #999;
}
.file-upload:hover {
  border-bottom: 1px solid #555;
}
::-webkit-file-upload-button {
  background: #eeeeee;
  border-radius: 0px 50px 50px 0px;
  padding: 7px;
  width: 180px;
  border: 1px solid #edffdd;
}
::-webkit-file-upload-button:hover {
  background: #dedede;
}
</style>