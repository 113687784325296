var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"fixed-tabs":"","slider-color":"#880E4F","large":"","color":"#880E4F"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tabs-slider'),_vm._l((2),function(i){return _c('v-tab',{key:i,attrs:{"value":'mobile-tabs-5-' + i}},[_c('v-col',[(i == 1)?_c('div',[_c('h2',[_vm._v("ລາຍການສິນຄ້າສັ່ງຕັດ")])]):_vm._e(),(i == 2)?_c('div',[_c('h2',[_vm._v("ລາຍການສິນຄ້າສັ່ງຊື້")])]):_vm._e()])],1)})],2),_c('v-divider')]},proxy:true}])},[_c('v-row',{staticClass:"ml-4",attrs:{"justify":"start"}},[_c('h1',[_vm._v("ຟອມຂາຍສິນຄ້າ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-6 mt-2",staticStyle:{"font-size":"24px"},attrs:{"rounded":"","text":"","color":"#304FFE"},on:{"click":_vm.GotoSale_Page}},[_vm._v(" ກັບສູ່ໜ້າເລຶອກສິນຄ້າ "),_c('v-icon',{attrs:{"large":"","color":"#304FFE","right":""}},[_vm._v("mdi-arrow-right-bold-hexagon-outline")])],1)],1)],1),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"search":_vm.newsearch,"headers":_vm.newHeaders,"items":_vm.cuttingItems,"single-expand":true,"expanded":_vm.newexpanded,"item-key":"cut_id","show-expand":""},on:{"update:expanded":function($event){_vm.newexpanded=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"font-size":"20px","font-weight":"bold"},attrs:{"color":"#004D40","outlined":""},on:{"click":function($event){return _vm.SaveOrderCut(item)}}},[_c('v-icon',{attrs:{"large":"","color":"#C51162"}},[_vm._v("mdi-cart-arrow-right")]),_vm._v(" ຂາຍ")],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-2",staticStyle:{"background":"#fafafaff"},attrs:{"headers":_vm.newdetailHeader,"items":item.orderDetail,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: "white--text" }),expression:"{ class: `white--text` }"}]},[_c('td',[_vm._v(_vm._s(item.idx))]),_c('td',[_c('v-card',{attrs:{"height":"100","width":"70"}},[_c('v-img',{attrs:{"src":_vm.fectImg(item.image)}})],1)],1),_c('td',[_vm._v(_vm._s(item.pro_name)+" ")]),_c('td',[_vm._v(_vm._s(item.color))]),_c('td',[_vm._v(_vm._s(item.size))]),_c('td',[_vm._v(" "+_vm._s(String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1 "))+" "+_vm._s(item.unit)+" ")]),_c('td',[_vm._v(" "+_vm._s(String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 "))+" ")]),_c('td',[_vm._v(" "+_vm._s(String(item.qty * item.price).replace( /(.)(?=(\d{3})+$)/g, "$1 " ))+" ")])])]}}],null,true)})],1)]}}])})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"search":_vm.newsearch,"headers":_vm.newOrderbuyHeaders,"items":_vm.confirm_orders,"single-expand":true,"expanded":_vm.newexpanded,"item-key":"idx","show-expand":""},on:{"update:expanded":function($event){_vm.newexpanded=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"font-size":"20px","font-weight":"bold"},attrs:{"color":"#004D40","outlined":""},on:{"click":function($event){return _vm.SaveOrderBuy(item)}}},[_c('v-icon',{attrs:{"large":"","color":"#C51162","left":""}},[_vm._v(" mdi-cart-arrow-right ")]),_vm._v(" ຂາຍ")],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-2",staticStyle:{"background":"#fafafaff"},attrs:{"headers":_vm.newOrderbuydetailHeader,"items":item.orderDetail,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: "white--text" }),expression:"{ class: `white--text` }"}]},[_c('td',[_vm._v(_vm._s(item.idx))]),_c('td',[(item)?_c('v-carousel',{attrs:{"show-arrows-on-hover":"","hide-delimiters":"","height":"100","width":"100"}},_vm._l((item.image),function(img,i){return _c('v-carousel-item',{key:i},[_c('v-card',{staticClass:"mx-auto",attrs:{"height":"100%","width":"100"}},[_c('v-img',{attrs:{"src":_vm.fectImg(img.img_url)}})],1)],1)}),1):_c('v-card',{attrs:{"height":"100","width":"70"}},[_c('v-img',{attrs:{"src":"https://pbs.twimg.com/profile_images/1150780350932684800/LIJ11DWR.jpg"}})],1)],1),_c('td',[_vm._v(_vm._s(item.pro_name))]),_c('td',[_vm._v(_vm._s(item.category))]),_c('td',[_vm._v(_vm._s(item.color))]),_c('td',[_vm._v(_vm._s(item.size))]),_c('td',[_vm._v(" "+_vm._s(String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1 "))+" "+_vm._s(item.unit)+" ")]),_c('td',[_vm._v(" "+_vm._s(String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 "))+" ")]),_c('td',[_vm._v(" "+_vm._s(String(item.qty * item.price).replace( /(.)(?=(\d{3})+$)/g, "$1 " ))+" ")])])]}}],null,true)})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }