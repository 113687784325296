<template>
  <v-app>
    <v-app-bar app color="white" height="57" elevation="1" class="hidden-sm-and-down">
      <v-avatar size="35" class="mr-1 ml-2 home" @click="HomePage">
        <v-img src="@/assets/images/DaDa_LoGo.png" />
      </v-avatar>
      <h3 class="homepage" @click="HomePage">DaDaFashion</h3>
      <v-spacer></v-spacer>
      <div class="css" @click="HomePage">ໜ້າຫຼັກ</div>
      <!-- </router-link> -->
      <!-- <router-link to="Product"> -->
      <div class="css" @click="ProductPage">ສິນຄ້າ</div>
      <!-- </router-link> -->
      <div class="css" @click="OrderCutPage">ສັ່ງຕັດເຄື່ອງ</div>
      <div class="css" @click="AboutPage">ກ່ຽວກັບຮ້ານ</div>
      <div class="css" @click="GiveInformationPage">ແຫຼ່ງໃຫ້ຂໍ້ມູນ</div>
      <div class="css" @click="ContactUsPage">ຕິດຕໍ່ພວກເຮົາ</div>
      <div class="css mr-9" @click="RegisterPage">ສະມັກສະມາຊິກ</div>
      <v-menu offset-y rounded :disabled="QtyList">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-badge
            class="mr-10"
            :content="messages"
            :value="messages"
            color="blue"
            overlap
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  large
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  @click="OpenCart"
                >
                  mdi-cart-variant
                </v-icon>
              </template>
              <span class="tooltip">ກະຕ່າສິນຄ້າ</span>
            </v-tooltip>
          </v-badge>
        </template>
        <v-list>
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="OpenCartOrderBuy"
              style="font-family: Noto Sans Lao"
            >
              <v-badge
                :content="CountOrderQty"
                :value="CountOrderQty"
                color="error"
                overlap
              >
                <v-icon> mdi-cart</v-icon>
              </v-badge>
              ລາຍການສັ່ງຊື້</v-list-item-title
            >
          </v-list-item>
          <v-list-item link>
            <v-list-item-title
              @click="OpenCartOrderCut"
              style="font-family: Noto Sans Lao"
            >
              <v-badge
                :content="CountOrderCut_Item"
                :value="CountOrderCut_Item"
                color="error"
                overlap
              >
                <v-icon> mdi-cart</v-icon>
              </v-badge>
              ລາຍການສັ່ງຕັດ</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="mr-2">
            <v-icon @click="login_Page">mdi-login</v-icon>
          </v-btn>
        </template>
        <span class="tooltip">ເຂົ້າລະບົບ</span>
      </v-tooltip>
    </v-app-bar>
    
    <v-app-bar app color="white" height="57" elevation="1" class="hidden-md-and-up">
       <v-menu offset-y rounded class="mx-3">
        <template v-slot:activator="{ on: menu, attrs }">
                <v-icon
                  large
                  v-bind="attrs"
                  v-on="{  ...menu }"
                >
                  mdi-menu
                </v-icon>
        </template>
        <v-list>
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="ProductPage"
              style="font-family: Noto Sans Lao"
            > ສິນຄ້າ</v-list-item-title
            >
          </v-list-item>
         <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="OrderCutPage"
              style="font-family: Noto Sans Lao"
            > ສັ່ງຕັດເຄື່ອງ</v-list-item-title
            >
          </v-list-item>
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="AboutPage"
              style="font-family: Noto Sans Lao"
            > ກ່ຽວກັບຮ້ານ</v-list-item-title
            >
          </v-list-item>
          
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="GiveInformationPage"
              style="font-family: Noto Sans Lao"
            > ແຫຼ່ງໃຫ້ຂໍ້ມູນ</v-list-item-title
            >
          </v-list-item>
          
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="ContactUsPage"
              style="font-family: Noto Sans Lao"
            > ຕິດຕໍ່ພວກເຮົາ</v-list-item-title
            >
          </v-list-item>
          
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="RegisterPage"
              style="font-family: Noto Sans Lao"
            > ສະມັກຕົວແທນ</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-avatar size="35" class="mr-1 ml-2 home" @click="HomePage">
        <v-img src="@/assets/images/DaDa_LoGo.png" />
      </v-avatar>
      <h3 class="homepage" @click="HomePage">DaDaFashion</h3>
      <v-spacer></v-spacer>
        
      <v-menu offset-y rounded :disabled="QtyList">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-badge
            class="mr-10"
            :content="messages"
            :value="messages"
            color="blue"
            overlap
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  large
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  @click="OpenCart"
                >
                  mdi-cart-variant
                </v-icon>
              </template>
              <span class="tooltip">ກະຕ່າສິນຄ້າ</span>
            </v-tooltip>
          </v-badge>
        </template>
        <v-list>
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="OpenCartOrderBuy"
              style="font-family: Noto Sans Lao"
            >
              <v-badge
                :content="CountOrderQty"
                :value="CountOrderQty"
                color="error"
                overlap
              >
                <v-icon> mdi-cart</v-icon>
              </v-badge>
              ລາຍການສັ່ງຊື້</v-list-item-title
            >
          </v-list-item>
          <v-list-item link>
            <v-list-item-title
              @click="OpenCartOrderCut"
              style="font-family: Noto Sans Lao"
            >
              <v-badge
                :content="CountOrderCut_Item"
                :value="CountOrderCut_Item"
                color="error"
                overlap
              >
                <v-icon> mdi-cart</v-icon>
              </v-badge>
              ລາຍການສັ່ງຕັດ</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="mr-1">
            <v-icon @click="login_Page">mdi-login</v-icon>
          </v-btn>
        </template>
        <span class="tooltip">ເຂົ້າລະບົບ</span>
      </v-tooltip>
    </v-app-bar>
    <router-view></router-view>
    <v-footer color="#EEEEEE" elevation="1">
      <v-row
        justify="space-around"
        v-for="(item, index) in Shop_Info"
        :key="index"
      >
        <v-col cols="12" sm="4">
          <h1 class="mystyle">ສິນຄ້າ</h1>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
            <h1 class="ms" @click="ProductPage">ສິນຄ້າໃໝ່</h1>
          </v-row>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
              <h1 class="ms" @click="ProductPage">ສິນຄ້າຍອດນິຍົມ</h1>
          </v-row>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
            <h1 class="ms" @click="OrderCutPage">ສັ່ງຕັດເຄື່ອງ</h1>
          </v-row>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
            <h1 class="ms" @click="RegisterPage">ສະມັກສະມາຊິກ</h1>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4">
          <h1 class="mystyle">ກ່ຽວກັບພວກເຮົາ</h1>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>

            <h1 class="ms" @click="AboutPage">ກ່ຽວກັບຮ້ານ ດາດ້າແຟຊັ່ນ</h1>
          </v-row>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
            <h1 class="ms" @click="GiveInformationPage">
              ແຫຼ່ງໃຫ້ຂໍ້ມູນເພີ່ມເຕີມ
            </h1>
          </v-row>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
            <a
              class="ms"
              href="https://www.google.com/maps/place/%E0%BA%95%E0%BA%B0%E0%BA%AB%E0%BA%BC%E0%BA%B2%E0%BA%94+%E0%BA%AB%E0%BA%BB%E0%BA%A7%E0%BA%82%E0%BA%BB%E0%BA%A7+(Hua+Khua+Market)/@17.975495,102.650165,16z/data=!4m5!3m4!1s0x0:0x95a541852ab6540!8m2!3d17.9754946!4d102.6501647?hl=en"
              target="brank"
              style="font-family: NotoSansLao; color: black"
              >ທີ່ຕັ້ງຂອງຮ້ານ</a
            >
          </v-row>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>

            <h1 class="ms" @click="Developer_infoPage">
              ຂໍ້ມູນກ່ຽວກັບຜູ້ພັດທະນາ
            </h1>
          </v-row>
        </v-col>
        <v-col>
          <h1 class="mystyle">ຕິດຕໍ່ພວກເຮົາ</h1>
          <v-row dense>
            <v-icon large color="black" id="icc"> mdi-phone-forward </v-icon>
            <h1 id="contact">+{{ item.tel }} , {{ item.whatsapp }}</h1>
          </v-row>
          <v-row dense>
            <v-icon large color="black" id="icc"> mdi-email </v-icon>
            <a href="mailto:houayang200786@gmail.com" id="contact">{{
              item.email
            }}</a>
          </v-row>
          <v-row dense>
            <v-icon large color="black" id="icc"> mdi-facebook </v-icon>
            <a
              href="https://www.facebook.com/DaDa-Fashion-Khaub-Ncaws-Hmoob-2392198184126093"
              target="brank"
              id="contact"
              >DaDa Fashion</a
            >
          </v-row>
        </v-col>
        <v-col
          color="#FF9800"
          class="text-center"
          cols="12"
          style="font-family: NotoSansLao; color: black; font-size: 25px"
        >
          <strong>Year </strong>{{ new Date().getFullYear() }} —
          <strong>DaDa Fashion</strong>
        </v-col>
      </v-row>
    </v-footer>
        <v-snackbar color="primary" top v-model="snackbar" :timeout='700'>
          <v-row no-gutters class="mx-2 my-2">
            <v-icon color="#ffffff" size="60" class="mr-5">mdi-alert-circle-outline</v-icon>
            <p style="font-size:2rem;font-family:Noto Sans Lao; margin-top:20px">ບໍ່ມີສິນຄ້າໃນກະຕ່າ....!</p>
          </v-row>
          <v-row no-gutters class="mx-2 justify-end">
            <v-btn text style="font-family:Noto Sans Lao;font-size:20px" @click="snackbar = false">
            <v-icon color="error" left large>mdi-close</v-icon>ປິດ</v-btn>
          </v-row>
      </v-snackbar>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    // messages:0,
    show: false,
     snackbar:false
  }),
  mounted() {
    this.$store.dispatch("Shop_Info");
    // this.$store.dispatch("Customer_List");
  },
  computed: {
    QtyList() {
      if (this.CountOrderQty > 0 && this.CountOrderCut_Item > 0) return false;
      return true;
    },
    CountOrderQty() {
      return this.$store.getters.CountOrderQty;
    },
    CountOrderCut_Item() {
      return this.$store.getters.CountOrderCut_Item;
    },
    messages() {
      return this.$store.getters.messages;
    },
    customerInfo() {
      //  alert(JSON.stringify(this.$store.getters.GetcustomerLogin));
      return this.$store.state.customerInfo;
    },
    Shop_Info() {
      return this.$store.state.Shop_Info;
    },
  },
  methods: {
    OpenCart() {
      if (
        this.$store.getters.CountOrderQty == 0 &&
        this.$store.getters.CountOrderCut_Item == 0
      ) {
        // alert(JSON.stringify("ບໍ່ມີສິນຄ້າໃນກະຕ່າ"));
         this.snackbar=true
        return;
      } else {
        if (
          this.$store.getters.CountOrderQty > 0 &&
          this.$store.getters.CountOrderCut_Item > 0
        ) {
          return;
        } else if (
          this.$store.getters.CountOrderQty > 0 &&
          JSON.parse(localStorage.getItem("Customer_Info")).cus_id != undefined
        ) {
          this.$router.push(`/Customer/Customer_OrderBuy_Cart`);
        } else if (
          this.$store.getters.CountOrderCut_Item > 0 &&
          JSON.parse(localStorage.getItem("Customer_Info")).cus_id != undefined
        ) {
          this.$router.push(`/Customer/Customer_OrderCut_Cart`);
        }
      }
    },
    OpenCartOrderBuy() {
      if (
        this.$store.getters.CountOrderQty > 0 &&
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id != undefined
      ) {
        this.$router.push(`/Customer/Customer_OrderBuy_Cart`);
      }
    },
    OpenCartOrderCut() {
      if (
        this.$store.getters.CountOrderCut_Item > 0 &&
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == undefined
      ) {
        this.$router.push(`/Customer/Customer_OrderCut_Cart`);
      }
    },
    HomePage() {
      this.$router.push(`/`);
    },
    ProductPage() {
      this.$router.push(`/Product`);
    },
    RegisterPage() {
      this.$router.push(`/Register`);
    },
    CustomerPage() {
      this.$router.push(`/Customer`);
    },
    OrderCutPage() {
      this.$router.push(`/ordercut`);
    },
    AboutPage() {
      this.$router.push(`/About`);
    },
    GiveInformationPage() {
      this.$router.push(`/GiveInformation`);
    },
    ContactUsPage() {
      this.$router.push(`/ContactUs`);
    },
    Developer_infoPage() {
      this.$router.push(`/Developer_info`);
    },
    login_Page() {
      this.$router.push(`/Customer_LogIn`);
    },
  },
};
</script>
<style>
.mystyle {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 20px;
  color: black;
  text-align: left;
  margin-left: 20px;
  font-family: "Noto Sans Lao";
  font-size: 30px;
}
.css1 {
  margin-top: 120px;
  background: #009933;
  color: aliceblue;
  text-align: center;
  height: 60px;
}
.ic {
  margin-top: 17px;
  margin-left: 15px;
  color: white;
}
#icc {
  margin-top: 20px;
  margin-left: 20px;
}
.ms {
  margin-top: 20px;
  margin-left: 20px;
  color: black;
  text-align: left;
  font-family: "Noto Sans Lao";
  font-size: 20px;
  font-weight: normal;
}
.ms:active {
  margin-top: 20px;
  margin-left: 20px;
  color: black;
  text-align: left;
  font-family: "Noto Sans Lao";
  font-size: 20px;
  font-weight: normal;
}
.ms:hover {
  margin-top: 20px;
  cursor: pointer;
  margin-left: 20px;
  color: black;
  text-align: left;
  font-family: "Noto Sans Lao";
  font-size: 20px;
  font-weight: normal;
  text-decoration: underline;
}
.css {
  color: black;
  margin-left: 20px;
  font-family: "Noto Sans Lao";
  font-size: 20px;
  text-decoration: none;
}
.css:hover {
  color: #00c853;
  cursor: pointer;
  margin-left: 20px;
  font-family: "Noto Sans Lao";
  text-decoration: none;
}
#title {
  text-align: center;
  margin-top: 15px;
  margin-left: 20px;
  font-size: 50px;
  font-family: "Noto Sans Lao";
  font-weight: bold;
}
.shopname {
  font-weight: bold;
  font-size: 50px;
  font-family: "Noto Sans Lao";
  text-align: center;
}
a:link {
  color: black;
  text-decoration: none;
}
#contact {
  font-size: 25px;
  font-family: "Noto Sans Lao";
  text-align: left;
  color: black;
  margin-top: 20px;
  margin-left: 20px;
  text-decoration: underline;
}
.tooltip {
  color: white;
  margin-left: 20px;
  font-family: "Noto Sans Lao";
  font-size: 15px;
  text-decoration: none;
  font-weight: normal;
}
</style>
<style lang="css">
.font {
  font-family: "NotoSansLao";
}
</style>