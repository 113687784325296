<template>
  <div>
    <v-row justify="center">
      <v-card class="elevation-1 mt-3" :width="breack">
        <v-card-title class="justify-center">
          <h3>ຈັດການຂໍ້ມູນປະເພດສິນຄ້າ</h3>
        </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-row no-gutters>
              <v-text-field
                class="mr-15"
                placeholder="ປ້ອນປະເພດສິນຄ້າ"
                append-icon="mdi-close"
                v-model="cateEdit.name"
                @click:append="Categoryclear"
              ></v-text-field>

              <v-btn
              rounded
              color="#ffffff"
                v-if="CategoryShowEdit"
                @click="CategoryEditComfirm"
                class="align-start ml-15 mt-4 mr-6"
                style="font-size: 20px; font-weight: bold"
              >
                <v-icon large color="orange accent-3">mdi-pencil</v-icon>
                ແກ້ໄຂ
              </v-btn>
              <v-btn
              rounded
              color="#ffffff"
                v-else
                @click="CategoryAdd"
                style="font-size: 20px; font-weight: bold"
                class="ml-15 mt-4 mr-6"
              >
                <v-icon large>mdi-plus</v-icon>
                ບັນທຶກ
              </v-btn>
            </v-row>
          </v-col>

          <v-data-table
            :headers="Categoryheaders"
            :search="Categorysearch"
            :items="category"
            sort-by="cate_id"
            class="elevation-0"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-text-field
                  dense
                  outlined
                  v-model="Categorysearch"
                  append-icon="mdi-magnify"
                  label="ຄົ້ນຫາຂໍ້ມູນປະເພດ"
                  single-line
                  hide-details
                ></v-text-field>
                <v-dialog v-model="CategoryDelete" max-width="500px">
                  <v-card>
                    <v-card-title
                    style="font-size: 20px; font-weight: bold;font-family:NotoSansLao"
                      >ທ່ານຕ້ອງການລຶບຂໍ້ມູນລາຍການນີ້ບໍ...?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="error" text @click="CloseCategory"  style="font-size: 20px;font-family:NotoSansLao"
                        >ຍົກເລິກ</v-btn
                      >
                      <v-btn
                        color="primary"
                        style="font-size: 20px;font-family:NotoSansLao"
                        text
                        @click="deletecategoryConfirm"
                        >ຢຶນຢັນລຶບ</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>

              <v-divider class=""></v-divider>
              <v-divider class=""></v-divider>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-row no-gutters class="justify-center">
                <v-icon
                  large
                  color="orange"
                  class="mr-2"
                  @click="editeCategory(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon color="error" large @click="deleteCategory(item)">
                  mdi-delete
                </v-icon>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    CategoryDelete: false,
    CategoryShowEdit: false,
    Categorysearch: "",
    Categoryheaders: [
      { text: "ລໍາດັບ", value: "idx" },
      { text: "ປະເພດ", value: "name" },
      { text: "Actions", align: "center", value: "actions", sortable: false },
    ],
    cateEdit: {
      id: "",
      name: "",
    },
    CategoryDeleteItem: [],
    cateList: [],
  }),
  computed: {
    ...mapGetters({
      category: "productMod/category",
    }),

    breack() {
      var w = "";
      if (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      ) {
        w = "100%";
      } else if (this.$vuetify.breakpoint.name == "md") {
        w = "80%";
      } else if (this.$vuetify.breakpoint.name == "lg") {
        w = "70%";
      } else if (this.$vuetify.breakpoint.name == "xl") {
        w = "50%";
      }
      return w;
    },
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    ...mapActions("productMod", [
      "getCategory",
      "InsertCategory",
      "UpdateCategory",
      "delCategory",
    ]),
    Categoryclear() {
      this.cateEdit={
      id: "",
      name: "",
    },
      this.CategoryDelete = false;
    },
    CategoryAdd() {
      try {
        if (this.cateEdit.name == null || this.cateEdit.name == "") {
          return;
        }
        this.InsertCategory(this.cateEdit);

        this.Categoryclear();
      } catch (error) {
        console.log(error);
      }
    },
    editeCategory(item) {
      this.cateEdit = item;
      this.CategoryShowEdit = true;
    },
    CategoryEditComfirm() {
      if (this.cateEdit.name == null || this.cateEdit.name == "") {
        return;
      }
      this.UpdateCategory(this.cateEdit);
      this.CategoryShowEdit = false;
      this.Categoryclear();
    },
    deleteCategory(item) {
      this.CategoryDeleteItem = item;
      this.CategoryDelete = true;
    },
    CloseCategory() {
      this.CategoryDelete = false;
    },
    deletecategoryConfirm() {
      this.delCategory(this.CategoryDeleteItem);
      this.CloseCategory();
    },
  },
};
</script>