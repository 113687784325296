<template>
  <v-data-table
    :headers="Headers"
    :items="material"
    show-expand
    :single-expand="true"
    :expanded.sync="expanded"
    :item-key="material.m_order_id"
    class="elevation-1"
    :search="search">
    <template v-slot:top>
      <v-row justify="center">
     <h2 class="mt-5 mb-7">ຂໍ້ມູນປະຫວັດການສັ່ງຊື້ວັດຖຸດິບ</h2>
   </v-row>
   <v-divider></v-divider>
      <div>
        <v-toolbar flat>
          <v-toolbar-title>ປະຫວັດສັ່ງຊື້ວັດຖຸດິບ</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider
          ><v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ຄົ້ນຫາ"
            single-line
            dense
            outlined
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <router-link to="material_order_steper">
            <v-btn class="mx-2" style="font-size: 20px; font-weight: bold" color="#ffffff" rounded>
                  <v-icon  large>
                mdi-plus
              </v-icon>
              ສັ່ງຊື້ວັດຖຸດິບ
            </v-btn>
          </router-link>
        </v-toolbar>
        <v-divider></v-divider>
        <v-divider></v-divider> 
        <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card rounded>
     <v-card-title style="font-size: 25px; font-weight: bold;font-family:NotoSansLao">ທ່ານຕ້ອງການລຶບຂໍ້ມູນລາຍການນີ້ບໍ...?</v-card-title>
        <v-divider class=""></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text style="font-size: 20px;font-family:NotoSansLao" @click="closeDelete">ຍົກເລິກ</v-btn>
          <v-btn color="primary" text style="font-size: 20px;font-family:NotoSansLao" @click="deleteConfirm"
            >ຢຶນຢັນລຶບ</v-btn >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </div>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length"  >
        <v-data-table
          v-if="item.id != '' || item.id != null"
          :headers="headersdetail"
          :items="item.detail"
          v-model="selected"
          hide-default-footer
          :sort-by="item.product"
          :item-key="item.product"
          class="elevation-0 my-2"
          style="background:#fafafaff"
        >
          <template v-slot:item="{ item }">
            <tr v-ripple="{ class: `white--text` }" >
              <td>{{ item.idx }}</td>
              <td>
                 <v-img  :src="fectImg(item.image)"
                          contain
                          height="100"
                          width="100"
                        >
                 </v-img>
              </td>
              <td>{{ item.product }}</td>
              <td>{{ item.category }}</td>
              <td>{{ item.color }}</td>
              <td>{{ item.size }}</td>
              <td>{{ String(item.qty).replace(/(.)(?=(\d{3})+$)/g,'$1,')}}</td>
                            <td>{{ item.unit }}</td>
            </tr>
          </template>
        </v-data-table>
      </td>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        large
        color="error"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapState ,mapGetters,mapActions} from "vuex";
export default {
  data: () => ({
    dialog: false,
    search: "",
    dialogDelete: false,
    expanded: [],
    selected: [],
    selectItem: [],
    CheckItem: null,
    singleExpand: false,
    Headers: [{
        text: "ລໍາດັບ",
        sortable: false,
        value: "idx"
      },
      {
        text: "ໃບຊື້ວັດຖຸດິບ",
        value: "id"
      },
      { text: "ພະນັກງານ", value: "employee" },
      { text: "ຜູ້ສະໜອງ", value: "name" },
      { text: "ວັນທີ່ສັ່ງຊື້", value: "date" },
      { text: "ເວລາສັ່ງຊື້", value: "time" },
      { text: "ລຶບ", value: 'actions', sortable: false},
      { text: "detail", value: "data-table-expand" }
    ],
   
    headersdetail: [
      {
        text: "ລໍາດັບ",
        sortable: false,
        value: "idx"
      },
      {
        text: "ຮູບ",
        align: "start",
        sortable: false,
        value: "detail.image"
      },
      { text: "ຊື່ວັດຖຸດິບ", value: "detail.pro_name" },
      { text: "ປະເພດວັດຖຸດິບ", value: "detail.category" },
      { text: "ສີ", value: "detail.color" },
      { text: "ຂະໜາດ", value: "detail.size" },
      { text: "ຈໍານວນ", value: "detail.qty" },
            { text: "ຫົວໜ່ວຍ", value: "detail.unit" },
    ],
  }),

  computed: {
    ...mapState({
      select: state => state.MaterialOrdertMod.selectItemList
    }),
    ...mapGetters({
      material:'materialOrderMod/material'
    })
    
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions('materialOrderMod',[
      'initialize','DeleteOrder','DeleteOrderDetail'
    ]),
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
  
    deleteItem(item){
      this.selectItem=item
      this.dialogDelete=true
    },
    deleteConfirm(){
this.DeleteOrder(this.selectItem)
this.DeleteOrderDetail(this.selectItem)
this.closeDelete()
    },
    closeDelete(){
      this.dialogDelete=false
    }
  }
};
</script>