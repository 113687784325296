<template >
    <div id="chart">
        <apexchart class='font' type="polarArea" height="380" :options="cutproducts.chartOptions" :series="cutproducts.series"></apexchart>
      </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
          apexchart: VueApexCharts,
        },
       data() {
    return  {
    }
        },
         computed: {
     ...mapGetters({
         cutproducts:'dashMod/cutproducts',
       })
         },
mounted() {
    this.getTopCutPro()
  },
  methods: {
    ...mapActions('dashMod', [
      'getTopCutPro',
    ]),
  }
}
</script>
<style lang="css">
.font {
  font-family: "NotoSansLao";
}
p {
  font-size: 1rem;
}