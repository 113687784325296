import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/Login.vue'

import admin from '../views/views.Admin/admin.home.vue'
import admin_dashboard from '../views/views.Admin/admin.dashboard.vue'
import manage_product from '../views/views.Admin/manage/manage.products.vue'
import product_add from '../views/views.Admin/manage/product.add.vue'
import product_edite from '../views/views.Admin/manage/product.edite.vue'
import manage_category from '../views/views.Admin/manage/manage.category.vue'
import manage_unit from '../views/views.Admin/manage/manage.unit.vue'
import manage_customer from '../views/views.Admin/manage/manage.customer.vue'
import customer_add from '../views/views.Admin/manage/customer.add.vue'
import customer_edite from '../views/views.Admin/manage/customer.edite.vue'
import manage_employee from '../views/views.Admin/manage/manage.employee.vue'
import employee_add from '../views/views.Admin/manage/employee.add.vue'
import employee_edite from '../views/views.Admin/manage/employee.edite.vue'
import manage_supplier from '../views/views.Admin/manage/manage.supplier.vue'
import supplier_add from '../views/views.Admin/manage/supplier.add.vue'
import supplier_edite from '../views/views.Admin/manage/supplier.edite.vue'
import manage_shop from '../views/views.Admin/manage/manage.shop.vue'
import material_order from '../views/views.Admin/service/material_order.vue'

import material_order_steper from '../views/views.Admin/service/material_order_steper.vue'
import material_import from '../views/views.Admin/service/material_import.vue'
import material_import_history from '../views/views.Admin/service/material_import_history.vue'
import recieve_customer from '../views/views.Admin/service/recieve_customer.vue'
import recieve_customer_more from '../views/views.Admin/service/recieve_customer_more.vue'
import recieve_orderBuy from '../views/views.Admin/service/recieve_orderBuy.vue'
import recieve_cut from '../views/views.Admin/service/recieve_cut.vue'
import sale_page from '../views/views.Admin/service/sale.vue'
import report_customer from '../views/views.Admin/report/report.customer.vue'
import sell_order from '../views/views.Admin/service/sell_order.vue'

import report_import from '../views/views.Admin/report/report.import.vue'
import report_orderBuy from '../views/views.Admin/report/report.orderBuy.vue'
import report_production from '../views/views.Admin/report/report.production.vue'
import report_sale from '../views/views.Admin/report/report.sale.vue'
import report_cut from '../views/views.Admin/report/report.cut.vue'
import shop_edit from '../views/views.Admin/manage/shop_edit.vue'
import manage_material from '../views/views.Admin/manage/manage.material.vue'
import material_add from '../views/views.Admin/manage/material.add.vue'
import material_edit from '../views/views.Admin/manage/material.edite.vue'


import Customer_Info from '../views/views.Customer/Customer_Info.vue'
import Customer_OrderBuy_Cart from '../views/views.Customer/Customer_OrderBuy_Cart.vue'
import Customer_OrderCut_Cart from '../views/views.Customer/Customer_OrderCut_Cart.vue'
import Member_Register from '../views/views.Customer/Member_Register.vue'
import OrderBuy_History from '../views/views.Customer/OrderBuy_History.vue'
import OrderCut_History from '../views/views.Customer/OrderCut_History.vue'


import DadaCustomer from '../views/views.Dadafashion/DadaCustomer.vue'//
import DadaClient from '../views/views.Dadafashion/DadaClient.vue'//
import Home from '../views/views.Dadafashion/Home.vue'//
import About from '../views/views.Dadafashion/About.vue'//
import ordercut from '../views/views.Dadafashion/ordercut.vue'//
import Product from '../views/views.Dadafashion/Product.vue'//
import ContactUs from '../views/views.Dadafashion/ContactUs.vue'//
import GiveInformation from '../views/views.Dadafashion/GiveInformation.vue'//
import ProductDetail from '../views/views.Dadafashion/ProductDetail.vue'//
import ProductInCart from '../views/views.Dadafashion/ProductInCart.vue'//
import Developer_info from '../views/views.Dadafashion/Developer_info.vue'//
import Customer_LogIn from '../views/views.Dadafashion/Customer_LogIn.vue'//
import ProductOrderCutDetail from '../views/views.Dadafashion/ProductOrderCutDetail.vue'//
import Register from '../views/views.Dadafashion/Register.vue'//
import OrdercutCart from '../views/views.Dadafashion/OrdercutCart.vue'//

import bills_material_order from '../views/views.Admin/report/bills.material_order.vue'
import bills_material_import from '../views/views.Admin/report/bills.material_import.vue'
import bills_sell_product from '../views/views.Admin/report/bills.sale.vue'
import bills_order_products from '../views/views.Admin/report/bills.customer_orderbuy.vue'
import bills_product_stock from '../views/views.Admin/report/bills.product_stock.vue'
import bills_product_bestsell from '../views/views.Admin/report/bills.product_bestsell.vue'
import bills_product_bestorderbuy from '../views/views.Admin/report/bills.product_bestorderbuy.vue'
import bills_product_bestordercut from '../views/views.Admin/report/bills.product_bestordercut.vue'
import bills_customer_member from '../views/views.Admin/report/bills.customer_Member.vue'
import bills_order_cut_product from '../views/views.Admin/report/bills.customer_ordercut.vue'

import bills_report_material_import from '../views/views.Admin/report/bills.report.material_import.vue'
import bills_report_order_buy from '../views/views.Admin/report/bills.report.order_buy.vue'
import bills_report_order_cut from '../views/views.Admin/report/bills.report.order_cut.vue'
import bills_report_sale from '../views/views.Admin/report/bills.report.sale.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'DadaClient',
    component: DadaClient,
  //   beforeEnter: (to, from, next) => {
  //    if (to.path=='/' && (JSON.parse(window.localStorage.getItem('Customer_Info'))).cus_id==null|| JSON.parse(window.localStorage.getItem('Customer_Info')).cus_id==undefined ) next()
  //    else next({ path: '/Customer' })
  //  },
    children:[
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/About',
        name: 'About',
        component: About
      },
      {
        path: '/ordercut',
        name: 'ordercut',
        component: ordercut
      },
      {
        path: '/Product',
        name: 'Product',
        component: Product
      },
      {
        path: '/ContactUs',
        name: 'ContactUs',
        component: ContactUs
      },
      {
        path: '/GiveInformation',
        name: 'GiveInformation',
        component: GiveInformation
      },
      {
        path: '/ProductDetail/:id',
        name: 'ProductDetail',
        component: ProductDetail,
        props:true
      },
      {
        path: '/ProductInCart',
        name: 'ProductInCart',
        component: ProductInCart
      },
      {
        path: '/Developer_info',
        name: 'Developer_info',
        component: Developer_info
      },
      {
        path: '/OrdercutCart',
        name: 'OrdercutCart',
        component: OrdercutCart
      },
      {
        path: '/ProductOrderCutDetail/:id',
        name: 'ProductOrderCutDetail',
        component: ProductOrderCutDetail,
        props:true
      },
      {
        path: '/Register',
        name: 'Register',
        component: Register
      },
      {
        path: '/Customer_Info',
        name: 'Customer_Info',
        component:Customer_Info 
      },
      {
        path: '/Customer_LogIn',
        name: 'Customer_LogIn',
        component: Customer_LogIn
      },
    ]
  },

  {
    path: '/login',
    name: 'login',
    component: login
  },

  {
    path: '/dashboard',
    name: 'admin',
    component: admin,
    children: [
      {
        path: '/',
        name: 'admin_dashboard',
        component: admin_dashboard
      },
       {
        path: '/manage_product',
        name: 'manage_product',
        component: manage_product,
        beforeEnter: (to, from, next) => {
          if (to.path=='/manage_product' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: '/manage_product-not-fond' })
        }
      },
      {
        path: '/product_add',
        name: 'product_add',
        component: product_add,
        beforeEnter: (to, from, next) => {
          if (to.path=='/product_add' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: '/product_add-not-fond' })
        }
      }, 

      {
        path: '/manage_material',
        name: 'manage_material',
        component: manage_material,
        beforeEnter: (to, from, next) => {
          if (to.path=='/manage_material' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: '/manage_Material-not-fond' })
        }
      },
      {
        path: '/material_add',
        name: 'material_add',
        component: material_add,
        beforeEnter: (to, from, next) => {
          if (to.path=='/material_add' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: '/product_add-not-fond' })
        }
      }, 
      {
        path: '/material_edit/:id',
        name: 'material_edit',
        component: material_edit,
      }, 

      {
        path: '/product_edite/:id',
        name: 'product_edite',
        component: product_edite,
      }, 
      {
        path: '/manage_category',
        name: 'manage_category',
        component: manage_category,
        beforeEnter: (to, from, next) => {
          if (to.path=='/manage_category' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: '/manage_category-not-fond' })
        }
      },
      {
        path: '/manage_unit',
        name: 'manage_unit',
        component: manage_unit,
        beforeEnter: (to, from, next) => {
          if (to.path=='/manage_unit' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: '/manage_unit-not-fond' })
        }
      },
      {
        path: '/manage_employee',
        name: 'manage_employee',
        component: manage_employee,
        beforeEnter: (to, from, next) => {
          if (to.path=='/manage_employee' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: '/manage_employee-not-fond' })
        }
      }, {
        path: '/employee_add',
        name: 'employee_add',
        component: employee_add,
        beforeEnter: (to, from, next) => {
          if (to.path=='/employee_add' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: '/employee_add-not-fond' })
        }
      }, {
        path: '/employee_edite/:id',
        name: 'employee_edite',
        component: employee_edite 
      },
      
      {
        path: '/manage_supplier',
        name: 'manage_supplier',
        component: manage_supplier,
        beforeEnter: (to, from, next) => {
          if (to.path=='/manage_supplier' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: '/manage_supplier-not-fond' })
        }
      }, {
        path: '/supplier_add',
        name: 'supplier_add',
        component: supplier_add,
        beforeEnter: (to, from, next) => {
          if (to.path=='/supplier_add' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: '/supplier_add-not-fond' })
        }
      }, {
        path: '/supplier_edite/:id',
        name: 'supplier_edite',
        component: supplier_edite 
      },
      {
        path: '/manage_shop',
        name: 'manage_shop',
        component: manage_shop,
        beforeEnter: (to, from, next) => {
          if (to.path=='/manage_shop' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: 'manage_shop-not-fond' })
        }
      }, 
      {
        path: '/shop_edit/:id',
        name: 'shop_edit',
        component: shop_edit,
      },
      {
        path: '/manage_customer',
        name: 'manage_customer',
        component: manage_customer,
        beforeEnter: (to, from, next) => {
          if (to.path=='/manage_customer' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: 'manage_customer-not-fond' })
        }
      }, {
        path: '/customer_add',
        name: 'customer_add',
        component: customer_add,
        beforeEnter: (to, from, next) => {
          if (to.path=='/customer_add' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: 'customer_add-not-fond' })
        }
      }, {
        path: '/customer_edite/:id',
        name: 'customer_edite',
        component: customer_edite
      },

      {
        path: '/material_order',
        name: 'material_order',
        component: material_order
      },
      {
        path: '/material_order_steper',
        name: 'material_order_steper',
        component: material_order_steper
      }, {
        path: '/material_import',
        name: 'material_import',
        component: material_import
      }, {
        path: '/material_import_history',
        name: 'material_import_history',
        component: material_import_history
      }, {
        path: '/recieve_orderBuy',
        name: 'recieve_orderBuy',
        component: recieve_orderBuy
      },
       {
        path: '/recieve_customer',
        name: 'recieve_customer',
        component: recieve_customer
      }, {
        path: '/recieve_customer_more/:id',
        name: 'recieve_customer_more',
        component: recieve_customer_more
      },
      {
        path: '/recieve_cut',
        name: 'recieve_cut',
        component: recieve_cut
      }, 
      {
        path: '/sale_page',
        name: 'sale_page',
        component: sale_page
      }, 
      {
        path: '/sell_order',
        name: 'sell_order',
        component: sell_order
      }, 
      {
        path: '/report_import',
        name: 'report_import',
        component: report_import,
         beforeEnter: (to, from, next) => {
          if (to.path=='/report_import' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: 'report_import-not-fond' })
        }
      },
      {
        path: '/report_customer',
        name: 'report_customer',
        component: report_customer,
         beforeEnter: (to, from, next) => {
          if (to.path=='/report_customer' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
          else next({ path: 'report_customer-not-fond' })
        }
      }, {
        path: '/report_production',
        name: 'report_production',
        component: report_production,
        beforeEnter: (to, from, next) => {
         if (to.path=='/report_production' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
         else next({ path: 'report_production-not-fond' })
       }
      }, {
        path: '/report_cut',
        name: 'report_cut',
        component: report_cut,
        beforeEnter: (to, from, next) => {
         if (to.path=='/report_cut' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
         else next({ path: 'report_cut-not-fond' })
       }
      }, {
        path: '/report_orderBuy',
        name: 'report_orderBuy',
        component: report_orderBuy,
        beforeEnter: (to, from, next) => {
         if (to.path=='/report_orderBuy' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
         else next({ path: 'report_orderBuy-not-fond' })
       }
      }, {
        path: '/report_sale',
        name: 'report_sale',
        component: report_sale,
        beforeEnter: (to, from, next) => {
         if (to.path=='/report_sale' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
         else next({ path: 'report_sale-not-fond' })
       }
      },

    ]
  },

  {
    path: '/Customer',
    name: 'DadaCustomer',
    component: DadaCustomer,
    children:[
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/Customer/About',
        name: 'About',
        component: About
      },
      {
        path: '/Customer/ordercut',
        name: 'ordercut',
        component: ordercut
      },
      {
        path: '/Customer/Product',
        name: 'Product',
        component: Product
      },
      {
        path: '/Customer/ContactUs',
        name: 'ContactUs',
        component: ContactUs
      },
      {
        path: '/Customer/GiveInformation',
        name: 'GiveInformation',
        component: GiveInformation
      },
      {
        path: '/Customer/ProductDetail/:id',
        name: 'ProductDetail',
        component: ProductDetail,
        props:true
      },
      {
        path: '/Customer/ProductInCart',
        name: 'ProductInCart',
        component: ProductInCart
      },
      {
        path: '/Customer/Developer_info',
        name: 'Developer_info',
        component: Developer_info
      },
      {
        path: '/Customer/OrdercutCart',
        name: 'OrdercutCart',
        component: OrdercutCart
      },
      {
        path: '/Customer/ProductOrderCutDetail/:id',
        name: 'ProductOrderCutDetail',
        component: ProductOrderCutDetail,
        props:true
      },
      {
        path: '/Customer/Customer_Info',
        name: 'Customer_Info',
        component:Customer_Info
      },
      {
        path: '/Customer/OrderBuy_History',
        name: 'OrderBuy_History',
        component:OrderBuy_History
      },
      {
        path: '/Customer/OrderCut_History',
        name: 'OrderCut_History',
        component:OrderCut_History
      },
      {
        path: '/Customer/Customer_OrderBuy_Cart',  
        name: 'Customer_OrderBuy_Cart',
        component:Customer_OrderBuy_Cart
      },
      {
        path: '/Customer/Customer_OrderCut_Cart',
        name: 'Customer_OrderCut_Cart',
        component:Customer_OrderCut_Cart   
      },
      {
        path: '/Customer/Member_Register',  
        name: 'Member_Register',
        component:Member_Register
      },
    ]
  },

  {
    path: '/bills_material_order',
    name: 'bills_material_order',
    component: bills_material_order,
    beforeEnter: (to, from, next) => {
      if (to.path=='/bills_material_order' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
      else next({ path: 'report-not-found' })
    }
  },
  {
    path: '/bills_material_import',
    name: 'bills_material_import',
    component: bills_material_import,
    beforeEnter: (to, from, next) => {
      if (to.path=='/bills_material_import' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
      else next({ path: 'report-not-found' })
    } 
  },
  {
    path: '/bills_sell_product',
    name: 'bills_sell_product',
    component: bills_sell_product,
  },{
    path: '/bills_report_material_import',
    name: 'bills_report_material_import',
    component: bills_report_material_import,
    beforeEnter: (to, from, next) => {
      if (to.path=='/bills_report_material_import' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
      else next({ path: 'report-not-found' })
    } 
  },
  {
    path: '/bills_report_order_cut',
    name: 'bills_report_order_cut',
    component: bills_report_order_cut,
    beforeEnter: (to, from, next) => {
      if (to.path=='/bills_report_order_cut' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
      else next({ path: 'report-not-found' })
    } 
  },
  
  {
    path: '/bills_report_order_buy',
    name: 'bills_report_order_buy',
    component: bills_report_order_buy,
    beforeEnter: (to, from, next) => {
      if (to.path=='/bills_report_order_buy' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
      else next({ path: 'report-not-found' })
    } 
  },
  {
    path: '/bills_report_sale',
    name: 'bills_report_sale',
    component: bills_report_sale,
    beforeEnter: (to, from, next) => {
      if (to.path=='/bills_report_sale' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
      else next({ path: 'report-not-found' })
    } 
  },
  {
    path: '/bills_order_products',
    name: 'bills_order_products',
    component: bills_order_products,   
  },
  {
    path: '/bills_order_cut_product',
    name: 'bills_order_cut_product',
    component: bills_order_cut_product,   
  },

  {
    path: '/bills_product_stock',
    name: 'bills_product_stock',
    component: bills_product_stock,
    beforeEnter: (to, from, next) => {
      if (to.path=='/bills_product_stock' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
      else next({ path: 'report-not-found' })
    }   
  },
  {
    path: '/bills_product_bestsell',
    name: 'bills_product_bestsell',
    component: bills_product_bestsell,
    beforeEnter: (to, from, next) => {
      if (to.path=='/bills_product_bestsell' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
      else next({ path: 'report-not-found' })
    }  
  },
  {
    path: '/bills_product_bestorderbuy',
    name: 'bills_product_bestorderbuy',
    component: bills_product_bestorderbuy,
    beforeEnter: (to, from, next) => {
      if (to.path=='/bills_product_bestorderbuy' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
      else next({ path: 'report-not-found' })
    }    
  },
  {
    path: '/bills_product_bestordercut',
    name: 'bills_product_bestordercut',
    component: bills_product_bestordercut,
    beforeEnter: (to, from, next) => {
      if (to.path=='/bills_product_bestordercut' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
      else next({ path: 'report-not-found' })
    }  
  },
  {
    path: '/bills_customer_member',
    name: 'bills_customer_member',
    component: bills_customer_member,
    beforeEnter: (to, from, next) => {
      if (to.path=='/bills_customer_member' && (JSON.parse(window.localStorage.getItem('token'))).status=='Admin' ) next()
      else next({ path: 'report-not-found' })
    }    
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
