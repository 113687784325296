<template>
  <div
    v-if="
      $store.state.authMod.token.status == 'Admin' ||
        $store.state.authMod.token.status == 'admin'
    "
  >
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top:2px; padding-bottom:10px">
            <v-row no-gutters class="justify-center">
              <h1 style="margin-top:30px;">ເພີ່ມຂໍ້ມູນຜູ້ສະໜອງ</h1>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                <v-text-field
                    v-model="suplier.name"
                    :error-messages="NameErrors"
                    :counter="100"
                    label='ຊື່ຜູ້ສະໜອງ'
                    required
                    @input="$v.suplier.name.$touch()"
                    @blur="$v.suplier.name.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="suplier.tel"
                    :error-messages="TelErrors"
                    :counter="100"
                    label='ເບີ້ໂທ'
                    required
                    @input="$v.suplier.tel.$touch()"
                    @blur="$v.suplier.tel.$touch()"
                    @keypress="number($event)"
                  ></v-text-field>
                  <v-text-field
                    v-model="suplier.email"
                    :error-messages="emailErrors"
                    label='ອີເມວ'
                    counter
                    required
                    @input="$v.suplier.email.$touch()"
                    @blur="$v.suplier.email.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="suplier.address"
                    :error-messages="AddressErrors"
                    :counter="100"
                    label='ທີ່ຢູ່'
                    required
                    @input="$v.suplier.address.$touch()"
                    @blur="$v.suplier.address.$touch()"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-5 ">
              <v-chip @click="back" class="ma-2 deep-purple lighten-4">
                <v-icon left>
                  mdi-arrow-left-circle
                </v-icon>ກັບສູ່ໜ້າສິນຄ້າ
              </v-chip>
              <v-spacer></v-spacer>

              <v-chip @click="clear" class="ma-2 error">
                <v-icon left>
                  mdi-close-circle
                </v-icon>ຍົກເລິກ
              </v-chip>

              <v-chip @click="submit" class="ma-2 green accent-3">
                <v-icon left>
                  mdi-check-circle
                </v-icon>ບັນທຶກ
              </v-chip>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
      <v-dialog v-model="dialogWarning" max-width="500px">
      <v-card>
        <v-card-text>
          <h2>{{dialogTitle}}</h2>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green accent-3" text @click="DialogWarning"
            >ໂອເຄ</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  email
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    suplier: {
      name: { required, maxLength: maxLength(100) },
      tel: { required, maxLength: maxLength(20) },
      email: { required, email },
      address: { required, maxLength: maxLength(300) },
    },
  },

  data: () => ({
    dialogWarning:false,
    dialogTitle:'',
    suplier: {
      name: "",
      tel: "",
      email: "",
      address: "",
    }
  }),

  computed: {
    NameErrors() {
      const errors = [];
      if (!this.$v.suplier.name.$dirty) return errors;
      !this.$v.suplier.name.maxLength &&
        errors.push('name required');
      !this.$v.suplier.name.required && errors.push('name required');
      return errors;
    },
    TelErrors() {
      const errors = [];
      if (!this.$v.suplier.tel.$dirty) return errors;
      !this.$v.suplier.tel.maxLength &&
        errors.push('tel required');
      !this.$v.suplier.tel.required && errors.push('tel required');
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.suplier.email.$dirty) return errors;
      !this.$v.suplier.email.email && errors.push('email required');
      !this.$v.suplier.email.required && errors.push('email required');
      return errors;
    },

    AddressErrors() {
      const errors = [];
      if (!this.$v.suplier.address.$dirty) return errors;
      !this.$v.suplier.address.maxLength &&
        errors.push('address required');
      !this.$v.suplier.address.required &&
        errors.push('address required');
      return errors;
    },
  },

  methods: {
    ...mapActions("supplierMod", ["InsertSuplier"]),
    async submit() {
      this.$v.$touch();
      try {
       
        if (
          this.suplier.name == "" ||
          this.suplier.name == null ||
          this.suplier.name.length > 100
        ) {
          this.dialogWarning=true
          this.dialogTitle="enter name"
          return;
        }
        if (
          this.suplier.tel == "" ||
          this.suplier.tel == null ||
          this.suplier.tel.length > 20
        ) {
           this.dialogWarning=true
          this.dialogTitle="enter tel"
          return;
        }
        if (
          this.suplier.email == "" ||
          this.suplier.email == null ||
          this.suplier.email.length > 20
        ) {
           this.dialogWarning=true
          this.dialogTitle="enter email"
          return;
        }
        
        if (
          this.suplier.address == "" ||
          this.suplier.address == null ||
          this.suplier.address.length > 300
        ) {
           this.dialogWarning=true
          this.dialogTitle="enter address"
          return;
        }
        
        this.InsertSuplier(this.suplier);
        this.clear();
      } catch (e) {
        this.errors.push(e);
      }
    },
    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        this.dialogWarning=true
          this.dialogTitle=this.$t('fix.only_number')
          
        evt.preventDefault();
      } else {
        return true;
      }
    },
DialogWarning(){
this.dialogWarning=false
},
    clear() {
      this.$v.$reset();
      this.suplier.name = "";
      this.suplier.email = "";
      this.suplier.tel = "";
      this.suplier.address = "";
    },
    back() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
</style>