//**************************Count Item In Product Cart************************ */
export const CountOrderQty = (state) => {
    let SumQty = 0;
    state.ProductCart.forEach(item => {
        SumQty += item.quantity;
    })
    return SumQty;
}
//**************************Caculate Item In Product Cart************************ */
export const TotalPay = (state) => {
    let Sum = 0;
    state.ProductCart.forEach(item => {
        Sum += item.total;
    })
    return Sum;
}
//**************************Count Item In Product OrderCutCart************************ */
export const CountOrderCut_Item = (state) => {
    let SumQty = 0;
    state.OrderCutCartItem.forEach(item => {
        SumQty += item.Quantity;
    })
    return SumQty;
}
//*********************************Sum Item In Cart************************************* */
export const messages = (state) => {
    let OrderByQty = 0, OrderCutQty = 0;
    state.ProductCart.forEach(item => {
        OrderByQty += item.quantity;
    })
    state.OrderCutCartItem.forEach(item => {
        OrderCutQty += item.Quantity;
    })
    return OrderByQty + OrderCutQty
}

//**************************Caculate Discount************************** */
// export const Caculate_Discount = (state) => {
//     let Sum = 0, LastSum = 0;
//     state.ProductCart.forEach(item => {
//         Sum += item.total;
//     })
//     if (JSON.parse(localStorage.getItem('customer_status')).status == "Member") {
//         state.Shop_Info.forEach(itm=>{
//             LastSum = Sum - (Sum * itm.discount) / 100;
//         })
//     } else {
//         LastSum = Sum;
//     }
//     return LastSum;
// }
//*********************************************Get Discount PerCent************** */
// export const Get_Discount_Percent=(state)=>{
//     let discount=0;
//     if (JSON.parse(localStorage.getItem('customer_status')).status == "Member") {
//         state.Shop_Info.forEach(itm=>{
//             discount=itm.discount
//         })
//     }else{
//         discount=0
//     }
//     return discount
// }

export const Money_Pay_First=(state)=>{
    let MoneyPay_Before=0;
    state.OrderCutCartItem.forEach(item=>{
        if(item.Price!==0){
            MoneyPay_Before+=(item.Quantity*item.Price)*10/100
        }else{
            MoneyPay_Before+=item.Quantity*50000
        }
    })
    return MoneyPay_Before
}