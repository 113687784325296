import axios from "axios";
import state from "./state";
import moment from "moment";
//*********************************************************************New Product******In Home**** Limit 4
export const getProducts = async ({ commit }) => {
    var productInit = [];
    var proImg = [];
    var productItem = []
    const result = await axios.get('http://localhost:2021/api/product/getnew')
    productInit = result.data;
    for (const key in productInit) {
        // if (productInit.hasOwnProperty(key)) {
        const item = productInit[key];
        const picture = await axios.get('http://localhost:2021/api/product/image/getall')
        proImg = picture.data;
        var imglist = []
        for (const i in proImg) {
            const image = proImg[i];
            if (item.img_id == image.img_id) {
                const img = await axios.get(
                    `http://localhost:2021/api/product/image/getById/${item.img_id}`
                );
                imglist = img.data;
            }
        }
        var list = {
            proID: item.pro_id,
            proName: item.pro_name,
            Category: item.cate_name,
            Size: item.size,
            Qty: item.qty,
            Price: item.price,
            Image: imglist,
            quantity: 1
        }

        // }
        productItem.push(list);
        // alert(JSON.stringify(productItem));
    }
    commit('Set_Products', productItem)
}//****************************************************************************Get Product Prototype */
export const getProducts_Prototype = async ({ commit }) => {
    var productInit = [];
    var proImg = [];
    var productItem = []
    const result = await axios.get('http://localhost:2021/api/product/get-prototype')
    productInit = result.data;
    for (const key in productInit) {
        // if (productInit.hasOwnProperty(key)) {
        const item = productInit[key];
        const picture = await axios.get('http://localhost:2021/api/product/image/getall')
        proImg = picture.data;
        var imglist = []
        for (const i in proImg) {
            const image = proImg[i];
            if (item.img_id == image.img_id) {
                const img = await axios.get(
                    `http://localhost:2021/api/product/image/getById/${item.img_id}`
                );
                imglist = img.data;
            }
        }
        var list = {
            proID: item.pro_id,
            proName: item.pro_name,
            Category: item.cate_name,
            Size: item.size,
            Qty: item.qty,
            Price: item.price,
            Image: imglist,
            quantity: 1
        }

        // }
        productItem.push(list);
        // alert(JSON.stringify(productItem));
    }
    commit('Set_Products', productItem)
}
//*************************************************************************Product By ID
// export const getProductByID = ({ commit }, productID) => {
//     axios.get(`http://localhost:2021/api/product/getbyId/${productID}`)
//         .then(response => {
//             commit('Set_Product', response.data);
//         })
// }
export const getProductByID = async ({ commit }, productID) => {
    var productInit = [];
    var proImg = [];
    var productItem = []
    const result = await axios.get(`http://localhost:2021/api/product/getbyId/${productID}`)
    productInit = result.data;
    for (const key in productInit) {
        // if (productInit.hasOwnProperty(key)) {
        const item = productInit[key];
        const picture = await axios.get('http://localhost:2021/api/product/image/getall')
        proImg = picture.data;
        var imglist = []
        for (const i in proImg) {
            const image = proImg[i];
            if (item.img_id == image.img_id) {
                const img = await axios.get(
                    `http://localhost:2021/api/product/image/getById/${item.img_id}`
                );
                imglist = img.data;
            }
        }
        var list = {
            proID: item.pro_id,
            proName: item.pro_name,
            Category: item.cate_name,
            Size: item.size,
            Qty: item.qty,
            Unit:item.unit_name,
            create_date:moment(String(item.create_date)).format("DD-MM-YYYY"),
            Color: item.color,
            Price: item.price,
            Image: imglist,
            quantity: 1
        }

        // }
        productItem.push(list);
    }
 //  alert(JSON.stringify(productItem))
    commit('Set_Product', productItem)
}
//***********************************************************************Product Sort By New -Old
export const GetProductList = async ({ commit }) => {
    var productInit = [];
    var proImg = [];
    var productItem = []
    const result = await axios.get('http://localhost:2021/api/product/get')
    productInit = result.data;
    for (const key in productInit) {
        // if (productInit.hasOwnProperty(key)) {
        const item = productInit[key];
        const picture = await axios.get('http://localhost:2021/api/product/image/getall')
        proImg = picture.data;
        var imglist = []
        for (const i in proImg) {
            const image = proImg[i];
            if (item.img_id == image.img_id) {
                const img = await axios.get(
                    `http://localhost:2021/api/product/image/getById/${item.img_id}`
                );
                imglist = img.data;
            }
        }
        var list = {
            proID: item.pro_id,
            proName: item.pro_name,
            Size: item.size,
            Qty: item.qty,
            Price: item.price,
            Image: imglist,
            quantity: 1
        }

        // }
        productItem.push(list);
    }
    commit('Set_ProductList', productItem)
}
//**********************************************************************Product Sort By Old-new
export const ProductOldNew = async ({ commit }) => {
    var productInit = [];
    var proImg = [];
    var productItem = []
    const result = await axios.get('http://localhost:2021/api/product/get-old-new')
    productInit = result.data;
    for (const key in productInit) {
        // if (productInit.hasOwnProperty(key)) {
        const item = productInit[key];
        const picture = await axios.get('http://localhost:2021/api/product/image/getall')
        proImg = picture.data;
        var imglist = []
        for (const i in proImg) {
            const image = proImg[i];
            if (item.img_id == image.img_id) {
                const img = await axios.get(
                    `http://localhost:2021/api/product/image/getById/${item.img_id}`
                );
                imglist = img.data;
            }
        }
        var list = {
            proID: item.pro_id,
            proName: item.pro_name,
            Size: item.size,
            Qty: item.qty,
            Price: item.price,
            Image: imglist,
            quantity: 1
        }

        // }
        productItem.push(list);
        // alert(JSON.stringify(productItem));
    }
    commit('Set_ProductList', productItem)
}
//******************************************************Top ProductS***************************** */
export const getTop_products=async({commit})=>{
    var productInit = [];
    var proImg = [];
    var productItem = []
    const result = await axios.get('http://localhost:2021/api/product/get-top-product')
    productInit = result.data;
    for (const key in productInit) {
        // if (productInit.hasOwnProperty(key)) {
        const item = productInit[key];
        const picture = await axios.get('http://localhost:2021/api/product/image/getall')
        proImg = picture.data;
        var imglist = []
        for (const i in proImg) {
            const image = proImg[i];
            if (item.img_id == image.img_id) {
                const img = await axios.get(
                    `http://localhost:2021/api/product/image/getById/${item.img_id}`
                );
                imglist = img.data;
            }
        }
        var list = {
            proID: item.pro_id,
            proName: item.pro_name,
            Size: item.size,
            Qty: item.qty,
            Price: item.price,
            Image: imglist,
            quantity: 1
        }

        // }
        productItem.push(list);
        // alert(JSON.stringify(productItem));
    }
    commit('SET_TOP_PRODUCT', productItem)
}

//******************************************************Get Popular ProductS***************************** */
export const GetPopular_Products=async({commit})=>{
    var productInit = [];
    var proImg = [];
    var productItem = []
    const result = await axios.get('http://localhost:2021/api/product/get-popular-product')
    productInit = result.data;
    for (const key in productInit) {
        // if (productInit.hasOwnProperty(key)) {
        const item = productInit[key];
        const picture = await axios.get('http://localhost:2021/api/product/image/getall')
        proImg = picture.data;
        var imglist = []
        for (const i in proImg) {
            const image = proImg[i];
            if (item.img_id == image.img_id) {
                const img = await axios.get(
                    `http://localhost:2021/api/product/image/getById/${item.img_id}`
                );
                imglist = img.data;
            }
        }
        var list = {
            proID: item.pro_id,
            proName: item.pro_name,
            Size: item.size,
            Qty: item.qty,
            Price: item.price,
            Image: imglist,
            quantity: 1
        }

        // }
        productItem.push(list);
        // alert(JSON.stringify(productItem));
    }
    commit('Set_ProductList', productItem)
}
//********************************************************************************Get Category******* */

export const GetCategory = ({ commit }) => {
    axios.get('http://localhost:2021/api/category/get')
        .then(response => {
            if (response.data.length == 0) {
                return
            }
            commit('Set_category', response.data)
        })
}
//*********************************************************************************Add Product To Cart
export const AddProductToCart = ({ commit }, { proID, proName, quantity, price, total, Image }) => {
    commit('Add_ToCart', { proID, proName, quantity, price, total, Image });
}
// export const GetCart_Items=({commit})=>{
//     var Cart_Product_Items=localStorage.getItem('CartList')
//   //  alert(JSON.stringify(Cart_Product_Items))
//     commit('Set_Cart',Cart_Product_Items)
// }
//***********************************************************************************Delete item In Cart Order */
export const DeleteCurrentItem = ({ commit }, proID) => {
    commit('Delete_CurrentItemIncart', proID);
}
//*************************************************************************************Clear Product Cart****** */
export const Clear_Cart_List = ({ commit }) => {
    commit('Delete_All_List_ProductCart');
}
export const DeleteOrderCut_CurrentItem = ({ commit }, { proName, Color, Size, Detail }) => {
    commit('Delete_ItemInOrderCut_Cart', { proName, Color, Size, Detail });
}
export const CancelAllItem = ({ commit }) => {
    commit('Delete_All_OrderCut_Item');
}

// export const SuttrackQtyInCart = ({ commit }, { proID, quantity }) => {
//     commit('Substract_Qty_InCart', { proID, quantity });
// }

//*********************************************** Customer-Order-Product *********************************************/
export const Submit_Order = ({ commit }, { order_id, cus_id, order_date, order_time, status, pay_method, card_image,discount }) => {
    var details = []
    for (const key in state.ProductCart) {
        const item = state.ProductCart[key];
        var list = {
            order_id: order_id,
            pro_id: item.proID,
            qty: item.quantity,
            price: item.price,
            discount:discount
        };
        details.push(list);
    }

    axios.post('http://localhost:2021/api/order/insert-order', {
        order_id,
        cus_id,
        order_date,
        order_time,
        status,
        pay_method,
        card_image
    })
        .then(response => {
            if (response.data.length == 0) {
                alert(JSON.stringify("Fail"));
                return
            }
            // alert(JSON.stringify(details));
            for (const idx in details) {
                //     alert(details[idx].qty);
                //    console.log(idx.qty)
                axios.post('http://localhost:2021/api/order/insert-order-detail', details[idx])
                    .then(result => {
                        if (result.data.length === 0) {
                            alert("Fail ? Try again");
                            return;
                        }
                    })
            }
            commit(response.data);
        })
  //  alert(JSON.stringify("Order Complete....!"));
    state.ProductCart = []
}
//*******************************************User Log IN*************************************************** */
export const GetCustomer_Info = ({ commit }, cus_id) => {
    axios.get(`http://localhost:2021/api/customer/getby-id/${cus_id}`)
        .then(response => {
            if (response.data.length === 0) {
                alert("Try Again.....!");
                return;
            }
            //  alert(JSON.stringify( response.data));
            commit('Set_Customer', response.data);
        })
        .catch(error => {
            console.log(error);
        });
}
//*********************************************************************Customer List************************************* */
export const Customer_List = ({ commit }) => {
    axios.get(`http://localhost:2021/api/customer/get`)
        .then(response => {
            if (response.data.length === 0) {
                return;
            }
            // alert(JSON.stringify( response.data));
            commit('Set_Customer_List', response.data);
        })
        .catch(error => {
            console.log(error);
        });
}
//*********************************************************************New Customer Order Product**************************/

export const Add_NewCustomer = ({ commit }, {
    cus_id, full_name, gender, tel, facebook, email, village, district, province, country, password, status, image }) => {
    var Customer_Info = {
        cus_id: cus_id, full_name: full_name, gender: gender, tel: tel, facebook: facebook,
        email: email, village: village, district: district, province: province, country: country,
        password: password, status: status, image: image
    }
    localStorage.setItem('Customer_Info', JSON.stringify(Customer_Info))
    var customer_status = {
        status: status
    }
    localStorage.setItem('customer_status', JSON.stringify(customer_status))

    axios.post('http://localhost:2021/api/customer/post-customer', {
        cus_id,
        full_name,
        gender,
        tel,
        facebook,
        email,
        village,
        district,
        province,
        country,
        password,
        status,
        image
    })
        .then(response => {
            if (response.data.length == 0) {
                alert(JSON.stringify("Fail"));
                return
            }
            commit(response.data)

        })

    //  alert(JSON.stringify("Complete......A"));
}
// ******************************************************Member Register**********************************
export const Add_Customer_member = ({ commit }, { cus_id, register_pay, register_date, pay_method, card_capture }) => {
    axios.post('http://localhost:2021/api/customer/post-customer-member', {
        cus_id,
        register_pay,
        register_date,
        pay_method,
        card_capture
    })
        .then(response => {
            if (response.data.length == 0) {
                alert(JSON.stringify("Fail"));
                return
            }
            commit(response.data)
        })
    alert("ສະມັກຕົວແທນສໍາເລັດ......ລໍຖ້າເຈົ້າຂອງຮ້ານຢືນຢັນ")
}
export const Edit_Customer_Status = async ({ commit }, { cus_id, status }) => {
    var customer_status = {
        status: status
    }
    localStorage.setItem('customer_status', JSON.stringify(customer_status))
    const result = await axios.post('http://localhost:2021/api/customer/edit-customer-status', {
        cus_id,
        status
    })
    commit(result.data)
}
//*********************************************************Order Product************************************************ */
export const Save_Order = ({ commit }, { order_id, cus_id, order_date, order_time, status, pay_method, card_image,discount }) => {
    var details = []
    for (const key in state.ProductCart) {
        const item = state.ProductCart[key];
        var list = {
            order_id: order_id,
            pro_id: item.proID,
            qty: item.quantity,
            price: item.price,
            discount:discount
        };
        details.push(list);
    }

    axios.post('http://localhost:2021/api/order/insert-order', {
        order_id,
        cus_id,
        order_date,
        order_time,
        status,
        pay_method,
        card_image
    })
        .then(response => {
            if (response.data.length == 0) {
                alert(JSON.stringify("Fail"));
                return
            }
            for (const idx in details) {
                axios.post('http://localhost:2021/api/order/insert-order-detail', details[idx])
                    .then(result => {
                        if (result.data.length === 0) {
                            alert("Fail ? Try again");
                            return;
                        }
                    })
            }
            commit(response.data);
        })
    alert(JSON.stringify("Order Complete....!"));
    state.ProductCart = []
}

//******************************************************************************************************* */
export const SaveIamge = ({ commit }, { img_id, img_url }) => {
    axios.post('http://localhost:2021/api/product/post-img', {
        img_id,
        img_url
    })
        .then(response => {
            if (response.data.length == 0) {
                alert(JSON.stringify("Fail"));
                return
            }
            commit(response.data);
            alert(JSON.stringify("Save Complete....!"));
        })
}

//***********************************************************************Add Product To OrderCut Cart****************************** */
export const OrderCutCart = ({ commit }, { proID, proName,Category, Color, Size, Quantity,Unit,Price, Detail, image }) => {
    commit('Add_ToOrderCutCart', { proID, proName,Category, Color, Size, Quantity,Unit,Price, Detail, image });
}

//*************************************New Customer Order Cut*********************************************************/
export const Submit_OrderCut1 = ({ commit }, {ordercut_id,cus_id, order_cut_date, order_cut_time, money_pay, day, status, comment, pay_method, card_image,discount }) => {
    var details = []
    for (const key in state.OrderCutCartItem) {
        const item = state.OrderCutCartItem[key];
        var list = {
            ordercut_id:ordercut_id,
            pro_name: item.proName,
            category:item.Category,
            qty: item.Quantity,
            unit:item.Unit,
            price:item.Price,
            color: item.Color,
            size: item.Size,
            discount:discount,
            detail: item.Detail,
            image: item.image
        };
        details.push(list);
    }

    axios.post('http://localhost:2021/api/ordercut/post-ordercut', {
        ordercut_id,
        cus_id,
        order_cut_date,
        order_cut_time,
        money_pay,
        day,
        status,
        comment,
        pay_method,
        card_image
    })
        .then(response => {
            if (response.data.length == 0) {
                alert(JSON.stringify("Fail"));
                return
            }
            for (const idx in details) {
                axios.post('http://localhost:2021/api/ordercut/post-ordercut-detail', details[idx])
                    .then(result => {
                        if (result.data.length === 0) {
                            alert("Fail ? Try again");
                            return;
                        }
                    })
            }
            commit(response.data);
        })
    alert(JSON.stringify("ສັ່ງຕັດສໍາເລັດ....!"));
    state.OrderCutCartItem = []
}
//*************************************************************Member Customer-Order-Cut */
export const Submit_OrderCut2 = ({ commit }, {cut_id,cus_id, order_cut_date, order_cut_time, money_pay, day, status,pay_method, card_image,discount }) => {
    var details = []
    for (const key in state.OrderCutCartItem) {
        const item = state.OrderCutCartItem[key];
        var list = {
            cut_id:cut_id,
            pro_name: item.proName,
            category:item.Category,
            qty: item.Quantity,
            unit:item.Unit,
            price:item.Price,
            color: item.Color,
            size: item.Size,
            discount:discount,
            detail: item.Detail,
            image: item.image
        };
        details.push(list);
    }
    axios.post('http://localhost:2021/api/ordercut/post-ordercut', {
        cut_id,
        cus_id,
        order_cut_date,
        order_cut_time,
        money_pay,
        day,
        status,
        pay_method,
        card_image
    })
        .then(response => {
            if (response.data.length == 0) {
                alert(JSON.stringify("Fail"));
                return
            }
            for (const idx in details) {
                axios.post('http://localhost:2021/api/ordercut/post-ordercut-detail', details[idx])
                    .then(result => {
                        if (result.data.length === 0) {
                            alert("Fail ? Try again");
                            return;
                        }
                    })
            }
            commit(response.data);
        })
    alert(JSON.stringify("ສັ່ງຕັດສໍາເລັດ....!"));
    state.OrderCutCartItem = []
}
//*****************************************************Insert Comment**********************************/
export const Submit_Comment = ({ commit }, { cus_id,coment_id, customer_content }) => {
    axios.post('http://localhost:2021/api/comment/insert', {
        cus_id,
        coment_id,
        customer_content
    })
        .then(response => {
            if (response.data.length == 0) {
                alert(JSON.stringify("Fail"));
                return
            }
            commit(response.data);
        })
}

//*******************************************************Select By Category*********************** */
export const CategorySelected = async ({ commit }, category) => {
    var productInit = [];
    var proImg = [];
    var productItem = []
    const result = await axios.get(`http://localhost:2021/api/product/get/bycategory/${category}`)
    productInit = result.data;
    for (const key in productInit) {
        // if (productInit.hasOwnProperty(key)) {
        const item = productInit[key];
        const picture = await axios.get('http://localhost:2021/api/product/image/getall')
        proImg = picture.data;
        var imglist = []
        for (const i in proImg) {
            const image = proImg[i];
            if (item.img_id == image.img_id) {
                const img = await axios.get(
                    `http://localhost:2021/api/product/image/getById/${item.img_id}`
                );
                imglist = img.data;
            }
        }
        var list = {
            proID: item.pro_id,
            proName: item.pro_name,
            Size: item.size,
            Qty: item.qty,
            Price: item.price,
            Image: imglist,
            quantity: 1
        }

        // }
        productItem.push(list);
    }
    commit('Set_ProductList', productItem)
}

//***************************************************************Search data*************************************** */

export const SearchData = async ({ commit }, keysearch) => {
    var productInit = [];
    var proImg = [];
    var productItem = []
    const result = await axios.get(`http://localhost:2021/api/product/get/search/${keysearch}`)
    productInit = result.data;
    for (const key in productInit) {
        // if (productInit.hasOwnProperty(key)) {
        const item = productInit[key];
        const picture = await axios.get('http://localhost:2021/api/product/image/getall')
        proImg = picture.data;
        var imglist = []
        for (const i in proImg) {
            const image = proImg[i];
            if (item.img_id == image.img_id) {
                const img = await axios.get(
                    `http://localhost:2021/api/product/image/getById/${item.img_id}`
                );
                imglist = img.data;
            }
        }
        var list = {
            proID: item.pro_id,
            proName: item.pro_name,
            Size: item.size,
            Qty: item.qty,
            Price: item.price,
            Image: imglist,
            quantity: 1
        }

        // }
        productItem.push(list);
    }
    commit('Set_ProductList', productItem)
}
//************************************************************************Get Shop Info*********************************** */
export const Shop_Info = ({ commit }) => {
    axios.get('http://localhost:2021/api/shop/get-shop-info')
        .then(response => {
            if (response.data.length == 0) {
                return
            }
            commit('Set_ShopInfo', response.data)
        })
}
//**********************************************************Edit Customer-Information************************************** */
export const Edit_Customer_Info = ({ commit }, {
    cus_id, full_name, gender, tel, facebook, email, village, district, province, country, password, status}) => {
    var Customer_Info = {
        cus_id: cus_id, full_name: full_name, gender: gender, tel: tel, facebook: facebook,
        email: email, village: village, district: district, province: province, country: country,
        password: password, status: status
    }
    localStorage.setItem('Customer_Info', JSON.stringify(Customer_Info))
    axios.post('http://localhost:2021/api/customer/edit-customer-info', {
        cus_id,
        full_name,
        gender,
        tel,
        facebook,
        email,
        village,
        district,
        province,
        country,
        password,
        status
    })
        .then(response => {
            if (response.data.length == 0) {
                alert(JSON.stringify("Fail"));
                return
            }
            commit(response.data)
        })
    alert(JSON.stringify("ແກ້ໄຂຂໍ້ມູນສຳເລັດ..........!"));
}

//************************************Get Order Buy History************************************************ */
export const GetOrder_BuyHistory = async ({ commit }, cus_id) => {
    // var orderbuy_detail=[]
    const orderbuy = await axios.get(`http://localhost:2021/api/order/get-orderbuy-history/${cus_id}`)

    //    for (const key in orderbuy.data) {
    //            orderbuy_detail=await axios.get(`http://localhost:2021/api/order/get-orderbuydetail-history/${orderbuy.data[key].order_id}`)
    //    }
    commit('Set_OrderBuy_List', orderbuy.data)
    //    commit('Set_OrderBuy_List_detail',orderbuy_detail.data)
    // alert(JSON.stringify(orderbuy.data))
}
//********************************************See_Notifications********************************************** */
export const See_Notifications=({commit},{receive_status,cus_id})=>{
    axios.post(`http://localhost:2021/api/comment/edit-receive-status`,{
        receive_status,
        cus_id
    })
    .then(response => {
        if (response.data.length == 0) {
            alert(JSON.stringify("Fail"));
            return
        }
        commit(response.data)
        alert("SC")
    })
}
//***********************************************Edit Customer Profile************************************* */
export const Edit_Customer_Profile=async({commit},{cus_id,image})=>{
    var customer_profile = {
        image: image
    }
    localStorage.setItem('customer_profile', JSON.stringify(customer_profile))
    
   const result=await axios.post('http://localhost:2021/api/customer/edit-customer-profile',{
    cus_id,
    image,
   })
   if(result.data.length>=0){
       commit(result.data)
   }
}

//******************************************************************Get Counrty************* */
export const Get_Counrtry=async({commit})=>{
    var Country=[]
   const result= await axios.get('https://restcountries.eu/rest/v2/all')
    for (const key in result.data) {
            const element = result.data[key];
       var countrylist={
           name:element.name
       }
       Country.push(countrylist)
    }
  //  alert(JSON.stringify(Country))
    commit('Set_Country',Country)
}