<template>
  <v-card height="550" class="elevation-0">
    <v-row justify="center" class="mt-3 mb-10">
      <v-card width="500" class="elevation-0 mb-10">
        <v-card-title>
          <span id="formm">ເຂົ້າລະບົບ</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col cols="12">
              <h1 class="mb-3" id="myfont">ອີແມວ*</h1>
              <v-text-field
                id="textfield"
                placeholder="Email"
                outlined
                v-model="email"
                append-icon="mdi-email"
                dense
                :error-messages="EmailErrors"
                required
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
              ></v-text-field>
              <h1 class="mb-3" id="myfont">ລະຫັດຜ່ານ*</h1>
              <v-text-field
                id="textfield"
                placeholder="Password"
                type="password"
                outlined
                v-model="password"
                :error-messages="PWDError"
                counter
                append-icon="mdi-key"
                dense
                required
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
              ></v-text-field>
              <!-- <v-checkbox
                class="myfont"
                v-model="checkbox"
                :rules="[(v) => !!v || 'You must agree to continue!']"
                label="ຈື່ລະຫັດໄວ້ ?"
                required
              ></v-checkbox> -->
              <v-row class="mt-5">
                <v-btn
                  rounded
                  color="#FF7043"
                  id="btnAdd"
                  class="mb-10 ml-16"
                  @click="Cancel_Login"
                >
                  <v-icon left>mdi-close</v-icon>
                  ຍົກເລິກ
                </v-btn>
                <v-btn
                  rounded
                  color="#00E676"
                  id="btnAdd"
                  class="mb-10 ml-16"
                  @click="Login"
                >
                  <v-icon class="ml-2" left>mdi-login-variant</v-icon>
                  ເຂົ້າລະບົບ
                </v-btn>
              </v-row>
              <v-row justify="end">
                <a
                  style="font-family: NotoSansLao;text-decoration: underline;font-size:20px;color:black"
                  @click="Register"
                  >ຍັງບໍ່ມີບັນຊີ</a
                >
              </v-row>
            </v-col>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    email: { required, email },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
    },
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  mounted() {
    this.$store.dispatch("Customer_List");
  },
  computed: {
    Customer_detail() {
      return this.$store.state.Customer_detail;
    },
    EmailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("Email is required");
      return errors;
    },
    PWDError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Password must be at less 6 characters short");
      !this.$v.password.maxLength &&
        errors.push("Password must be at most 20 characters long");
      !this.$v.password.required && errors.push("Password is required.");
      return errors;
    },
  },
  methods: {
    Login() {
      var found = true;
      if (
        this.email == "" ||
        this.email == null ||
        this.password == "" ||
        this.password == null
      ) {
        this.$v.$touch();
        return;
      }
      if (this.email != "" && this.password != "") {
        for (const key in this.Customer_detail) {
          const element = this.Customer_detail[key];
          if (
            this.email == element.email &&
            this.password == element.password
          ) {
            var Customer_Info = {
              cus_id: element.cus_id,
              full_name: element.full_name,
              gender: element.gender,
              tel: element.tel,
              facebook: element.facebook,
              email: element.email,
              village: element.village,
              district: element.district,
              province: element.province,
              country: element.country,
              password: element.password,
              status: element.status,
              image: element.image,
            };
            var customer_status = {
              status: element.status,
            };
            var customer_profile = {
              image: element.image,
            };
            localStorage.setItem(
              "customer_status",
              JSON.stringify(customer_status)
            );
            localStorage.setItem(
              "Customer_Info",
              JSON.stringify(Customer_Info)
            );
            localStorage.setItem(
              "customer_profile",
              JSON.stringify(customer_profile)
            );
            this.$router.push(`/Customer`);
            return;
          } else {
            found = false;
          }
        }
      }
      if (found === false) {
        alert(
          "<...ອີແມວ ຫຼື ລະຫັດຜ່ານຂອງທ່ານບໍ່ຖຶກຕ້ອງ...>...ກະລຸນາກວດຄືນ...!"
        );
      }
    },
    Register() {
      this.$router.push("/Register");
    },
    Cancel_Login() {
      this.$v.$reset();
      this.email = "";
      this.password = "";
    },
  },
};
</script>

<style>
#formm {
  font-size: 25px;
  font-family: "Noto Sans Lao";
  font-weight: bold;
  align-content: center;
  margin-left: 180px;
  margin-top: 10px;
  color: black;
}
</style>
