<template>
  <v-card class="elevation-1">
    <v-row justify="center">
      <h2 class="mt-7 mb-5">ຮັບການສັ່ງຕັດເຄື່ອງຈາກລູກຄ້າ</h2>
    </v-row>
    <v-divider></v-divider>
    <v-tabs
      class="pa-1"
      v-model="tabs"
      centered
      grow
      slider-color="blue"
      slider-size="3"
      background-color="transparent"
    >
      <v-tab
        class="pt-5"
        background-color="transparent"
        v-for="n in 3"
        :key="n"
      >
        <h2 v-if="n == 1">ປະຫວັດສັ່ງຕັດສິນຄ້າ</h2>
        <v-badge v-if="n == 2" color="error" :content="new_cutItems.length">
          <h2>ລາຍການສັ່ງຕັດສິນຄ້າໃໝ່</h2>
        </v-badge>
        <h2 v-if="n == 3">ຮັບສັ່ງຕັດສິນຄ້າ</h2>
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-card flat>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="cutItems"
            :single-expand="true"
            :expanded.sync="expanded"
            item-key="cut_id"
            show-expand
            class="elevation-3"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>ປະຫວັດສັ່ງຕັດສິນຄ້າ</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ຄົ້ນຫາ"
                  dense
                  outlined
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="detailHeader"
                  :items="item.orderDetail"
                  hide-default-footer
                  class="elevation-2"
                  style="background: #fafafaff"
                >
                  <template v-slot:item="{ item }">
                    <tr v-ripple="{ class: `white--text` }">
                      <td>{{ item.idx }}</td>
                      <td>
                        <v-card
                          height="100"
                          width="100"
                          :img="fectImg(item.image)"
                        ></v-card>
                      </td>
                      <td>{{ item.pro_name }}</td>
                      <td>{{ item.color }}</td>
                      <td>{{ item.size }}</td>
                      <td>
                        {{
                          String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                        {{ item.unit }}
                      </td>
                      <td>
                        {{
                          String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                      </td>
                      <td>
                        {{
                          String(item.qty * item.price).replace(
                            /(.)(?=(\d{3})+$)/g,
                            "$1 "
                          )
                        }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <!-- _______________________________________________________________-222-___________________________________ -->
      <v-tab-item>
        <v-card flat>
          <v-data-table
            :search="newsearch"
            :headers="newHeaders"
            :items="new_cutItems"
            :single-expand="true"
            :expanded.sync="newexpanded"
            item-key="cut_id"
            show-expand
            class="elevation-3"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>ລາຍການສັ່ງຕັດສິນຄ້າ</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="newsearch"
                  append-icon="mdi-magnify"
                  single-line
                  hide-details
                  label="ຄົ້ນຫາ"
                  dense
                  outlined
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon large color="#C51162" @click="editeItem(item)"
                >mdi-cart-check</v-icon
              >
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="newdetailHeader"
                  :items="item.orderDetail"
                  hide-default-footer
                  class="elevation-2"
                  style="background: #fafafaff"
                >
                  <template v-slot:item="{ item }">
                    <tr v-ripple="{ class: `white--text` }">
                      <td>{{ item.idx }}</td>
                      <td>
                        <v-card height="100" width="70">
                          <v-img :src="fectImg(item.image)"></v-img>
                        </v-card>
                      </td>
                      <td>{{ item.pro_name }}</td>
                      <td>{{ item.color }}</td>
                      <td>{{ item.size }}</td>
                      <td>
                        {{
                          String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                        {{ item.unit }}
                      </td>
                      <td>
                        {{
                          String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                      </td>
                      <td>
                        {{
                          String(item.qty * item.price).replace(
                            /(.)(?=(\d{3})+$)/g,
                            "$1 "
                          )
                        }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <!-- _______________________________________________________-333-_______________________________________ -->
      <v-tab-item>
        <v-card class="elevation-0">
          <v-row no-gutters class="justify-center ma-3 ml-1">
            <v-col cols="12" md="2" class="align-center">
              <v-card width="100%" height="52" class="ma-1 pa-3">
                <h5>
                  ເລກບີນ:
                  <span>{{ editList.cut_id }}</span>
                </h5>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" class="align-center">
              <v-card width="100%" height="52" class="ma-1 pa-3">
                <h5>
                  ຜູ້ສັ່ງຕັດ:
                  <span>{{ editList.full_name }}</span>
                </h5>
              </v-card>
            </v-col>
            <v-col cols="12" md="2" class="align-center">
              <v-card width="100%" height="52" class="ma-1 pa-3">
                <h3>
                  ສ່ວນຫຼຸດ:
                  <span>{{ discount }}</span
                  >%
                </h3>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" class="align-center">
              <v-card width="100%" height="52" class="ma-1 pa-3">
                ຄອມແມັ້ນ:
                <span>{{ editList.customer_content }}</span>
              </v-card>
            </v-col>
          </v-row>
          <v-data-table
            :headers="recievedetailHeader"
            :items="editList.orderDetail"
            hide-default-footer
            class="elevation-2 mx-2"
          >
            <template v-slot:top>
              <div>
                <v-row no-gutters justify="start">
                  <v-col cols="12" md="2" align="start">
                    <h4 class="mt-3 ml-3">ເລຶອກຮູບສິນຄ້າ</h4>
                    <v-card class="mx-1 py-1" outlined>
                      <input
                        type="file"
                        accept="image/*"
                        class="mx-1 file-upload"
                        @change="onFileSelected"
                        placeholder="image"
                      />
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="2" align="start">
                    <h4 class="mt-3 ml-3">ຊື່ສິນຄ້າ</h4>
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.pro_name"
                      placeholder="Product"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="1" align="start">
                    <h4 class="mt-3 ml-3">ປະເພດສິນຄ້າ</h4>
                    <v-autocomplete
                      dense
                      outlined
                      v-model="editedItem.category"
                      :items="cate_names"
                      placeholder="Category"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="1" align="start">
                    <h4 class="mt-3 ml-3">ສີ</h4>
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.color"
                      placeholder="Color"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="1" align="start">
                    <h4 class="mt-3 ml-3">ຂະໜາດ</h4>
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.size"
                      placeholder="Size"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="1" align="start">
                    <h4 class="mt-3 ml-3">ຈໍານວນ</h4>
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.qty"
                      placeholder="Quantity"
                      @keypress="number($event)"
                      @keyup="CheckNumber(editedItem)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="1" align="start">
                    <h4 class="mt-3 ml-3">ຫົວໜ່ວຍ</h4>
                    <v-autocomplete
                      dense
                      outlined
                      v-model="editedItem.unit"
                      :items="unit_names"
                      placeholder="Unit"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2" align="start">
                    <h4 class="mt-3 ml-3">ລາຄາ</h4>
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.price"
                      @keypress="number($event)"
                      placeholder="Price"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="1" align="center">
                    <v-btn
                      :disabled="add"
                      @click="save"
                      class="mt-9"
                      style="font-size: 18px; font-weight: bold"
                      color="#FFFFFF"
                      rounded
                    >
                      <v-icon color="primary">mdi-check-underline</v-icon>ເພີ່ມ
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider class></v-divider>
                <v-divider class></v-divider>
              </div>
            </template>

            <template v-slot:item="{ item }">
              <tr v-ripple="{ class: `white--text` }">
                <td>{{ item.idx }}</td>
                <td>
                  <v-card
                    height="100"
                    width="100"
                    :img="fectImg(item.image)"
                  ></v-card>
                </td>
                <td>{{ item.pro_name }}</td>
                <td>{{ item.category }}</td>
                <td>{{ item.color }}</td>
                <td>{{ item.size }}</td>
                <td>
                  <v-text-field
                    class="mt-3 text-center tcenter"
                    filled
                    dense
                    rounded
                    prepend-inner-icon="mdi-minus"
                    append-icon="mdi-plus"
                    autofocus
                    v-model="item.qty"
                    hint="ຈໍານວນທີ່ຕ້ອງການ"
                    @click:append="Add(item)"
                    @click:prepend-inner="Minus(item)"
                    @keypress="isNumber($event, item)"
                    @keyup="CheckNumber(item)"
                  >
                    <template slot="append">
                      <v-btn
                        style="margin-top: -7px; margin-right: -20px"
                        color="green"
                        icon
                        @click="Add(item)"
                      >
                        <v-icon large>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <template slot="prepend-inner">
                      <v-btn
                        style="margin-top: -7px; margin-left: -20px"
                        color="green"
                        icon
                        @click="Minus(item)"
                      >
                        <v-icon large>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </td>
                <td>{{ item.unit }}</td>
                <td>
                  {{ String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }}
                </td>
                <td>
                  {{
                    String(item.qty * item.price).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1 "
                    )
                  }}
                </td>
                <td>
                  {{ item.detail }}
                </td>
                <td>
                  <v-row no-gutters class="justify-center">
                    <v-icon
                      class="mr-2"
                      color="orange"
                      @click="updateItem(item)"
                      >mdi-pencil</v-icon
                    >
                    <v-icon class="mr-2" color="error" @click="deleteItem(item)"
                      >mdi-delete</v-icon
                    >
                  </v-row>
                </td>
              </tr>
            </template>

            <template v-slot:footer>
              <v-divider class></v-divider>
              <v-row class="ma-5" align="center" justify="center">
                <v-spacer></v-spacer>
                <v-text-field
                  dense
                  label="ຄ່າມັດຈໍາ"
                  v-model="editList.money_pay"
                  @keypress="number($event)"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-text-field
                  dense
                  label="ຈໍານວນມື້ທີ່ຄາດວ່າຈະຕັດໄດ້"
                  v-model="editList.day"
                  placeholder="ຈໍານວນມື້ທີ່ຄາດວ່າຈະຕັດໄດ້"
                  @keypress="number($event)"
                ></v-text-field>
                <v-spacer></v-spacer>
                <h3>
                  ລາຄາລວມ:
                  {{ String(Todal).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }} ກິບ
                </h3>
                <v-spacer></v-spacer>
                <h3>
                  ລາຄາທີ່ຕ້ອງຊໍາລະ:
                  {{ String(Sum).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }} ກິບ
                </h3>
              </v-row>
            </template>
          </v-data-table>
          <v-row no-gutters class="ma-2 justify-center">
            <v-card width="100%" class="elevation-0">
              <h4>ຄໍາຄິດເຫັນ ຫຼື ລາຍລະອຽດໃຫ້ລູກຄ້າ</h4>
              <v-textarea
                background-color="grey lighten-2"
                color="cyan"
                v-model="editList.receive_content"
                placeholder="Add Comment Or Other Details"
              ></v-textarea>
            </v-card>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="font-size: 20px; font-weight: bold"
              color="#FF8A65"
              rounded
              class="mb-5 mr-3"
              @click="back"
            >
              <v-icon left large> mdi-close-circle </v-icon>ຍົກເລິກ</v-btn
            >
            <v-btn
              style="font-size: 20px; font-weight: bold"
              color="#1DE9B6"
              rounded
              class="mb-5 mr-5"
              @click="Submit"
            >
              <v-icon left large> mdi-check-circle </v-icon
              >ຢຶນຢັນຮັບສັ່ງຕັດ</v-btn
            >
          </v-card-actions>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card rounded>
              <v-card-title
                style="
                  font-size: 25px;
                  font-weight: bold;
                  font-family: NotoSansLao;
                "
                >ທ່ານຕ້ອງການລຶບຂໍ້ມູນລາຍການນີ້ບໍ...?</v-card-title
              >
              <v-divider class></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="closeDelete"
                  style="font-size: 20px; font-family: NotoSansLao"
                  >ຍົກເລິກ</v-btn
                >
                <v-btn
                  color="primary"
                  text
                  @click="deleteConfirm"
                  style="font-size: 20px; font-family: NotoSansLao"
                  >ຢຶນຢັນລຶບ</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="dialogCustomer" max-width="1000">
      <v-card rounded>
        <v-card-title
          class="ml-16"
          style="font-size: 25px; font-weight: bold; font-family: NotoSansLao"
        >
          <v-row no-gutters class="mx-2">
            <span>ເລຶອກລູກຄ້າ</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchCustomer"
              append-icon="mdi-magnify"
              single-line
              hide-details
              label="ຄົ້ນຫາຂໍ້ມູນລູກຄ້າ"
              dense
              outlined
            ></v-text-field>
          </v-row>
        </v-card-title>

        <v-data-table
        style="font-family: NotoSansLao"
          large
          :headers="CustomerHeaders"
          :items="customers"
          :search="searchCustomer"
          fixed-header
          class="elevation-2 table ma-2 mx-7"
        >
          <template v-slot:item="{ item }">
            <tr class="center" v-ripple="{ class: `white--text` }">
              <td>
                <v-row class="justify-center">
                  <v-btn color="#880E4F" outlined style="font-size:20px" @click="ChoiceCustomer(item)">
                  <v-icon
                    class="mr-2"
                    color="success"
                    left
                    large
                  >
                    mdi-account-check
                  </v-icon>
                  ເລຶອກ</v-btn>
                </v-row>
              </td>
              <td>{{ item.idx }}</td>
              <td align="center">
                <v-card class="mx-auto" height="100" width="100">
                  <v-img :src="fectCustomerImg(item.image)"></v-img>
                </v-card>
              </td>
              <td>{{ item.full_name }}</td>
              <td>{{ item.gender }}</td>
              <td>{{ item.village }}</td>
              <td>{{ item.district }}</td>
              <td>{{ item.province }}</td>
              <td>{{ item.country }}</td>
            </tr>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="closeDelete" style="font-family: NotoSansLao;font-size:25px">ປິດ</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// import axios from "axios";
export default {
  data: () => ({
    tabs: 1,
    text: "",
    dialog: false,
    dialogCustomer: false,
    search: "",
    newsearch: "",
    dialogDelete: false,
    expanded: [],
    newexpanded: [],
    editList: [],
    cut_id: "",
    editedItem: {
      cut_id: null,
      idx: 1,
      pro_name: null,
      category: null,
      unit: null,
      color: null,
      size: null,
      qty: null,
      price: null,
      discount: 0,
      detail: null,
      image: null,
    },
    discount: 0,
    item_length: 0,
    deleteList: [],
    headers: [
      { text: "ລໍາດັບ", value: "idx" },
      {
        text: "ບິນສັ່ງຕັດ",
        align: "start",
        value: "cut_id",
      },
      { text: "ພະນັກງານ", value: "emp_name" },
      { text: "ຜູ້ສັ່ງຕັດ", value: "full_name" },
      { text: "ເບີ້ໂທ", value: "customer_tel" },
      { text: "ອີມິວ", value: "customer_email" },
      { text: "ວັນທີ່ສັ່ງຕັດ", value: "order_cut_date" },
      { text: "ເວລາສັ່ງຕັດ", value: "order_cut_time" },
      { text: "ສ່ວນຫຼຸດ", value: "discount" },
      { text: "ຈ່າຍຜ່ານ", value: "pay_method" },
      { text: "ເມັ້ນຂອງລູກຄ້າ", value: "customer_content" },
      { text: "ໃຫ້ຄໍາແນະນໍາ", value: "receive_content" },
      { text: "", value: "data-table-expand" },
    ],
    detailHeader: [
      { text: "ລໍາດັບ", value: "idx" },
      {
        text: "ຮູບ",
        sortable: false,
        value: "image",
      },
      { text: "ຊື່ສິນຄ້າ", value: "pro_name" },
      { text: "ສີ", value: "color" },
      { text: "ຂະໜາດ", value: "size" },
      { text: "ຈໍານວນ", value: "qty" },
      { text: "ລາຄາ(ກິບ)", value: "price" },
      { text: "ລາຄາລວມ(ກິບ)", value: "price*qty" },
    ],
    newHeaders: [
      { text: "ລໍາດັບ", value: "idx" },
      { text: "ຜູ້ສັ່ງຕັດ", value: "full_name" },
      { text: "ເບີ້ໂທ", value: "customer_tel" },
      { text: "ອີມິວ", value: "customer_email" },
      { text: "ວັນທີ່ສັ່ງຕັດ", value: "order_cut_date" },
      { text: "ຈໍານວນມື້", value: "day" },
      { text: "ເມັ້ນຂອງລູກຄ້າ", value: "customer_content" },
      { text: "ຈ່າຍຜ່ານ", value: "pay_method" },
      { text: "ສ່ວນຫຼຸດ", value: "discount" },
      { text: "ສະຖານະ", value: "status" },
      { text: "ຮັບສັ່ງຕັດ", value: "actions" },
      { text: "", value: "data-table-expand" },
    ],
    newdetailHeader: [
      { text: "ລໍາດັບ", value: "idx" },
      {
        text: "ຮູບ",
        sortable: false,
        value: "image",
      },
      { text: "ຊື່ສິນຄ້າ", value: "pro_name" },
      { text: "ສີ", value: "color" },
      { text: "ຂະໜາດ", value: "size" },
      { text: "ຈໍານວນ", value: "qty" },
      { text: "ລາຄາ(ກິບ)", value: "price" },
      { text: "ລາຄາລວມ(ກິບ)", value: "price*qty" },
    ],
    recievedetailHeader: [
      { text: "ລໍາດັບ", value: "idx" },
      {
        text: "ຮູບ",
        align: "center",
        sortable: false,
        value: "image",
      },
      { text: "ຊື່ສິນຄ້າ", value: "pro_name" },
      { text: "ປະເພດ", value: "category" },
      { text: "ສີ", value: "color" },
      { text: "ຂະໜາດ", value: "size" },
      { text: "ຈໍານວນ", value: "qty" },
      { text: "ຫົວໜ່ວຍ", value: "unit" },
      { text: "ລາຄາ(ກິບ)", value: "price" },
      { text: "ລາຄາລວມ(ກິບ)", value: "price*qty" },
      { text: "ລາຍລະອຽດ", value: "detail" },
      { text: "ຈັດການ", value: "actions", align: "center", sortable: false },
    ],
    searchCustomer: "",
    CustomerHeaders: [
      { text: "ເລຶອກ", value: "actions", sortable: false },
      { text: "ລໍາດັບ", value: "idx" },
      {
        text: "ຮູບ",
        align: "start",

        sortable: false,
        value: "image",
      },
      { text: "ຊື່ລູກຄ້າ", value: "full_name" },
      { text: "ເພດ", value: "gender" },
      { text: "ບ້ານ", value: "village" },
      { text: "ເມືອງ", value: "district" },
      { text: "ແຂວງ", value: "province" },
      { text: "ປະເທດ", value: "country" },
    ],
  }),

  computed: {
    ...mapGetters({
      products: "productMod/products",
      new_cutItems: "cutMod/new_cutItems",
      // cuttingItems: "cutMod/cuttingItems",
      cutItems: "cutMod/cutItems",
      cate_names: "productMod/cate_names",
      unit_names: "productMod/unit_names",
    }),

    ...mapGetters({
      customers: "customerMod/customers",
    }),
    Todal() {
      var sum = 0;
      if (this.editList.length < 1) {
        return sum;
      }
      for (const key in this.editList.orderDetail) {
        const el = this.editList.orderDetail[key];
        if (
          parseInt(el.qty) > 0 &&
          el.qty != "" &&
          parseFloat(el.price) > 0 &&
          el.price != null
        ) {
          sum += parseInt(el.qty, 10) * parseFloat(el.price);
        }
      }
      return sum;
    },
    Sum() {
      var sum = 0;
      sum = this.Todal - (this.Todal * parseFloat(this.discount)) / 100;
      return sum;
    },
    add() {
      if (
        this.editedItem.pro_name == "" ||
        this.editedItem.pro_name == undefined ||
        this.editedItem.pro_name == null ||
        this.editedItem.size == "" ||
        this.editedItem.size == undefined ||
        this.editedItem.size == null ||
        this.editedItem.color == "" ||
        this.editedItem.color == undefined ||
        this.editedItem.color == null ||
        this.editedItem.qty == "" ||
        this.editedItem.qty == undefined ||
        this.editedItem.qty == null ||
        this.editedItem.price == "" ||
        this.editedItem.price == undefined ||
        this.editedItem.price == null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.closeDelete();
    },
    cuttingItems(val) {
      if (val.length == 0) {
        this.tabs = 0;
      }
    },
  },
  mounted() {
    this.initial();
    this.$store.dispatch("Shop_Info");
  },
  methods: {
    ...mapActions("productMod", [
      "InsertProduct",
      "Insert_Image",
      "getProduct",
      "getCategory",
      "getUnit",
    ]),
    ...mapActions("saleMod", ["InsertSale"]),
    ...mapActions("commentMod", ["UpdateComment","InsertCutComment"]),
    ...mapActions("cutMod", [
      "getCutted",
      "getCutting",
      "getNewCut",
      "UpdateCuting",
      "InsertCutting",
    ]),
    ...mapActions("customerMod", ["getCustomer"]),

    initial() {
      this.getCategory();
      this.getUnit();
      this.getProduct();
      this.getCutted();
      this.getCutting();
      this.getNewCut();
      this.getCustomer();
    },
    onFileSelected(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.url = e.target.result;
      };
      this.editedItem.image = event.target.files[0].name;
    },
    fectCustomerImg(img){
          var imageUrl;
   if (img) {
        imageUrl = require("@/assets/images/customer/" + img);
      } else {
        imageUrl = require("@/assets/Emty_Profile.png");
      }
      return imageUrl;
    },
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
    Add(pro) {
      if (
        (typeof pro.qty == "string" && pro.qty == "") ||
        typeof pro.qty == undefined
      ) {
        pro.qty = 1;
        return;
      }
      pro.qty = parseInt(pro.qty, 10) + 1;
    },
    Minus(pro) {
      if (parseInt(pro.qty, 10) < 2) return;
      pro.qty = parseInt(pro.qty, 10) - 1;
    },
    isNumber: function ($event, pro) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      } else {
        if (
          (typeof pro.qty == "string" && pro.qty == "") ||
          pro.qty == null ||
          pro.qty == 0
        ) {
          pro.qty = 1;
        }
        return true;
      }
    },
    number($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      } else {
        return true;
      }
    },

    CheckNumber(pro) {
      if (
        (typeof pro.qty == "string" && pro.qty == "") ||
        pro.qty == null ||
        pro.qty == 0
      ) {
        pro.qty = 1;
      }
    },
    ChoiceCustomer(item) {
      var dcount = 0;
      if (item.status == "Member") {
        dcount = this.$store.state.Shop_Info[0].discount
      }
      this.discount=dcount
      this.editList = {
        idx: 1,
        cut_id: "",
        cus_id: item.cus_id,
        full_name: item.full_name,
        customer_tel: item.tel,
        customer_email: item.email,
        order_cut_date: null,
        order_cut_time: null,
        day: 7,
        money_pay: 0,
        status: "Confirm",
        pay_method: "ຈ່າຍໜ້າຮ້ານ",
        com_id: "",
        customer_content: "ລໍຖ້າຮັບສັ່ງຕັດເຄື່ອງ>>>",
        receive_content:
          "ຮ້ານດາດ້າແຟຊັ່ນຮັບຕັດສິນຄ້າຂອງທ່ານແລ້ວ...ກະລຸນາລໍຖ້າຕາມເວລາທີ່ກໍານົດ...ຂໍຂອບໃຈ",
        card_image: "",
        discount: dcount,
        orderDetail: [],
      };
      this.dialogCustomer = false;
    },
    editeItem(item) {
      this.editList = item;
      this.cut_id = item.cut_id;
      this.item_length = item.length;
      this.discount = this.editList.orderDetail[0].discount;
      if (this.editList.status == "Confirm") {
        if (
          this.editList.receive_content == "" ||
          this.editList.receive_content == undefined ||
          this.editList.receive_content == null
        ) {
          this.editList.receive_content =
            "ຮ້ານດາດ້າແຟຊັ່ນຮັບຕັດສິນຄ້າຂອງທ່ານແລ້ວ...ກະລຸນາລໍຖ້າຕາມເວລາທີ່ກໍານົດ...ຂໍຂອບໃຈ";
        }
      } else {
        if (
          this.editList.receive_content == "" ||
          this.editList.receive_content ==
            "ຮ້ານດາດ້າແຟຊັ່ນຮັບຕັດສິນຄ້າຂອງທ່ານແລ້ວ...ກະລຸນາລໍຖ້າຕາມເວລາທີ່ກໍານົດ...ຂໍຂອບໃຈ" ||
          this.editList.receive_content == undefined ||
          this.editList.receive_content == null
        ) {
          this.editList.receive_content =
            "ສິນຄ້າທີ່ທ່ານສັ່ງຕັດຕັດສໍາເລັດແລ້ວ...ກະລຸນາລໍຖ້າພະນັກງານຈະຕິດຕໍ່ກັບ...ຂໍຂອບໃຈ";
        }
      }

      this.tabs = 2;
    },
    updateItem(item) {
      this.editedItem = item;
    },
    save() {
      if (
        this.editList.cus_id == "" ||
        this.editList.cus_id == null ||
        this.editList.cus_id == undefined
      ) {
        this.dialogCustomer = true;
        return;
      }
      if (this.editList.orderDetail.length > 0) {
        for (const key in this.editList.orderDetail) {
          const element = this.editList.orderDetail[key];
          if (
            element.pro_name == this.editedItem.pro_name &&
            element.color == this.editedItem.color &&
            element.size == this.editedItem.size
          ) {
            this.editList.orderDetail.splice(key, 1);
          }
          this.editedItem.idx = parseInt(key) + 2;
          this.editedItem.discount = this.discount;
        }
      }
      this.editedItem.detail = "ສັ່ງຕັດ";
      this.editedItem.cut_id = "0";
      this.editedItem.discount=this.discount
      this.editList.orderDetail.push(this.editedItem);
      this.clear();
    },
    deleteItem(item) {
      this.deleteList = item;
      this.dialog = true;
    },
    deleteConfirm() {
      for (const idx in this.editList.orderDetail) {
        const element = this.editList.orderDetail[idx];
        if (element.pro_name == this.deleteList.pro_name) {
          this.editList.orderDetail.splice(idx, 1);
          this.deleteList = [];
        }
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialog = false;
      this.dialogCustomer = false;
    },
    async Submit() {
      this.editList.emp_id = this.$store.state.authMod.token.emp_id;
      if (this.editList.status == "Confirm") {
        this.editList.status = "Cutting";
        if (
          this.editList.receive_content == "" ||
          this.editList.receive_content == undefined ||
          this.editList.receive_content == null
        ) {
          this.editList.receive_content =
            "ຮ້ານດາດ້າແຟຊັ່ນຮັບຕັດສິນຄ້າຂອງທ່ານແລ້ວ...ກະລຸນາລໍຖ້າຕາມເວລາທີ່ກໍານົດ...ຂໍຂອບໃຈ";
        }
        if (this.editList.cut_id == "") {
          var date = new Date().toJSON().slice(0, 10).replace(/-/g, "");
          var time = new Date()
            .toTimeString()
            .replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1", "")
            .replace(":", "")
            .replace(":", "");
          var cut_date = new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
            cut_time = new Date().toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1")
          this.editList.cut_id = "DOC" + date.slice(2, date.length) + time;
          this.editList.order_cut_date = cut_date;
          this.editList.order_cut_time = cut_time;
          this.InsertCutting(this.editList);

          var addcomment = {
            emp_id: this.$store.state.authMod.token.emp_id,
            cus_id: this.editList.cus_id,
            comment_id: "DOC" + date.slice(2, date.length) + time,
            customer_content: "ຂໍຄວາມຊ່ວຍເຫຼືອສັ່ງຕັດເຄື່ອງ",
            receive_content: this.editList.receive_content,
            receive_date: cut_date,
            receive_time: cut_time,
            receive_status: 1,
          };
          this.InsertCutComment(addcomment);
        }else{
        this.UpdateCuting(this.editList);
        var comment = {
          id: this.editList.com_id,
          emp_id: this.$store.state.authMod.token.emp_id,
          receive_content: this.editList.receive_content,
        };
        this.UpdateComment(comment);
      }
      }
      this.clear();
      this.back();
    },
    clear() {
      this.editedItem = {
        cut_id: null,
        idx: 1,
        pro_name: null,
        category: null,
        unit: null,
        color: null,
        size: null,
        qty: null,
        price: null,
        discount: 0,
        detail: null,
        image: null,
      };
    },
    back() {
      this.editList = [];
      this.tabs = 1;
      this.initial();
    },
  },
};
</script>
<style scoped>
.tcenter {
  width: 200px;
  min-width: 100px;
}
.tcenter >>> input {
  text-align: center;
}
</style>