import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import { header } from '../../config/constant'

Vue.use(Vuex)

const orderModule = {
  namespaced: true,
  state: {
    orders: [],
    new_orders:[],
    confirm_orders:[]
  },
  mutations: {
    setOrder(state, order) {
      state.orders = order
    },
    setNewOrder(state, order) {
      state.new_orders = order
    },
    setConfirmOrder(state, order) {
      state.confirm_orders = order
    },
    
  },

  actions: {
    async getOrder(context) {
      try {
        var orderList = [],detailList=[]
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/buy/getall`,{
            headers:header
          }
        );
        for (const key in response.data) {
            detailList=[]
          const element = response.data[key];
              const detail = await axios.get(
                `http://localhost:2021/api/dadabackend/order/buy/detail/getById/${element.order_id}`,{
                  headers:header
                }
              );
              detailList=[]
              for (const idx in detail.data) {
                  const pro = detail.data[idx];
                  var imglist = []
              const img = await axios.get(
                `http://localhost:2021/api/dadabackend/product/image/getById/${pro.pro_id}`,{
                  headers:header
                }
              );
              imglist = img.data;
          var products = {
            order_id: element.order_id,
            idx: parseInt(idx)+1,
            pro_id: pro.pro_id,
            pro_name: pro.pro_name,
            category: pro.category,
            unit: pro.unit,
            color: pro.color,
            size: pro.size,
            qty: pro.qty,
            price: pro.price,
            discount: pro.discount,
            image: imglist
          };
          detailList.push(products)
              }

          var orders = {
            idx: parseInt(key, 10)+1,
            order_id: element.order_id,
            emp_id: element.emp_id,
            emp_name: element.emp_name+" "+element.emp_surname,
            cus_id: element.cus_id,
            full_name: element.full_name,
            customer_tel:element.tel,
            customer_email:element.email,
            order_date: moment(String(element.order_date)).format('DD-MM-YYYY'),
            order_time: element.order_time,
            status: element.status,
            pay_method: element.pay_method,
            com_id:element.com_id,
            customer_content: element.customer_content,
            receive_content: element.receive_content,
            card_image: element.card_image,
          discount:detailList[0].discount,
            orderDetail: detailList
          };
          orderList.push(orders);
        }

        context.commit('setOrder', orderList)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getNewOrder(context) {
      try {
        var orderList = [],detailList=[]
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/buy/getnew`,{
            headers:header
          }
        );
        for (const key in response.data) {
          const element = response.data[key];
              const detail = await axios.get(
                `http://localhost:2021/api/dadabackend/order/buy/detail/getById/${element.order_id}`,{
                  headers:header
                }
              );
              detailList=[]
              for (const idx in detail.data) {
                  const pro = detail.data[idx];
                  var imglist = []
              const img = await axios.get(
                `http://localhost:2021/api/dadabackend/product/image/getById/${pro.pro_id}`,{
                  headers:header
                }
              );
              imglist = img.data;
          var products = {
            order_id: element.order_id,
            idx: parseInt(idx)+1,
            pro_id: pro.pro_id,
            pro_name: pro.pro_name,
            category: pro.category,
            unit: pro.unit,
            color: pro.color,
            size: pro.size,
            qty: pro.qty,
            price: pro.price,
            discount: pro.discount,
            image: imglist
          };
          detailList.push(products)
              }

          var orders = {
            idx: parseInt(key, 10)+1,
            order_id: element.order_id,
            emp_id: null,
            cus_id: element.cus_id,
            full_name: element.full_name,
            customer_tel:element.tel,
            customer_email:element.email,
            order_date: moment(String(element.order_date)).format('YYYY-MM-DD'),
            order_time: element.order_time,
            status: element.status,
            pay_method: element.pay_method,
            com_id:element.com_id,
            customer_content: element.customer_content,
            receive_content: element.receive_content,
            card_image: element.card_image,
          discount:detailList[0].discount,
            orderDetail: detailList
          };
          orderList.push(orders);
        }

        context.commit('setNewOrder', orderList)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getConfirmOrder(context) {
      try {
        var orderList = [],detailList=[]
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/buy/getconfirm`,{
            headers:header
          }
        );
        for (const key in response.data) {
          const element = response.data[key];
              const detail = await axios.get(
                `http://localhost:2021/api/dadabackend/order/buy/detail/getById/${element.order_id}`,{
                  headers:header
                }
              );
              detailList=[]
              for (const idx in detail.data) {
                  const pro = detail.data[idx];
                  var imglist = []
              const img = await axios.get(
                `http://localhost:2021/api/dadabackend/product/image/getById/${pro.pro_id}`,{
                  headers:header
                }
              );
              imglist = img.data;
          var products = {
            order_id: element.order_id,
            idx: parseInt(idx)+1,
            pro_id: pro.pro_id,
            pro_name: pro.pro_name,
            category: pro.category,
            unit: pro.unit,
            color: pro.color,
            size: pro.size,
            qty: pro.qty,
            price: pro.price,
            discount: pro.discount,
            image: imglist
          };
          detailList.push(products)
              }

          var orders = {
            idx: parseInt(key, 10)+1,
            order_id: element.order_id,
            emp_id: null,
            cus_id: element.cus_id,
            full_name: element.full_name,
            customer_tel:element.tel,
            customer_email:element.email,
            order_date: moment(String(element.order_date)).format('YYYY-MM-DD'),
            order_time: element.order_time,
            status: element.status,
            pay_method: element.pay_method,
            com_id:element.com_id,
            customer_content: element.customer_content,
            receive_content: element.receive_content,
            card_image: element.card_image,
          discount:detailList[0].discount,
            orderDetail: detailList
          };
          orderList.push(orders);
        }

        context.commit('setConfirmOrder', orderList)
      } catch (e) {
        this.errors.push(e);
      }
    },
     SubmitOrder(context,order) {
      try {
        axios.post(
          `http://localhost:2021/api/dadabackend/order/buy/update`,order,{
            headers:header
          }
        ).then(up => {
          if (up.data.length === 0) {
            alert("No response data of order update ? Try again");
            return;
          }
          axios.post(
            `http://localhost:2021/api/dadabackend/order/buy/detail/delete`,order,{
              headers:header
            }
          ).then(del => {
            if (del.data.length === 0) {
              alert("No response data of order detail delete ? Try again");
              return;
            }
            axios.post(
              `http://localhost:2021/api/dadabackend/order/buy/detail/insert`,order,{
                headers:header
              }
            ).then(ins => {
              if (ins.data.length === 0) {
                alert("No response data of order detail insert ? Try again");
                return;
              }
              context.dispatch("getOrder")
              context.dispatch("getNewOrder")
          })
            })
            })
      } catch (e) {
        this.errors.push(e);
      }
    },
   async RecieveOrder(context,order) {
      try {
       await axios.post(
          `http://localhost:2021/api/dadabackend/order/buy/update-status`,order,{
            headers:header
          })
              context.dispatch("getOrder")
              context.dispatch("getNewOrder")
      } catch (e) {
        this.errors.push(e);
      }
    },
  },
  getters: {
    orders: state => state.orders,
    new_orders: state => state.new_orders,
    confirm_orders: state => state.confirm_orders,
    
  }
}

export default orderModule;

