var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.OrderCutHistory.length > 0)?_c('v-card',{staticClass:"mt-5 mb-5 ml-5 mr-5"},[_c('v-row',{staticClass:"mt-3 mb-3",attrs:{"justify":"center"}},[_c('h1',{attrs:{"id":"myfont"}},[_vm._v("ປະຫວັດການສັ່ງຕັດສິນຄ້າ")])]),_c('v-data-table',{staticClass:"elevation-3",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.OrderCutHistory,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"OrderID","show-expand":"","id":"head"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("ປະຫວັດການສັ່ງຕັດ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"id":"textfield","dense":"","outlined":"","append-icon":"mdi-magnify","label":"ຄົ້ນຫາ","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.OrderID != '' || item.OrderID != null)?_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.detailHeader,"items":item.OrderCut_List,"hide-default-footer":"","id":"bgColor"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.No))]),_c('td',[_c('v-card',{staticClass:"mx-auto",attrs:{"height":"100","width":"100","aspect-ratio":"1.7","img":_vm.fectImg(item.image)}})],1),_c('td',[_vm._v(_vm._s(item.pro_name))]),_c('td',[_vm._v(_vm._s(item.color))]),_c('td',[_vm._v(_vm._s(item.size))]),_c('td',[_vm._v(_vm._s(item.qty + " " + item.unit))]),_c('td',[_vm._v(" "+_vm._s(String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 "))+" ")]),_c('td',[_vm._v(_vm._s(item.detail))])])]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-row',{staticClass:"ma-5",attrs:{"align":"center","justify":"end"}},[_c('p',[_vm._v(" ລວມເປັນ: "+_vm._s(String(item.todalMoney).replace(/(.)(?=(\d{3})+$)/g, "$1 "))+" ກິບ ")])])]},proxy:true}],null,true)}):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"large":"","color":"error"},on:{"click":function($event){return _vm.PrintBill(item)}}},[_vm._v(" mdi-download ")])]}}],null,false,1668395835)})],1):_c('v-card',{staticClass:"elevation-0",attrs:{"height":"500"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('h1',{staticClass:"mt-16",attrs:{"id":"myfont"}},[_vm._v("ບໍ່ມີຂໍ້ມູນ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }