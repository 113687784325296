<template>
  <v-card class="elevation-1"
    v-if="
      $store.state.authMod.token.status == 'Admin' ||
      $store.state.authMod.token.status == 'admin'
    "
  >
  <v-row justify="center">
     <h2 class="mt-5 mb-10">ຈັດການຂໍ້ມູນຮ້ານ</h2>
   </v-row>
   <v-divider></v-divider>
    <v-data-table
      large
      :headers="headers"
      :items="shop"
      :search="search"
      fixed-header
    >
      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-icon class="mr-2" color="error" @click="Edit_Shop(item)" large>
            mdi-pencil
          </v-icon>
        </v-row>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "ຊື່ຮ້ານ",
          value: "shop_name",
          align: "start",
          sortable: false,
        },
        { text: "ເບີໂທລະສັບ", value: "tel" },
        { text: "ເບີວອດແອັບ", value: "whatsapp" },
        { text: "ອີແມວ", value: "email" },
        { text: "ເລກບັນຊີທະນາຄານການຄ້າ", value: "bank_account_no" },
        { text: "ຊື່ບັນຊີ", value: "bank_account_name" },
        { text: "PayPal ອີແມວ", value: "paypal_email" },
        { text: "ຄ່າສະມັກຕົວແທນ", value: "register_pay" },
        { text: "ສ່ວນຫຼຸດ", value: "discount" },
        { text: "ແກ້ໄຂ", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      shop: "shopMod/shop_info",
    }),
  },
    mounted() {
    this.shoploading();

  },
  methods: {
    ...mapActions("shopMod", ["getShop_Info"]),

    shoploading() {
      this.getShop_Info();
    },
    // DeleteEmployee(item) {
    //   this.dialogDelete = true;
    //   this.deleteItem = item;
    // },
    // async deleteEmployeeConfirm() {
    //   this.delEmployee(this.deleteItem);
    //   this.closeDelete();
    // },
    // closeDelete() {
    //   this.dialogDelete = false;
    // },
    Edit_Shop(item) {
      this.$router.push(`/shop_edit/${item.shop_id}`);
    },
    // fectImg(img) {
    //   var imageUrl;
    //   if (img) {
    //     imageUrl = require("@/assets/images/Employees/" + img);
    //   } else {
    //     imageUrl = require("@/assets/logo.png");
    //   }
    //   return imageUrl;
    // },
  },
};
</script>