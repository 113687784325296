
      <template>
  <v-card
    v-if="($store.state.authMod.token.status=='Admin') || ($store.state.authMod.token.status=='admin')"
  >
    <v-data-table
      :headers="headers"
      :items="suplier"
      :search="search"
      item-key="idx"
      sort-by="name"
      class="elevation-2 table"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ຈັດການຂໍ້ມູນຜູ້ສະໜອງ</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <router-link to="supplier_add">
            <v-btn class="mb-2" large icon>
              <v-icon color="green" large>mdi-plus</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <v-divider></v-divider>
      </template>
      <template v-slot:item="{ item }">
        <tr v-ripple="{ class: `white--text` }">
          <td>{{item.idx}}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.tel }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.address }}</td>
          <td>
            <v-row>
              <v-icon @click="EditSuplier(item)" class="mr-2" color="orange">mdi-pencil</v-icon>
              <v-icon color="red" @click="DeleteSuplier(item)">mdi-delete</v-icon>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card rounded>
        <v-card-title class="headline justify-center">Delete suplier</v-card-title>
        <v-divider class></v-divider>
        <v-card-text>Are you sure you want to delete this item?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="green accent-3" text @click="deleteSuplierConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    search: "",
    dialogDelete: false,
    deleteItem: [],
    headers: [
      { text: "ລໍາດັບ", value: "idx" },
      { text: "ຊື່ຜູ້ສະໜອງ", value: "name" },
      { text: "ເບີ້ໂທ", value: "tel" },
      { text: "ອີເມວ", value: "email" },
      { text: "ທີ່ຢູ່", value: "address" },
      { text: "Actions", value: "actions", sortable: false }
    ]
  }),

  computed: {
    ...mapGetters({
      suplier: "supplierMod/suplier"
    })
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  mounted() {
    this.getSuplier();
  },
  methods: {
    ...mapActions("supplierMod", ["getSuplier", "delSuplier"]),
    EditSuplier(item) {
      this.$router.push(`supplier_edite/${item.sup_id}`);
    },

    DeleteSuplier(item) {
      this.deleteItem = item;
      this.dialogDelete = true;
    },

    deleteSuplierConfirm() {
      this.delSuplier(this.deleteItem);
      this.dialogDelete = false;
    }
  }
};
</script>
<style scoped>
.table {
  border-radius: 3px;
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
}
</style>