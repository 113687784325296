import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import { header } from '../../config/constant'

Vue.use(Vuex)

const cutModule = {
  namespaced: true,
  state: {
    cutItems: [],
    cuttingItems: [],
    new_cutItems: []
  },
  mutations: {
    setCutted(state, order) {
      state.cutItems = order
    },
    setCutting(state, order) {
      state.cuttingItems = order
      // alert(JSON.stringify(order))
    },
    setNewCut(state, order) {
      state.new_cutItems = order
      // alert(JSON.stringify(order))
    },
  },

  actions: {
    async getCutted(context) {
      try {
        var orderList = [], detailList = []
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/cut/getall`, {
          headers: header
        }
        );
        for (const key in response.data) {
          detailList = []
          const element = response.data[key];
          const detail = await axios.get(
            `http://localhost:2021/api/dadabackend/order/cut/detail/getById/${element.cut_id}`, {
            headers: header
          }
          );
          detailList = []
          for (const idx in detail.data) {
            const pro = detail.data[idx];
            var products = {
              cut_id: element.cut_id,
              idx: parseInt(idx) + 1,
              pro_name: pro.pro_name,
              category: pro.category,
              unit: pro.unit,
              color: pro.color,
              size: pro.size,
              qty: pro.qty,
              price: pro.price,
              discount: pro.discount,
              detail: pro.detail,
              image: pro.image
            };
            detailList.push(products)
          }
          var discount = 0
          if (detailList.length > 0) discount = detailList[0].discount
          var orders = {
            idx: parseInt(key, 10) + 1,
            cut_id: element.cut_id,
            emp_id: element.emp_id,
            emp_name: element.emp_name + " " + element.emp_surname,
            cus_id: element.cus_id,
            full_name: element.full_name,
            customer_tel: element.tel,
            customer_email: element.email,
            order_cut_date: moment(String(element.order_cut_date)).format('YYYY-MM-DD'),
            order_cut_time: element.order_cut_time,
            day: element.day,
            money_pay: element.money_pay,
            status: element.status,
            pay_method: element.pay_method,
            com_id: element.com_id,
            customer_content: element.customer_content,
            receive_content: element.receive_content,
            card_image: element.card_image,
            discount: discount,
            orderDetail: detailList
          };
          orderList.push(orders);
        }

        context.commit('setCutted', orderList)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getCutting(context) {
      try {
        var orderList = [], detailList = []
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/cut/new/getcutting`, {
          headers: header
        }
        );
        for (const key in response.data) {
          const element = response.data[key];
          const detail = await axios.get(
            `http://localhost:2021/api/dadabackend/order/cut/detail/getById/${element.cut_id}`, {
            headers: header
          }
          );
        // alert(JSON.stringify(detail.data))
          detailList = []
          for (const idx in detail.data) {
            const pro = detail.data[idx];
            var products = {
              idx: parseInt(idx) + 1,
              cut_id: element.cut_id,
              pro_name: pro.pro_name,
              category: pro.category,
              unit: pro.unit,
              color: pro.color,
              size: pro.size,
              qty: pro.qty,
              price: pro.price,
              discount: pro.discount,
              detail: pro.detail,
              image: pro.image
            };
            detailList.push(products)
          }
          var orders = {
            idx: parseInt(key, 10) + 1,
            cut_id: element.cut_id,
            cus_id: element.cus_id,
            full_name: element.full_name,
            customer_tel: element.tel,
            customer_email: element.email,
            order_cut_date: moment(String(element.order_cut_date)).format('YYYY-MM-DD'),
            order_cut_time: element.order_cut_time,
            day: element.day,
            money_pay: element.money_pay,
            status: element.status,
            pay_method: element.pay_method,
            com_id: element.com_id,
            customer_content: element.customer_content,
            receive_content: element.receive_content,
            card_image: element.card_image,
            discount: detailList[0].discount,
            orderDetail: detailList
          }
          orderList.push(orders);
        }
        context.commit('setCutting', orderList)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getNewCut(context) {
      try {
        var orderList = [], detailList = []
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/cut/new/getall`, {
          headers: header
        }
        );
        for (const key in response.data) {
          detailList = []
          const element = response.data[key];
          const detail = await axios.get(
            `http://localhost:2021/api/dadabackend/order/cut/detail/getById/${element.cut_id}`, {
            headers: header
          }
          );
          detailList = []
          for (const idx in detail.data) {
            const pro = detail.data[idx];
            var products = {
              cut_id: element.cut_id,
              idx: parseInt(idx) + 1,
              pro_name: pro.pro_name,
              category: pro.category,
              unit: pro.unit,
              color: pro.color,
              size: pro.size,
              qty: pro.qty,
              price: pro.price,
              discount: pro.discount,
              detail: pro.detail,
              image: pro.image
            };
            detailList.push(products)
          }


          var orders = {
            idx: parseInt(key, 10) + 1,
            cut_id: element.cut_id,
            cus_id: element.cus_id,
            full_name: element.full_name,
            customer_tel: element.tel,
            customer_email: element.email,
            order_cut_date: moment(String(element.order_cut_date)).format('YYYY-MM-DD'),
            order_cut_time: element.order_cut_time,
            day: element.day,
            money_pay: element.money_pay,
            status: element.status,
            pay_method: element.pay_method,
            com_id: element.com_id,
            customer_content: element.customer_content,
            receive_content: element.receive_content,
            card_image: element.card_image,
            discount: detailList[0].discount,
            orderDetail: detailList
          };
          orderList.push(orders);
        }
        context.commit('setNewCut', orderList)
      } catch (e) {
        this.errors.push(e);
      }
    },
    InsertCutting(context, order) {
      try {
        axios.post(
          `http://localhost:2021/api/dadabackend/order/cut/insert`, order, {
          headers: header
        }
        ).then(ins => {
          if (ins.data.length === 0) {
            alert("No response data of order detail insert ? Try again");
            return;
          }
          axios.post(
            `http://localhost:2021/api/dadabackend/order/cut/detail/insert`, order, {
            headers: header
          }).then(ins => {
            if (ins.data.length === 0) {
              alert("No response data of order detail insert ? Try again");
              return;
            }
            context.dispatch("getCutting")
            context.dispatch("getNewCut")
            context.dispatch("getCutted")
          })
        })
      } catch (e) {
        this.errors.push(e);
      }
    },
    UpdateCuting(context, order) {
      try {
        axios.post(
          `http://localhost:2021/api/dadabackend/order/cut/update`, order, {
          headers: header
        }
        ).then(up => {
          if (up.data.length === 0) {
            alert("No response data of order update ? Try again");
            return;
          }
          axios.post(
            `http://localhost:2021/api/dadabackend/order/cut/detail/delete`, order, {
            headers: header
          }
          ).then(del => {
            if (del.data.length === 0) {
              alert("No response data of order detail delete ? Try again");
              return;
            }
            axios.post(
              `http://localhost:2021/api/dadabackend/order/cut/detail/insert`, order, {
              headers: header
            }
            ).then(ins => {
              if (ins.data.length === 0) {
                alert("No response data of order detail insert ? Try again");
                return;
              }
              context.dispatch("getCutting")
              context.dispatch("getNewCut")
              context.dispatch("getCutted")
            })
          })
        })
      } catch (e) {
        this.errors.push(e);
      }
    },
    SubmitCut(context, order) {
      try {
        axios.post(
          `http://localhost:2021/api/dadabackend/order/buy/update`, order, {
          headers: header
        }
        ).then(up => {
          if (up.data.length === 0) {
            alert("No response data of order update ? Try again");
            return;
          }
          axios.post(
            `http://localhost:2021/api/dadabackend/order/buy/detail/delete`, order, {
            headers: header
          }
          ).then(del => {
            if (del.data.length === 0) {
              alert("No response data of order detail delete ? Try again");
              return;
            }
            axios.post(
              `http://localhost:2021/api/dadabackend/order/buy/detail/insert`, order, {
              headers: header
            }
            ).then(ins => {
              if (ins.data.length === 0) {
                alert("No response data of order detail insert ? Try again");
                return;
              }
              context.dispatch("getCut")
              context.dispatch("getNewCut")
            })
          })
        })
      } catch (e) {
        this.errors.push(e);
      }
    },
  },
  getters: {
    cutItems: state => state.cutItems,
    cuttingItems: state => state.cuttingItems,
    new_cutItems: state => state.new_cutItems,
  }
}

export default cutModule;