<template>
  <v-app class="font">
    <v-card class="ma-2 elevation-0">
      <v-row>
        <div class="d-flex mt-9">
          <v-avatar size="100" color="#fafafafa" class="pa-2">
            <v-img src="@/assets/images/DaDa_LoGo.png" />
          </v-avatar>
        </div>
        <v-col cols="9">
          <v-row no-gutters class="justify-center mt-5">
            <p style="font-size: 1.2rem">ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</p>
          </v-row>
          <v-row no-gutters class="justify-center">
            <p style="font-size: 1.2rem">
              ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດຖະນາຖາວອນ
            </p>
          </v-row>
          <v-row no-gutters class="justify-center mt-3">
            <p style="font-size: 2rem; text-decoration: bold">ລາຍງານສິນຄ້າທີ່ນິຍົມສັ່ງຕັດ</p>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class>
        <v-col cols="6">
          <p style="font-size: 1.4rem">ຮ້ານ ດາດ້າເເຟຊັນ</p>
          <p>ໂທລະສັບ: 020 92602326</p>

          <p>ອີເມວ: houyang0087@gmail.com</p>
          <p>ຕັ້ງຢູ່: ບ້ານ ຫົວຂົວ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ</p>
        </v-col>
        <v-col cols="6" class="align-end align-content-end">
          <v-row class="justify-end">
            <v-card class="mr-5 elevation-0">
              <p>ວັນທີ່: {{ new Date().toLocaleDateString() }}</p>
              <p>ເວລາ: {{new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds()}}</p>
            </v-card>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters class>
        <v-progress-linear value="100" height="1"></v-progress-linear>
      </v-row>
      <v-row no-gutters class>
        <v-card width="100%" class="mt-2 elevation-0" height="100%">
          <v-data-table
            :headers="headers"
            :items="products"
            :search="search"
            item-key="id"
            sort-by="idx"
            class="elevation-0"
            items-per-page="100000"
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr v-ripple="{ class: `white--text` }">
                <td>{{ item.idx }}</td>
                <td>
                      <v-card height="100%" width="100">
                        <v-img :src="fectImg(item.image)"></v-img>
                      </v-card>
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.qty }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-divider class></v-divider>
        </v-card>
      </v-row>
      <v-progress-linear value="100" height="1"></v-progress-linear>
    </v-card>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "ລໍາດັບ", value: "idx" },
        {
          text: "ຮູບ",
          sortable: false,
          value: "image",
        },
        { text: "ລານການສິນຄ້າ", value: "name" },
        { text: "ຈໍານວນ", value: "qty" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      products: "reportpdfMod/pro_best_ordercut",
    }),
  },
  created() {
    this.getProductBest_OrderCut();
  },
  methods: {
    ...mapActions("reportpdfMod", ["getProductBest_OrderCut"]),
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
  },
};
</script>
<style lang="css">
.font {
  font-family: "NotoSansLao";
}
p {
  font-size: 1rem;
}
</style>