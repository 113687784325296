import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)
import router from '@/router';
const authModule = {
  namespaced: true,
  state: { 
    token: window.localStorage.getItem('token')?JSON.parse(window.localStorage.getItem('token')):[],
    // token:[],
    profile: { },
    responseLogin: {}
  },
  mutations: { 
    setToken (state, tken) {
      state.token = tken
    },
    setProfile (state, profile) {
      state.profile = profile
    },
    setResponseLogin(state, response){
      state.responseLogin = response
    }
  },
  actions: { 
    getToken (context, tken) {
      context.commit('setToken', tken)
    },
    doResponseLogin(context){
      context.commit('setResponseLogin', {});
    },
   async doLogin(context, user) {
      try{
      var res = await axios.post(`http://localhost:2021/api/dadabackend/employee/lognin`,user
      )
      if (res.data.data.token.emp_id!='' || res.data.data.token.emp_id!=undefined || res.data.data.token.emp_id!=null){
      context.commit('setToken', res.data.data.token)
      router.push(`/dashboard`)
      window.localStorage.setItem('token',JSON.stringify(res.data.data.token))
      }else{
        alert('Not found One')
      }
    }catch(err ) {
      console.log(err)
          window.localStorage.clear();
          context.commit('getToken',null);
          context.commit('setResponseLogin', {success: false, code: 500, message: 'Internet connection failed'});
      }
    },
   
  },
  getters: {
    token: state => state.token,
  }
};
export default authModule;
