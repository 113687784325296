<template >
  <div id="chart">
    <v-row no-gutters class="justify-center">
      <h1>ລາຍງານຂໍ້ມູນສິນຄ້າທີ່ມີໃນຮ້ານ</h1>
    </v-row>
    <v-row class="mx-0 mt-3 py-3 justify-center">
      <v-tabs v-model="page" fixed-tabs slider-color="#00E676" large color="#00C853">
        <v-tab  v-for="item in items" :key="item" large><span>{{ item }}</span></v-tab>
      </v-tabs>
    </v-row>

    <v-tabs-items v-model="page">

        <v-tab-item>
        <v-card flat>
      <v-row no-gutters class="ml-7 mt-7">
        <h3>ສິນຄ້າທັງໝົດ: {{proReport.series[0].data.length}} ລາຍການ</h3>
        <v-spacer></v-spacer>
        <v-btn class color="primary" @click="PrintProductStock">
          <v-icon dark left>mdi-printer</v-icon>print
        </v-btn>
      </v-row>
      <apexchart type="bar" :height="100*(proReport.series[0].data.length)" :options="proReport.chartOptions" :series="proReport.series"></apexchart>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
      <v-row no-gutters class="ml-7 mt-7">
        <h3>ສິນຄ້າທີ່ໄດ້ຂາຍດີ: {{sellproducts.series[0].data.length}} ລາຍການ</h3>
        <v-spacer></v-spacer>
        <v-btn class color="primary" @click="PrintProductBestSell">
          <v-icon dark left>mdi-printer</v-icon>print
        </v-btn>
      </v-row>
      <apexchart type="bar" height="500" :options="sellproducts.chartOptions" :series="sellproducts.series"></apexchart>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
      <v-row no-gutters class="ml-7 mt-7">
        <h3>ສິນຄ້ານິຍົມສັ່ງຊື້: {{orderproducts.series[0].data.length}} ລາຍການ</h3>
        <v-spacer></v-spacer>
        <v-btn class color="primary" @click="PrintProductBest_OrderBuy">
          <v-icon dark left>mdi-printer</v-icon>print
        </v-btn>
      </v-row>
                <apexchart type="bar" height="500" :options="orderproducts.chartOptions" :series="orderproducts.series"></apexchart>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
      <v-row no-gutters class="ml-7 mt-7">
        <h3>ສິນຄ້ານິຍົມສັ່ງຕັດ: {{cutproducts.series[0].data.length}} ຢ່າງ</h3>
        <v-spacer></v-spacer>
        <v-btn class color="primary" @click="PrintProductBest_OrderCut">
          <v-icon dark left>mdi-printer</v-icon>print
        </v-btn>
      </v-row>
      <apexchart type="bar" height="500" :options="cutproducts.chartOptions" :series="cutproducts.series"></apexchart>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      page: "ສິນຄ້າໃນສະຕ໊ອກ",
      items: [
        "ສິນຄ້າໃນສະຕ໊ອກ",
        "ສິນຄ້າທີ່ໄດ້ຂາຍດີ",
        "ສິນຄ້ານິຍົມສັ່ງຊື້",
        "ສິນຄ້ານິຍົມສັ່ງຕັດ"
      ]
    };
  },
  computed: {
    ...mapGetters({
      proReport: "reportMod/proReport",
      sellproducts: "reportMod/sellproducts",
      orderproducts:"reportMod/orderproducts",
      cutproducts:"reportMod/cutproducts",
    })
  },
  mounted() {
    this.getReportPro();
    this.getTopSalePro()
    this.getTopOrderPro()
    this.getTopCutPro()
  },
  methods: {
    ...mapActions("reportMod", ["getReportPro","getTopSalePro","getTopOrderPro","getTopCutPro"]),
    PrintProductStock(){
        let routeData = this.$router.resolve({name: 'bills_product_stock', query: {data: "ລາຍງານຂໍ້ມູນສິນຄ້າໃນສະຕ໊ອກ"}});
        window.open(routeData.href, '_blank');
    },
 PrintProductBestSell(){
        let routeData = this.$router.resolve({name: 'bills_product_bestsell', query: {data: "ລາຍງານຂໍ້ມູນສິນຄ້າທີ່ໄດ້ຂາຍດີ 10 ອັນດັບ"}});
        window.open(routeData.href, '_blank');
    },
    PrintProductBest_OrderBuy(){
        let routeData = this.$router.resolve({name: 'bills_product_bestorderbuy', query: {data: "ລາຍງານຂໍ້ມູນສິນຄ້າທີ່ນິຍົມສັ່ງຊື້ 10 ອັນດັບ"}});
        window.open(routeData.href, '_blank');
    },
      PrintProductBest_OrderCut(){
        let routeData = this.$router.resolve({name: 'bills_product_bestordercut', query: {data: "ລາຍງານຂໍ້ມູນສິນຄ້າທີ່ນິຍົມສັ່ງຕັດ 10 ອັນດັບ"}});
        window.open(routeData.href, '_blank');
    },
  }
};
</script>
<style scoped>
span{
  font-size: 1.5rem;
}
</style>