<template>
  <v-card flat>
    <v-toolbar>
      <v-row justify="start" class="ml-4">
        <h1>ຟອມຂາຍສິນຄ້າ</h1>
        <v-spacer></v-spacer>
        <v-btn class="mr-6 mt-2" rounded style="font-size:24px" text color="#304FFE" @click="GotoSale_Page">
          ກັບສູ່ໜ້າເລຶອກສິນຄ້າ
          <v-icon large color="#304FFE" right
            >mdi-arrow-right-bold-hexagon-outline</v-icon
          >
        </v-btn>
      </v-row>
      <template v-slot:extension>
        <v-tabs
          v-model="tabs"
          fixed-tabs
          slider-color="#880E4F"
          large
          color="#880E4F"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="i in 2" :key="i" :value="'mobile-tabs-5-' + i">
            <v-col>
              <div v-if="i == 1">
                <h2>ລາຍການສິນຄ້າສັ່ງຕັດ</h2>
              </div>
              <div v-if="i == 2">
                <h2>ລາຍການສິນຄ້າສັ່ງຊື້</h2>
              </div>
            </v-col>
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-card flat>
          <v-data-table
            :search="newsearch"
            :headers="newHeaders"
            :items="cuttingItems"
            :single-expand="true"
            :expanded.sync="newexpanded"
            item-key="cut_id"
            show-expand
            class="elevation-3"
          >
            <template v-slot:item.actions="{ item }">
                 <v-btn color="#004D40" outlined style="font-size:20px;font-weight:bold" @click="SaveOrderCut(item)">
              <v-icon large color="#C51162"
                >mdi-cart-arrow-right</v-icon
              >
                    ຂາຍ</v-btn>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="newdetailHeader"
                  :items="item.orderDetail"
                  hide-default-footer
                  class="elevation-2"
                  style="background: #fafafaff"
                >
                  <template v-slot:item="{ item }">
                    <tr v-ripple="{ class: `white--text` }">
                      <td>{{ item.idx }}</td>
                      <td>
                        <v-card height="100" width="70">
                          <v-img :src="fectImg(item.image)"></v-img>
                        </v-card>
                      </td>
                      <td>{{ item.pro_name }} </td>
                      <td>{{ item.color }}</td>
                      <td>{{ item.size }}</td>
                      <td>
                        {{
                          String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                        {{ item.unit }}
                      </td>
                      <td>
                        {{
                          String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                      </td>
                      <td>
                        {{
                          String(item.qty * item.price).replace(
                            /(.)(?=(\d{3})+$)/g,
                            "$1 "
                          )
                        }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-data-table
            :search="newsearch"
            :headers="newOrderbuyHeaders"
            :items="confirm_orders"
            :single-expand="true"
            :expanded.sync="newexpanded"
            item-key="idx"
            show-expand
            class="elevation-3"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn color="#004D40" outlined style="font-size:20px;font-weight:bold" @click="SaveOrderBuy(item)">
              <v-icon large color="#C51162" left>
                mdi-cart-arrow-right
              </v-icon>
              ຂາຍ</v-btn>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="newOrderbuydetailHeader"
                  :items="item.orderDetail"
                  hide-default-footer
                  class="elevation-2"
                  style="background: #fafafaff"
                >
                  <template v-slot:item="{ item }">
                    <tr v-ripple="{ class: `white--text` }">
                      <td>{{ item.idx }}</td>
                      <td>
                        <v-carousel
                          v-if="item"
                          show-arrows-on-hover
                          hide-delimiters
                          height="100"
                          width="100"
                        >
                          <v-carousel-item
                            v-for="(img, i) in item.image"
                            :key="i"
                          >
                            <v-card class="mx-auto" height="100%" width="100">
                              <v-img :src="fectImg(img.img_url)"></v-img>
                            </v-card>
                          </v-carousel-item>
                        </v-carousel>
                        <v-card v-else height="100" width="70">
                          <v-img
                            src="https://pbs.twimg.com/profile_images/1150780350932684800/LIJ11DWR.jpg"
                          ></v-img>
                        </v-card>
                      </td>
                      <td>{{ item.pro_name }}</td>
                      <td>{{ item.category }}</td>
                      <td>{{ item.color }}</td>
                      <td>{{ item.size }}</td>
                      <td>
                        {{
                          String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                        {{ item.unit }}
                      </td>
                      <td>
                        {{
                          String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                      </td>
                      <td>
                        {{
                          String(item.qty * item.price).replace(
                            /(.)(?=(\d{3})+$)/g,
                            "$1 "
                          )
                        }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      tabs: null,

      newHeaders: [
        { text: "ລໍາດັບ", value: "idx" },
        { text: "ຜູ້ສັ່ງຕັດ", value: "full_name" },
        { text: "ເບີ້ໂທ", value: "customer_tel" },
        { text: "ອີມິວ", value: "customer_email" },
        { text: "ວັນທີ່ສັ່ງຕັດ", value: "order_cut_date" },
        { text: "ຈໍານວນມື້", value: "day" },
        { text: "ເມັ້ນຂອງລູກຄ້າ", value: "customer_content" },
        { text: "ຈ່າຍຜ່ານ", value: "pay_method" },
        { text: "ສ່ວນຫຼຸດ", value: "discount" },
        { text: "ສະຖານະ", value: "status" },
        { text: "ຮັບສັ່ງຕັດ", value: "actions" },
        { text: "", value: "data-table-expand" },
      ],
      newdetailHeader: [
        { text: "ລໍາດັບ", value: "idx" },
        {
          text: "ຮູບ",
          sortable: false,
          value: "image",
        },
        { text: "ຊື່ສິນຄ້າ", value: "pro_name" },
        { text: "ສີ", value: "color" },
        { text: "ຂະໜາດ", value: "size" },
        { text: "ຈໍານວນ", value: "qty" },
        { text: "ລາຄາ(ກິບ)", value: "price" },
        { text: "ລາຄາລວມ(ກິບ)", value: "price*qty" },
      ],

      newOrderbuyHeaders: [
        { text: "ລໍາດັບ", value: "idx" },
        { text: "ຜູ້ສັ່ງຊື້", value: "full_name" },
        { text: "ເບີ້ໂທ", value: "customer_tel" },
        { text: "ອີມິວ", value: "customer_email" },
        { text: "ວັນທີ່ສັ່ງຊື້", value: "order_date" },
        { text: "ເວລາສັ່ງຊື້", value: "order_time" },
        { text: "ຈ່າຍຜ່ານ", value: "pay_method" },
        { text: "ເມັ້ນຂອງລູກຄ້າ", value: "customer_content" },
        { text: "ສ່ວນຫຼຸດ", value: "discount" },
        { text: "ຮັບສັ່ງຊື້", value: "actions" },
        { text: "", value: "data-table-expand" },
      ],
      newOrderbuydetailHeader: [
        { text: "ລໍາດັບ", value: "idx" },
        {
          text: "ຮູບ",
          align: "center",
          sortable: false,
          value: "image",
        },
        { text: "ຊື່ສິນຄ້າ", value: "pro_name" },
        { text: "ປະເພດ", value: "category" },
        { text: "ສີ", value: "color" },
        { text: "ຂະໜາດ", value: "size" },
        { text: "ຈໍານວນ", value: "qty" },
        { text: "ລາຄາ(ກິບ)", value: "price" },
        { text: "ລາຄາລວມ(ກິບ)", value: "price*qty" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      products: "productMod/products",
      cuttingItems: "cutMod/cuttingItems",
      cate_names: "productMod/cate_names",
      unit_names: "productMod/unit_names",
    }),
    ...mapGetters({
      confirm_orders: "orderMod/confirm_orders",
      //   products: "saleMod/products",
    }),
  },
  mounted() {
    this.initialcut();
    this.initialbuy();
  },
  methods: {
    ...mapActions("productMod", [
      "InsertProduct",
      "Insert_Image",
      "getProduct",
      "getCategory",
      "getUnit",
    ]),
    ...mapActions("saleMod", ["InsertSale"]),
    ...mapActions("commentMod", ["UpdateComment"]),
    ...mapActions("cutMod", [
      "getCutted",
      "getCutting",
      "getNewCut",
      "UpdateCuting",
    ]),
    ...mapActions("orderMod", ["getOrder", "getConfirmOrder", "SubmitOrder"]),
    ...mapActions("saleMod", ["UpdateProduct"]),

    initialcut() {
      this.getCategory();
      this.getUnit();
      this.getProduct();
      this.getCutted();
      this.getCutting();
    },
    initialbuy() {
      this.getProduct();
      this.getOrder();
      this.getConfirmOrder();
    },
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/images/D (32).jpg");
      }
      return imageUrl;
    },
    GotoSale_Page(){
        this.$router.push("/sale_page")
    },
    onFileSelected(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.url = e.target.result;
      };
      this.image_url = event.target.files[0].name;
      // alert(JSON.stringify(this.url));
    },
    isNumber: function ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    async SaveOrderCut(item) {
      if (item.cus_id == "") {
        return;
      }
      if (item.orderDetail.length < 0) {
        return;
      }
      if (
        item.receive_content == "" ||
        item.receive_content ==
          "ຮ້ານດາດ້າແຟຊັ່ນຮັບຕັດສິນຄ້າຂອງທ່ານແລ້ວ...ກະລຸນາລໍຖ້າຕາມເວລາທີ່ກໍານົດ...ຂໍຂອບໃຈ" ||
        item.receive_content == undefined ||
        item.receive_content == null
      ) {
        item.receive_content =
          "ສິນຄ້າທີ່ທ່ານສັ່ງຕັດຕັດສໍາເລັດແລ້ວ...ກະລຸນາລໍຖ້າພະນັກງານຈະຕິດຕໍ່ກັບ...ຂໍຂອບໃຈ";
      }
      for (const or in item.orderDetail) {
        if (
          item.orderDetail[or].category == null ||
          item.orderDetail[or].unit == null
        ) {
          return;
        }
      }
      var prolist = item.orderDetail;
      var details = [];
      var date = new Date().toJSON().slice(0, 10).replace(/-/g, "");
      var time = new Date()
        .toTimeString()
        .replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1", "")
        .replace(":", "")
        .replace(":", "");
      for (const or in prolist) {
        const order = prolist[or];
        var category_id = await axios.post(
          `http://localhost:2021/api/dadabackend/category/getByName`,
          prolist[or],
          {
            headers: {
              "Content-Type": "application/json",
              token: JSON.parse(window.localStorage.getItem("token")),
            },
          }
        );
        prolist[or].cate_id = category_id.data[0].cate_id;
        var unit_id = await axios.post(
          `http://localhost:2021/api/dadabackend/unit/getByName`,
          prolist[or],
          {
            headers: {
              "Content-Type": "application/json",
              token: JSON.parse(window.localStorage.getItem("token")),
            },
          }
        );
        prolist[or].unit_id = unit_id.data[0].unit_id;
        prolist[or].pro_id = "";
        var pro_id = date + time + "" + or,
          qty = order.qty,
          price = order.price,
          old_pro = false;
        for (const p in this.products) {
          const pro = this.products[p];
          if (
            order.pro_name == pro.name &&
            order.category == pro.category &&
            order.unit == pro.unit &&
            order.size == pro.size &&
            order.color == pro.color
          ) {
            if (old_pro == false) {
              pro_id = pro.id;
              prolist[or].pro_id = pro.id;
            }
            old_pro = true;
          }
        }
        if (prolist[or].pro_id == "") {
          var products = {
            id: pro_id,
            name: order.pro_name,
            cate_id: order.cate_id,
            category: order.category,
            unit_id: order.unit_id,
            unit: order.unit,
            color: order.color,
            size: order.size,
            qty: 0,
            price: order.price,
            status: "Prototype",
            image: order.image,
          };
          //  alert(JSON.stringify(products))
          this.InsertProduct(products);
          // alert(JSON.stringify("ProID" + pro_id));
          // /////////////////////////////////////
          this.Insert_Image(products);
        }
        var list = {
          pro_id: pro_id,
          qty: qty,
          price: price,
          discount: item.orderDetail[0].discount 
        };
        details.push(list);
      }

      item.emp_id = this.$store.state.authMod.token.emp_id;
      item.status = "Success";
      this.UpdateCuting(item);

      var sale = {
        sell_id: "BILL" + date.slice(2, date.length) + time,
        emp_id: this.$store.state.authMod.token.emp_id,
        cus_id: item.cus_id,
        detail: details,
      };
      var data = {
        sell_id: "BILL" + date.slice(2, date.length) + time,
      };
      let routeData = this.$router.resolve({
        name: "bills_sell_product",
        query: data,
      });
      window.open(routeData.href, "_blank");

      this.InsertSale(sale);
      //    alert(JSON.stringify(item.receive_content));
      var comment = {
        id: item.com_id,
        emp_id: this.$store.state.authMod.token.emp_id,
        receive_content: item.receive_content,
      };
      this.UpdateComment(comment);
    },
    SaveOrderBuy(item) {
      var prolist = [];
      for (const or in item.orderDetail) {
        const order = item.orderDetail[or];
        for (const p in this.products) {
          const pro = this.products[p];
          if (order.pro_id == pro.id) {
            if (order.qty > pro.qty) {
              alert(
                "ສິນຄ້າລາຍການ " +
                  order.pro_name +
                  " ບໍພໍ stock: " +
                  pro.qty +
                  " < " +
                  order.qty
              );
              return;
            }
            var mypro = {
              pro_id: order.pro_id,
              qty: parseInt(pro.qty) - parseInt(order.qty),
              status: pro.status,
            };
            prolist.push(mypro);
          }
        }
      }
      item.emp_id = this.$store.state.authMod.token.emp_id;
       if (
          item.receive_content == "" ||
          item.receive_content == undefined ||
          item.receive_content == null
        ) {
          item.receive_content =
            "ຮ້ານດາດ້າແຟຊັ່ນ ຢືນຢັນຮັບການສັ່ງຊື້ສິນຄ້າຂອງທ່ານແລ້ວ...ກະລຸນາລໍຖ້າພະນັກງານຈະຕິດຕໍ່ກັບໃນໄວໄວນີ້...ຂໍຂອບໃຈ";
        }
        var comment = {
        id: item.com_id,
        emp_id: this.$store.state.authMod.token.emp_id,
        receive_content: item.receive_content,
      };
      var date = new Date().toJSON().slice(0, 10).replace(/-/g, "");
      var time = new Date()
        .toTimeString()
        .replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1", "")
        .replace(":", "")
        .replace(":", "");
      var sale = {
        sell_id: "BILL" + date.slice(2, date.length) + time,
        emp_id: this.$store.state.authMod.token.emp_id,
        cus_id: item.cus_id,
        detail: item.orderDetail,
      };
      this.SubmitOrder(item);
      this.InsertSale(sale);
      for (const key in prolist) {
        this.UpdateProduct(prolist[key]);
      }
      var data = {
        sell_id: "BILL" + date.slice(2, date.length) + time,
      };
      let routeData = this.$router.resolve({
        name: "bills_sell_product",
        query: data,
      });
      window.open(routeData.href, "_blank");

      this.UpdateComment(comment);
     // window.location.reload();
    },
  },
};
</script>

<style>
.csss {
  font-size: 20px;
  font-family: "Noto Sans Lao";
  text-align: left;
  color: black;
}
</style>
<style scoped>
.tcenter >>> input {
  text-align: center;
}
</style>


