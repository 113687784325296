<template>
  <div>
    <v-card cols="12" sm="4" class="elevation-0 mb-7" v-for="(shop_info, idx) in Shop_Info"
      :key="idx">
      <v-row justify="center" class="mt-3 mb-3">
        <h1 id="myfont">ສັ່ງຊື້ສິນຄ້າ</h1>
      </v-row>
      <v-stepper v-model="e1" class="elevation-0">
        <v-stepper-header
          style="font-family: Noto Sans Lao"
          class="elevation-0"
        >
          <v-stepper-step :complete="e1 > 1" step="1" @click="e1 = 1">
            ຂັ້ນຕອນທີ່ 1
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2" @click="e1 = 2">
            ຂັ້ນຕອນທີ່ 2
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3"> ຂັ້ນຕອນທີ່ 3 </v-stepper-step>
        </v-stepper-header>
        <v-progress-linear color="black" value="100" height="2px">
        </v-progress-linear>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-simple-table fixed-header>
              <template>
                <thead>
                  <tr id="tbheader">
                    <th class="text-left" width="10%">ລໍາດັບ</th>
                    <th class="text-left" width="12%">ຮູບ</th>
                    <th class="text-left" width="35%">ລາຍການສິນຄ້າ</th>
                    <th class="text-left" width="17%">ຈໍານວນ</th>
                    <th class="text-left" width="10%">ລາຄາ</th>
                    <th class="text-left" width="10%">ລວມ</th>
                    <th class="text-left" width="10%">ລຶບ</th>
                  </tr>
                </thead>
                <tbody style="font-family: Noto Sans Lao; font-size: 20px">
                  <tr v-for="(item, index) in ProductCart" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <v-carousel
                        hide-delimiter-background
                        height="100"
                        :show-arrows="false"
                      >
                        <v-carousel-item
                          v-for="(img, i) in item.Image"
                          :key="i"
                        >
                          <v-card
                            class="mx-auto"
                            height="100%"
                            aspect-ratio="1.7"
                            :img="fectImg(img.img_url)"
                          >
                          </v-card>
                        </v-carousel-item>
                      </v-carousel>
                    </td>
                    <td>{{ item.proName }}</td>
                    <td>
                      <v-text-field
                        width="10px"
                        class="mt-7 text-center tcenter"
                        filled
                        flat
                        dense
                        rounded
                        prepend-inner-icon="mdi-minus"
                        append-icon="mdi-plus"
                        v-model="item.quantity"
                        @keypress="isNumber($event, item)"
                        @keyup="CheckNumber(item)"
                      >
                        <template slot="append">
                          <v-btn
                            style="margin-top: -7px; margin-right: -20px"
                            color="green darken-4"
                            icon
                            @click="IncrementQty(item)"
                          >
                            <v-icon large>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <template slot="prepend-inner">
                          <v-btn
                            style="margin-top: -7px; margin-left: -20px"
                            color="green darken-4"
                            icon
                            @click="SuttrackQtyInCart(item)"
                          >
                            <v-icon large>mdi-minus</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </td>
                    <td>
                      {{
                        String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                      }}
                    </td>
                    <td>
                      {{
                        String(item.total).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                      }}
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="grey darken-4"
                        @click.prevent="DeleteCurrentItem(item.proID)"
                      >
                        <v-icon large>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-progress-linear
              color="black"
              value="100"
              height="1px"
            ></v-progress-linear>
            <v-col class="mr-7 mt-8">
              <v-row justify="end">
                <h1 id="cssstyle" class="text-right mt-4">ລວມ:</h1>
                <h1 class="text-left mt-4">
                  {{ String(TotalPay).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }}
                </h1>
                <h1 id="cssstyle" class="text-center mt-4 ml-5">ກິບ</h1>
              </v-row>
              <v-row justify="end">
                <h1 id="cssstyle" class="text-right mt-4">ສ່ວນຫຼຸດ:</h1>
                <h1 class="text-left mt-4">{{ Get_Discount_Percent }}</h1>
                <h1 id="cssstyle" class="text-center mt-4 ml-5">ເປີເຊັນ(%)</h1>
              </v-row>
              <v-row justify="end">
                <h1 id="cssstyle" class="text-right mt-4">ເງີນທີ່ຕ້ອງຈ່າຍ:</h1>
                <h1 class="text-left mt-4">
                  {{
                    String(Caculate_Discount).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1 "
                    )
                  }}
                </h1>
                <h1 id="cssstyle" class="text-center mt-4 ml-5">ກິບ</h1>
              </v-row>
            </v-col>

            <v-row>
              <v-row justify="start">
                <router-link to="Product">
                  <v-btn
                    text
                    id="btntext"
                    class="mt-16 ml-15"
                    color="grey darken-4"
                  >
                    <v-icon class="mr-2"> mdi-plus </v-icon>
                    ເລຶອກສິນຄ້າເພີ່ມ
                  </v-btn>
                </router-link>
              </v-row>
              <v-row justify="end">
                <v-btn
                  id="btntext"
                  class="mt-16 mb-16 mr-10"
                  color="error"
                  @click="ClearCart"
                >
                  ຍົກເລິກທັງໝົດ
                </v-btn>
                <v-btn
                  id="btntext"
                  class="mt-16 mb-16 mr-10"
                  color="primary"
                  @click="GoToStep2"
                >
                  ດໍາເນີນການສັ່ງຊື້
                </v-btn>
              </v-row>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-row justify="center" class="ml-9 mb-6 mt-5"
              ><h1 id="myfont">*ບັນທຶກຂໍ້ມູນລູກຄ້າ</h1></v-row
            >
            <v-radio-group v-model="CustomerStatus">
              <v-radio
                class="radio ml-10"
                label="ລູກຄ້າໃໝ່"
                value="General"
              ></v-radio>
              <v-radio
                class="radio ml-10 mt-3"
                label="ເປັນສະມາຊິກ ຫຼື ເປັນຕົວແທນແລ້ວ"
                value="Member"
                @click="OpenLogIN"
              ></v-radio>
            </v-radio-group>
            <v-divider></v-divider>
            <v-row cols="2" sm="6" class="mt-3">
              <v-col lg="6" cols="12" sm="6">
                <h1 class="ml-9" id="myfont">ຊື່ເຕັມ*</h1>
                <v-text-field
                  id="textfield"
                  class="mt-2 mr-9 ml-9"
                  outlined
                  placeholder="Enter Your Full Name"
                  v-model="txtfullname"
                  :error-messages="NameErrors"
                  :counter="100"
                  required
                  @input="$v.txtfullname.$touch()"
                  @blur="$v.txtfullname.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ເພດ*</h1>
                <v-row dense justify="space-around">
                  <v-radio-group
                    required
                    :error-messages="GenderErrors"
                    @change="$v.gender.$touch()"
                    @blur="$v.gender.$touch()"
                    v-model="gender"
                    row
                  >
                    <v-radio
                      label="ຊາຍ/Male"
                      value="ຊາຍ"
                      class="mr-15 radio"
                    ></v-radio>
                    <v-radio
                      label="ຍິງ/Female"
                      value="ຍິງ"
                      class="ml-15 radio"
                    ></v-radio>
                  </v-radio-group>
                </v-row>
                <h1 class="ml-9 mt-7" id="myfont">ເບີໂທຕິດຕໍ່*</h1>
                <v-text-field
                  id="textfield"
                  class="mt-1 mr-9 ml-9"
                  outlined
                  placeholder="Enter Your Phone Number"
                  v-model="txttel"
                  :error-messages="TelErrors"
                  :counter="12"
                  required
                  @keypress="PhoneNumber($event)"
                  @input="$v.txttel.$touch()"
                  @blur="$v.txttel.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ແຟສບຸກ*</h1>
                <v-text-field
                  id="textfield"
                  class="mt-1 mr-9 ml-9"
                  outlined
                  placeholder="Enter Your Facebook"
                  v-model="txtfacebook"
                  :error-messages="FacebookErrors"
                  :counter="100"
                  required
                  @input="$v.txtfacebook.$touch()"
                  @blur="$v.txtfacebook.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ອີແມວ*</h1>
                <v-text-field
                  id="textfield"
                  class="mt-1 mr-9 ml-9"
                  outlined
                  placeholder="Enter Your Email"
                  v-model="txtemail"
                  :error-messages="emailErrors"
                  :counter="100"
                  required
                  @input="$v.txtemail.$touch()"
                  @blur="$v.txtemail.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <h1 class="ml-9" id="myfont">ບ້ານຢູ່ປັດຈຸບັນ*</h1>
                <v-text-field
                  id="textfield"
                  class="mt-1 mr-9 ml-9"
                  outlined
                  placeholder="Enter Your Village"
                  v-model="txtvillage"
                  :error-messages="VillageErrors"
                  :counter="100"
                  required
                  @input="$v.txtvillage.$touch()"
                  @blur="$v.txtvillage.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ເມືອງ*</h1>
                <v-text-field
                  id="textfield"
                  class="mt-1 mr-9 ml-9"
                  outlined
                  placeholder="Enter Your District"
                  v-model="txtdistrict"
                  :error-messages="DistrictErrors"
                  :counter="100"
                  required
                  @input="$v.txtdistrict.$touch()"
                  @blur="$v.txtdistrict.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ແຂວງ*</h1>
                <v-text-field
                  id="textfield"
                  class="mt-1 mr-9 ml-9"
                  outlined
                  placeholder="Enter Your Province"
                  v-model="txtprovince"
                  :error-messages="ProvinceErrors"
                  :counter="100"
                  required
                  @input="$v.txtprovince.$touch()"
                  @blur="$v.txtprovince.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ປະເທດ*</h1>
                <v-select
                  id="textfield"
                   :items="Country_Name"
                  outlined
                  placeholder="Select Your Country"
                  class="mt-1 mr-9 ml-9"
                  v-model="country"
                  :error-messages="CountryErrors"
                  required
                  @change="$v.country.$touch()"
                  @blur="$v.country.$touch()"
                ></v-select>
                <h1 class="ml-9" id="myfont">ຕັ້ງລະຫັດຜ່ານ*</h1>
                <v-text-field
                  id="textfield"
                  class="mt-1 mr-9 ml-9"
                  outlined
                  placeholder="Create Password"
                  type="password"
                  v-model="txtpassword"
                  :error-messages="PasswordErrors"
                  counter
                  required
                  @input="$v.txtpassword.$touch()"
                  @blur="$v.txtpassword.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ຢືນຢັນລະຫັດຜ່ານ*</h1>
                <v-text-field
                  id="textfield"
                  class="mt-1 mr-9 ml-9"
                  outlined
                  v-model="comfirmpassword"
                  placeholder="Comfirm Password"
                  type="password"
                  :error-messages="ComfirmPasswordErrors"
                  counter
                  required
                  @input="$v.comfirmpassword.$touch()"
                  @blur="$v.comfirmpassword.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-btn
                class="mt-16 mb-16 mr-10"
                @click="e1 = 1"
                id="btntext"
                color="error"
              >
                ກັບຄືນ
              </v-btn>
              <v-btn
                class="mt-16 mb-16 mr-10"
                color="primary"
                @click="GotoStep3"
                id="btntext"
              >
                ຖັດໄປ
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-row justify="center">
              <h1 class="ml-9 mt-7 mb-7" id="myfont">*ສໍາລະເງີນ</h1>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="6" md="5">
                <h1 class="ml-7 mt-8" id="myfont">*ໂອນຜ່ານບັນຊີທະນາຄານ</h1>
                <v-img
                  class="ml-9"
                  height="120"
                  width="120"
                  src="@/assets/images/bcelone.png"
                ></v-img>
                <v-row>
                  <h1 class="ml-7 mt-7" id="myfont">ເລກບັນຊີ:</h1>
                  <h1 class="ml-7 mt-7" id="myfont"> {{ shop_info.bank_account_no }}</h1>
                </v-row>
                <v-row>
                  <h1 class="ml-7 mt-7" id="myfont">ຊື່ບັນຊີ:</h1>
                  <h1 class="ml-7 mt-7" id="myfont"> {{ shop_info.bank_account_name }}</h1>
                </v-row>
                <v-row>
                  <h1 class="ml-7 mt-7" id="myfont">ປະເພດບັນຊີ:</h1>
                  <h1 class="ml-7 mt-7 mb-7" id="myfont">Saving</h1>
                </v-row>
                <h1 class="ml-7" id="myfont">*QR Code</h1>
                <v-img
                  class="ml-9 mb-8"
                  height="300"
                  width="300"
                  src="@/assets/images/houa.png"
                ></v-img>
                    <h2
                  class="ml-5"
                  style="
                    font-size: 25px;
                    font-weight: bold;
                    font-family: NotoSansLao;
                  "
                >
                  *ຊໍາລະຜ່ານ Paypal
                </h2>
                <h1
                  class="ml-5"
                  style="
                    font-size: 45px;
                    font-weight: bold;
                    font-family: NotoSansLao;
                    color: #2962ff;
                  "
                >
                  PayPal
                </h1>
                <v-row>
                  <h1 class="ml-7 mt-7" id="myfont">ອີແມວ:</h1>
                  <h1
                    class="ml-8 mt-7"
                    style="
                      font-size: 25px;
                      font-weight: bold;
                      font-family: NotoSansLao;
                    "
                  >
                    {{ shop_info.paypal_email }}
                  </h1>
                </v-row>
                <v-col class="mt-3">
                  <a
                    class="mt-13"
                    href="https://www.paypal.com/us/signin"
                    target="brank"
                     style="
                      font-size: 20px;
                      font-weight: bold;
                      font-family: NotoSansLao;text-decoration: underline;color:black
                    "
                    >ເຂົ້າສູ່ລະບົບ PayPal</a
                  >
                </v-col>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <h1 class="mt-9 mr-7 ml-9" id="myfont">ຈໍານວນເງີນທີ່ຕ້ອງຊໍາລະ*</h1>
                <v-row>
                  <v-text-field
                    id="textfield"
                    class="mt-5 ml-12 mr-5"
                    outlined
                    style="font-size: 25px; font-weight: bold"
                    :value="
                      String(Caculate_Discount).replace(
                        /(.)(?=(\d{3})+$)/g,
                        '$1 '
                      )
                    "
                  ></v-text-field>
                  <h1 class="mt-9 mr-5" id="myfont">ກິບ</h1>
                </v-row>
                <v-divider class="ml-7 mt-15 mb-4"></v-divider>
                <h1 class="ml-9" id="myfont">ຮູບແບບການຈ່າຍເງີນ*</h1>
                <v-row dense justify="start" class="ml-8">
                  <v-radio-group
                    required
                    :error-messages="Pay_MethodError"
                    @change="$v.pay_method.$touch()"
                    @blur="$v.pay_method.$touch()"
                    v-model="pay_method"
                    row
                  >
                    <v-col>
                      <v-radio
                        label="ຈ່າຍຜ່ານ Bcel One"
                        value="ຈ່າຍຜ່ານ Bcel One"
                        class="radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍຜ່ານ Paypal"
                        value="ຈ່າຍຜ່ານ Paypal"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍຜ່ານ Western Union"
                        value="ຈ່າຍຜ່ານ Western Union"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍຜ່ານ MoneyGram"
                        value="ຈ່າຍຜ່ານ MoneyGram"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍປາຍທາງ"
                        value="ຈ່າຍປາຍທາງ"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍຮູບແບບອື່ນໆ"
                        value="ຈ່າຍຮູບແບບອື່ນໆ"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-text-field
                        id="textfield"
                        v-if="pay_method == 'ຈ່າຍຮູບແບບອື່ນໆ'"
                        class="mt-5 mr-10"
                        outlined
                        placeholder="Pay Method"
                        :error-messages="Pay_ByError"
                        @change="$v.Pay_By.$touch()"
                        @blur="$v.Pay_By.$touch()"
                        v-model="Pay_By"
                      ></v-text-field>
                    </v-col>
                  </v-radio-group>
                </v-row>

                <h1 class="mt-15 mr-7 ml-9" id="myfont">
                  ຮູບອ້າງອິງການຈ່າຍເງີນ*
                </h1>
                <input
                  prepend-icon="mdi-camera"
                  type="file"
                  class="file-upload ml-7 mt-5"
                  @change="onFileSelected"
                  show-size
                  label="Image input"
                  required
                />
                <v-divider class="ml-7"></v-divider>
                <v-img
                  :src="url"
                  v-show="Img_Card"
                  max-height="450"
                  aspect-ratio="1.7"
                  contain
                ></v-img>
                <h1 class="mt-9 mr-7 ml-9" id="myfont">ລາຍລະອຽດເພີ່ມເຕີມ*</h1>
                <v-textarea
                  id="textfield"
                  class="mt-5 ml-7"
                  outlined
                  placeholder="Wrtie Some Detail"
                  name="input-7-4"
                  v-model="txtdetail"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-btn
                id="btntext"
                class="mt-16 mb-16 mr-10"
                color="error"
                @click="e1 = 2"
              >
                ກັບຄືນ
              </v-btn>
              <v-btn
                id="btntext"
                color="primary"
                @click="Submit"
                class="mt-16 mb-16 mr-10"
              >
                ຢຶນຢັນ
              </v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { mapGetters, mapActions } from "vuex";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    txtfullname: { required, maxLength: maxLength(100) },
    gender: { required },
    txttel: { required, maxLength: maxLength(12) },
    txtfacebook: { required, maxLength: maxLength(100) },
    txtemail: { required, email },
    txtvillage: { required, maxLength: maxLength(100) },
    txtdistrict: { required, maxLength: maxLength(100) },
    txtprovince: { required, maxLength: maxLength(100) },
    country: { required },
    txtpassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
    },
    comfirmpassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
    },
    Pay_By: { required },
    pay_method: { required },
  },
  data() {
    return {
      e1: 1,
      url: null,
      Img_Card: "",
      CustomerStatus: "General",
      //Customer Info
      txtfullname: "",
      gender: "",
      txttel: "",
      txtfacebook: "",
      txtemail: "",
      txtvillage: "",
      txtdistrict: "",
      txtprovince: "",
      country: "",
      txtpassword: "",
      comfirmpassword: "",
      pay_method: "",
      Pay_By: "",
    };
  },
  mounted() {
    this.$store.dispatch("Shop_Info");
    this.$store.dispatch("Customer_List");
     this.getCountry();
  },
  computed: {
       ...mapGetters({
      Country_Name: "countryMod/Country_Name",
    }),
    ProductCart() {
      return this.$store.state.ProductCart;
    },
    TotalPay() {
      return this.$store.getters.TotalPay;
    },
    Shop_Info() {
      return this.$store.state.Shop_Info;
    },
    Customer_detail() {
      return this.$store.state.Customer_detail;
    },
    Caculate_Discount() {
      let Sum = 0,
        LastSum = 0;
      this.ProductCart.forEach((item) => {
        Sum += item.total;
      });
      if (
        JSON.parse(localStorage.getItem("customer_status")).status == "Member"
      ) {
        this.Shop_Info.forEach((itm) => {
          LastSum = Sum - (Sum * itm.discount) / 100;
        });
      } else {
        LastSum = Sum;
      }
      return LastSum;
    },

    Get_Discount_Percent() {
      let discount = 0;
      if (
        JSON.parse(localStorage.getItem("customer_status")).status == "Member"
      ) {
        this.Shop_Info.forEach((itm) => {
          discount = itm.discount;
        });
      } else {
        discount = 0;
      }
      return discount;
    },

    NameErrors() {
      const errors = [];
      if (!this.$v.txtfullname.$dirty) return errors;
      !this.$v.txtfullname.maxLength &&
        errors.push("Name must be at most 100 characters long");
      !this.$v.txtfullname.required && errors.push("Name is required.");
      return errors;
    },
    GenderErrors() {
      const errors = [];
      if (!this.$v.gender.$dirty) return errors;
      !this.$v.gender.required && errors.push("");
      return errors;
    },
    TelErrors() {
      const errors = [];
      if (!this.$v.txttel.$dirty) return errors;
      !this.$v.txttel.maxLength &&
        errors.push("Tel must be at most 100 characters long");
      !this.$v.txttel.required && errors.push("Tel is required.");
      return errors;
    },
    FacebookErrors() {
      const errors = [];
      if (!this.$v.txtfacebook.$dirty) return errors;
      !this.$v.txtfacebook.maxLength &&
        errors.push("Facebook must be at most 100 characters long");
      !this.$v.txtfacebook.required && errors.push("Facebook is required.");
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.txtemail.$dirty) return errors;
      !this.$v.txtemail.email && errors.push("Must be valid e-mail");
      !this.$v.txtemail.required && errors.push("E-mail is required");
      if (this.txtemail != "") {
        for (const key in this.Customer_detail) {
          const element = this.Customer_detail[key];
          if (this.txtemail == element.email) {
            errors.push("ອີແມວນີ້ຖຶກໃຊ້ແລ້ວ...!");
          }
        }
      }
      return errors;
    },
    VillageErrors() {
      const errors = [];
      if (!this.$v.txtvillage.$dirty) return errors;
      !this.$v.txtvillage.maxLength &&
        errors.push("Village must be at most 100 characters long");
      !this.$v.txtvillage.required && errors.push("Village is required.");
      return errors;
    },

    DistrictErrors() {
      const errors = [];
      if (!this.$v.txtdistrict.$dirty) return errors;
      !this.$v.txtdistrict.maxLength &&
        errors.push("District must be at most 100 characters long");
      !this.$v.txtdistrict.required && errors.push("District is required.");
      return errors;
    },

    ProvinceErrors() {
      const errors = [];
      if (!this.$v.txtprovince.$dirty) return errors;
      !this.$v.txtprovince.maxLength &&
        errors.push("Province must be at most 100 characters long");
      !this.$v.txtprovince.required && errors.push("Province is required.");
      return errors;
    },
    CountryErrors() {
      const errors = [];
      if (!this.$v.country.$dirty) return errors;
      !this.$v.country.required && errors.push("Country is required");
      return errors;
    },
    PasswordErrors() {
      const errors = [];
      if (!this.$v.txtpassword.$dirty) return errors;
      !this.$v.txtpassword.minLength &&
        errors.push("Password must be at less 6 characters short");
      !this.$v.txtpassword.maxLength &&
        errors.push("Password must be at most 20 characters long");
      !this.$v.txtpassword.required && errors.push("Password is required.");
      return errors;
    },
    ComfirmPasswordErrors() {
      const errors = [];
      if (!this.$v.comfirmpassword.$dirty) return errors;
      !this.$v.comfirmpassword.minLength &&
        errors.push("comfirmpassword must be at less 6 characters short");
      !this.$v.comfirmpassword.maxLength &&
        errors.push("comfirmpassword must be at most 20 characters long");
      !this.$v.comfirmpassword.required &&
        errors.push("comfirmpassword is required.");

      if (this.comfirmpassword != this.txtpassword) {
        errors.push("Comfirm Password Again...!");
      }
      return errors;
    },
    Pay_ByError() {
      const errors = [];
      if (!this.$v.Pay_By.$dirty) return errors;
      !this.$v.Pay_By.required && errors.push("Pay Method is required.");
      return errors;
    },
    Pay_MethodError() {
      const errors = [];
      if (!this.$v.pay_method.$dirty) return errors;
      !this.$v.pay_method.required && errors.push("");
      return errors;
    },
  },
  methods: {
        ...mapActions("countryMod", ["getCountry"]),
    PhoneNumber: function ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    isNumber: function ($event, item) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      } else {
        if (
          (typeof item.quantity == "string" && item.quantity == "") ||
          item.quantity == null ||
          item.quantity == 0
        ) {
          item.quantity = 1;
          item.total = item.quantity * item.price;
        }
        item.quantity = parseInt(item.quantity, 10);
        item.total = item.quantity * item.price;
        return true;
      }
    },
    CheckNumber(item) {
      if (
        (typeof item.quantity == "string" && item.quantity == "") ||
        item.quantity == null ||
        item.quantity == 0
      ) {
        item.quantity = 1;
        item.total = item.quantity * item.price;
      }
      item.quantity = parseInt(item.quantity, 10);
      item.total = item.quantity * item.price;
    },
    DeleteCurrentItem(proID) {
      if (confirm("ຕ້ອງການລຶບລາຍການນີ້ບໍ ?"))
        this.$store.dispatch("DeleteCurrentItem", proID);
      if (this.$store.getters.CountOrderQty == 0) {
        this.$router.push(`/Product`);
      }
    },
    ClearCart() {
      if (confirm("ຕ້ອງການຍົກເລິກລາຍການສິນຄ້າທັງໝົດໃນກະຕ່າບໍ ?"))
        this.$store.dispatch("Clear_Cart_List");
      if (this.$store.getters.CountOrderQty == 0) {
        this.$router.push(`/Product`);
      }
    },
    SuttrackQtyInCart(item) {
      if (item.quantity <= 1) return;
      item.quantity--;
      item.total = item.quantity * item.price;
    },
    IncrementQty(item) {
      item.quantity++;
      item.total = item.quantity * item.price;
    },
    Submit() {
      //  alert(JSON.stringify(this.$store.state.ProductCart));
      if (this.$store.getters.CountOrderQty <= 0) {
        alert(JSON.stringify("No Product In Cart"));
        return;
      }
      if (this.pay_method == "") {
        this.$v.$touch();
        return;
      }
      if (this.pay_method == "ຈ່າຍຮູບແບບອື່ນໆ" && this.Pay_By == "") {
        this.$v.$touch();
        return;
      }
      if (this.pay_method == "ຈ່າຍຮູບແບບອື່ນໆ") {
        this.pay_method = this.Pay_By;
      }
      if (this.txtdetail == "" || this.txtdetail == null) {
        this.txtdetail = "ຕ້ອງການສັ່ງຊື້ສິນຄ້າ.....";
      }
      var datenow = new Date().toJSON().slice(0, 10).replace(/-/g, "");
      var timenow = new Date()
        .toTimeString()
        .replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1", "")
        .replace(":", "")
        .replace(":", "");
      var cusID = "CUS" + datenow.slice(2, datenow.length) + timenow;

      var order_id = "DOB" + datenow.slice(2, datenow.length) + timenow;

      if (this.CustomerStatus == "General") {
        this.$store.dispatch("Add_NewCustomer", {
          cus_id: cusID,
          full_name: this.txtfullname,
          gender: this.gender,
          tel: this.txttel,
          facebook: this.txtfacebook,
          email: this.txtemail,
          village: this.txtvillage,
          district: this.txtdistrict,
          province: this.txtprovince,
          country: this.country,
          password: this.txtpassword,
          status: "General",
          image: "",
        });
        this.$store.dispatch("Save_Order", {
          order_id: order_id,
          cus_id: cusID,
          order_date: datenow,
          order_time: timenow,
          status: "Confirm",
          pay_method: this.pay_method,
          card_image: this.Img_Card,
          discount: "0",
        });
        this.$store.dispatch("Submit_Comment", {
          cus_id: cusID,
          coment_id: order_id,
          customer_content: this.txtdetail,
        });
      } else {
        var discount = "";
        for (const key in this.Shop_Info) {
          const element = this.Shop_Info[key];
          if (
            JSON.parse(localStorage.getItem("customer_status")).status ==
            "Member"
          ) {
            discount = element.discount;
          } else {
            discount = 0;
          }
        }
        this.$store.dispatch("Submit_Order", {
          order_id: order_id,
          cus_id: JSON.parse(localStorage.getItem("Customer_Info")).cus_id,
          order_date: datenow,
          order_time: timenow,
          status: "Confirm",
          pay_method: this.pay_method,
          card_image: this.Img_Card,
          discount: discount,
        });
        this.$store.dispatch("Submit_Comment", {
          cus_id: JSON.parse(localStorage.getItem("Customer_Info")).cus_id,
          coment_id: order_id,
          customer_content: this.txtdetail,
        });
      }
      this.$router.push(`/Customer`);
    },
    GoToStep2() {
      if (this.$store.getters.CountOrderQty == 0) {
        return;
      } else {
        this.e1 = 2;
      }
    },
    GotoStep3() {
      if (this.CustomerStatus == "General") {
        if (
          this.txtfullname == "" ||
          this.txttel == "" ||
          this.txtfacebook == "" ||
          this.txtemail == "" ||
          this.txtvillage == "" ||
          this.txtdistrict == "" ||
          this.txtprovince == "" ||
          this.txtpassword == "" ||
          this.gender == "" ||
          this.country == ""
        ) {
          this.$v.$touch();
          return;
        }
        if (this.txtemail != "") {
          for (const key in this.Customer_detail) {
            const element = this.Customer_detail[key];
            if (this.txtemail == element.email) {
              return;
            }
          }
        }
        if (this.txtpassword != this.comfirmpassword) {
          return;
        }
      }
      this.e1 = 3;
      this.$v.$reset();
    },
    onFileSelected(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.url = e.target.result;
      };
      this.Img_Card = event.target.files[0].name;
      // alert(JSON.stringify(this.url));
    },
    OpenLogIN() {
      this.$v.$reset();
      this.$router.push(`/Customer_Login`);
    },
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
  },
};
</script>

<style>
#cssstyle {
  font-size: 30px;
  font-family: "Noto Sans Lao";
  text-align: left;
  margin-right: 30px;
  margin-top: 7px;
  color: black;
  font-weight: normal;
}
#myfont {
  font-size: 25px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: black;
}
#dialog {
  font-size: 25px;
  font-family: "Noto Sans Lao";
  font-weight: bold;
  align-content: center;
  margin-left: 135px;
  margin-top: 10px;
  color: black;
}
#btntext {
  font-size: 18px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: white;
}
#tbheader {
  height: 10px;
  font-size: 50px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: black;
}
.radio {
  font-size: 40px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: black;
}
</style>
<style scoped>
.tcenter >>> input {
  text-align: center;
}
</style>