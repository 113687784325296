import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import { header } from '../../config/constant'


Vue.use(Vuex)

const productModule = {
  namespaced: true,
  state: {
    products: [],
    cutproducts: [],
    orderproducts: [],
    customertop: [],
    list: [],
    bestlist: [],
    protodal: 0,
    emptodal: 0,
    selltodal: 0.0,
    custtodal: 0,
    ordcuttodal: 0,
    empCutTodal:0.0,
    empBuyTodal:0.0,
    empSellTodal:0.0,
    empCustTodal:0.0,
  },
  mutations: {

    setTopPro(state, pro) {
      state.products = pro
    },
    setTopCutPro(state, pro) {
      state.cutproducts = pro
    },
    setTopOrderPro(state, pro) {
      state.orderproducts = pro
    },
    setTopCustomer(state, cust) {
      state.customertop = cust
    },
    setTodalPro(state, todal) {
      state.protodal = todal
    },
    setTodalSell(state, todal) {
      state.selltodal = todal
    },
    setTodalEmp(state, todal) {
      state.emptodal = todal
    },
    setTodalCust(state, todal) {
      state.custtodal = todal
    },
    setTodalNewCut(state, todal) {
      state.ordcuttodal = todal
    },
    setTodalCutEmp(state, todal) {
      state.empCutTodal = todal
    },
    setTodalBuyEmp(state, todal) {
      state.empBuyTodal = todal
    },
    setTodalSellEmp(state, todal) {
      state.empSellTodal = todal
    },
    setTodalCustEmp(state, todal) {
      state.empCustTodal = todal
    },
    setIncome(state, list) {
      state.list = list
    },
    setBestProFucture(state, list) {
      state.bestlist = list
      // alert(JSON.stringify(list))
    },
  },

  actions: {

    async getTopPro(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/product/gettop`, {
          headers: header
        }
        );
        var pro_name = [], sum = []
        for (const key in response.data) {
          const el = response.data[key];
          pro_name.push(el.pro_name)
          sum.push(String(el.sum).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
        }
        var products = {
          series: [{
            data: sum
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              events: {
                click: function (chart, w, e) {
                  console.log(chart, w, e)
                }
              }, 
              toolbar: {
                show: false
              },
              zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: false,
                zoomedArea: {
                  fill: {
                    color: '#90CAF9',
                    opacity: 0.4
                  },
                  stroke: {
                    color: '#0D47A1',
                    opacity: 0.4,
                    width: 1
                  }
                }
              }
            },
            colors: ['#2E7D32','#1565C0', '#7B1FA2', '#AD1457', '#E65100',],
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            xaxis: {
              categories: pro_name,
              labels: {
                style: {
                  colors: ['#2E7D32','#1565C0', '#7B1FA2', '#AD1457', '#E65100',],
                  fontSize: '16px',
                  fontFamily:'NoTo Sans Lao'
                }
              }
            },
            responsive: [
              {
                breakpoint: 1000,
                options: {
                  plotOptions: {
                    bar: {
                      horizontal: false
                    }
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ],

          }

        }
      
        context.commit('setTopPro', products)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTopOrderPro(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/buy/gettop`, {
          headers: header
        }
        );
        var pro_name = [], sum = []
        for (const key in response.data) {
          const el = response.data[key];
          pro_name.push(el.pro_name)
          sum.push(String(el.sum).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
        }

        var products = {

          labels: pro_name,
          datasets: [
            {
              label: "ສິນຄ້າທີນີ້ຍົມສັ່ງຊື້",
              borderWidth: 1,
              pointBorderColor: "#FBF",
              data: sum,
              backgroundColor: [
                "rgba(00,250,25,0.7)",
                "rgba(100,250,255,0.7)",
                "rgba(255,110,255,0.7)",
                "rgba(200,200,150,0.7)",
                "rgba(105,00,255,0.7)",],

              responsive: true,
              maintainAspectRatio: false,
              pieceLabel: {
                mode: 'percentage',
                precision: 1
              }
            }
          ]

        }
        context.commit('setTopOrderPro', products)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTopCutPro(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/cut/gettop`, {
          headers: header
        }
        );
        var pro_name = [], sum = []
        for (const key in response.data) {
          const el = response.data[key];
          pro_name.push(el.pro_name)
          sum.push(el.sum)
        }

        var products = {
          series: sum,
          chartOptions: {
            chart: {
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              width: 380,
              type: 'polarArea'
            },
            labels: pro_name,
            fill: {
              opacity: 1
            },
            stroke: {
              width: 1,
              colors: undefined
            },
            yaxis: {
              show: false
            },
            legend: {
              position: 'top'
            },
            plotOptions: {
              polarArea: {
                rings: {
                  strokeWidth: 0
                },
                spokes: {
                  strokeWidth: 0
                },
              }
            },
            theme: {
              monochrome: {
                enabled: true,
                shadeTo: 'light',
                shadeIntensity: 0.6
              }
            }
          },


        }
        context.commit('setTopCutPro', products)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTopCustomer(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/customer/gettop`
          , {
            headers: header
          }
        );
        var cust_name = [], count = []
        for (const key in response.data) {
          const el = response.data[key];
          cust_name.push(el.full_name)
          count.push(el.count)
        }

        var customer = {
          series: [{
            data: count
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 380,
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: true,
                dataLabels: {
                  position: 'bottom',
                  fontFamily: 'NoTo Sans Lao'
                },
              },
             
            },
            colors: ['#00E676', '#039BE5', '#546E7A', '#5D4037', '#8E24AA'
            ],
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#E65100'],
                fontSize: '20px',
                fontFamily: 'NoTo Sans Lao'
              },
              formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + " :  " + val + " ຄັ້ງ"
              },
              offsetX: 0,
              dropShadow: {
                enabled: true
              }
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            xaxis: {
              categories: cust_name,
              labels: {
                style: {
                  fontFamily:'NoTo Sans Lao'
                }
              }
            },
            yaxis: {
              labels: {
                show: false
              }
            },
            legend: {
              show:false,
              position: "bottom"
            },
            tooltip: {
              theme: 'dark',
              x: {
                show: false
              },
              y: {
                title: {
                  formatter: function () {
                    return ''
                  }
                }
              }
            }
          },

        }
        context.commit('setTopCustomer', customer)
      } catch (e) {
        this.errors.push(e);
      }
    },

    async getTodalPro(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/product/gettodal`, {
          headers: header
        }
        );
        context.commit('setTodalPro', String(response.data[0].todal).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalSell(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/sale/gettodal`, {
          headers: header
        }
        );
        context.commit('setTodalSell', String(parseInt(response.data[0].todal)).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalEmp(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/employee/gettodal`, {
          headers: header
        }
        );
        context.commit('setTodalEmp', String(parseInt(response.data[0].todal)).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalCust(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/customer/gettodal`, {
          headers: header
        }
        );
        context.commit('setTodalCust', String(parseInt(response.data[0].todal)).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalCut(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/cut/gettodal`, {
          headers: header
        }
        );
        context.commit('setTodalNewCut', String(response.data[0].todal).replace(/(.)(?=(\d{3})+$)/g, '$1,'))
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalCutEmp(context,emp_id) {
      try {
        const response = await axios.post(
          `http://localhost:2021/api/dadabackend/order/cut/user/gettodal`,{emp_id:emp_id}, {
          headers: header
        }
        );
        var sum=0;
        if(response.data[0].todal!=null){
          sum= parseFloat(response.data[0].todal)
        }
        var todal = {
          series: [sum],
          chartOptions: {
            chart: {
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              height: 350,
              type: 'radialBar',
              toolbar: {
                show: false
              }
            },
            colors: ['#D500F9'],
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                  margin: 0,
                  size: '60%',
                  background: '#F3E5F5',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'back',
                  dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                  }
                },
                track: {
                  background: '#fff',
                  strokeWidth: '67%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: true,
                    top: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.35
                  }
                },

                dataLabels: {
                  show: true,
                  name: {
                    offsetY: -10,
                    show: true,
                    color: '#888',
                    fontSize: '17px'
                  },
                  value: {
                    formatter: function (val) {
                      return parseInt(val);
                    },
                    color: '#111',
                    fontSize: '20px',
                    show: true,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horyzontal',
                shadeIntensity: 1,
                gradientToColors: ['#F48FB1',],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },
            labels: ['ສະກຸນເງິນ (ກິບ)'],
          },


        }
        context.commit('setTodalCutEmp', todal)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalBuyEmp(context,emp_id) {
      try {
        const response = await axios.post(
          `http://localhost:2021/api/dadabackend/order/buy/user/gettodal`,{emp_id:emp_id}, {
          headers: header
        }
        );
        var sum=0;
        if(response.data[0].todal!=null){
          sum= parseFloat(response.data[0].todal)
        }
        var todal = {
          series: [sum],
          chartOptions: {
            chart: {
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              height: 350,
              type: 'radialBar',
              toolbar: {
                show: false
              }
            },
            colors: ['#00B0FF'],
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                  margin: 0,
                  size: '60%',
                  background: '#E1F5FE',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'back',
                  dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                  }
                },
                track: {
                  background: '#fff',
                  strokeWidth: '67%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: true,
                    top: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.35
                  }
                },

                dataLabels: {
                  show: true,
                  name: {
                    offsetY: -10,
                    show: true,
                    color: '#888',
                    fontSize: '17px'
                  },
                  value: {
                    formatter: function (val) {
                      return parseInt(val);
                    },
                    color: '#111',
                    fontSize: '20px',
                    show: true,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                gradientToColors: ['#84FFFF',],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },
            labels: ['ສະກຸນເງິນ (ກິບ)'],
          },


        }
        context.commit('setTodalBuyEmp', todal)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalSellEmp(context,emp_id) {
      try {
        const response = await axios.post(
          `http://localhost:2021/api/dadabackend/sale/user/gettodal`,{emp_id:emp_id}, {
          headers: header
        }
        );
        var sum=0;
        if(response.data[0].todal!=null){
          sum= parseFloat(response.data[0].todal)
        }
        var todal = {
          series: [sum],
          chartOptions: {
            chart: {
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              height: 350,
              type: 'radialBar',
              toolbar: {
                show: false
              }
            },
            colors: ['#00E676'],
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                  margin: 0,
                  size: '60%',
                  background: '#B9F6CA',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'front',
                  dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                  }
                },
                track: {
                  background: '#fff',
                  strokeWidth: '67%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: true,
                    top: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.35
                  }
                },

                dataLabels: {
                  show: true,
                  name: {
                    offsetY: -10,
                    show: true,
                    color: '#888',
                    fontSize: '17px'
                  },
                  value: {
                    formatter: function (val) {
                      return parseInt(val);
                    },
                    color: '#111',
                    fontSize: '20px',
                    show: true,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'horyzontal',
                shadeIntensity: 1,
                gradientToColors: ['#B2FF59',],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },
            labels: ['ສະກຸນເງິນ (ກິບ)'],
          },


        }
        context.commit('setTodalSellEmp', todal)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getTodalCustEmp(context,emp_id) {
      try {
        const response = await axios.post(
          `http://localhost:2021/api/dadabackend/customer/member/user/gettodal`,{emp_id:emp_id}, {
          headers: header
        }
        );
        var sum=0;
        if(response.data[0].todal>0){
          sum= parseFloat(response.data[0].todal)
        }
        var todal = {
          series: [sum],
          chartOptions: {
            chart: {
              fontFamily: 'NoTo Sans Lao, Arial, sans-serif',
              height: 350,
              type: 'radialBar',
              toolbar: {
                show: false
              }
            },
            colors: ['#FFFF00'],
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                  margin: 0,
                  size: '60%',
                  background: '#FFF3E0',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'front',
                  dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                  }
                },
                track: {
                  background: '#fff',
                  strokeWidth: '67%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: true,
                    top: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.35
                  }
                },

                dataLabels: {
                  show: true,
                  name: {
                    offsetY: -10,
                    show: true,
                    color: '#888',
                    fontSize: '17px'
                  },
                  value: {
                    formatter: function (val) {
                      return parseInt(val);
                    },
                    color: '#111',
                    fontSize: '20px',
                    show: true,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                gradientToColors: ['#FF8F00',],
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },
            labels: ['ຮັບຕົວແທນ (ຄົນ)'],
          },


        }
        context.commit('setTodalCustEmp', todal)
      } catch (e) {
        this.errors.push(e);
      }
    },

    async getSellDay(context) {
      try {

        const current = new Date();
        var todal = 0, data = [], date = []
        for (let index = 0; index < 30; index++) {
          const prior = new Date().setDate(current.getDate() - index);
          date.push(new Date(prior).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"))
        }
        date.sort();
        var end_date = new Date().toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
          first_date = new Date(new Date().setDate(new Date().getDate() - 30)).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
        const res = await axios.post(
          `http://localhost:2021/api/dadabackend/sale/getday`, { first_date: first_date.toString(), end_date: end_date.toString() }
          , {
            headers: header
          }
        );
        for (let i = 0; i < date.length; i++) {
          var sum = 0
          const e = date[i];
          for (const key in res.data) {
            const el = res.data[key];
            if (e == moment(String(el.sell_date)).format('YYYY-MM-DD')) {
              sum = parseFloat(el.sum)
            }
          }
          data.push(sum)
          todal = todal + parseFloat(sum)
        }
        var datacollection = {
          labels: date,
          datasets: [
            {
                  fontFamily:'NoTo Sans Lao',
              label: "ຍອດຂາຍປະຈໍາວັນ",
              todal: todal,
              data:data,
              pointBackgroundColor: "#fff",
              borderWidth: 3,
              pointBorderColor: "#00E676",
              borderColor: ["#00E676"],
              backgroundColor: ["rgb(153, 255, 153)"]
            }
          ]
        };
        context.commit('setIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getOrderDay(context) {
      try {

        const current = new Date();
        var todal = 0, data = [], date = []
        for (let index = 0; index < 30; index++) {
          const prior = new Date().setDate(current.getDate() - index);
          date.push(new Date(prior).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"))
        }
        date.sort();
        var end_date = new Date().toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
          first_date = new Date(new Date().setDate(new Date().getDate() - 30)).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
        const res = await axios.post(
          `http://localhost:2021/api/dadabackend/order/buy/getday`, { first_date: first_date.toString(), end_date: end_date.toString() }
          , {
            headers: header
          }
        );
        for (let i = 0; i < date.length; i++) {
          var sum = 0
          const e = date[i];
          for (const key in res.data) {
            const el = res.data[key];
            if (e == moment(String(el.order_date)).format('YYYY-MM-DD')) {
              sum = parseFloat(el.sum)
            }
          }
          data.push(sum)
          todal = todal + parseFloat(sum)
        }
        var datacollection = {
          labels: date,
          datasets: [
            {
              label: "ຍອດສັ່ງຊື້ປະຈໍາວັນ",
              todal: todal,
              data:data,
              pointBorderColor: "#039BE5",
              pointBackgroundColor: "#B39DDB",
              borderWidth: 3,
              borderColor: ["#039BE5"],
              backgroundColor: ["rgb(102, 204, 255)"]
            }
          ]
        };
        context.commit('setIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getCutDay(context) {
      try {
        const current = new Date();
        var todal = 0, data = [], date = []
        for (let index = 0; index < 30; index++) {
          const prior = new Date().setDate(current.getDate() - index);
          date.push(new Date(prior).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"))
        }
        date.sort();
        var end_date = new Date().toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
          first_date = new Date(new Date().setDate(new Date().getDate() - 30)).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
        const res = await axios.post(
          `http://localhost:2021/api/dadabackend/order/cut/getday`, { first_date: first_date.toString(), end_date: end_date.toString() }
          , {
            headers: header
          }
        );
        for (let i = 0; i < date.length; i++) {
          var sum = 0
          const e = date[i];
          for (const key in res.data) {
            const el = res.data[key];
            if (e == moment(String(el.order_cut_date)).format('YYYY-MM-DD')) {
              sum = el.sum
            }
          }
          data.push(sum)
          todal = todal + parseFloat(sum)
        }
        var datacollection = {
          labels: date,
          datasets: [
            {
              label: "ຍອດສັ່ງຕັດປະຈໍາວັນ",
              todal: todal,
              data:data,
              pointBorderColor: '#FF8F00',
              pointBackgroundColor: "#E040FB",
              borderWidth: 3,
              borderColor: ["#FF8F00"],
              backgroundColor: ["rgb(255, 204, 153)"]
            }
          ]
        };
        context.commit('setIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getAllDay(context) {
      try {
        const current = new Date();
        var buytodal = 0, cuttodal = 0, selltodal = 0, buydata = [], cutdata = [], selldata = [], date = []
        for (let index = 0; index < 30; index++) {
          const prior = new Date().setDate(current.getDate() - index);
          date.push(new Date(prior).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-"))
        }
        date.sort();

        var end_date = new Date().toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
          first_date = new Date(new Date().setDate(new Date().getDate() - 30)).toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")

        const cutres = await axios.post(
          `http://localhost:2021/api/dadabackend/order/cut/getday`, { first_date: first_date.toString(), end_date: end_date.toString() }
          , {
            headers: header
          }
        );

        const buyres = await axios.post(
          `http://localhost:2021/api/dadabackend/order/buy/getday`, { first_date: first_date.toString(), end_date: end_date.toString() }
          , {
            headers: header
          }
        );

        const sellres = await axios.post(
          `http://localhost:2021/api/dadabackend/sale/getday`, { first_date: first_date.toString(), end_date: end_date.toString() }
          , {
            headers: header
          }
        );
        for (let i = 0; i < date.length; i++) {
          var bsum = 0, csum = 0, ssum = 0
          const e = date[i];
          for (const key in cutres.data) {
            const el = cutres.data[key];
            if (e == moment(String(el.order_cut_date)).format('YYYY-MM-DD')) {
              csum = el.sum
            }
          }
          for (const key in buyres.data) {
            const el = buyres.data[key];
            if (e == moment(String(el.order_date)).format('YYYY-MM-DD')) {
              bsum = el.sum
            }
          }
          for (const key in sellres.data) {
            const el = sellres.data[key];
            if (e == moment(String(el.sell_date)).format('YYYY-MM-DD')) {
              ssum = el.sum
            }
          }
          cutdata.push(csum)
          buydata.push(bsum)
          selldata.push(ssum)

          cuttodal = cuttodal + parseFloat(csum)
          buytodal = buytodal + parseFloat(bsum)
          selltodal = selltodal + parseFloat(ssum)

        }

        var datacollection = {
          labels: date,
          datasets: [
            {
              label: "ຍອດສັ່ງຕັດປະຈໍາວັນ",
              data: cutdata,
              todal: cuttodal,
              pointBorderColor: '#FF8F00',
              pointBackgroundColor: "#E040FB",
              borderWidth: 3,
              borderColor: ["#FF8F00"],
              backgroundColor: ["rgb(255, 204, 153)"]
            },
            {
              label: "ຍອດສັ່ງຊື້ປະຈໍາວັນ",
              todal: buytodal,
              data: buydata,
              pointBorderColor: "#039BE5",
              pointBackgroundColor: "#B39DDB",
              borderWidth: 3,
              borderColor: ["#039BE5"],
              backgroundColor: ["rgb(102, 204, 255)"]
            },
            {
              label: "ຍອດຂາຍປະຈໍາວັນ",
              todal: selltodal,
              data: selldata,
              pointBackgroundColor: "#fff",
              borderWidth: 3,
              pointBorderColor: "#00E676",
              borderColor: ["#00E676"],
              backgroundColor: ["rgb(153, 255, 153)"]
            }
          ]
        };
        context.commit('setIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },


    async getSellMonth(context) {
      try {
        var todal = 0.0, data = [], date = [], aday = 0, sday = 0, y = 0,
        arrmonth = [
          "ມັງກອນ",
          "ກຸມພາ",
          "ມີນາ",
          "ເມສາ",
          "ພຶດສະພາ",
          "ມິຖຸນາ",
          "ກໍລະກົດ",
          "ສິງຫາ",
          "ກັນຍາ",
          "ຕຸລາ",
          "ພະຈິກ",
          "ທັນວາ"
        ]
      const response = await axios.get(
        `http://localhost:2021/api/dadabackend/sale/getMonth`
        , {
          headers: header
        }
      );

      for (let indx = 0; indx < 12; indx++) {
        var sum = 0.0, month = '', mth = new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-")
        if ((parseInt(mth.slice(5, 7)) - aday) > 0) {
          month = (parseInt(mth.slice(5, 7)) - aday).toString()
          aday = aday + 1
          y = new Date().getFullYear()
        } else {
          month = (12 - sday).toString()
          y = parseInt(new Date().getFullYear()) - 1
          sday = sday + 1
        }
        for (const key in response.data) {
          const el = response.data[key];
          var m = moment(String(el.sell_date)).format('YYYY-MM-DD').slice(5, 7)
          if (parseInt(m) == parseInt(month)) {
            sum += parseFloat(el.sum)
          }
        }
        todal = todal + sum
        data.push(sum)
        date.push(y + " " + arrmonth[month - 1])
      }
      date.reverse();
      data.reverse();
        var datacollection = {
          labels: date,
          datasets: [
            {
              label: "ຍອດຂາຍປະຈໍາເດືອນ",
              todal: todal,
              data: data,
              pointBackgroundColor: "#fff",
              borderWidth: 3,
              pointBorderColor: "#00E676",
              borderColor: ["#00E676"],
              backgroundColor: ["rgb(153, 255, 153)"]
            }
          ]
        };
        context.commit('setIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getCutMonth(context) {
      try {
        var todal = 0.0, data = [], date = [], aday = 0, sday = 0, y = 0,
        arrmonth = [
          "ມັງກອນ",
          "ກຸມພາ",
          "ມີນາ",
          "ເມສາ",
          "ພຶດສະພາ",
          "ມິຖຸນາ",
          "ກໍລະກົດ",
          "ສິງຫາ",
          "ກັນຍາ",
          "ຕຸລາ",
          "ພະຈິກ",
          "ທັນວາ"
        ]
      const response = await axios.get(
        `http://localhost:2021/api/dadabackend/order/cut/getMonth`
        , {
          headers: header
        }
      );

      for (let indx = 0; indx < 12; indx++) {
        var sum = 0.0, month = '', mth = new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-")
        if ((parseInt(mth.slice(5, 7)) - aday) > 0) {
          month = (parseInt(mth.slice(5, 7)) - aday).toString()
          aday = aday + 1
          y = new Date().getFullYear()
        } else {
          month = (12 - sday).toString()
          y = parseInt(new Date().getFullYear()) - 1
          sday = sday + 1
        }
        for (const key in response.data) {
          const el = response.data[key];
          var m = moment(String(el.order_cut_date)).format('YYYY-MM-DD').slice(5, 7)
          if (parseInt(m) == parseInt(month)) {
            sum += parseFloat(el.sum)
          }
        }
        todal = todal + sum
        data.push(sum)
        date.push(y + " " + arrmonth[month - 1])
      }
      date.reverse();
      data.reverse();

        var datacollection = {
          labels: date.reverse(),
          datasets: [
            {
              label: "ຍອດສັ່ງຕັດປະຈໍາເດືອນ",
              todal: todal,
              data:data,
              pointBorderColor: '#FF8F00',
              pointBackgroundColor: "#E040FB",
              borderWidth: 3,
              borderColor: ["#FF8F00"],
              backgroundColor: ["rgb(255, 204, 153)"]
            }
          ]
        };
        context.commit('setIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getOrderMonth(context) {
      try {
        var todal = 0.0, data = [], date = [], aday = 0, sday = 0, y = 0,
        arrmonth = [
          "ມັງກອນ",
          "ກຸມພາ",
          "ມີນາ",
          "ເມສາ",
          "ພຶດສະພາ",
          "ມິຖຸນາ",
          "ກໍລະກົດ",
          "ສິງຫາ",
          "ກັນຍາ",
          "ຕຸລາ",
          "ພະຈິກ",
          "ທັນວາ"
        ]
      const response = await axios.get(
        `http://localhost:2021/api/dadabackend/order/buy/getMonth`
        , {
          headers: header
        }
      );

      for (let indx = 0; indx < 12; indx++) {
        var sum = 0.0, month = '', mth = new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-")
        if ((parseInt(mth.slice(5, 7)) - aday) > 0) {
          month = (parseInt(mth.slice(5, 7)) - aday).toString()
          aday = aday + 1
          y = new Date().getFullYear()
        } else {
          month = (12 - sday).toString()
          y = parseInt(new Date().getFullYear()) - 1
          sday = sday + 1
        }
        for (const key in response.data) {
          const el = response.data[key];
          var m = moment(String(el.order_date)).format('YYYY-MM-DD').slice(5, 7)
          if (parseInt(m) == parseInt(month)) {
            sum += parseFloat(el.sum)
          }
        }
        todal = todal + sum
        data.push(sum)
        date.push(y + " " + arrmonth[month - 1])
      }
      date.reverse();
      data.reverse();

        var datacollection = {
          labels: date.reverse(),
          datasets: [
            {
              label: "ຍອດສັ່ງຊື້ປະຈໍາເດືອນ",
              todal: todal,
              data:data,
              pointBorderColor: "#039BE5",
              pointBackgroundColor: "#B39DDB",
              borderWidth: 3,
              borderColor: ["#039BE5"],
              backgroundColor: ["rgb(102, 204, 255)"]
            }
          ]
        };
        context.commit('setIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getAllMonth(context) {
      try {
        var buytodal = 0, cuttodal = 0, selltodal = 0, buydata = [], cutdata = [], selldata = [], date = [],aday = 0, sday = 0, y = 0,
        arrmonth = [
          "ມັງກອນ",
          "ກຸມພາ",
          "ມີນາ",
          "ເມສາ",
          "ພຶດສະພາ",
          "ມິຖຸນາ",
          "ກໍລະກົດ",
          "ສິງຫາ",
          "ກັນຍາ",
          "ຕຸລາ",
          "ພະຈິກ",
          "ທັນວາ"
        ]
        const cutres = await axios.get(
          `http://localhost:2021/api/dadabackend/order/cut/getMonth`
          , {
            headers: header
          }
        );
        const buyres = await axios.get(
          `http://localhost:2021/api/dadabackend/order/buy/getMonth`
          , {
            headers: header
          }
        );
        const sellres = await axios.get(
          `http://localhost:2021/api/dadabackend/sale/getMonth`
          , {
            headers: header
          }
        );
        for (let indx = 0; indx < 12; indx++) {
          var csum = 0.0,bsum = 0.0, ssum = 0.0, month = '', mth = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
          if ((parseInt(mth.slice(5, 7)) - aday) > 0) {
            month = (parseInt(mth.slice(5, 7)) - aday).toString()
            aday = aday + 1
            y = new Date().getFullYear()
          } else {
            month = (12 - sday).toString()
            y = parseInt(new Date().getFullYear()) - 1
            sday = sday + 1
          }
          for (const key in cutres.data) {
            const el = cutres.data[key];
            var cm = moment(String(el.order_cut_date)).format('YYYY-MM-DD').slice(5, 7)
            if (parseInt(cm) == parseInt(month)) {
              csum += parseFloat(el.sum)
            }
          }
          for (const key in buyres.data) {
            const el = buyres.data[key];
            var bm = moment(String(el.order_date)).format('YYYY-MM-DD').slice(5, 7)
            if (parseInt(bm) == parseInt(month)) {
              bsum += parseFloat(el.sum)
            }
          }
          for (const key in sellres.data) {
            const el = sellres.data[key];
            var sm = moment(String(el.sell_date)).format('YYYY-MM-DD').slice(5, 7)
            if (parseInt(sm) == parseInt(month)) {
              ssum += parseFloat(el.sum)
            }
          }
          cuttodal = cuttodal + csum
          buytodal = buytodal + bsum
          selltodal = selltodal + ssum

          cutdata.push(csum)
          buydata.push(bsum)
          selldata.push(ssum)
          date.push(y + " " + arrmonth[month - 1])
        }
        date.reverse()
        cutdata.reverse()
        buydata.reverse()
        selldata.reverse()

        var datacollection = {
          labels: date,
          datasets: [
            {
              label: "ຍອດສັ່ງຕັດປະຈໍາເດືອນ",
              todal: cuttodal,
              data: cutdata,
              pointBorderColor: '#FF8F00',
              pointBackgroundColor: "#E040FB",
              borderWidth: 3,
              borderColor: ["#FF8F00"],
              backgroundColor: ["rgb(255, 204, 153)"]
            },
            {
              
              label: "ຍອດສັ່ງຊື້ປະຈໍາເດືອນ",
              todal: buytodal,
              data: buydata,
              pointBorderColor: "#039BE5",
              pointBackgroundColor: "#B39DDB",
              borderWidth: 3,
              borderColor: ["#039BE5"],
              backgroundColor: ["rgb(102, 204, 255)"]
            },
            {
              label: "ຍອດຂາຍປະຈໍາເດືອນ",
              todal: selltodal,
              data: selldata,
              pointBackgroundColor: "#fff",
              borderWidth: 3,
              pointBorderColor: "#00E676",
              borderColor: ["#00E676"],
              backgroundColor: ["rgb(153, 255, 153)"]
            }
          ]
        }
        context.commit('setIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },

    async getSellYear(context) {
      try {
        var todal = 0.0, data = [], date = []
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/sale/getyear`
          , {
            headers: header
          }
        );

        for (let index = 0; index < 5; index++) {
          var year = parseInt(new Date().getFullYear(), 10), sum = 0.0
          for (const key in response.data) {
            const el = response.data[key];
            var y = moment(String(el.sell_date)).format('YYYY-MM-DD').slice(0, 4)

            if (year - index == y) {
              sum += parseFloat(el.sum)
            }
          }
          todal = todal + sum 

          data.push(sum)
          date.push(year -= index)
        }
        date.reverse();
        data.reverse();

        var datacollection = {
          labels: date,
          datasets: [
            {
              label: "ຍອດຂາຍປະຈໍາປີ",
              todal: todal,
              data:data,
              pointBackgroundColor: "#fff",
              borderWidth: 3,
              pointBorderColor: "#00E676",
              borderColor: ["#00E676"],
              backgroundColor: ["rgb(153, 255, 153)"]
            }
          ]
        };
        context.commit('setIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getOrderYear(context) {
      try {
        var todal = 0.0, data = [], date = []
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/buy/getyear`
          , {
            headers: header
          }
        );

        for (let index = 0; index < 5; index++) {
          var year = parseInt(new Date().getFullYear(), 10), sum = 0.0
          for (const key in response.data) {
            const el = response.data[key];
            var y = moment(String(el.order_date)).format('YYYY-MM-DD').slice(0, 4)
            if (year - index == y) {
              sum += parseFloat(el.sum)
            }
          }
          todal = todal + sum
          data.push(sum)
          date.push(year -= index)
        }
        date.reverse();
        data.reverse();

        var datacollection = {
          labels: date,
          datasets: [
            {
              label: "ຍອດສັ່ງຊີ້ປະຈໍາປີ",
              todal: todal,
              data:data,
              pointBorderColor: "#039BE5",
              pointBackgroundColor: "#B39DDB",
              borderWidth: 3,
              borderColor: ["#039BE5"],
              backgroundColor: ["rgb(102, 204, 255)"]
            }
          ]
        };
        context.commit('setIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getCutYear(context) {
      try {
        var todal = 0.0, data = [], date = []
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/cut/getyear`
          , {
            headers: header
          }
        );

        for (let index = 0; index < 5; index++) {
          var year = parseInt(new Date().getFullYear(), 10), sum = 0.0
          for (const key in response.data) {
            const el = response.data[key];
            var y = moment(String(el.order_cut_date)).format('YYYY-MM-DD').slice(0, 4)
            if (year - index == y) {
              sum += parseFloat(el.sum)
            }
          }
          todal = todal + sum
          data.push(sum)
          date.push(year -= index)
        }
        date.reverse();
        data.reverse();
        var datacollection = {
          labels: date,
          datasets: [
            {
              label: "ຍອດສັ່ງຕັດປະຈໍາປີ",
              todal: todal,
              data:data,
              pointBorderColor: '#FF8F00',
              pointBackgroundColor: "#E040FB",
              borderWidth: 3,
              borderColor: ["#FF8F00"],
              backgroundColor: ["rgb(255, 204, 153)"]
            }
          ]
        };
        context.commit('setIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getAllYear(context) {
      try {
        var buytodal = 0, cuttodal = 0, selltodal = 0, buydata = [], cutdata = [], selldata = [], date = []
          const cutres = await axios.get(
            `http://localhost:2021/api/dadabackend/order/cut/getyear`
            , {
              headers: header
            }
          );
          const buyres = await axios.get(
            `http://localhost:2021/api/dadabackend/order/buy/getyear`
            , {
              headers: header
            }
          );
          const sellres = await axios.get(
            `http://localhost:2021/api/dadabackend/sale/getyear`
            , {
              headers: header
            }
          );
          for (let index = 0; index < 5; index++) {
            var year = parseInt(new Date().getFullYear(), 10), csum = 0.0,bsum=0.0, ssum=0.0
            for (const key in cutres.data) {
              const el = cutres.data[key];
              var cy = moment(String(el.order_cut_date)).format('YYYY-MM-DD').slice(0, 4)
  
              if (year - index == cy) {
                csum += parseFloat(el.sum)
              }
            }

            for (const key in buyres.data) {
              const el = buyres.data[key];
              var by = moment(String(el.order_date)).format('YYYY-MM-DD').slice(0, 4)
  
              if (year - index == by) {
                bsum += parseFloat(el.sum)
              }
            }

            for (const key in sellres.data) {
              const el = sellres.data[key];
              var sy = moment(String(el.sell_date)).format('YYYY-MM-DD').slice(0, 4)
  
              if (year - index == sy) {
                ssum += parseFloat(el.sum)
              }
            }

            cuttodal = cuttodal + csum
          buytodal = buytodal + bsum
          selltodal = selltodal + ssum

          cutdata.push(csum)
          buydata.push(bsum)
          selldata.push(ssum)
            date.push(year -= index)
          }
          date.reverse()
          cutdata.reverse()
          buydata.reverse()
          selldata.reverse()
  
       
        var datacollection = {
          labels: date,
          datasets: [
            {
              label: "ຍອດສັ່ງຕັດປະຈໍາປີ",
              todal: cuttodal,
              data: cutdata,
              pointBorderColor: '#FF8F00',
              pointBackgroundColor: "#E040FB",
              borderWidth: 3,
              borderColor: ["#FF8F00"],
              backgroundColor: ["rgb(255, 204, 153)"]
            },
            {
              label: "ຍອດສັ່ງຊື້ປະຈໍາປີ",
              todal: buytodal,
              data: buydata,
              pointBorderColor: "#039BE5",
              pointBackgroundColor: "#B39DDB",
              borderWidth: 3,
              borderColor: ["#039BE5"],
              backgroundColor: ["rgb(102, 204, 255)"]
            },
            {
              label: "ຍອດຂາຍປະຈໍາປີ",
              todal: selltodal,
              data: selldata,
              pointBackgroundColor: "#fff",
              borderWidth: 3,
              pointBorderColor: "#00E676",
              borderColor: ["#00E676"],
              backgroundColor: ["rgb(153, 255, 153)"]
            }
          ]
        };
        context.commit('setIncome', datacollection)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getBestProFucture(context) {
      try {
        var todal = [], pro = [], date = [], aday = 0, sday = 0,
          arrmonth = [
            "January",
            "Feebruary",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "Octorber",
            "November",
            "December"
          ]
        for (let index = 2; index > -1; index--) {
          const response = await axios.get(
            `http://localhost:2021/api/dadabackend/sale/getbest/${index}`
            , {
              headers: header
            }
          );

          todal.push(response.data)

          var month = '', mth = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-")
          if ((parseInt(mth.slice(5, 7), 10) - aday) > 0) {
            month = (parseInt(mth.slice(5, 7), 10) - aday).toString()
            aday = aday + 1
          } else {
            month = (12 - sday).toString()
            sday = sday + 1
          }

          for (const key in response.data) {
            var sum = 0.0
            const el = response.data[key];
            for (const key in pro) {
              if (pro[key].name == el.pro_name) {
                sum = parseInt(pro[key].qty, 10)
                pro.slice(key, 1)
              }
            }
            var product = {
              name: el.pro_name,
              qty: parseInt(el.sum, 10) + sum
            }
            pro.push(product)
          }


          date.push(arrmonth[month - 1])
        }
        pro.sort((a, b) => {
          return b.qty - a.qty;
        });
        pro.splice(3, pro.length)
        var pname = [], count = []
        for (const key in pro) {
          const p1 = pro[key]
          for (const k in todal[key]) {
            const p2 = todal[k];
            for (const i in p2) {
              var q = 0;
              const p22 = p2[i];
              if (p1.name == p22.pro_name) {
                q = p22.sum
              }
              count.push(q)
            }
          }
          pname.push(p1.name)

        }
        alert(JSON.stringify(pname))

        // var datacollection = {
        //   labels: date.reverse(),
        //   datasets: [
        //     {
        //       label: "ຍອດຂາຍ",
        //       pointBackgroundColor: "#99f",
        //       borderWidth: 1,
        //       pointBorderColor: "#0ff",
        //       data: todal,
        //       borderColor: ["#1f9"],
        //       // borderWidth:2,
        //       backgroundColor: ["rgba(250,250,25,0.7)"]
        //     }
        //   ]
        // };
        context.commit('setBestProFucture', pro)
      } catch (e) {
        this.errors.push(e);
      }
    },
  },
  getters: {
    products: state => state.products,
    protodal: state => state.protodal,
    selltodal: state => state.selltodal,
    emptodal: state => state.emptodal,
    custtodal: state => state.custtodal,
    ordcuttodal: state => state.ordcuttodal,
    cutproducts: state => state.cutproducts,
    orderproducts: state => state.orderproducts,
    customertop: state => state.customertop,
    list: state => state.list,
    bestlist: state => state.bestlist,
    empCutTodal:state => state.empCutTodal,
    empBuyTodal:state => state.empBuyTodal,
    empSellTodal:state => state.empSellTodal,
    empCustTodal:state => state.empCustTodal,
  }
}

export default productModule;

