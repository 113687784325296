import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import { header } from '../../config/constant'

Vue.use(Vuex)

const productModule = {
  namespaced: true,
  state: {
    proid:[],
    products: [],
    bills: [],
    cut_Item:0,
    order_item:0
  },
  mutations: {
   setProID(state,id){
    state.proid=id
   },
    setProduct(state, pro) {
      state.products = pro
    },
    setBillSale(state, bill) {
      state.bills = bill
    },
    setCount_CutItem(state, cut_Item) {
      state.cut_Item = cut_Item
    },
    setCount_OrderItem(state, order_item) {
      state.order_item = order_item
    },
  },

  actions: {
    async getBillSale(context, sell_id) {
      try {
        const sell = await axios.get(
          `http://localhost:2021/api/dadabackend/sale/getById/${sell_id}`, {
          headers: header
        }
        );
       // alert(JSON.stringify(sell.data))
        const details = await axios.get(
          `http://localhost:2021/api/dadabackend/sale/detail/getById/${sell_id}`, {
          headers: header
        }
        );
       // alert(JSON.stringify(details.data))
        var productList = [],discount=0
        for (const key in details.data) {
          const element = details.data[key];
          var imglist = []
          const img = await axios.get(
            `http://localhost:2021/api/dadabackend/product/image/getById/${element.pro_id}`, {
            headers: header
          }
          );
          imglist = img.data;
          var products = {
            idx: parseInt(key) + 1,
            pro_name: element.pro_name,
            category: element.category,
            size: element.size,
            qty: element.qty,
            unit: element.unit,
            price: element.price,
            discount:element.discount,
            image:imglist
          };
          discount=details.data[0].discount
          productList.push(products);
        }

        var bills = {
          sell_id: sell.data[0].sell_id,
          emp_name: sell.data[0].emp_name + " " + sell.data[0].emp_surname,
          full_name: sell.data[0].full_name,
          sell_date: moment(String(sell.data[0].sell_date)).format('DD-MM-YYYY'),
          sell_time: sell.data[0].sell_time,
          discount:discount,
          detail: productList
        }
        context.commit('setBillSale', bills)
    //    alert(JSON.stringify(bills))
      } catch (e) {
        alert(JSON.stringify(e));
      }
    },

    async getProduct(context) {
      try {
        var productList = [],id=[]
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/product/get-for-sell`,{
            headers:header
          }
        );
     //   alert(JSON.stringify(response.data))
        for (const key in response.data) {
          const element = response.data[key];
          var imglist = []
              const img = await axios.get(
                `http://localhost:2021/api/dadabackend/product/image/getById/${element.pro_id}`,{
                  headers:header
                }
              );
              imglist = img.data;
          var products =  {
            idx: parseInt(key, 10),
            id: element.pro_id,
            name: element.pro_name,
            category: element.cate_name,
            cate_id: element.cate_id,
            unit: element.unit_name,
            unit_id: element.unit_id,
            color: element.color,
            size: element.size,
            qty: parseInt(element.qty, 10),
            price: element.price,
            status: element.status,
            create_date:  moment(String(element.create_date)).format('YYYY-MM-DD'),
            image: imglist,
            addqty: 0,
            btncolor: "#1B5E20" };
          productList.push(products);
          id.push( element.pro_id)
          
        }
        context.commit('setProID', id)
        context.commit('setProduct', productList)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async searchProduct(context,value) {
      try {
        var productList = [],id=[]
        const response = await axios.post(
          `http://localhost:2021/api/dadabackend/product/search`,{val:value},{
            headers:header
          }
        );
        for (const key in response.data) {
          const element = response.data[key];
          var imglist = []
              const img = await axios.get(
                `http://localhost:2021/api/dadabackend/product/image/getById/${element.pro_id}`,{
                  headers:header
                }
              );
              imglist = img.data;
          var products =  {
            idx: parseInt(key, 10),
            id: element.pro_id,
            name: element.pro_name,
            category: element.cate_name,
            cate_id: element.cate_id,
            unit: element.unit_name,
            unit_id: element.unit_id,
            color: element.color,
            size: element.size,
            qty: parseInt(element.qty, 10),
            price: element.price,
            status: element.status,
            create_date:  moment(String(element.create_date)).format('YYYY-MM-DD'),
            image: imglist,
            addqty: 0,
            btncolor: "#1B5E20" };
          productList.push(products);
          id.push( element.pro_id)
          
        }
        context.commit('setProduct', productList)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async InsertSale(context,sale){
        axios.post(
            `http://localhost:2021/api/dadabackend/sale/insert`,
            sale,{
              headers:header
            }
          ) .then(ressell => {
            if (ressell.data.length === 0) {
              alert("No response data of sell insert ? Try again");
              return;
            }
            for (const idx in sale.detail) {
              axios
                .post(
                  `http://localhost:2021/api/dadabackend/sale/detail/insert`,{
                    sell_id:sale.sell_id,
                    pro_id:sale.detail[idx].pro_id,
                    qty:sale.detail[idx].qty,
                    price:sale.detail[idx].price,
                    discount:sale.detail[idx].discount,
                  },{
                    headers:header
                  }
                )
                .then(ressaled => {
                  if (ressaled.data.length === 0) {
                    alert(
                      "No response data of sell detail insert ? Try again"
                    );
                    return;
                  }
                });
            }
          });
    },
     UpdateProduct(context,pro){
        axios
        .post(
          `http://localhost:2021/api/dadabackend/product/update-qty`,
          pro,{
            headers:header
          }
        )
        .then(respro => {
          if (respro.data.length === 0) {
            alert("No response data of product update ? Try again");
            return;
          }
        });
    },
    async getCountOrderCut(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/cut/getcountcutitem`,{
            headers:header
          }
        );
     // alert(JSON.stringify(response.data[0].cutItem))
        context.commit('setCount_CutItem', response.data[0].cutItem)
      } catch (e) {
        this.errors.push(e);
      }
    },
    async getCountOrderBuy(context) {
      try {
        const response = await axios.get(
          `http://localhost:2021/api/dadabackend/order/buy/getcountorderitem`,{
            headers:header
          }
        );
      alert(JSON.stringify("k"))
        context.commit('setCount_OrderItem', response.data[0].orderItem)
      } catch (e) {
        this.errors.push(e);
      }
    },

  },
  getters: {
    products: state => state.products,
    proid: state =>state.proid,
    bills: state => state.bills,
    cut_Item:state => state.cut_Item,
    order_item:state => state.order_item
  }
}

export default productModule;

