<template>
  <div
    v-if="
      $store.state.authMod.token.status == 'Admin' ||
      $store.state.authMod.token.status == 'admin'
    "
  >
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="20" style="margin-top: 2px; padding-bottom: 10px">
            <v-row no-gutters class="justify-center">
              <v-avatar size="200">
                <v-img
                  :src="fect_Customer_Img(customers.image)"
                  max-height="450"
                  aspect-ratio="1.7"
                  contain
                  v-ripple="{ class: `white--text` }"
                ></v-img>
              </v-avatar>
            </v-row>
            <v-row no-gutters class="justify-center">
              <h2>ຂໍ້ມູນລາຍລະອຽດລູກຄ້າສະມັກຕົວແທນ</h2>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    readonly
                    :value="customers.cus_id"
                    label="ລະຫັດລູກຄ້າ"
                  ></v-text-field
                  ><v-text-field
                    readonly
                    :value="customers.full_name"
                    label="ຊື່ເຕັມ"
                  ></v-text-field>
                  <v-radio-group label="ເພດ" v-model="customers.gender" row>
                    <v-row class="justify-space-around">
                      <v-radio label="ຊາຍ" value="ຊາຍ"></v-radio>
                      <v-radio label="ຍິງ" value="ຍິງ"></v-radio>
                    </v-row>
                  </v-radio-group>
                  <v-divider style="margin-top: -12px"></v-divider>
                  <v-text-field
                    readonly
                    :value="customers.tel"
                    label="ເບີໂທລະສັບ"
                    @keypress="number($event)"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    :value="customers.facebook"
                    label="ແຟ໌ສບຸກ"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    :value="customers.email"
                    label="ອີແມວ"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    :value="customers.village"
                    label="ບ້ານຢູ່ປັດຈຸບັນ"
                  ></v-text-field>

                  <v-text-field
                    readonly
                    :value="customers.district"
                    label="ເມືອງ"
                  ></v-text-field>

                  <v-text-field
                    readonly
                    :value="customers.province"
                    label="ແຂວາງ"
                  ></v-text-field>

                  <v-text-field
                    readonly
                    :value="customers.country"
                    label="ປະເທດ"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    :value="`${String(customers.register_pay).replace(
                      /(.)(?=(\d{3})+$)/g,
                      '$1 '
                    )} ກິບ`"
                    label="ຄ່າສະໝັກ"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    :value="customers.register_date"
                    label="ວັນທີສະໝັກ"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    :value="customers.pay_method"
                    label="ຈ່າຍຜ່ານ"
                  ></v-text-field>
                  <v-radio-group v-model="customers.status" row label="ສະຖານະ">
                    <v-row class="justify-space-around">
                      <v-radio label="Member" value="Member"></v-radio>

                      <v-radio label="Confirm" value="Confirm"></v-radio>
                    </v-row>
                  </v-radio-group>
                  <v-divider style="margin-top: -10px"></v-divider>
                  <h3 class="mt-5 mb-4">ຮູບອ້າງອິງການຈ່າຍຄ່າສະມັກ</h3>
                  <v-img
                    :src="fectImg(customers.card_capture)"
                    max-height="450"
                    aspect-ratio="1.7"
                    contain
                    v-ripple="{ class: `white--text` }"
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-5">
              <v-btn @click="back" class="ma-2 deep-purple lighten-4" style="font-size: 20px; font-weight: bold" rounded>
                <v-icon left> mdi-arrow-left-circle </v-icon>
                ກັບຄືນ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                @click="Save"
                class="ma-2"
                style="font-size: 20px; font-weight: bold"
                color="#1DE9B6"
                rounded
              >
                <v-icon left dense> mdi-check-circle </v-icon>
                ຢຶນຢັນຮັບຕົວແທນ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    imgUrl: "",
    brow: false,
  }),

  computed: {
    ...mapGetters({
      customers: "customerMod/cust_onemember",
    }),
  },
  mounted() {
    this.getOneCustnewMember(this.$route.params.id);
  },
  methods: {
    ...mapActions("customerMod", ["UpdateCustMember", "getOneCustnewMember"]),
    ...mapActions("commentMod", ["UpdateComment"]),

    fect_Customer_Img(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/customer/" + img);
      } else {
        imageUrl = require("@/assets/Emty_Profile.png");
      }
      return imageUrl;
    },

      fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },

    Save() {
      this.customers.update_date = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
      this.UpdateCustMember(this.customers);
            var cus_status={
        status:"Member"
      }
       localStorage.setItem("customer_status", JSON.stringify(cus_status));
      var comment = {
        id: this.customers.com_id,
        emp_id: this.$store.state.authMod.token.emp_id,
        receive_content: "ສະໝັກເປັນສະມາຊິກສົມບຸນແລ້ວ",
      };
      this.UpdateComment(comment);
      this.back();
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.file-upload {
  margin: 7px 0px;
  font-size: 16px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #999;
}
.file-upload:hover {
  border-bottom: 1px solid #555;
}
::-webkit-file-upload-button {
  background: #eeeeee;
  border-radius: 0px 50px 50px 0px;
  padding: 7px;
  width: 180px;
  border: 1px solid #edffdd;
}
::-webkit-file-upload-button:hover {
  background: #dedede;
}
</style>