
      <template>
  <v-card
    v-if="
      $store.state.authMod.token.status == 'Admin' ||
      $store.state.authMod.token.status == 'admin'
    "
  >
    <v-row justify="center">
      <h2 class="mt-5 mb-5">ຈັດການຂໍ້ມູນລູກຄ້າ</h2>
    </v-row>
    <v-divider></v-divider>
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="12" md="3" align="center">
          <h3>ຂໍ້ມູນລູກຄ້າ</h3>
        </v-col>
        <v-col cols="12" md="9">
          <v-row>
            <v-spacer></v-spacer>
            <v-text-field
            class="mt-4"
              v-model="search"
              append-icon="mdi-magnify"
              label="ຄົ້ນຫາຂໍ້ມູນລູກຄ້າ"
               dense
                  outlined
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <router-link to="/customer_add">
              <v-btn
                class="mb-2 mt-5 mr-3"
                style="font-size: 20px; font-weight: bold"
                color="#ffffff"
                rounded
              >
                <v-icon large> mdi-account-plus </v-icon>
                ເພີ່ມລູກຄ້າ
              </v-btn>
            </router-link>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
     <v-divider></v-divider>
    <v-data-table large
      :headers="headers"
      :items="customers"
      :search="search"
      fixed-header
       class="elevation-2 table"
    >
      <template v-slot:item="{ item }">
        <tr class="center" v-ripple="{ class: `white--text` }">
          <td>{{ item.idx }}</td>
           <td align='center' >
                <v-card class="mx-auto" height="100" width="100">
                  <v-img
                    :src="fectImg(item.image)"
                  ></v-img>
                </v-card>
          </td>
          <td>{{ item.full_name }}</td>
          <td>{{ item.gender }}</td>
          <td>{{ item.tel }}</td>
          <td>{{ item.facebook }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.village }}</td>
          <td>{{ item.district }}</td>
          <td>{{ item.province }}</td>
          <td>{{ item.country }}</td>
          <td>{{ item.status }}</td>
          <td>
            <v-row>
              <v-icon @click="EditCustomer(item)" class="mr-2" color="orange">
                mdi-pencil
              </v-icon>
              <v-icon color="red" @click="DeleteCustomer(item)">
                mdi-delete
              </v-icon>
            </v-row>
          </td>
        </tr>
      </template>
      <template v-slot: item.actions="{ item }">
        <v-row>
          <v-icon @click="EditCustomer(item)" class="mr-2" color="orange">
            mdi-pencil
          </v-icon>
          <v-icon color="error" @click="DeleteCustomer(item)">
            mdi-delete
          </v-icon>
        </v-row>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title style="font-size: 25px; font-weight: bold;font-family:NotoSansLao">ທ່ານຕ້ອງການລຶບຂໍ້ມູນລູກຄ້າຄົນນີ້ບໍ...?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDelete" style="font-size: 20px;font-family:NotoSansLao">ຍົກເລິກ</v-btn>
          <v-btn color="primary" text @click="deleteCustomerConfirm" style="font-size: 20px;font-family:NotoSansLao"
            >ຢຶນຢັນລຶບ</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialogDelete: false,
      deleteItem: [],
      search: "",
      headers: [
          { text: "ລໍາດັບ", value: "idx" },
        {
          text: "ຮູບ",
          align: "start",
          sortable: false,
          value: "image",
        },
        { text: "ຊື່ລູກຄ້າ", value: "full_name" },
        { text: "ເພດ", value: "gender" },
        { text: "ເບີໂທລະສັບ", value: "tel" },
        { text: "ເຟສບຸກ", value: "facebook" },
        { text: "ອີແມວ", value: "email" },
        { text: "ບ້ານ", value: "village" },
        { text: "ເມືອງ", value: "district" },
        { text: "ແຂວງ", value: "province" },
        { text: "ປະເທດ", value: "country" },
        { text: "ສະຖານະ", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      customers: "customerMod/customers",
    }),
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.getCustomer();
  },
  created() {},
  methods: {
    ...mapActions("customerMod", ["getCustomer", "delCustomer"]),

    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/customer/" + img);
      } else {
        imageUrl = require("@/assets/Emty_Profile.png");
      }
      return imageUrl;
    },
    DeleteCustomer(item) {
      this.dialogDelete = true;
      this.deleteItem = item;
    },
    deleteCustomerConfirm() {
      this.delCustomer(this.deleteItem);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    EditCustomer(item) {
      this.$router.push(`/customer_edite/${item.cus_id}`);
    },
  },
};
</script>
<style scoped>
.table {
  border-radius: 3px;
  background-clip: border-box;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
}
</style>