<template>
  <div class="mb-10">
    <v-card flat tile color="#ffffff">
      <v-row class="mb-10">
        <v-menu
          offset-y
          close-on-content-click
          open-on-hover
          internal-activator
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" class="mt-10 ml-10" id="css">
              <v-icon large>mdi-sort-variant</v-icon>
              ປະເພດສິນຄ້າ
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(itm, index) in Category" :key="index" link>
              <v-row>
                <v-list-item-title
                  id="css"
                  @click="SelectedCategory(itm.cate_name)"
                >
                  {{ itm.cate_name }}
                </v-list-item-title>
              </v-row>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y close-on-content-click open-on-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" class="mt-10 ml-10" id="css">
              <v-icon large>mdi-sort-variant</v-icon>
              ຈັດລຽງສິນຄ້າ
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index" link>
              <v-list-item-title id="css" @click="SelectedMenu(item.title)">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <!-- <h1 id="csss" class="mb-10 mt-7 ml-15">ຄົ້ນຫາ</h1> -->
        <v-row align="center" justify="space-around">
          <v-text-field
            class="mt-9 ml-15 mr-15"
            dense
            outlined
            hide-details
            v-model="txtsearch"
            placeholder="ຄົ້ນຫາຂໍ້ມູນສິນຄ້າ"
            id="txtsearch"
            @keyup.enter="SearchData"
          >
            <template slot="append">
              <v-btn style="margin-top: -7px; margin-right: -12px" text>
                <v-icon large @click="SearchData">mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-row>
      </v-row>

      <v-row v-if="$store.state.productList.length > 0" class="mr-1 ml-1">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="(pro, idx) in productList"
          :key="idx"
        >
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 15 : 5" :class="{ 'on-hover': hover }">
              <v-carousel
                cycle
                height="400"
                show-arrows-on-hover
                hide-delimiter-background
              >
                <v-carousel-item v-for="(img, i) in pro.Image" :key="i">
                  <v-card
                    class="mx-auto"
                    height="100%"
                    aspect-ratio="1.7"
                    :img="fectImg(img.img_url)"
                    @click="ViewDetail(pro.proID)"
                  >
                  </v-card>
                </v-carousel-item>
              </v-carousel>
              <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                style="font-family: Noto Sans Lao"
              >
                {{ text }} {{ pro.quantity }} {{ "ລາຍການເຂົ້າໃນກະຕ່າແລ້ວ" }}
                <template v-slot:action="{ attrs }">
                  <router-link to="ProductInCart">
                    <v-btn
                      style="font-family: Noto Sans Lao"
                      width="150"
                      color="blue"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      ເບີ່ງສິນຄ້າໃນກະຕ່າ
                    </v-btn>
                  </router-link>
                </template>
              </v-snackbar>

              <v-card-actions class="white justify-center">
                <v-col>
                  <h1 id="name">ຊື່ສິນຄ້າ: {{ pro.proName }}</h1>
                  <h1 id="name">ເບີ້/ຂະໜາດ: {{ pro.Size }}</h1>
                  <h1 id="name">
                    ລາຄາ:
                    {{ String(pro.Price).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }}
                    K
                  </h1>
                  <v-row align="center" justify="space-around">
                    <v-text-field
                      class="mt-10 ml-15 mr-15 text-center tcenter"
                      filled
                      dense
                      rounded
                      prepend-inner-icon="mdi-minus"
                      append-icon="mdi-plus"
                      @keypress="isNumber($event)"
                      @keyup="CheckNumber(pro)"
                      v-model="pro.quantity"
                    >
                      <template slot="append">
                        <v-btn
                          style="margin-top: -7px; margin-right: -20px"
                          color="green darken-4"
                          icon
                        >
                          <v-icon large @click="IncrementQty(pro)"
                            >mdi-plus</v-icon
                          >
                        </v-btn>
                      </template>
                      <template slot="prepend-inner">
                        <v-btn
                          style="margin-top: -7px; margin-left: -20px"
                          color="green darken-4"
                          icon
                        >
                          <v-icon large @click="DecrementQty(pro)"
                            >mdi-minus</v-icon
                          >
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-row>
                  <v-row justify="end">
                    <v-row justify="start">
                      <v-btn
                        rounded
                        color="#ffffff"
                        id="btnAdd"
                        class="mt-3 ml-4"
                        @click="ViewDetail(pro.proID)"
                      >
                        <v-icon> mdi-eye-settings </v-icon>
                      </v-btn>
                    </v-row>
                    <v-btn
                      rounded
                      color="#ffffff"
                      id="btnAdd"
                      justify-space-around
                      @click="
                        AddToCart(
                          pro.proID,
                          pro.proName,
                          pro.quantity,
                          pro.Price,
                          pro.Image
                        )
                      "
                    >
                      <v-icon> mdi-cart-plus </v-icon>
                      ເພີ່ມໃສ່ກະຕ່າ
                    </v-btn>
                  </v-row>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-card v-else height="500" class="elevation-0">
        <v-row justify="center">
          <h1 id="myfont" class="mt-16">ບໍ່ມີຂໍ້ມູນ</h1></v-row
        >
      </v-card>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    snackbar: false,
    text: "ທ່ານໄດ້ເພີ່ມສິນຄ້າ",
    timeout: 1000,
    items: [
      { title: "ສິນຄ້າໃໝ່-ເກົ່າ" },
      { title: "ສິນຄ້າເກົ່າ-ໃໝ່" },
      { title: "ສິນຄ້າຍອດນິຍົມ" },
    ],
    title: "ສິນຄ້າໃໝ່-ເກົ່າ",
  }),
  mounted() {
    this.$store.dispatch("GetProductList");
    this.$store.dispatch("GetCategory");
  },
  computed: {
    productList() {
      // alert(JSON.stringify(this.$store.state.products));
      return this.$store.state.productList;
    },
    Category() {
      return this.$store.state.Category;
    },
  },
  methods: {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ViewDetail(proID) {
      if (
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id != undefined
      ) {
        this.$router.push(`/Customer/ProductDetail/${proID}`);
      } else {
        this.$router.push(`/ProductDetail/${proID}`);
        // this.$store.state.productID = proID;
      }
    },

    AddToCart(proID, proName, quantity, price, Image) {
      if (
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == undefined ||
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == null ||
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == ""
      ) {
        this.$router.push('/Customer_Login')
        return
      }
      this.$store.dispatch("AddProductToCart", {
        proID,
        proName,
        quantity,
        price,
        total: price * quantity,
        Image,
      });
      // alert(JSON.stringify(Image));
    },

    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/images/D (32).jpg");
      }
      return imageUrl;
    },

    minus(pro) {
      if (pro.addqty <= 1) return;
      pro.addqty--;
    },
    isNumber: function ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    CheckNumber(pro) {
      if (
        (typeof pro.quantity == "string" && pro.quantity == "") ||
        pro.quantity == null ||
        pro.quantity < 1
      ) {
        pro.quantity = 1;
      }
      pro.quantity = parseInt(pro.quantity, 10);
    },
    DecrementQty(pro) {
      if (pro.quantity <= 1) return;
      pro.quantity--;
    },
    IncrementQty(pro) {
      pro.quantity++;
    },
    SelectedMenu(title) {
      // alert(JSON.stringify(title));
      if (title == "ສິນຄ້າເກົ່າ-ໃໝ່") {
        this.$store.dispatch("ProductOldNew");
      } else if (title == "ສິນຄ້າໃໝ່-ເກົ່າ") {
        this.$store.dispatch("GetProductList");
      } else if (title == "ສິນຄ້າຍອດນິຍົມ") {
        this.$store.dispatch("GetPopular_Products");
      }
    },

    SelectedCategory(category) {
      this.$store.dispatch("CategorySelected", category);
    },

    SearchData() {
      if (this.txtsearch == "" || this.txtsearch == null) {
        this.$store.dispatch("GetProductList");
        return;
      }
      this.$store.dispatch("SearchData", this.txtsearch);
    },
  },
};
</script>

<style>
#css {
  font-size: 20px;
  font-family: "Noto Sans Lao";
  text-align: left;
  margin-left: 7px;
  margin-top: 7px;
  color: black;
}
#name {
  font-size: 20px;
  font-family: "Noto Sans Lao";
  text-align: left;
  margin-left: 7px;
  margin-top: 7px;
  color: black;
}
#txtsearch {
  font-size: 20px;
  font-family: "Noto Sans Lao";
  text-align: left;
  margin-left: 7px;
  color: black;
}
#btnAdd {
  font-size: 18px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: black;
}
</style>
<style scoped>
.tcenter >>> input {
  text-align: center;
}
</style>