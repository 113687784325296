<template>
  <div>
    <v-card
      cols="12"
      sm="4"
      class="elevation-0 mb-7"
      v-for="(shop_info, idx) in Shop_Info"
      :key="idx"
    >
      <v-row justify="center" class="mt-3 mb-3">
        <h1 id="myfont">ສັ່ງຊື້ສິນຄ້າ</h1>
      </v-row>
      <v-stepper v-model="e1" class="elevation-0">
        <v-stepper-header
          style="font-family: Noto Sans Lao"
          class="elevation-0"
        >
          <v-stepper-step :complete="e1 > 1" step="1" @click="e1 = 1">
            ຂັ້ນຕອນທີ່ 1
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2" @click="e1 = 2">
            ຂັ້ນຕອນທີ່ 2
          </v-stepper-step>
        </v-stepper-header>
        <v-progress-linear color="black" value="100" height="2px">
        </v-progress-linear>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-simple-table fixed-header>
              <template>
                <thead>
                  <tr id="tbheader">
                    <th class="text-left" width="10%">ລໍາດັບ</th>
                    <th class="text-left" width="12%">ຮູບ</th>
                    <th class="text-left" width="35%">ລາຍການສິນຄ້າ</th>
                    <th class="text-left" width="17%">ຈໍານວນ</th>
                    <th class="text-left" width="10%">ລາຄາ</th>
                    <th class="text-left" width="10%">ລວມ</th>
                    <th class="text-left" width="10%">ລຶບ</th>
                  </tr>
                </thead>
                <tbody style="font-family: Noto Sans Lao; font-size: 20px">
                  <tr v-for="(item, index) in ProductCart" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <v-carousel
                        hide-delimiter-background
                        height="100"
                        :show-arrows="false"
                      >
                        <v-carousel-item
                          v-for="(img, i) in item.Image"
                          :key="i"
                        >
                          <v-card
                            class="mx-auto"
                            height="100%"
                            aspect-ratio="1.7"
                            :img="fectImg(img.img_url)"
                          >
                          </v-card>
                        </v-carousel-item>
                      </v-carousel>
                    </td>
                    <td>{{ item.proName }}</td>
                    <td>
                      <v-text-field
                        width="10px"
                        class="mt-7 text-center tcenter"
                        filled
                        flat
                        dense
                        rounded
                        prepend-inner-icon="mdi-minus"
                        append-icon="mdi-plus"
                        v-model="item.quantity"
                        @keypress="isNumber($event, item)"
                        @keyup="CheckNumber(item)"
                      >
                        <template slot="append">
                          <v-btn
                            style="margin-top: -7px; margin-right: -20px"
                            color="green darken-4"
                            icon
                            @click="IncrementQty(item)"
                          >
                            <v-icon large>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <template slot="prepend-inner">
                          <v-btn
                            style="margin-top: -7px; margin-left: -20px"
                            color="green darken-4"
                            icon
                            @click="SuttrackQtyInCart(item)"
                          >
                            <v-icon large>mdi-minus</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </td>
                    <td>
                      {{
                        String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                      }}
                    </td>
                    <td>
                      {{
                        String(item.total).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                      }}
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="grey darken-4"
                        @click.prevent="DeleteCurrentItem(item.proID)"
                      >
                        <v-icon large>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-progress-linear
              color="black"
              value="100"
              height="1px"
            ></v-progress-linear>

            <v-col class="mr-7 mt-8">
              <v-row justify="end">
                <h1 id="cssstyle" class="text-right mt-4">ລວມ:</h1>
                <h1 class="text-left mt-4">
                  {{ String(TotalPay).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }}
                </h1>
                <h1 id="cssstyle" class="text-center mt-4 ml-5">ກິບ</h1>
              </v-row>
              <v-row justify="end">
                <h1 id="cssstyle" class="text-right mt-4">ສ່ວນຫຼຸດ:</h1>
                <h1 class="text-left mt-4">{{ Get_Discount_Percent }}</h1>
                <h1 id="cssstyle" class="text-center mt-4 ml-5">ເປີເຊັນ(%)</h1>
              </v-row>
              <v-row justify="end">
                <h1 id="cssstyle" class="text-right mt-4">ເງີນທີ່ຕ້ອງຈ່າຍ:</h1>
                <h1 class="text-left mt-4">
                  {{
                    String(Caculate_Discount).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1 "
                    )
                  }}
                </h1>
                <h1 id="cssstyle" class="text-center mt-4 ml-5">ກິບ</h1>
              </v-row>
            </v-col>

            <v-row>
              <v-row justify="start">
                <router-link to="/Customer/Product">
                  <v-btn
                    text
                    id="btntext"
                    class="mt-16 ml-15"
                    color="grey darken-4"
                  >
                    <v-icon class="mr-2"> mdi-plus </v-icon>
                    ເລຶອກສິນຄ້າເພີ່ມ
                  </v-btn>
                </router-link>
              </v-row>
              <v-row justify="end">
                <v-btn
                  id="btntext"
                  class="mt-16 mb-16 mr-10"
                  color="error"
                  @click="ClearCart"
                >
                  ຍົກເລິກທັງໝົດ
                </v-btn>
                <v-btn
                  id="btntext"
                  class="mt-16 mb-16 mr-10"
                  color="primary"
                  @click="GoToStep2"
                >
                  ດໍາເນີນການສັ່ງຊື້
                </v-btn>
              </v-row>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-row justify="center">
              <h1 class="ml-9 mt-7 mb-7" id="myfont">*ຊໍາລະເງີນຄ່າສິນຄ້າ</h1>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="6" md="5">
                <h1
                  class="ml-5 mt-8 mb-3"
                  style="
                    font-size: 25px;
                    font-weight: bold;
                    font-family: NotoSansLao;
                  "
                >
                  *ຊໍາລະຜ່ານບັນຊີທະນາຄານການຄ້າຕ່າງປະເທດ
                </h1>
                <v-img
                  class="ml-9"
                  height="120"
                  width="120"
                  src="@/assets/images/bcelone.png"
                ></v-img>
                <v-row>
                  <h1 class="ml-7 mt-7" id="myfont">ເລກບັນຊີ:</h1>
                  <h1
                    class="ml-7 mt-7"
                    style="
                      font-size: 25px;
                      font-weight: bold;
                      font-family: NotoSansLao;
                    "
                  >
                    {{ shop_info.bank_account_no }}
                  </h1>
                </v-row>
                <v-row>
                  <h1 class="ml-7 mt-7" id="myfont">ຊື່ບັນຊີ:</h1>
                  <h1
                    class="ml-7 mt-7"
                    style="
                      font-size: 25px;
                      font-weight: bold;
                      font-family: NotoSansLao;
                    "
                  >
                    {{ shop_info.bank_account_name }}
                  </h1>
                </v-row>
                <v-row>
                  <h1 class="ml-7 mt-7" id="myfont">ປະເພດບັນຊີ:</h1>
                  <h1
                    class="ml-7 mt-7 mb-7"
                    style="
                      font-size: 25px;
                      font-weight: bold;
                      font-family: NotoSansLao;
                    "
                  >
                    Saving
                  </h1>
                </v-row>
                <h1 class="ml-7" id="myfont">*QR Code</h1>
                <v-img
                  class="ml-9 mb-8"
                  height="300"
                  width="300"
                  src="@/assets/images/houa.png"
                ></v-img>
                <h2
                  class="ml-5"
                  style="
                    font-size: 25px;
                    font-weight: bold;
                    font-family: NotoSansLao;
                  "
                >
                  *ຊໍາລະຜ່ານ Paypal
                </h2>
                <h1
                  class="ml-5"
                  style="
                    font-size: 45px;
                    font-weight: bold;
                    font-family: NotoSansLao;
                    color: #2962ff;
                  "
                >
                  PayPal
                </h1>
                <v-row>
                  <h1 class="ml-7 mt-7" id="myfont">ອີແມວ:</h1>
                  <h1
                    class="ml-8 mt-7"
                    style="
                      font-size: 25px;
                      font-weight: bold;
                      font-family: NotoSansLao;
                    "
                  >
                    {{ shop_info.paypal_email }}
                  </h1>
                </v-row>
                <v-col class="mt-3">
                  <a
                    class="mt-13"
                    href="https://www.paypal.com/us/signin"
                    target="brank"
                    style="
                      font-size: 20px;
                      font-weight: bold;
                      font-family: NotoSansLao;
                      text-decoration: underline;
                      color: black;
                    "
                    >ເຂົ້າສູ່ລະບົບ PayPal</a
                  >
                </v-col>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <h1 class="mt-9 mr-7 ml-9" id="myfont">
                  *ຈໍານວນເງີນທີ່ຕ້ອງຊໍາລະ
                </h1>
                <v-row>
                  <v-text-field
                    id="textfield"
                    class="mt-5 ml-12 mr-5"
                    outlined
                    readonly
                    style="font-size: 25px; font-weight: bold"
                    :value="
                      String(Caculate_Discount).replace(
                        /(.)(?=(\d{3})+$)/g,
                        '$1 '
                      )
                    "
                  ></v-text-field>
                  <h1 class="mt-9 mr-5" id="myfont">ກິບ</h1>
                </v-row>
                <v-divider class="ml-7 mt-10 mb-4"></v-divider>
                <h1 class="ml-9" id="myfont">ຮູບແບບການຈ່າຍເງີນ*</h1>
                <v-row dense justify="start" class="ml-8">
                  <v-radio-group
                    required
                    :error-messages="Pay_MethodError"
                    @change="$v.pay_method.$touch()"
                    @blur="$v.pay_method.$touch()"
                    v-model="pay_method"
                    row
                  >
                    <v-col>
                      <v-radio
                        label="ຈ່າຍຜ່ານ Bcel One"
                        value="ຈ່າຍຜ່ານ Bcel One"
                        class="radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍຜ່ານ Paypal"
                        value="ຈ່າຍຜ່ານ Paypal"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍຜ່ານ Western Union"
                        value="ຈ່າຍຜ່ານ Western Union"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍຜ່ານ MoneyGram"
                        value="ຈ່າຍຜ່ານ MoneyGram"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍປາຍທາງ"
                        value="ຈ່າຍປາຍທາງ"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍຮູບແບບອື່ນໆ"
                        value="ຈ່າຍຮູບແບບອື່ນໆ"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-text-field
                        v-if="pay_method == 'ຈ່າຍຮູບແບບອື່ນໆ'"
                        id="textfield"
                        class="mt-5 mr-10"
                        outlined
                        placeholder="Pay Method"
                        :error-messages="Pay_ByError"
                        @change="$v.Pay_By.$touch()"
                        @blur="$v.Pay_By.$touch()"
                        v-model="Pay_By"
                      ></v-text-field>
                    </v-col>
                  </v-radio-group>
                </v-row>
                <h1 class="mr-7 ml-9" id="myfont">ຮູບອ້າງອິງການຈ່າຍເງີນ*</h1>
                <input
                  prepend-icon="mdi-camera"
                  type="file"
                  class="file-upload ml-7 mt-5"
                  @change="onFileSelected"
                  show-size
                  label="Image input"
                  required
                />
                <v-divider class="ml-7"></v-divider>
                <v-img
                  :src="url"
                  v-show="Img_Card"
                  max-height="450"
                  aspect-ratio="1.7"
                  contain
                ></v-img>
                <h1 class="mt-9 mr-7 ml-9" id="myfont">ລາຍລະອຽດເພີ່ມເຕີມ*</h1>
                <v-textarea
                  id="textfield"
                  class="mt-5 ml-7"
                  outlined
                  placeholder="Wrtie Some Detail"
                  name="input-7-4"
                  v-model="txtdetail"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-btn
                id="btntext"
                class="mt-16 mb-16 mr-10"
                color="error"
                @click="e1 = 1"
              >
                ກັບຄືນ
              </v-btn>
              <v-btn
                id="btntext"
                color="#01BEFE"
                @click="Submit"
                class="mt-16 mb-16 mr-10"
              >
                ຢຶນຢັນ
              </v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-snackbar color="#004D40" centered v-model="snackbar" :timeout='10000'>
          <v-row no-gutters class="mx-2 my-2">
            <v-icon color="#ffffff" size="60">mdi-check-bold</v-icon>
            <p style="font-size:2rem;font-family:Noto Sans Lao; margin-top:20px">ສັ່ງຊື້ສິນຄ້າສໍາເລັດ</p>
          </v-row>
          <v-row no-gutters class="mx-2 justify-end">
            <v-btn text style="font-family:Noto Sans Lao;font-size:20px" @click="snackbar = false">
            <v-icon color="error" left large>mdi-close</v-icon>ປິດ</v-btn>
          </v-row>
      </v-snackbar>
    </v-card>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    Pay_By: { required },
    pay_method: { required },
  },
  data() {
    return {
      e1: 1,
      url: null,
      profile: "",
      pay_method: "",
      Pay_By: "",
      snackbar:false
    };
  },
  mounted() {
    this.$store.dispatch("Shop_Info");
    // this.$store.dispatch("GetCart_Items");
  },
  computed: {
    ProductCart() {
      return this.$store.state.ProductCart;
    },
    TotalPay() {
      return this.$store.getters.TotalPay;
    },
    Shop_Info() {
      return this.$store.state.Shop_Info;
    },

    Caculate_Discount() {
      let Sum = 0,
        LastSum = 0;
      this.ProductCart.forEach((item) => {
        Sum += item.total;
      });
      if (
        JSON.parse(localStorage.getItem("customer_status")).status == "Member"
      ) {
        this.Shop_Info.forEach((itm) => {
          LastSum = Sum - (Sum * itm.discount) / 100;
        });
      } else {
        LastSum = Sum;
      }
      return LastSum;
    },

    Get_Discount_Percent() {
      let discount = 0;
      if (
        JSON.parse(localStorage.getItem("customer_status")).status == "Member"
      ) {
        this.Shop_Info.forEach((itm) => {
          discount = itm.discount;
        });
      } else {
        discount = 0;
      }
      return discount;
    },

    Pay_ByError() {
      const errors = [];
      if (!this.$v.Pay_By.$dirty) return errors;
      !this.$v.Pay_By.required && errors.push("Pay Method is required.");
      return errors;
    },
    Pay_MethodError() {
      const errors = [];
      if (!this.$v.pay_method.$dirty) return errors;
      !this.$v.pay_method.required && errors.push("");
      return errors;
    },
  },
  methods: {
    isNumber: function ($event, item) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      } else {
        if (
          (typeof item.quantity == "string" && item.quantity == "") ||
          item.quantity == null ||
          item.quantity == 0
        ) {
          item.quantity = 1;
          item.total = item.quantity * item.price;
        }
        item.quantity = parseInt(item.quantity, 10);
        item.total = item.quantity * item.price;
        return true;
      }
    },
    CheckNumber(item) {
      if (
        (typeof item.quantity == "string" && item.quantity == "") ||
        item.quantity == null ||
        item.quantity == 0
      ) {
        item.quantity = 1;
        item.total = item.quantity * item.price;
      }
      item.quantity = parseInt(item.quantity, 10);
      item.total = item.quantity * item.price;
    },
    DeleteCurrentItem(proID) {
      if (confirm("ຕ້ອງການລຶບລາຍການນີ້ບໍ ?"))
        this.$store.dispatch("DeleteCurrentItem", proID);
      if (this.$store.getters.CountOrderQty == 0) {
        this.$router.push(`/Customer/Product`);
      }
    },
    ClearCart() {
      if (this.$store.getters.CountOrderQty <= 0) {
        return;
      }
      if (confirm("ຕ້ອງການຍົກເລິກລາຍການສິນຄ້າທັງໝົດໃນກະຕ່າບໍ ?"))
        this.$store.dispatch("Clear_Cart_List");
      if (this.$store.getters.CountOrderQty == 0) {
        this.$router.push(`/Customer/Product`);
      }
    },
    SuttrackQtyInCart(item) {
      if (item.quantity <= 1) return;
      item.quantity--;
      item.total = item.quantity * item.price;
    },
    IncrementQty(item) {
      item.quantity++;
      item.total = item.quantity * item.price;
    },
    Submit() {
      //alert(JSON.stringify(this.Get_Discount_Percent()));
      if (this.$store.getters.CountOrderQty <= 0) {
        alert(JSON.stringify("No Product In Cart"));
        return;
      }
      if (this.pay_method == "") {
        this.$v.$touch();
        return;
      }
      if (this.pay_method == "ຈ່າຍຮູບແບບອື່ນໆ" && this.Pay_By == "") {
        this.$v.$touch();
        return;
      }
      if (this.pay_method == "ຈ່າຍຮູບແບບອື່ນໆ") {
        this.pay_method = this.Pay_By;
      }
      if (this.txtdetail == "" || this.txtdetail == null) {
        this.txtdetail = "ຕ້ອງການສັ່ງຊື້ສິນຄ້າ.....";
      }

      var datenow = new Date().toJSON().slice(0, 10).replace(/-/g, "");
      var timenow = new Date()
        .toTimeString()
        .replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1", "")
        .replace(":", "")
        .replace(":", "");
      var order_id = "DOB" + datenow.slice(2, datenow.length) + timenow;

      var discount = "";
      for (const key in this.Shop_Info) {
        const element = this.Shop_Info[key];
        if (
          JSON.parse(localStorage.getItem("customer_status")).status == "Member"
        ) {
          discount = element.discount;
        } else {
          discount = 0;
        }
      }
// console.log(discount+order_id) 
      this.$store.dispatch("Submit_Order", {
        order_id: order_id,
        cus_id: JSON.parse(localStorage.getItem("Customer_Info")).cus_id,
        order_date: datenow,
        order_time: timenow,
        status: "Confirm",
        pay_method: this.pay_method,
        card_image: this.Img_Card,
        discount: discount,
      });
      this.$store.dispatch("Submit_Comment", {
        cus_id: JSON.parse(localStorage.getItem("Customer_Info")).cus_id,
        coment_id: order_id,
        customer_content: this.txtdetail,
      });
     this.snackbar=true
     this.$router.push(`/Customer/OrderBuy_History`);
    },
    GoToStep2() {
      if (this.$store.getters.CountOrderQty <= 0) {
        return;
      }
      this.e1 = 2;
    },
    onFileSelected(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.url = e.target.result;
      };
      this.Img_Card = event.target.files[0].name;
      // alert(JSON.stringify(this.url));
    },
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/images/D (32).jpg");
      }
      return imageUrl;
    },
  },
};
</script>

<style>
#cssstyle {
  font-size: 30px;
  font-family: "Noto Sans Lao";
  text-align: left;
  margin-right: 30px;
  margin-top: 7px;
  color: black;
  font-weight: normal;
}
#myfont {
  font-size: 25px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: black;
}
#dialog {
  font-size: 25px;
  font-family: "Noto Sans Lao";
  font-weight: bold;
  align-content: center;
  margin-left: 135px;
  margin-top: 10px;
  color: black;
}
#btntext {
  font-size: 18px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: white;
}
#tbheader {
  height: 10px;
  font-size: 50px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: black;
}
.radio {
  font-size: 40px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: black;
}
</style>
<style scoped>
.tcenter >>> input {
  text-align: center;
}
</style>