
      <template>
  <v-card
    v-if="($store.state.authMod.token.status=='Admin') || ($store.state.authMod.token.status=='admin')"
    class="elevation-1"
  >
    <v-row justify="center">
      <h2 class="mt-7 mb-5">ຮັບຕົວແທນ ຫຼື ຮັບສະມາຊິກ</h2>
    </v-row>
        <v-divider></v-divider>
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="12" md="3" align="center">
          <h3>ຂໍ້ມູນລູກຄ້າທີ່ສະມັກເປັນຕົວແທນ</h3>
        </v-col>
        <v-col cols="12" md="9">
          <v-row>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field
            class="mt-3"
              v-model="search"
              append-icon="mdi-magnify"
              label="ຄົ້ນຫາ"
              single-line
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      class="table"
      large
      :headers="headers"
      :items="newmember"
      :search="search"
      fixed-header
    >
      <template v-slot:item="{ item }">
        <tr class="member" v-ripple="{ class: `white--text` }">
             <td>{{ item.idx }}</td>
          <td class="align-baseline justify-start">
            <v-card class="mx-auto" height="100" width="100">
              <v-img :src="fectImg(item.image)"></v-img>
            </v-card>
          </td>
          <td>{{ item.full_name }}</td>
          <td>{{ item.gender }}</td>
          <td>{{ item.tel }}</td>
          <td>{{ item.facebook }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.country }}</td>
          <td>{{ String(item.register_pay).replace(/(.)(?=(\d{3})+$)/g,'$1,')}}</td>
          <td>{{ item.pay_method }}</td>
          <td>
            <v-row>
              <v-icon @click="EditCustomer(item)" class="mr-4" color="error" large>mdi-account-check</v-icon>
              <v-icon color="#1B5E20" @click="MoreInfo(item)" large>mdi-eye-settings</v-icon>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title style="
                    font-size: 23px;
                    font-weight: bold;
                    font-family: NotoSansLao;
                  ">ທ່ານຕ້ອງການຮັບລູກຄ້າຄົນນີ້ເປັນຕົວແທນແທ້ບໍ...?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="close" style="font-size: 20px; font-family: NotoSansLao">ຍົກເລິກ</v-btn>
          <v-btn color="primary" text @click="CustomerConfirm" style="font-size: 20px; font-family: NotoSansLao">ຢຶນຢັນຮັບ</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      Item: [],
      search: "",
      headers: [
         { text: "ລໍາດັບ", value: "idx" },
        {
          text: "ຮູບ",
          align: "center",
          sortable: false,
          value: "image"
        },
        { text: "ຊື່ລູກຄ້າ", value: "full_name" },
        { text: "ເພດ", value: "gender" },
        { text: "ເບີໂທລະສັບ", value: "tel" },
        { text: "ເຟສບຸກ", value: "facebook" },
        { text: "ອີແມວ", value: "email" },
        { text: "ປະເທດ", value: "country" },
        { text: "ຄ່າສະໝັກ", value: "register_pay" },
        { text: "ຈ່າຍຜ່ານ", value: "pay_method" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    ...mapGetters({
      newmember: "customerMod/cust_newmember"
    })
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  mounted() {
    this.getCustnewMember();
  },
  created() {},
  methods: {
    ...mapActions("customerMod", ["UpdateCustMember", "getCustnewMember"]),
    ...mapActions("commentMod", ["UpdateComment"]),
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/customer/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
    CustomerConfirm() {
      this.Item.update_date = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
      this.UpdateCustMember(this.Item);
      var cus_status={
        status:"Member"
      }
       localStorage.setItem("customer_status", JSON.stringify(cus_status));
      var comment = {
        id: this.Item.com_id,
        emp_id: this.$store.state.authMod.token.emp_id,
        receive_content: "ສະໝັກເປັນສະມາຊິກສົມບຸນແລ້ວ"
      };
      this.UpdateComment(comment);
      this.close();
    },

    close() {
      this.dialog = false;
    },
    EditCustomer(item) {
      item.emp_id = this.$store.state.authMod.token.emp_id;
      item.status = "Member";
      this.Item = item;
      this.dialog = true;
    },
    MoreInfo(item) {
      this.$router.push(`/recieve_customer_more/${item.cus_id}`);
    }
  }
};
</script>
<style scoped>
.table {
  border-radius: 3px;
  background-clip: border-box;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
}
</style>