<template>
  <v-app class="font">
    <v-card class="ma-2 elevation-0">
      <v-row>
        <div class="d-flex mt-9">
          <v-avatar size="100" color="#fafafafa" class="pa-2">
            <v-img src="@/assets/images/DaDa_LoGo.png" />
          </v-avatar>
        </div>
        <v-col cols="9">
          <v-row no-gutters class="justify-center mt-5">
            <p style="font-size: 1.2rem">ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</p>
          </v-row>
          <v-row no-gutters class="justify-center">
            <p style="font-size: 1.2rem">
              ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດຖະນາຖາວອນ
            </p>
          </v-row>
          <v-row no-gutters class="justify-center mt-3">
            <p style="font-size: 2rem; text-decoration: bold">
              ໃບສັ່ງຕັດສິນຄ້າ
            </p>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class>
        <v-col cols="6">
          <p style="font-size: 1.4rem">ຮ້ານ ດາດ້າເເຟຊັນ</p>
          <p>ໂທລະສັບ: 020 92602326</p>

          <p>ອີເມວ: houyang0087@gmail.com</p>
          <p>ຕັ້ງຢູ່: ບ້ານ ຫົວຂົວ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ</p>
        </v-col>
        <v-col cols="6" class="align-end align-content-end">
          <v-row class="justify-end">
            <v-card class="mr-5 elevation-0">
              <p>ບິນເລກທີ່: {{ bills_order_cut_product[0].OrderID }}</p>
              <p>ວັນທີ່ສັ່ງຊື້: {{ bills_order_cut_product[0].OrderDate }}</p>
              <p>ເວລາສັ່ງຊື້: {{ bills_order_cut_product[0].order_cut_time }}</p>
              <p>ຜູ້ສັ່ງຊື້: {{CusName}}</p>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class>
        <v-progress-linear value="100" height="1"></v-progress-linear>
      </v-row>
      <v-row no-gutters class>
        <v-card width="100%" class="mt-2 elevation-0">
          <v-data-table
            :headers="headers"
            :items="bills_order_cut_product[0].details"
            :search="search"
            item-key="No"
            sort-by="No"
            class="elevation-0"
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr v-ripple="{ class: `white--text` }">
                <td>{{ item.No }}</td>
                <td>
                      <v-card height="100%" width="100">
                        <v-img :src="fectImg(item.image)"></v-img>
                      </v-card>
                </td>
                <td>{{ item.pro_name }}</td>
                <td>{{ item.size }}</td>
                <td>
                  {{ String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1,") }}
                </td>
                <td>
                  {{ String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1,") }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-divider class></v-divider>
        </v-card>
      </v-row>
      <v-progress-linear value="100" height="1"></v-progress-linear>
      <v-row no-gutters class="mt-2">
        <v-col cols="4">
          <v-row no-gutters class="justify-center">
            <div>
            </div>
          </v-row>
        </v-col>
        <v-col cols="8">
          <v-row no-gutters class="mr-5 justify-end">
            <div>
              <v-row no-gutters class="justify-center">
                <v-row no-gutters class="justify-end">
                  <p style="margin-top: 10px; margin-right: 10px">
                    ລວມເປັນມູນຄ່າ: {{bills_order_cut_product[0].total}}
                  </p>
                </v-row>
                <v-row no-gutters class="justify-start">
                  <p style="margin-top: 10px">
              
                    ກິບ
                  </p>
                </v-row>
              </v-row>
              <v-row no-gutters class="justify-center">
                <v-row no-gutters class="justify-end">
                  <p style="margin-top: 10px; margin-right: 10px">
                    ຄ່າມັດຈໍາ: {{bills_order_cut_product[0].money_pay}}
                  </p>
                </v-row>
                <v-row no-gutters class="justify-start">
                  <p style="margin-top: 10px">
              
                    ກິບ
                  </p>
                </v-row>
              </v-row>

              <v-row no-gutters class="justify-center">
                <v-col cols="6">
                  <v-row no-gutters class="justify-end">
                    <p style="margin-right: 10px">ສ່ວນຫຼຸດ:{{bills_order_cut_product[0].discount}}</p>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row no-gutters class="justify-start">
                    <p> ເປີເຊັນ</p>
                  </v-row>
                </v-col>
              </v-row>

              <v-row no-gutters class="justify-center">
                <v-row no-gutters class="justify-end">
                  <p style="margin-right: 10px">ຊໍາລະເປັນມູນຄ່າ:</p>
                </v-row>

                <v-row no-gutters class="justify-start">
                  <p>
               {{ bills_order_cut_product[0].todalMoney }}
                    ກິບ
                  </p>
                </v-row>
              </v-row>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "ລໍາດັບ", value: "No" },
        {
          text: "ຮູບ",
          sortable: false,
          value: "image",
        },
        { text: "ຊື່ວັດຖຸດິບ", value: "pro_name" },
        { text: "ຂະໜາດ", value: "size" },
        { text: "ຈໍານວນ", value: "qty" },
        { text: "ລາຄາ(ກິບ)", value: "price" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      bills_order_cut_product: "OrderCutMod/OrderCut_Bill",
    }),
    CusName(){
      return JSON.parse(localStorage.getItem("Customer_Info")).full_name
    },
  },

  created() {
    this.getOrderCutBill(this.$route.query.OrderID);
  },
  methods: {
    ...mapActions("OrderCutMod", ["getOrderCutBill"]),
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
  },
};
</script>
<style lang="css">
.font {
  font-family: "NotoSansLao";
}
p {
  font-size: 1rem;
}
</style>