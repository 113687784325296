<template>
  <v-card class="elevation-1">
    <v-row justify="center">
      <h2 class="mt-7 mb-5">ຮັບການສັ່ງຊື້ສິນຄ້າຈາກລູກຄ້າ</h2>
    </v-row>
    <v-divider></v-divider>
    <v-tabs
      class="pa-1"
      v-model="tabs"
      centered
      grow
      slider-color="#9C27B0"
      slider-size="3"
      color="#9C27B0"
      background-color="transparent"
    >
      <v-tab
        class="pt-5"
        background-color="transparent"
        v-for="n in 3"
        :key="n"
      >
        <h2 v-if="n == 1">ປະຫວັດການຮັບສັ່ງຊື້ສິນຄ້າ</h2>
        <v-badge v-if="n == 2" color="error" :content="newOrders.length">
          <h2>ລາຍການສັ່ງຊື້ສິນຄ້າໃໝ່</h2>
        </v-badge>
        <h2 v-if="n == 3">ຮັບສັ່ງຊື້ສິນຄ້າ</h2>
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-card flat>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="orders"
            :single-expand="true"
            :expanded.sync="expanded"
            item-key="order_id"
            show-expand
            class="elevation-3"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title
                  >ຂໍ້ມູນປະຫວັດການຮັບສັ່ງຊື້ສິນຄ້າ</v-toolbar-title
                >
                <v-divider class="mx-4" inset vertical></v-divider
                ><v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ຄົ້ນຫາຂໍ້ມູນ"
                  outlined
                  dense
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-divider></v-divider>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="detailHeader"
                  :items="item.orderDetail"
                  hide-default-footer
                  class="elevation-2"
                  style="background: #fafafaff"
                >
                  <template v-slot:item="{ item }">
                    <tr v-ripple="{ class: `white--text` }">
                      <td>{{ item.idx }}</td>
                      <td align="center">
                        <v-carousel
                          v-if="item.image.length > 0"
                          show-arrows-on-hover
                          hide-delimiters
                          height="100"
                          width="100"
                        >
                          <v-carousel-item
                            v-for="(img, i) in item.image"
                            :key="i"
                          >
                            <v-card
                              class="mx-auto"
                              height="100%"
                              width="100"
                              :img="fectImg(img.img_url)"
                            >
                            </v-card>
                          </v-carousel-item>
                        </v-carousel>
                        <v-card v-else height="100" width="100">
                          <v-img
                            src="https://pbs.twimg.com/profile_images/1150780350932684800/LIJ11DWR.jpg"
                          ></v-img>
                        </v-card>
                      </td>
                      <td>{{ item.pro_name }}</td>
                      <td>{{ item.category }}</td>
                      <td>{{ item.color }}</td>
                      <td>{{ item.size }}</td>
                      <td>
                        {{
                          String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                        {{ item.unit }}
                      </td>
                      <td>
                        {{
                          String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                      </td>
                      <td>
                        {{
                          String(item.qty * item.price).replace(
                            /(.)(?=(\d{3})+$)/g,
                            "$1 "
                          )
                        }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <!-- _______________________________________________________________-2-___________________________________ -->
      <v-tab-item>
        <v-card flat>
          <v-data-table
            :search="newsearch"
            :headers="newHeaders"
            :items="newOrders"
            :single-expand="true"
            :expanded.sync="newexpanded"
            item-key="idx"
            show-expand
            class="elevation-3"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>ລາຍການສັ່ງຊື້ສິນຄ້າ</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider
                ><v-spacer></v-spacer>
                <v-text-field
                  v-model="newsearch"
                  append-icon="mdi-magnify"
                  label="ຄົ້ນຫາຂໍ້ມູນ"
                  single-line
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon large color="#C51162" @click="editeItem(item)">
                mdi-cart-check
              </v-icon>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="newdetailHeader"
                  :items="item.orderDetail"
                  hide-default-footer
                  class="elevation-2"
                  style="background: #fafafaff"
                >
                  <template v-slot:item="{ item }">
                    <tr v-ripple="{ class: `white--text` }">
                      <td>{{ item.idx }}</td>
                      <td>
                        <v-carousel
                          v-if="item"
                          show-arrows-on-hover
                          hide-delimiters
                          height="100"
                          width="100"
                        >
                          <v-carousel-item
                            v-for="(img, i) in item.image"
                            :key="i"
                          >
                            <v-card class="mx-auto" height="100%" width="100">
                              <v-img :src="fectImg(img.img_url)"></v-img>
                            </v-card>
                          </v-carousel-item>
                        </v-carousel>
                        <v-card v-else height="100" width="70">
                          <v-img
                            src="https://pbs.twimg.com/profile_images/1150780350932684800/LIJ11DWR.jpg"
                          ></v-img>
                        </v-card>
                      </td>
                      <td>{{ item.pro_name }}</td>
                      <td>{{ item.category }}</td>
                      <td>{{ item.color }}</td>
                      <td>{{ item.size }}</td>
                      <td>
                        {{
                          String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                        {{ item.unit }}
                      </td>
                      <td>
                        {{
                          String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                      </td>
                      <td>
                        {{
                          String(item.qty * item.price).replace(
                            /(.)(?=(\d{3})+$)/g,
                            "$1 "
                          )
                        }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <!-- _______________________________________________________-333-_______________________________________ -->
      <v-tab-item>
        <v-card v-if="Todal > 0" class="elevation-0">
          <v-row no-gutters class="justify-center ma-3 ml-1">
            <v-col cols="12" md="2" class="align-center">
              <v-card width="100%" height="52" class="ma-1 pa-3">
                <h4>
                  ໃບບີນ: <span>{{ editList.order_id }}</span>
                </h4>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" class="align-center">
              <v-card width="100%" height="52" class="ma-1 pa-3">
                <h4>
                  ຜູ້ສັ່ງຊື້:<span> {{ editList.full_name }}</span>
                </h4>
              </v-card>
            </v-col>
            <v-col cols="12" md="2" class="align-center">
              <v-card width="100%" height="52" class="ma-1 pa-3">
                <h4>
                  ສ່ວນຫຼຸດ: <span> {{ discount }}</span
                  >%
                </h4>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" class="align-center">
              <v-card width="100%" height="52" class="ma-1 pa-3">
                ຄອມເມັ້ນ: <span>{{ editList.customer_content }}</span>
              </v-card>
            </v-col>
          </v-row>
          <v-data-table
            :headers="recievedetailHeader"
            :items="editList.orderDetail"
            hide-default-footer
            class="elevation-2 mx-2"
          >
            <template v-slot:item="{ item }">
              <tr v-ripple="{ class: `white--text` }">
                <td>{{ item.idx }}</td>
                <td align="center">
                  <v-carousel
                    v-if="item"
                    show-arrows-on-hover
                    hide-delimiters
                    height="100"
                    width="100"
                  >
                    <v-carousel-item v-for="(img, i) in item.image" :key="i">
                      <v-card class="mx-auto" height="100%" width="100">
                        <v-img :src="fectImg(img.img_url)"></v-img>
                      </v-card>
                    </v-carousel-item>
                  </v-carousel>
                  <v-card v-else height="100" width="70">
                    <v-img
                      src="https://pbs.twimg.com/profile_images/1150780350932684800/LIJ11DWR.jpg"
                    ></v-img>
                  </v-card>
                </td>
                <td>{{ item.pro_name }}</td>
                <td>{{ item.category }}</td>
                <td>{{ item.color }}</td>
                <td>{{ item.size }}</td>
                <td>
                  <v-text-field
                    class="mt-3 text-center tcenter"
                    filled
                    dense
                    rounded
                    prepend-inner-icon="mdi-minus"
                    append-icon="mdi-plus"
                    autofocus
                    v-model="item.qty"
                    hint="ຈໍານວນທີ່ຕ້ອງການ"
                    @click:append="Add(item)"
                    @click:prepend-inner="Minus(item)"
                    @keypress="number($event)"
                    @keyup="CheckNumber(item)"
                  >
                    <template slot="append">
                      <v-btn
                        style="margin-top: -7px; margin-right: -20px"
                        color="#004D40"
                        icon
                        @click="Add(item)"
                      >
                        <v-icon large>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <template slot="prepend-inner">
                      <v-btn
                        style="margin-top: -7px; margin-left: -20px"
                        color="#004D40"
                        icon
                        @click="Minus(item)"
                      >
                        <v-icon large>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </td>
                <td>{{ item.unit }}</td>
                <td>
                  {{ String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }}
                </td>
                <td>
                  {{
                    String(item.qty * item.price).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1 "
                    )
                  }}
                </td>
                <td>
                  <v-row no-gutters class="justify-center">
                    <v-icon
                      class="mr-2"
                      color="error"
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-row>
                </td>
              </tr>
            </template>

            <template v-slot:footer>
              <v-row class="ma-5" align="center" justify="center">
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <h3>
                  ຈ່າຍຜ່ານ:
                  {{ editList.pay_method }}
                </h3>
                <v-spacer></v-spacer>
                <h3>
                  ລວມເປັນ:
                  {{ String(Todal).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }} ກິບ
                </h3>
                <v-spacer></v-spacer>
                <h3>
                  ຊໍາລະເປັນ:
                  {{ String(Sum).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }} ກິບ
                </h3>
              </v-row>
            </template>
          </v-data-table>
          <v-row no-gutters class="ma-2 justify-center">
            <v-card width="100%" class="elevation-0">
              <h4>ຄໍາຄິດເຫັນ ຫຼື ລາຍລະອຽດໃຫ້ລູກຄ້າ</h4>
              <v-textarea
                background-color="grey lighten-2"
                color="cyan"
                v-model="editList.receive_content"
                placeholder="Add Comment Or Other Details"
              ></v-textarea>
            </v-card>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="back"
              style="font-size: 20px; font-weight: bold"
              color="#FF8A65"
              rounded
              class="mb-5 mr-3"
            >
              <v-icon left large> mdi-close-circle </v-icon>
              ຍົກເລິກ
            </v-btn>
            <v-btn
              @click="Submit"
              style="font-size: 20px; font-weight: bold"
              color="#1DE9B6"
              rounded
              class="mb-5 mr-5"
            >
              <v-icon left large> mdi-check-circle </v-icon>
              ຢຶນຢັນຮັບສັ່ງຊື້
            </v-btn>
          </v-card-actions>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card rounded>
              <v-card-title
                style="
                  font-size: 25px;
                  font-weight: bold;
                  font-family: NotoSansLao;
                "
                >ທ່ານຕ້ອງການລຶບຂໍ້ມູນລາຍການນີ້ບໍ...?</v-card-title
              >
              <v-divider class="mt-5"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="closeDelete"
                  style="font-size: 20px; font-family: NotoSansLao"
                  >ຍົກເລິກ</v-btn
                >
                <v-btn
                  color="primary"
                  text
                  @click="deleteConfirm"
                  style="font-size: 20px; font-family: NotoSansLao"
                  >ຢຶນຢັນລຶບ</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
        <v-row v-else class="ma-5 justify-center">
          <h1 style="color: green">...ບໍ່ມີຂໍ້ມູນ...</h1></v-row
        >
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

  
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    tabs: 1,
    text: "",
    dialog: false,
    search: "",
    newsearch: "",
    dialogDelete: false,
    expanded: [],
    newexpanded: [],
    editList: [],
    discount: 0,
    deleteList: [],
    headers: [
      { text: "ລໍາດັບ", value: "idx" },
      {
        text: "ໃບສັ່ງຊື້",
        align: "start",
        value: "order_id",
      },
      { text: "ພະນັກງານ", value: "emp_name" },
      { text: "ຜູ້ສັ່ງຊື້", value: "full_name" },
      { text: "ເບີ້ໂທ", value: "customer_tel" },
      { text: "ອີມິວ", value: "customer_email" },
      { text: "ວັນທີ່ສັ່ງຊື້", value: "order_date" },
      // { text: "ເວລາສັ່ງຊື້", value: "order_time" },
      { text: "ສ່ວນຫຼຸດ", value: "discount" },
      { text: "ເມັ້ນຂອງລູກຄ້າ", value: "customer_content" },
      { text: "ໃຫ້ຄໍາແນະນໍາ", value: "receive_content" },
      { text: "ລາຍລະອຽດ", value: "data-table-expand" },
    ],
    detailHeader: [
      { text: "ລໍາດັບ", value: "idx" },
      {
        text: "ຮູບ",
        align: "center",
        sortable: false,
        value: "image",
      },
      { text: "ຊື່ສິນຄ້າ", value: "pro_name" },
      { text: "ປະເພດ", value: "category" },
      { text: "ສີ", value: "color" },
      { text: "ຂະໜາດ", value: "size" },
      { text: "ຈໍານວນ", value: "qty" },
      { text: "ລາຄາ(ກິບ)", value: "price" },
      { text: "ລາຄາລວມ(ກິບ)", value: "price*qty" },
    ],
    newHeaders: [
      { text: "ລໍາດັບ", value: "idx" },
      { text: "ຜູ້ສັ່ງຊື້", value: "full_name" },
      { text: "ເບີ້ໂທ", value: "customer_tel" },
      { text: "ອີມິວ", value: "customer_email" },
      { text: "ວັນທີ່ສັ່ງຊື້", value: "order_date" },
      { text: "ເວລາສັ່ງຊື້", value: "order_time" },
      { text: "ຈ່າຍຜ່ານ", value: "pay_method" },
      { text: "ເມັ້ນຂອງລູກຄ້າ", value: "customer_content" },
      { text: "ສ່ວນຫຼຸດ", value: "discount" },
      { text: "ຮັບສັ່ງຊື້", value: "actions" },
      { text: "", value: "data-table-expand" },
    ],
    newdetailHeader: [
      { text: "ລໍາດັບ", value: "idx" },
      {
        text: "ຮູບ",
        align: "center",
        sortable: false,
        value: "image",
      },
      { text: "ຊື່ສິນຄ້າ", value: "pro_name" },
      { text: "ປະເພດ", value: "category" },
      { text: "ສີ", value: "color" },
      { text: "ຂະໜາດ", value: "size" },
      { text: "ຈໍານວນ", value: "qty" },
      { text: "ລາຄາ(ກິບ)", value: "price" },
      { text: "ລາຄາລວມ(ກິບ)", value: "price*qty" },
    ],
    recievedetailHeader: [
      { text: "ລໍາດັບ", value: "idx" },
      {
        text: "ຮູບ",
        sortable: false,
        value: "image",
      },
      { text: "ຊື່ສິນຄ້າ", value: "pro_name" },
      { text: "ປະເພດ", value: "category" },
      { text: "ສີ", value: "color" },
      { text: "ຂະໜາດ", value: "size" },
      { text: "ຈໍານວນ", value: "qty" },
      { text: "ຫົວໜ່ວຍ", value: "unit" },
      { text: "ລາຄາ(ກິບ)", value: "price" },
      { text: "ລາຄາລວມ(ກິບ)", value: "price*qty" },
      { text: "ລົບອອກ", value: "actions", align: "center", sortable: false },
    ],
  }),

  computed: {
    ...mapGetters({
      orders: "orderMod/orders",
      newOrders: "orderMod/new_orders",
      products: "saleMod/products",
    }),
    Todal() {
      var sum = 0;
      if (this.editList.length < 1) {
        return sum;
      }
      for (const key in this.editList.orderDetail) {
        const el = this.editList.orderDetail[key];
        if (parseInt(el.qty) > 0 && el.qty != "") {
          sum += parseInt(el.qty, 10) * parseFloat(el.price, 10);
        }
      }
      return sum;
    },
    Sum() {
      var sum = 0;
      sum = this.Todal - (this.Todal * parseFloat(this.discount)) / 100;
      return sum;
    },
  },
  watch: {
    dialog(val) {
      val || this.closeDelete();
    },
    newOrders(val) {
      if (val.length == 0) {
        this.tabs = 0;
      }
    },
  },
  mounted() {
    this.initial();
  },
  methods: {
    ...mapActions("orderMod", ["getOrder", "getNewOrder", "RecieveOrder"]),
    ...mapActions("saleMod", ["getProduct", "InsertSale", "UpdateProduct"]),
    ...mapActions("commentMod", ["UpdateComment"]),
    initial() {
      this.getProduct();
      this.getOrder();
      this.getNewOrder();
    },
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
    Add(pro) {
      if (
        (typeof pro.qty == "string" && pro.qty == "") ||
        typeof pro.qty == undefined
      ) {
        pro.qty = 1;
        return;
      }
      pro.qty = parseInt(pro.qty, 10) + 1;
    },
    Minus(pro) {
      if (parseInt(pro.qty, 10) < 2) return;
      pro.qty = parseInt(pro.qty, 10) - 1;
    },
    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        //   alert("Enter Only Number ? (0-9)");
        evt.preventDefault();
      } else {
        return true;
      }
    },
    CheckNumber(pro) {
      if (
        (typeof pro.qty == "string" && pro.qty == "") ||
        pro.qty == null ||
        pro.qty == 0
      ) {
        pro.qty = 1;
      }
    },
    editeItem(item) {
      this.editList = item;
      this.discount = this.editList.orderDetail[0].discount;
      
      if (this.editList.status == "Confirm") {
        if (
          this.editList.receive_content == "" ||
          this.editList.receive_content == undefined ||
          this.editList.receive_content == null
        ) {
          this.editList.receive_content =
            "ຮ້ານດາດ້າແຟຊັ່ນ ຢືນຢັນຮັບການສັ່ງຊື້ສິນຄ້າຂອງທ່ານແລ້ວ...ກະລຸນາລໍຖ້າພະນັກງານຈະຕິດຕໍ່ກັບໃນໄວໄວນີ້...ຂໍຂອບໃຈ";
        }
      }
      this.tabs = 2;
    },
    deleteItem(item) {
      this.deleteList = item;
      this.dialog = true;
    },
    deleteConfirm() {
      for (const idx in this.editList.orderDetail) {
        const element = this.editList.orderDetail[idx];
        if (element.pro_name == this.deleteList.pro_name) {
          this.editList.orderDetail.splice(idx, 1);
          this.deleteList = [];
        }
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialog = false;
    },
    Submit() {
      this.editList.emp_id = this.$store.state.authMod.token.emp_id;
      if(this.editList.status=='Confirm'){
        this.editList.status='Waiting'
      this.RecieveOrder(this.editList);
        var comment = {
          id: this.editList.com_id,
          emp_id: this.$store.state.authMod.token.emp_id,
          receive_content: this.editList.receive_content,
        };
        this.UpdateComment(comment);
      }
      this.back();
    },
    back() {
      this.editList = [];
      this.tabs = 1;
      this.initial();
    },
  },
};
</script>
<style scoped>
.tcenter {
  width: 200px;
  min-width: 100px;
}
.tcenter >>> input {
  text-align: center;
}
</style>