import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import { header } from '../../config/constant';

Vue.use(Vuex)

const SupplierModule = {
  namespaced: true,
  state: {
    suplier: [],
    suplieredit: [],
  },
  mutations: {
    setSuplier(state, suplier) {
      state.suplier = suplier
    },
    setSuplierByone(state, suplier) {
      state.suplieredit = suplier
    },
  },

  actions: {
    async getSuplier(context) {
      try {
        var response = await axios.get(`http://localhost:2021/api/dadabackend/supplier/getall`, {
          headers: header
        })
        var supliers=[]
        for (const key in response.data) {
            const el = response.data[key];
            var suplier={
              idx:parseInt(key)+1,
              sup_id:el.sup_id,
              name:el.name,
              tel:el.tel,
              email:el.email,
              address:el.address
            }
            supliers.push(suplier)
        }
        context.commit('setSuplier', supliers)
      } catch (e) {
        console.log(e)
      }
    },
    async getSuplierByone(context, id) {
      try {
        var res = await axios.get(
          `http://localhost:2021/api/dadabackend/supplier/getById/${id}`, {
          headers: header
        }
        );
        context.commit('setSuplierByone', res.data[0])
      } catch (e) {
        console.log(e)
      }
    },
    async InsertSuplier(context, Item) {
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/supplier/insert`,
          Item, {
          headers: header
        }
        );
        context.dispatch('getSuplier')
      } catch (e) {
        console.log(e)
      }
    },
    async UpdateSuplier(context, updateItem) {
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/supplier/update`,
          updateItem, {
          headers: header
        }
        );
        context.dispatch('getSuplier')
      } catch (e) {
        console.log(e)
      }
    },
    async delSuplier(context, delItem) {
      try {
        await axios.post(`http://localhost:2021/api/dadabackend/supplier/delete`, delItem, {
          headers: header
        })
        context.dispatch('getSuplier')
      } catch (e) {
        console.log(e)
      }
    },
  },
  getters: {
    suplier: state => state.suplier,
    suplieredit: state => state.suplieredit,
  }
}

export default SupplierModule;

