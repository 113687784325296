<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="10" style="margin-top:2px; padding-bottom:10px">
            <v-row no-gutters class="justify-center">
              <h1 style="margin-top:30px;">ປັບປຸງຂໍ້ມູນວັດຖຸດິບ</h1>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                  <v-text-field
                    v-model="material.pro_name"
                    :error-messages="NameErrors"
                    :counter="100"
                    label="ຊື່ວັດຖຸດິບ"
                    required
                    @input="$v.material.pro_name.$touch()"
                    @blur="$v.material.pro_name.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="material.category"
                    :error-messages="CategoryErrors"
                    label="ປະເພດວັດຖຸດິບ"
                    allow-overflow
                    :counter="100"
                    required
                    @input="$v.material.category.$touch()"
                    @blur="$v.material.category.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="material.color"
                    :error-messages="ColorErrors"
                    :counter="100"
                    label="ສີ"
                    required
                    @input="$v.material.color.$touch()"
                    @blur="$v.material.color.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="material.size"
                    :error-messages="SizeErrors"
                    label="ຂະໜາດ"
                    :counter="100"
                    required
                    @input="$v.material.size.$touch()"
                    @blur="$v.material.size.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="material.unit"
                    :error-messages="UnitErrors"
                    label="ຫົວໜ່ວຍ"
                    required
                    @input="$v.material.unit.$touch()"
                    @blur="$v.material.unit.$touch()"
                  ></v-text-field>
               <input
                    type="file"
                    class="file-upload"
                    multiple
                    ref="file"
                    @change="onFileSelected"
                    label="Image input"
                  />
                      <v-card v-if="material.image!=''" 
                      class="mx-auto white" height="400" width="300"
                      :img="fectImg(material.image)">
                      </v-card>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class></v-divider>
            <v-row class="ma-5">
               <v-btn @click="back" style="font-size: 20px; font-weight: bold" color="deep-purple lighten-4" rounded class="mt-2">
                <v-icon left large>mdi-arrow-left-circle</v-icon>ກັບສູ່ໜ້າສິນຄ້າ
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn @click="clear" style="font-size: 20px; font-weight: bold" color="#FF8A65" rounded class="mt-2 mr-7">
                <v-icon left large>mdi-close-circle</v-icon>ຍົກເລິກ
              </v-btn>

              <v-btn @click="submit" style="font-size: 20px; font-weight: bold" color="#1DE9B6" rounded class="mt-2 mr-5">
                <v-icon large left>mdi-check-circle</v-icon>ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
     material: {
      pro_name: { required, maxLength: maxLength(100) },
      category: { required, maxLength: maxLength(100) },
      unit: { required, maxLength: maxLength(100) },
      color: { required, maxLength: maxLength(20) },
      size: { required, maxLength: maxLength(100) },
    },
  },

  data: () => ({
    // material: {
    //   pro_name: "",
    //   category: "",
    //   color: "",
    //   size: "",
    //   unit: "",
    //   image: "",
    // }
  }),

  computed: {
    ...mapGetters({
      material: "materialMod/materialEdit"
    }),

     NameErrors() {
      const errors = [];
      if (!this.$v.material.pro_name.$dirty) return errors;
      !this.$v.material.pro_name.maxLength &&
        errors.push("pro name must be at most 100 characters long");
      !this.$v.material.pro_name.required && errors.push("Name is required.");
      return errors;
    },
    CategoryErrors() {
      const errors = [];
      if (!this.$v.material.category.$dirty) return errors;
      !this.$v.material.category.maxLength &&
        errors.push("category must be at most 100 characters long");
      !this.$v.material.category.required &&
        errors.push("category is required.");
      return errors;
    },
    UnitErrors() {
      const errors = [];
      if (!this.$v.material.unit.$dirty) return errors;
      !this.$v.material.unit.maxLength &&
        errors.push("unit must be at most 20 characters long");
      !this.$v.material.unit.required && errors.push("unit is required.");
      return errors;
    },
    ColorErrors() {
      const errors = [];
      if (!this.$v.material.color.$dirty) return errors;
      !this.$v.material.color.maxLength &&
        errors.push("color must be at most 100 characters long");
      !this.$v.material.color.required && errors.push("color is required.");
      return errors;
    },

    SizeErrors() {
      const errors = [];
      if (!this.$v.material.size.$dirty) return errors;
      !this.$v.material.size.maxLength &&
        errors.push("Size must be at most 100 characters long");
      !this.$v.material.size.required && errors.push("Size is required");
      return errors;
    },
    
  },
  mounted() {
    this.getMaterialOne(this.$route.params.id);
  },
  methods: {
    ...mapActions("materialMod", [
      "getMaterialOne",
      "UpdateMaterial",
    ]),
   
    onFileSelected(event) {
      this.material.image = event.target.files[0].name;
    },
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/logo.png");
      }
      return imageUrl;
    },
    
     submit() {
      this.$v.$touch();
      try {
        if (
          this.material.m_id == "" ||
          this.material.m_id == null 
        ) {
          alert("Your ID ?");
          return;
        }
        if (
          this.material.pro_name == "" ||
          this.material.pro_name == null ||
          this.material.pro_name.length > 100
        ) {
          alert("Your Name ?");
          return;
        }
        if (
          this.material.category == "" ||
          this.material.category == null ||
          this.material.category.length > 100
        ) {
          alert("Your category?");
          return;
        }

        if (
          this.material.unit == "" ||
          this.material.unit == null ||
          this.material.unit.length > 20
        ) {
          alert("Your unit ?");
          return;
        }
        if (
          this.material.color == "" ||
          this.material.color == null ||
          this.material.color.length > 20
        ) {
          alert("Your color?");
          return;
        }
        if (
          this.material.size == "" ||
          this.material.size == null ||
          this.material.size.length > 100
        ) {
          alert("Your size?");
          return;
        }

        this.UpdateMaterial( this.material);
        this.back();
      } catch (e) {
        this.errors.push(e);
      }
    },
    back() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.file-upload {
  margin: 7px 0px;
  font-size: 16px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #999;
}
.file-upload:hover {
  border-bottom: 1px solid #555;
}
::-webkit-file-upload-button {
  background: #eeeeee;
  border-radius: 0px 50px 50px 0px;
  padding: 7px;
  width: 180px;
  border: 1px solid #edffdd;
}
::-webkit-file-upload-button:hover {
  background: #dedede;
}

.imgtitle {
  padding: 7px;
  background: transparent;
  color: black;
  border-radius: 50px;
}

.imgtitle:hover {
  background: #44ff;
  color: white;
}
</style>