import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './auth.store'
import dashModule from './admin/dashboard.store'
import employeeModule from './admin/employee.store'
import customerModule from './admin/customer.store'
import supplierModule from './admin/supplier.store'
import productModule from './admin/product.store'
import MaterialOrdertModule from './admin/material_order.store'
import MaterialImportModule from './admin/material_import.store'
import saleModule from './admin/sale.store'
import orderModule from './admin/ordering.store'
import cutModule from './admin/cuting.store'
import commentModule from './admin/comment.store'
import countryModule from './client/GetData'
import shopModule from './admin/shop'
import MaterialModule from './admin/material.store'
import OrderModule from './client/customer_order.store'
import ordercutModule from './client/ordercut.store'
import reportModule from './admin/reports.store'
import ReportPDF_Module from './admin/report_pdf.store'
Vue.use(Vuex)
import state from "./state";
import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";
export default new Vuex.Store({
  modules: {
    authMod:authModule,
    dashMod:dashModule,
    employeeMod: employeeModule,
    customerMod:customerModule,
    supplierMod:supplierModule,
    productMod: productModule,
    materialOrderMod:MaterialOrdertModule,
    materialImportMod:MaterialImportModule,
    saleMod:saleModule,
    orderMod:orderModule,
    cutMod:cutModule,
    commentMod:commentModule,
    shopMod:shopModule,
    materialMod:MaterialModule,
    countryMod:countryModule,
    customerOrderMod:OrderModule,
    OrderCutMod:ordercutModule,
    reportMod:reportModule,
    reportpdfMod:ReportPDF_Module,
  },
  state,
  getters,
  mutations,
  actions
})
