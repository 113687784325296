<template>
  <v-app>
    <v-app-bar app color="white" height="57" elevation="1" class="hidden-sm-and-down">
      <v-avatar size="35" class="mr-1 ml-2 home" @click="HomePage">
        <v-img src="@/assets/images/DaDa_LoGo.png" />
      </v-avatar>
      <h3 class="homepage" @click="HomePage">DaDaFashion</h3>

      <v-spacer></v-spacer>
      <div class="css" @click="HomePage">ໜ້າຫຼັກ</div>
      <div class="css" @click="ProductPage">ສິນຄ້າ</div>
      <div class="css" @click="OrderCutPage">ສັ່ງຕັດເຄື່ອງ</div>
      <div class="css" @click="AboutPage">ກ່ຽວກັບຮ້ານ</div>
      <div class="css" @click="GiveInformationPage">ແຫຼ່ງໃຫ້ຂໍ້ມູນ</div>
      <div class="css" @click="ContactUsPage">ຕິດຕໍ່ພວກເຮົາ</div>
      <v-menu offset-y rounded>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-badge
            class="mr-10"
            :content="notification"
            :value="notification"
            color="error"
            overlap
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  large
                  v-bind="attrs"
                  class="ml-10"
                  v-on="{ ...tooltip, ...menu }"
                  @click="See_Notifications"
                >
                  mdi-bell
                </v-icon>
              </template>
              <span class="tooltip">ແຈ້ງເຕືອນ</span>
            </v-tooltip>
          </v-badge>
        </template>
        <v-card
          width="400"
          class="scroll_list"
          v-if="this.Notification_Items.length > 0"
        >
          <h1 class="cus_name mt-5 mb-3 ml-5">ແຈ້ງເຕືອນ</h1>
          <v-divider></v-divider>
          <v-list three-line>
            <template v-for="(item, index) in Notification_Items">
              <v-card
                :key="index"
                class="elevation-0"
                @click="Selected_Notifications(item.title)"
              >
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="item.header"
                ></v-subheader>
                <v-list-item v-else :key="item.title">
                  <v-list-item-avatar>
                    <v-img :src="fectImg(item.avatar)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="item.title"
                      style="
                        font-family: Noto Sans Lao;
                        font-size: 17px;
                        font-weight: bold;
                      "
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      style="
                        font-family: Noto Sans Lao;
                        font-size: 17px;
                        color: #004d40;
                      "
                      v-html="item.subtitle"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      style="
                        font-family: Noto Sans Lao;
                        font-size: 15px;
                        color: #c51162;
                      "
                      v-html="item.ago_text"
                      class="mt-4"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index < Notification_Items.length - 1"
                  :key="index"
                ></v-divider>
              </v-card>
            </template>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu offset-y rounded :disabled="QtyList">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-badge
            class="mr-10"
            :content="messages"
            :value="messages"
            color="blue"
            overlap
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  large
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  @click="OpenCart"
                >
                  mdi-cart-variant
                </v-icon>
              </template>
              <span class="tooltip">ກະຕ່າສິນຄ້າ</span>
            </v-tooltip>
          </v-badge>
        </template>
        <v-list>
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="OpenCartOrderBuy"
              style="font-family: Noto Sans Lao"
            >
              <v-badge
                :content="CountOrderQty"
                :value="CountOrderQty"
                color="error"
                overlap
              >
                <v-icon> mdi-cart</v-icon>
              </v-badge>
              ລາຍການສັ່ງຊື້</v-list-item-title
            >
          </v-list-item>
          <v-list-item link>
            <v-list-item-title
              @click="OpenCartOrderCut"
              style="font-family: Noto Sans Lao"
            >
              <v-badge
                :content="CountOrderCut_Item"
                :value="CountOrderCut_Item"
                color="error"
                overlap
              >
                <v-icon> mdi-cart</v-icon>
              </v-badge>
              ລາຍການສັ່ງຕັດ</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="dialog" persistent max-width="400px">
        <v-card>
          <v-card-title>
            <span id="dialog">ແກ້ໄຂຮູບໂປຣໄຟ໌ລ</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-img
                :src="url"
                v-show="Profile"
                max-height="450"
                aspect-ratio="1.7"
                contain
              ></v-img>
              <v-divider class="ml-7"></v-divider>
              <input
                prepend-icon="mdi-camera"
                type="file"
                class="file-upload ml-7 mt-5"
                @change="onFileSelected"
                show-size
                label="Image input"
                required
              />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              id="btntext"
              class="mb-10 ml-16"
              text
              @click.stop="dialog = false"
            >
              ຍົກເລິກ
            </v-btn>
            <v-btn
              color="blue darken-1"
              id="btntext"
              class="mb-10 ml-16"
              text
              @click="Edit_Profile"
            >
              ບັນທຶກ
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-menu offset-y rounded>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-avatar v-bind="attrs" v-on="{ ...menu }">
            <v-img :src="FectCustomer_Profile(Profile_Img)"></v-img>
          </v-avatar>
        </template>
        <v-list>
          <v-row justify="start">
            <v-avatar class="ml-8 mt-8" @click="dialog = true">
              <v-img :src="FectCustomer_Profile(Profile_Img)"></v-img>
            </v-avatar>
            <h1 class="cus_name mt-9 mr-5">{{ Cus_Name }}</h1>
          </v-row>
          <div class="profile mt-5 mb-4" @click="Customer_Info">
            ຈັດການຂໍ້ມູນສ່ວນຕົວ
          </div>
          <v-divider></v-divider>
          <v-list-item link class="mt-4" @click="OrderBuy_HistoryPage">
            <v-list-item-title class="mr-5" style="font-family: Noto Sans Lao">
              <v-icon large> mdi-history</v-icon>
              ປະຫັວດການສັ່ງຊື້</v-list-item-title
            >
          </v-list-item>
          <v-list-item link @click="OrderCut_HistoryPage">
            <v-list-item-title style="font-family: Noto Sans Lao">
              <v-icon large> mdi-history</v-icon>
              ປະຫວັດການສັ່ງຕັດ</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            link
            @click="Member_Register"
            v-if="this.status != 'Member' && this.status != 'Confirm'"
          >
            <v-list-item-title style="font-family: Noto Sans Lao">
              <v-icon large> mdi-account-plus</v-icon>
              ສະມັກເປັນຕົວແທນ</v-list-item-title
            >
          </v-list-item>
          <v-list-item link @click="LogOUT">
            <v-list-item-title style="font-family: Noto Sans Lao">
              <v-icon large> mdi-logout-variant</v-icon>
              ອອກລະບົບ</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    
    <v-app-bar app color="white" height="57" elevation="1"  class="hidden-md-and-up">
        <v-menu offset-y rounded class="mx-3">
        <template v-slot:activator="{ on: menu, attrs }">
                <v-icon
                  large
                  v-bind="attrs"
                  v-on="{  ...menu }"
                >
                  mdi-menu
                </v-icon>
        </template>
        <v-list>
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="HomePage"
              style="font-family: Noto Sans Lao"
            > ໜ້າຫຼັກ</v-list-item-title
            >
          </v-list-item>
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="ProductPage"
              style="font-family: Noto Sans Lao"
            > ສິນຄ້າ</v-list-item-title
            >
          </v-list-item>
         <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="OrderCutPage"
              style="font-family: Noto Sans Lao"
            > ສັ່ງຕັດເຄື່ອງ</v-list-item-title
            >
          </v-list-item>
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="AboutPage"
              style="font-family: Noto Sans Lao"
            > ກ່ຽວກັບຮ້ານ</v-list-item-title
            >
          </v-list-item>
          
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="GiveInformationPage"
              style="font-family: Noto Sans Lao"
            > ແຫຼ່ງໃຫ້ຂໍ້ມູນ</v-list-item-title
            >
          </v-list-item>
          
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="ContactUsPage"
              style="font-family: Noto Sans Lao"
            > ຕິດຕໍ່ພວກເຮົາ</v-list-item-title
            >
          </v-list-item>
          
        </v-list>
      </v-menu>
      
      <v-avatar size="35" class="mr-1 ml-2 home" @click="HomePage">
        <v-img src="@/assets/images/DaDa_LoGo.png" />
      </v-avatar>
      <h3 class="homepage" @click="HomePage">DaDaFashion</h3>

      <v-spacer></v-spacer>
      <v-menu offset-y rounded>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-badge
            class="mr-10"
            :content="notification"
            :value="notification"
            color="error"
            overlap
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  large
                  v-bind="attrs"
                  class="ml-10"
                  v-on="{ ...tooltip, ...menu }"
                  @click="See_Notifications"
                >
                  mdi-bell
                </v-icon>
              </template>
              <span class="tooltip">ແຈ້ງເຕືອນ</span>
            </v-tooltip>
          </v-badge>
        </template>
        <v-card
          width="400"
          class="scroll_list"
          v-if="this.Notification_Items.length > 0"
        >
          <h1 class="cus_name mt-5 mb-3 ml-5">ແຈ້ງເຕືອນ</h1>
          <v-divider></v-divider>
          <v-list three-line>
            <template v-for="(item, index) in Notification_Items">
              <v-card
                :key="index"
                class="elevation-0"
                @click="Selected_Notifications(item.title)"
              >
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="item.header"
                ></v-subheader>
                <v-list-item v-else :key="item.title">
                  <v-list-item-avatar>
                    <v-img :src="fectImg(item.avatar)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="item.title"
                      style="
                        font-family: Noto Sans Lao;
                        font-size: 17px;
                        font-weight: bold;
                      "
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      style="
                        font-family: Noto Sans Lao;
                        font-size: 17px;
                        color: #004d40;
                      "
                      v-html="item.subtitle"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      style="
                        font-family: Noto Sans Lao;
                        font-size: 15px;
                        color: #c51162;
                      "
                      v-html="item.ago_text"
                      class="mt-4"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index < Notification_Items.length - 1"
                  :key="index"
                ></v-divider>
              </v-card>
            </template>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu offset-y rounded :disabled="QtyList">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-badge
            class="mr-10"
            :content="messages"
            :value="messages"
            color="blue"
            overlap
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  large
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  @click="OpenCart"
                >
                  mdi-cart-variant
                </v-icon>
              </template>
              <span class="tooltip">ກະຕ່າສິນຄ້າ</span>
            </v-tooltip>
          </v-badge>
        </template>
        <v-list>
          <v-list-item link>
            <v-list-item-title
              class="mr-5"
              @click="OpenCartOrderBuy"
              style="font-family: Noto Sans Lao"
            >
              <v-badge
                :content="CountOrderQty"
                :value="CountOrderQty"
                color="error"
                overlap
              >
                <v-icon> mdi-cart</v-icon>
              </v-badge>
              ລາຍການສັ່ງຊື້</v-list-item-title
            >
          </v-list-item>
          <v-list-item link>
            <v-list-item-title
              @click="OpenCartOrderCut"
              style="font-family: Noto Sans Lao"
            >
              <v-badge
                :content="CountOrderCut_Item"
                :value="CountOrderCut_Item"
                color="error"
                overlap
              >
                <v-icon> mdi-cart</v-icon>
              </v-badge>
              ລາຍການສັ່ງຕັດ</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="dialog" persistent max-width="400px">
        <v-card>
          <v-card-title>
            <span id="dialog">ແກ້ໄຂຮູບໂປຣໄຟ໌ລ</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-img
                :src="url"
                v-show="Profile"
                max-height="450"
                aspect-ratio="1.7"
                contain
              ></v-img>
              <v-divider class="ml-7"></v-divider>
              <input
                prepend-icon="mdi-camera"
                type="file"
                class="file-upload ml-7 mt-5"
                @change="onFileSelected"
                show-size
                label="Image input"
                required
              />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              id="btntext"
              class="mb-10 ml-16"
              text
              @click.stop="dialog = false"
            >
              ຍົກເລິກ
            </v-btn>
            <v-btn
              color="blue darken-1"
              id="btntext"
              class="mb-10 ml-16"
              text
              @click="Edit_Profile"
            >
              ບັນທຶກ
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-menu offset-y rounded>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-avatar v-bind="attrs" v-on="{ ...menu }">
            <v-img :src="FectCustomer_Profile(Profile_Img)"></v-img>
          </v-avatar>
        </template>
        <v-list>
          <v-row justify="start">
            <v-avatar class="ml-8 mt-8" @click="dialog = true">
              <v-img :src="FectCustomer_Profile(Profile_Img)"></v-img>
            </v-avatar>
            <h1 class="cus_name mt-9 mr-5">{{ Cus_Name }}</h1>
          </v-row>
          <div class="profile mt-5 mb-4" @click="Customer_Info">
            ຈັດການຂໍ້ມູນສ່ວນຕົວ
          </div>
          <v-divider></v-divider>
          <v-list-item link class="mt-4" @click="OrderBuy_HistoryPage">
            <v-list-item-title class="mr-5" style="font-family: Noto Sans Lao">
              <v-icon large> mdi-history</v-icon>
              ປະຫັວດການສັ່ງຊື້</v-list-item-title
            >
          </v-list-item>
          <v-list-item link @click="OrderCut_HistoryPage">
            <v-list-item-title style="font-family: Noto Sans Lao">
              <v-icon large> mdi-history</v-icon>
              ປະຫວັດການສັ່ງຕັດ</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            link
            @click="Member_Register"
            v-if="this.status != 'Member' && this.status != 'Confirm'"
          >
            <v-list-item-title style="font-family: Noto Sans Lao">
              <v-icon large> mdi-account-plus</v-icon>
              ສະມັກເປັນຕົວແທນ</v-list-item-title
            >
          </v-list-item>
          <v-list-item link @click="LogOUT">
            <v-list-item-title style="font-family: Noto Sans Lao">
              <v-icon large> mdi-logout-variant</v-icon>
              ອອກລະບົບ</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    
    <router-view></router-view>
    <v-footer color="#EEEEEE" elevation="1">
      <v-row
        justify="space-around"
        v-for="(item, index) in Shop_Info"
        :key="index"
      >
        <v-col cols="12" sm="4">
          <h1 class="mystyle">ສິນຄ້າ</h1>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
            <h1 class="ms" @click="HomePage">ໜ້າຫຼັກ</h1>
          </v-row>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
            <h1 class="ms" @click="ProductPage">ສິນຄ້າໃໝ່</h1>
          </v-row>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
            <h1 class="ms" @click="ProductPage">ສິນຄ້າຍອດນິຍົມ</h1>
          </v-row>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
            <h1 class="ms" @click="OrderCutPage">ສັ່ງຕັດເຄື່ອງ</h1>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4">
          <h1 class="mystyle">ກ່ຽວກັບພວກເຮົາ</h1>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
            <h1 class="ms" @click="AboutPage">ກ່ຽວກັບຮ້ານ ດາດ້າແຟຊັ່ນ</h1>
          </v-row>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
            <h1 class="ms" @click="GiveInformationPage">
              ແຫຼ່ງໃຫ້ຂໍ້ມູນເພີ່ມເຕີມ
            </h1>
          </v-row>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
            <a
              class="ms"
              href="https://www.google.com/maps/place/%E0%BA%95%E0%BA%B0%E0%BA%AB%E0%BA%BC%E0%BA%B2%E0%BA%94+%E0%BA%AB%E0%BA%BB%E0%BA%A7%E0%BA%82%E0%BA%BB%E0%BA%A7+(Hua+Khua+Market)/@17.975495,102.650165,16z/data=!4m5!3m4!1s0x0:0x95a541852ab6540!8m2!3d17.9754946!4d102.6501647?hl=en"
              target="brank"
              style="font-family: NotoSansLao; color: black"
              >ທີ່ຕັ້ງຂອງຮ້ານ</a
            >
          </v-row>
          <v-row>
            <v-icon large class="ic">mdi-chevron-right</v-icon>
            <h1 class="ms" @click="Developer_infoPage">
              ຂໍ້ມູນກ່ຽວກັບຜູ້ພັດທະນາ
            </h1>
          </v-row>
        </v-col>
        <v-col>
          <h1 class="mystyle">ຕິດຕໍ່ພວກເຮົາ</h1>
          <v-row dense>
            <v-icon large color="black" id="icc"> mdi-phone-forward </v-icon>
            <h1 id="contact">+{{ item.tel }} , {{ item.whatsapp }}</h1>
          </v-row>
          <v-row dense>
            <v-icon large color="black" id="icc"> mdi-email </v-icon>
            <a href="mailto:houayang200786@gmail.com" id="contact">{{
              item.email
            }}</a>
          </v-row>
          <v-row dense>
            <v-icon large color="black" id="icc"> mdi-facebook </v-icon>
            <a
              href="https://www.facebook.com/DaDa-Fashion-Khaub-Ncaws-Hmoob-2392198184126093"
              target="brank"
              id="contact"
              >DaDa Fashion</a
            >
          </v-row>
        </v-col>
        <v-col
          color="#FF9800"
          class="text-center"
          cols="12"
          style="font-family: NotoSansLao; color: black; font-size: 25px"
        >
          <strong>Year </strong>{{ new Date().getFullYear() }} —
          <strong>DaDa Fashion</strong>
        </v-col>
      </v-row>
              <v-snackbar color="primary" top v-model="snackbar" :timeout='700'>
          <v-row no-gutters class="mx-2 my-2">
            <v-icon color="#ffffff" size="60" class="mr-5">mdi-alert-circle-outline</v-icon>
            <p style="font-size:2rem;font-family:Noto Sans Lao; margin-top:20px">ບໍ່ມີສິນຄ້າໃນກະຕ່າ....!</p>
          </v-row>
          <v-row no-gutters class="mx-2 justify-end">
            <v-btn text style="font-family:Noto Sans Lao;font-size:20px" @click="snackbar = false">
            <v-icon color="error" left large>mdi-close</v-icon>ປິດ</v-btn>
          </v-row>
      </v-snackbar>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    notification: 0,
    snackbar:false,
    show: false,
    dialog: false,
    Cus_Name: "",
    Profile_Img: "",
    Notification_Items: {
      header: "",
      avatar: "",
      title: "",
      subtitle: "",
      ago_text: "",
    },
  }),
  mounted() {
    this.$store.dispatch("Shop_Info");
    //  this.$store.dispatch("GetCart_Items");
    // this.$store.dispatch("Customer_List");
    // alert((localStorage.getItem('CartList')))
    // alert(JSON.parse(localStorage.getItem('Customer_Info')).cus_id)
    this.Cus_Name = JSON.parse(localStorage.getItem("Customer_Info")).full_name;
    this.status = JSON.parse(localStorage.getItem("customer_status")).status;
    this.Profile_Img = JSON.parse(
      localStorage.getItem("customer_profile")
    ).image;
    // this.GetNotifications_Data();
    this.Count_Notification_Items();
  },
  computed: {
    QtyList() {
      if (this.CountOrderQty > 0 && this.CountOrderCut_Item > 0) return false;
      return true;
    },
    CountOrderQty() {
      return this.$store.getters.CountOrderQty;
    },
    CountOrderCut_Item() {
      return this.$store.getters.CountOrderCut_Item;
    },
    messages() {
      return this.$store.getters.messages;
    },
    customerInfo() {
      //  alert(JSON.stringify(this.$store.getters.GetcustomerLogin));
      return this.$store.state.customerInfo;
    },
    Shop_Info() {
      return this.$store.state.Shop_Info;
    },
  },
  created() {
    this.GetNotifications_Data();
  },
  methods: {
    async GetNotifications_Data() {
      var items = [];
      var dString = "";
      var cus_id = JSON.parse(localStorage.getItem("Customer_Info")).cus_id;
      var notice_list = await axios.get(
        `http://localhost:2021/api/comment/getbyval/${cus_id}`
      );
      // alert(JSON.stringify(notice_list.data));
      for (const key in notice_list.data) {
        const element = notice_list.data[key];
        dString =
          moment(String(element.receive_date)).format("YYYY-MM-DD") +
          " " +
          element.receive_time;

        var d1 = new Date(dString);
        var d2 = new Date();
        var t2 = d2.getTime();
        var t1 = d1.getTime();
        var d1Y = d1.getFullYear();
        var d2Y = d2.getFullYear();
        var d1M = d1.getMonth();
        var d2M = d2.getMonth();

        var time_obj = {};
        time_obj.year = d2.getFullYear() - d1.getFullYear();
        var weeks = parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
        //  time_obj.week = parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
        if (weeks >= 1 && weeks <= 4) {
          time_obj.week = parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
        } else if (weeks > 4) {
          time_obj.month = d2M + 12 * d2Y - (d1M + 12 * d1Y);
        } else if (weeks < 1) {
          time_obj.week = parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
          time_obj.day = parseInt((t2 - t1) / (24 * 3600 * 1000));
          time_obj.hour = parseInt((t2 - t1) / (3600 * 1000));
          time_obj.minute = parseInt((t2 - t1) / (60 * 1000));
          time_obj.second = parseInt((t2 - t1) / 1000);
        }

        for (const obj_key in time_obj) {
          if (time_obj[obj_key] == 0) {
            delete time_obj[obj_key];
          }
        }
        var ago_text = "just now";

        if (typeof Object.keys(time_obj)[0] != "undefined") {
          var time_key = Object.keys(time_obj)[0];
          var time_val = time_obj[Object.keys(time_obj)[0]];
          time_key += time_val > 1 ? "s" : "";
          ago_text = time_val + " " + time_key + " ago";
        }

        this.List = {
          avatar: element.image,
          title: element.comment_id,
          subtitle:
            element.emp_name +
            " " +
            element.emp_surname +
            "-" +
            element.receive_content,
          ago_text: ago_text,
        };
        items.push(this.List);
      }
      this.Notification_Items = items;
      //  alert(JSON.stringify(this.Notification_Items));
    },
    async Count_Notification_Items() {
      var cus_id = JSON.parse(localStorage.getItem("Customer_Info")).cus_id;
      var count_item = await axios.get(
        `http://localhost:2021/api/comment/getcount-item/${cus_id}`
      );
      for (const key in count_item.data) {
        const element = count_item.data[key];
        this.notification = element.item;
      }
      //  alert(JSON.stringify(this.dString))
    },
    See_Notifications() {
      this.GetNotifications_Data();
      if (this.notification <= 0) {
        return;
      }
      var cus_id = JSON.parse(localStorage.getItem("Customer_Info")).cus_id;
      this.$store.dispatch("See_Notifications", {
        receive_status: "2",
        cus_id: cus_id,
      });
      this.notification = 0;
    },
    OpenCart() {
      if (
        this.$store.getters.CountOrderQty == 0 &&
        this.$store.getters.CountOrderCut_Item == 0
      ) {
         this.snackbar=true
        return;
      } else {
        if (
          this.$store.getters.CountOrderQty > 0 &&
          this.$store.getters.CountOrderCut_Item > 0
        ) {
          return;
        } else if (this.$store.getters.CountOrderQty > 0) {
          this.$router.push(`/Customer/Customer_OrderBuy_Cart`);
        } else if (this.$store.getters.CountOrderCut_Item > 0) {
          this.$router.push(`/Customer/Customer_OrderCut_Cart`);
        }
      }
    },
    OpenCartOrderBuy() {
      this.$router.push(`/Customer/Customer_OrderBuy_Cart`);
    },
    OpenCartOrderCut() {
      this.$router.push(`/Customer/Customer_OrderCut_Cart`);
    },
    HomePage() {
      this.$router.push(`/Customer`);
    },
    ProductPage() {
      this.$router.push(`/Customer/Product`);
    },
    Customer_Info() {
      this.$router.push(`/Customer/Customer_Info`);
    },
    OrderCutPage() {
      this.$router.push(`/Customer/ordercut`);
    },
    AboutPage() {
      this.$router.push(`/Customer/About`);
    },
    GiveInformationPage() {
      this.$router.push(`/Customer/GiveInformation`);
    },
    ContactUsPage() {
      this.$router.push(`/Customer/ContactUs`);
    },
    Developer_infoPage() {
      this.$router.push(`/Customer/Developer_info`);
    },
    OrderBuy_HistoryPage() {
      this.$router.push(`/Customer/OrderBuy_History`);
    },
    OrderCut_HistoryPage() {
      this.$router.push(`/Customer/OrderCut_History`);
    },
    LogOUT() {
      localStorage.setItem("Customer_Info", JSON.stringify(""));
      localStorage.setItem("customer_status", JSON.stringify(""));
      localStorage.setItem("customer_profile", JSON.stringify(""));
      this.$router.push(`/`);
      window.location.reload();
    },
    Member_Register() {
      this.$router.push(`/Customer/Member_Register`);
    },

    onFileSelected($event) {
      const reader = new FileReader();
      reader.readAsDataURL($event.target.files[0]);
      reader.onload = (e) => {
        this.url = e.target.result;
      };
      this.Profile = $event.target.files[0].name;
      // alert(JSON.stringify(this.url));
      this.location.reload();
    },

    async TestTimeAgo() {
      // var dString = "2021-07-08 12:50:00";

      // var d1 = new Date(dString);
      // var d2 = new Date();
      // var t2 = d2.getTime();
      // var t1 = d1.getTime();
      // var d1Y = d1.getFullYear();
      // var d2Y = d2.getFullYear();
      // var d1M = d1.getMonth();
      // var d2M = d2.getMonth();

      // var time_obj = {};
      // time_obj.year = d2.getFullYear() - d1.getFullYear();
      // var weeks = parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
      // //  time_obj.week = parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
      // if (weeks >= 1 && weeks <= 4) {
      //   time_obj.week = parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
      // } else if (weeks > 4) {
      //   time_obj.month = d2M + 12 * d2Y - (d1M + 12 * d1Y);
      // } else if (weeks < 1) {
      //   time_obj.week = parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
      //   time_obj.day = parseInt((t2 - t1) / (24 * 3600 * 1000));
      //   time_obj.hour = parseInt((t2 - t1) / (3600 * 1000));
      //   time_obj.minute = parseInt((t2 - t1) / (60 * 1000));
      //   time_obj.second = parseInt((t2 - t1) / 1000);
      // }

      // for (const obj_key in time_obj) {
      //   if (time_obj[obj_key] == 0) {
      //     delete time_obj[obj_key];
      //   }
      // }
      // var ago_text = "just now";

      // if (typeof Object.keys(time_obj)[0] != "undefined") {
      //   var time_key = Object.keys(time_obj)[0];
      //   var time_val = time_obj[Object.keys(time_obj)[0]];
      //   time_key += time_val > 1 ? "s" : "";
      //   ago_text = time_val + " " + time_key + " ago";
      //   alert(JSON.stringify(ago_text));
      // }
      var employeeID = "";
      var year = new Date().getFullYear();
      var result = await axios.get(
        "http://localhost:2021/api/employee/getMaxID"
      );
      for (const key in result.data) {
        const element = result.data[key];
        employeeID = element.empID;
      }
      if (employeeID == "") {
        employeeID = "Emp" + year + "1";
      } else {
        var emp = employeeID.slice(employeeID, 3);
        var num = employeeID.slice(3, employeeID.length);
        var lastnum = num.slice(4, num.length);
        var newnum = parseInt(lastnum) + 1;
        employeeID = emp + year + newnum;
        alert(employeeID);
      }
    },

    //  getMaxEmployeeID: function () {
    //    var i=8

    // },

    Selected_Notifications(item) {
      var itm = item.slice(item, 3);
      if (itm == "DOB") {
        this.$router.push(`/Customer/OrderBuy_History`);
      }
      if (itm == "DOC") {
        this.$router.push(`/Customer/OrderCut_History`);
      }
      if (itm == "CUS") {
        this.$router.push(`/Customer/Customer_Info`);
      }
    },
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/Employees/" + img);
      } else {
        imageUrl = require("@/assets/images/Employees/user.png");
      }
      return imageUrl;
    },
    FectCustomer_Profile(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/customer/" + img);
      } else {
        imageUrl = require("@/assets/images/Employees/user.png");
      }
      return imageUrl;
    },

    Edit_Profile() {
      var cus_id = JSON.parse(localStorage.getItem("Customer_Info")).cus_id;
      this.$store.dispatch("Edit_Customer_Profile", {
        image: this.Profile,
        cus_id: cus_id,
      });
      window.location.reload();
    },
  },
};
</script>
<style>
.mystyle {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 20px;
  color: black;
  text-align: left;
  margin-left: 20px;
  font-family: "Noto Sans Lao";
  font-size: 30px;
}
.css1 {
  margin-top: 120px;
  background: #009933;
  color: aliceblue;
  text-align: center;
  height: 60px;
}
.ic {
  margin-top: 17px;
  margin-left: 15px;
  color: white;
}
#icc {
  margin-top: 20px;
  margin-left: 20px;
}
.ms {
  margin-top: 20px;
  margin-left: 20px;
  color: black;
  text-align: left;
  font-family: "Noto Sans Lao";
  font-size: 20px;
  font-weight: normal;
}
.ms:hover {
  margin-top: 20px;
  margin-left: 20px;
  color: black;
  text-align: left;
  font-family: "Noto Sans Lao";
  font-size: 20px;
  font-weight: normal;
  text-decoration: underline;
}
.css {
  color: black;
  margin-left: 20px;
  font-family: "Noto Sans Lao";
  font-size: 20px;
  text-decoration: none;
}
.css:hover {
  color: #00c853;
  cursor: pointer;
  margin-left: 20px;
  font-family: "Noto Sans Lao";
  text-decoration: none;
}
#title {
  text-align: center;
  margin-top: 15px;
  margin-left: 20px;
  font-size: 50px;
  font-family: "Noto Sans Lao";
  font-weight: bold;
}
.shopname {
  font-weight: bold;
  font-size: 50px;
  font-family: "Noto Sans Lao";
  text-align: center;
}
a:link {
  color: black;
  text-decoration: none;
}
#contact {
  font-size: 25px;
  font-family: "Noto Sans Lao";
  text-align: left;
  color: black;
  margin-top: 20px;
  margin-left: 20px;
  text-decoration: underline;
}
.tooltip {
  color: white;
  margin-left: 20px;
  font-family: "Noto Sans Lao";
  font-size: 15px;
  text-decoration: none;
  font-weight: normal;
}
.cus_name {
  color: black;
  margin-left: 20px;
  font-family: "Noto Sans Lao";
  font-size: 20px;
  text-decoration: none;
  font-weight: normal;
}
.profile {
  color: blue;
  margin-left: 20px;
  font-family: "Noto Sans Lao";
  font-size: 20px;
  text-decoration: none;
  font-weight: normal;
}
.profile:hover {
  cursor: pointer;
  margin-left: 20px;
  font-family: "Noto Sans Lao";
  text-decoration: none;
}
.scroll_list {
  width: 200px;
  height: 600px;
  overflow-y: auto;
}
.scroll_list a {
  background-color: cadetblue;
  color: black;
  display: block;
  padding: 120px;
  text-decoration: none;
}
.scroll_list a:hover {
  background-color: cadetblue;
  cursor: pointer;
}
.scroll_list a.active {
  background-color: red;
}
.homepage {
  font-family: "Noto Sans Lao";
  font-size: 25px;
}
.homepage:hover {
  cursor: pointer;
  font-family: "Noto Sans Lao";
  font-size: 25px;
}
</style>