export default {
    productList: [],
    products: [],
    product: null,
    ProductCart: [],
    customerInfo: null,//for log In
    Customer_detail: [],// for Check.......

    OrderCutCartItem: [],
    imagelist: [],

    // for order_cut
    productID: "",
    Category: [],
    Shop_Info: null,
    TopProduct:[],

    Country:[],
}