<template>
  <v-card flat tile>
    <h1 id="csss" class="text-md-center mb-10 mt-7">
      ***ສະແດງລາຍລະອຽດທັງໝົດຂອງສິນຄ້າ***
    </h1>
    <v-divider></v-divider>
    <div>
      <v-row v-for="pro in product" v-bind:key="pro.proID">
        <v-col cols="12" sm="6" md="4" lg="5" class="mt-7 mb-7">
          <v-card class="ml-5">
            <div class="ml-3">
              <v-carousel height="600" show-arrows v-model="keymodel" hide-delimiter-background>
                <v-carousel-item v-for="(img, i) in pro.Image" :key="i">
                  <v-card
                    class="mx-auto"
                    height="100%"
                    aspect-ratio="1.7"
                    :img="fectImg(img.img_url)"
                  >
                  </v-card>
                </v-carousel-item>
              </v-carousel>
              <v-divider></v-divider>
              <v-row no-gutters justify="center">
                <div
                  class="d-flex flex-nowrap ma-3"
                  align="center"
                  v-for="(img, i) in pro.Image"
                  :key="i"
                >
                  <v-card
                    outlined
                    class="elevation-0"
                    height="70"
                    id="btn-action"
                    width="100"
                    color="primary"
                    v-ripple="{ class: `error--text` }"
                    :img="fectImg(img.img_url)"
                    @click="mykey(i)"
                  >
                    <v-icon color="green" size="70">mdi-{{ img.class }}</v-icon>
                  </v-card>
                </div>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col class="mt-7 ml-9">
          <v-row>
            <label id="myfont" class="mt-7">*ຊື່ສິນຄ້າ:</label>
            <label id="myfont" class="mt-7 ml-7" ref="proname">{{
              pro.proName
            }}</label>
          </v-row>
          <v-row>
            <h1 id="myfont" class="mt-7">*ປະເພດ:</h1>
            <h1 id="myfont" class="mt-7 ml-7">{{pro.Category}}</h1>
          </v-row>
          <v-row>
            <h1 id="myfont" class="mt-7">*ສີ:</h1>
            <v-text-field
              id="textfield"
              v-model="txtcolor"
              class="mt-5 mr-9 ml-15"
              outlined
              placeholder="Enter Color"
              :counter="50"
              required
              :error-messages="ColorErrors"
              @input="$v.txtcolor.$touch()"
              @blur="$v.txtcolor.$touch()"
            ></v-text-field>
          </v-row>
          <v-row>
            <h1 id="myfont" class="mt-7">*ເບີ້:</h1>
            <v-text-field
             id="textfield"
              v-model="txtsize"
              class="mt-5 mr-9 ml-15"
              outlined
              placeholder="Enter Size"
              :counter="10"
              required
              :error-messages="SizeError"
              @input="$v.txtsize.$touch()"
              @blur="$v.txtsize.$touch()"
            ></v-text-field>
          </v-row>
          <v-row>
            <h1 id="myfont" class="mt-7">*ລາຄາ:</h1>
            <h1 id="myfont" class="mt-7 ml-7">{{ String(pro.Price).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }} ກິບ</h1>
          </v-row>
          <v-row>
            <h1 id="myfont" class="mt-7">*ຈໍານວນທີ່ຕ້ອງການສັ່ງຕັດ:</h1>
            <v-row class="mt-7 ml-7">
              <v-text-field
              id="textfield"
                class="mr-15 text-center tcenter"
                filled
                dense
                rounded
                prepend-inner-icon="mdi-minus"
                append-icon="mdi-plus"
                v-model="pro.quantity"
                @keypress="isNumber($event)"
                @keyup="CheckNumber(pro)"
              >
                <template slot="append">
                  <v-btn
                    style="margin-top: -7px; margin-right: -20px"
                    color="green darken-4"
                    icon
                  >
                    <v-icon large @click="IncrementQty(pro)">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <template slot="prepend-inner">
                  <v-btn
                    style="margin-top: -7px; margin-left: -20px"
                    color="green darken-4"
                    icon
                  >
                    <v-icon large @click="DecrementQty(pro)">mdi-minus</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-row>
          </v-row>
          <v-row>
            <h1 id="myfont" class="mt-7">*ລາຍລະອຽດເພີ່ມເຕີມ:</h1>
            <v-textarea
            id="textfield"
              v-model="txtdetail"
              class="mt-7 mr-9 ml-9"
              placeholder="Enter Some More Details"
              outlined
              :error-messages="DetailError"
              :counter="500"
              required
              @input="$v.txtdetail.$touch()"
              @blur="$v.txtdetail.$touch()"
            ></v-textarea>
          </v-row>
          <v-btn
            @click="AddToCart(pro.proName,pro.Category, pro.quantity,pro.Price,pro.Unit, pro.Image)"
            class="mt-9 mb-7"
            rounded
            color="#D9F2FE"
            style="font-family: Noto Sans Lao"
            id="btn-add"
          >
            <v-icon left medium> mdi-cart-plus </v-icon>
            ເພີ່ມໃສ່ກະຕ່າ</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>


<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    txtsize: { required, maxLength: maxLength(10) },
    txtcolor: { required, maxLength: maxLength(50) },
    txtdetail: { required, maxLength: maxLength(500) },
  },
  data: () => ({
    snackbar: false,
    text: "ທ່ານໄດ້ເພີ່ມສິນຄ້າ",
    timeout: 1000,
    addqty: null,
    txtsize: "",
    txtcolor: "",
    txtdetail: "",
    keymodel: "",
  }),
  props: ["id"],
  computed: {
    product() {
      //alert(JSON.stringify(this.$store.state.product));
      return this.$store.state.product;
    },

    SizeError() {
      const errors = [];
      if (!this.$v.txtsize.$dirty) return errors;
      !this.$v.txtsize.maxLength &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.txtsize.required &&
        errors.push("ປ້ອນຂະໜາດ ຫຼື ເບີ້ທີ່ຕ້ອງການສັ່ງຕັດ");
      return errors;
    },
    ColorErrors() {
      const errors = [];
      if (!this.$v.txtcolor.$dirty) return errors;
      !this.$v.txtcolor.maxLength &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.txtcolor.required && errors.push("ປ້ອນສີທີ່ຕ້ອງການສັ່ງຕັດ");
      return errors;
    },
    DetailError() {
      const errors = [];
      if (!this.$v.txtdetail.$dirty) return errors;
      !this.$v.txtdetail.maxLength &&
        errors.push("Detail must be at most 500 characters long");
      !this.$v.txtdetail.required && errors.push("ປ້ອນລາຍລະອຽດເພີ່ມເຕີມ");
      return errors;
    },
  },
  mounted() {
    this.$store.dispatch("getProductByID", this.id);
    //  alert(JSON.stringify(this.id));
  },
    watch: {
    keymodel(val) {
      for (const key in this.product) {
        const element = this.product[key];
        var imagelist=element.Image
      }
      for (const key in imagelist) {
        const element = imagelist[key];
        if (key != val) {
          element.class = "";
        } else {
          element.class = "eye-check-outline";
        }
      }
    },
  },
  methods: {
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/images/D (32).jpg");
      }
      return imageUrl;
    },

    DecrementQty(pro) {
      if (pro.quantity <= 1) return;
      pro.quantity--;
    },
    IncrementQty(pro) {
      pro.quantity++;
    },
    isNumber: function ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    CheckNumber(pro) {
      if (
        (typeof pro.quantity == "string" && pro.quantity == "") ||
        pro.quantity == null ||
        pro.quantity < 1
      ) {
        pro.quantity = 1;
      }
      pro.quantity = parseInt(pro.quantity, 10);
    },

    AddToCart(proname,category, quantity,price,unit, Image) {
      var image = "";
      if (this.txtsize == "" || this.txtcolor == "" || this.txtdetail == "") {
        this.$v.$touch();
        return;
      }
      for (const key in Image) {
        const element = Image[key];
        image = element.img_url;
      }
      this.$store.dispatch("OrderCutCart", {
        // product: this.product,
        proID: this.id,
        proName: proname,
        Category:category,
        Color: this.txtcolor,
        Size: this.txtsize,
        Quantity: quantity,
        Unit:unit,
        Price:price,
        Detail: this.txtdetail,
        image,
      });
    },
    mykey(i) {
      this.keymodel = i;
    },
  },
};
</script>
<style>
#txttext {
  margin-right: 30px;
  background-color: white;
}
#btn-action:hover {
  color: green;
  cursor: pointer;
  background-color: "red";
}
#btn-add {
  font-size: 20px;
  height: 45px;
}
</style>

	<style type="text/css">
.pic-box {
  width: 500px;
  height: 500px;
  border: 1px solid #eee;
}
</style>
<style scoped>
.tcenter >>> input {
  text-align: center;
}
</style>