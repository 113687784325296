<template>
  <v-card flat tile>
    <h1 id="csss" class="text-md-center mb-10 mt-7">
      ***ສະແດງລາຍລະອຽດທັງໝົດຂອງສິນຄ້າ***
    </h1>
    <v-divider></v-divider>
    <div>
      <v-row v-for="pro in product" v-bind:key="pro.proID">
        <v-col cols="12" sm="6" md="4" lg="5" class="mt-7 mb-7">
          <v-card class="ml-4">
            <div class="ml-3">
              <v-carousel
                height="500"
                width="500"
                show-arrows
                hide-delimiter-background
                v-model="keymodel"
              >
                <v-carousel-item v-for="(img, i) in pro.Image" :key="i">
                  <v-card
                    class="mx-auto elevation-0"
                    height="500"
                    width="500"
                    aspect-ratio="1.7"
                     :img="fectImg(img.img_url)"
                  >
                  </v-card>
                </v-carousel-item>
              </v-carousel>
              <v-row no-gutters justify="center">
                <div
                  class="d-flex flex-nowrap ma-3"
                  align="center"
                  v-for="(img, i) in pro.Image"
                  :key="i"
                >
                  <v-card
                    outlined
                    height="70"
                    width="100"
                    color="#c977ff"
                    v-ripple="{ class: `error--text` }"
                    :img="fectImg(img.img_url)"
                    @click="mykey(i)"
                  >
                    <v-icon color="green" size="70">mdi-{{ img.class }}</v-icon>
                  </v-card>
                </div>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col class="mt-7 ml-9">
          <v-row>
            <label id="myfont" class="mt-7">*ຊື່ສິນຄ້າ/Product Name:</label>
            <label id="myfont" class="mt-7 ml-7" ref="proname">{{
              pro.proName
            }}</label>
          </v-row>
          <v-row>
            <h1 id="myfont" class="mt-7">*ປະເພດສິນຄ້າ/Category:</h1>
            <h1 id="myfont" class="mt-7 ml-7">{{ pro.Category }}</h1>
          </v-row>
          <v-row>
            <h1 id="myfont" class="mt-7">*ສີ/Color:</h1>
            <h1 id="myfont" class="mt-7 ml-7">{{ pro.Color }}</h1>
          </v-row>
          <v-row>
            <h1 id="myfont" class="mt-7">*ເບີ້/Size:</h1>
            <h1 id="myfont" class="mt-7 ml-7">{{ pro.Size }}</h1>
          </v-row>
          <v-row>
            <h1 id="myfont" class="mt-7">*ລາຄາ/Price:</h1>
            <h1 id="myfont" class="mt-7 ml-7">
              {{ String(pro.Price).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }}
            </h1>
            <h1 id="myfont" class="mt-7 ml-5">ກິບ</h1>
          </v-row>
          <v-row>
            <h1 id="myfont" class="mt-7">*ວັນທີ່ຜະລິດ/Published Date:</h1>
            <h1 id="myfont" class="mt-7 ml-7">{{ pro.create_date }}</h1>
          </v-row>
          <v-row>
            <h1 id="myfont" class="mt-7">
              *ເພີ່ມຈໍານວນທີ່ຕ້ອງການສັ່ງຊື້/Add Quantity:
            </h1>
            <v-row class="mt-7 ml-7">
              <v-text-field
                class="mr-15 text-center tcenter"
                filled
                dense
                rounded
                prepend-inner-icon="mdi-minus"
                append-icon="mdi-plus"
                v-model="pro.quantity"
                @keypress="isNumber($event)"
                @keyup="CheckNumber(pro)"
              >
                <template slot="append">
                  <v-btn
                    style="margin-top: -7px; margin-right: -20px"
                    color="green darken-4"
                    icon
                  >
                    <v-icon large @click="IncrementQty(pro)">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <template slot="prepend-inner">
                  <v-btn
                    style="margin-top: -7px; margin-left: -20px"
                    color="green darken-4"
                    icon
                  >
                    <v-icon large @click="DecrementQty(pro)">mdi-minus</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-row>
          </v-row>
          <v-btn
            @click="AddToCart(pro.proName, pro.quantity, pro.Price, pro.Image)"
            class="mt-9 mb-7"
            rounded
            color="#D9F2FE"
            style="font-family: Noto Sans Lao"
            id="btn-add"
          >
            <v-icon left medium> mdi-cart-plus </v-icon>
            ເພີ່ມໃສ່ກະຕ່າ</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>


<script>
export default {
  data: () => ({
    snackbar: false,
    text: "ທ່ານໄດ້ເພີ່ມສິນຄ້າ",
    timeout: 1000,
    addqty: null,
    keymodel: "",
  }),
  props: ["id"],
  computed: {
    product() {
      //alert(JSON.stringify(this.$store.state.product));
      return this.$store.state.product;
    },
  },
  mounted() {
    this.$store.dispatch("getProductByID", this.id);
    // alert(JSON.stringify(this.id));
    // this.SelectedById();
  },
  watch: {
    keymodel(val) {
      for (const key in this.product) {
        const element = this.product[key];
        var imagelist=element.Image
      }
      for (const key in imagelist) {
        const element = imagelist[key];
        if (key != val) {
          element.class = "";
        } else {
          element.class = "eye-check-outline";
        }
      }
    },
  },
  methods: {
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/images/D (32).jpg");
      }
      return imageUrl;
    },

    DecrementQty(pro) {
      if (pro.quantity <= 1) return;
      pro.quantity--;
    },
    IncrementQty(pro) {
      pro.quantity++;
    },
    isNumber: function ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    CheckNumber(pro) {
      if (
        (typeof pro.quantity == "string" && pro.quantity == "") ||
        pro.quantity == null ||
        pro.quantity < 1
      ) {
        pro.quantity = 1;
      }
      pro.quantity = parseInt(pro.quantity, 10);
    },

    AddToCart(proname, quantity, price, Image) {
      if (
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == undefined ||
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == null ||
        JSON.parse(localStorage.getItem("Customer_Info")).cus_id == ""
      ) {
        this.$router.push('/Customer_Login')
        return
      }
      this.$store.dispatch("AddProductToCart", {
        // product: this.product,
        proID: this.id,
        proName: proname,
        quantity: quantity,
        price: price,
        total: quantity * price,
        Image,
      });

      // alert(JSON.stringify(qty*price));
    },
    mykey(i) {
      this.keymodel = i;
    },
  },
};
</script>
<style>
#txtqty {
  height: 36px;
  border-style: solid;
  width: 80px;
  text-align: center;
  background-color: white;
}
#btn-action:hover {
  color: green;
  cursor: pointer;
  background-color: "#E0E0E0";
}
#btn-add {
  font-size: 20px;
  height: 45px;
}
</style>

	<style type="text/css">
.big-img {
  width: 350px;
  height: 400px;
  float: left;
  margin-top: 20px;
  margin-left: 10px;
}
.pic-box {
  width: 500px;
  height: 500px;
}
</style>
<style scoped>
.tcenter >>> input {
  text-align: center;
}
</style>