<template>
  <v-card v-if="($store.state.authMod.token.status=='Admin') || ($store.state.authMod.token.status=='admin')"  class="elevation-1">
     <v-row justify="center">
     <h2 class="mt-5 mb-7">ຈັດການຂໍ້ມູນພະນັກງານ</h2>
   </v-row>
   <v-divider></v-divider>
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="12" md="3" align="center">
          <h3 >ຂໍ້ມູນພະນັກງານ</h3>
        </v-col>
        <v-col cols="12" md="9">
          <v-row>
            <v-spacer></v-spacer>
            <v-text-field
            class="mt-4"
              v-model="search"
              append-icon="mdi-magnify"
              label="ຄົ້ນຫາຂໍ້ມູນພະນັກງານ"
              single-line
               dense
                  outlined
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <router-link to="/employee_add">
            <v-btn class="mb-2 mt-5 mr-3" style="font-size: 20px; font-weight: bold" color="#ffffff" rounded>
              <v-icon  large>
                mdi-account-plus
              </v-icon>
              ເພີ່ມພະນັກງານ
            </v-btn>
            </router-link>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
     <v-divider></v-divider>
    <v-data-table large
      :headers="headers"
      :items="employee"
      :search="search"
      fixed-header
      class="elevation-2 table"
    >
    <template v-slot:item="{ item }">
        <tr justify='center'  v-ripple="{ class: `white--text` }">
          <td>{{ item.idx }}</td>
          <td align='center' >
                <v-card class="mx-auto" height="100" width="100">
                  <v-img
                    :src="fectImg(item.image)"
                  ></v-img>
                </v-card>
          </td>
          <td>{{ item.full_name }}</td>
          <td>{{ item.gender }}</td>
          <td>{{ item.tel }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.facebook }}</td>
          <td>{{ item.village }}</td>
          <td>{{ item.district }}</td>
          <td>{{ item.province }}</td>
          <td>
            <v-row>
              <v-icon @click="EditEmployee(item)" class="mr-2" color="orange">
                mdi-pencil
              </v-icon>
              <v-icon color="error" @click="DeleteEmployee(item)">
                mdi-delete
              </v-icon>
            </v-row>
          </td>
        </tr>
      </template>
   
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title  style="font-size: 25px; font-weight: bold;font-family:NotoSansLao">ທ່ານຕ້ອງການລຶບຂໍ້ມູນພະນັກງານຄົນນີ້ບໍ...?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="closeDelete" style="font-size: 20px;font-family:NotoSansLao">ຍົກເລິກ</v-btn>
              <v-btn color="primary" text @click="deleteEmployeeConfirm" style="font-size: 20px;font-family:NotoSansLao">ຢຶນຢັນລຶບ</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      dialogDelete: false,
      deleteItem:[],
      search: "",
      headers: [
         { text: "ລໍາດັບ", value: "idx" },
        { text: "ຮູບ", value: "image",
          align: "center",
          sortable: false, },
        { text: "ຊື່ ແລະ ນາມສະກຸນ",
         value: "full_name",
         },
        { text: "ເພດ", value: "gender" },
        { text: "ເບີໂທ", value: "tel" },
        { text: "ອີແມວ", value: "email" },
        { text: "ເຟສບຸກ", value: "facebook" },
        { text: "ບ້ານ", value: "village" },
        { text: "ເມືອງ", value: "district" },
        { text: "ແຂວງ", value: "province" },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      employee:'employeeMod/employees'
    }),
    
  },
  watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
   mounted() {
    this.employeeloading();

  },
  created(){
  },
  methods:{
    ...mapActions('employeeMod', [
      'getEmployee',
      'delEmployee'
    ]),

    employeeloading(){
    this.getEmployee();
    },
      DeleteEmployee(item) {
      this.dialogDelete = true
      this.deleteItem=item;
    },
    async deleteEmployeeConfirm () {
      this.delEmployee(this.deleteItem)
        this.closeDelete()
      },
      closeDelete () {
        this.dialogDelete = false
      },
   EditEmployee(item) {
      this.$router.push(`/employee_edite/${item.emp_id}`);
    },
    fectImg(img){
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/Employees/" + img);
      } else {
        imageUrl = require("@/assets/Emty_Profile.png");
      }
      return imageUrl;
    },
  }
};
</script>
<style lang="scss" scope>
table.v-table thead th {
  font-size: 20px !important;
}
</style>