<template>
  <div>
    <v-card cols="12" sm="4" elevation="0">
      <h1 style="font-family:Noto Sans Lao;font-weight:bold;font-size:25px" class="text-md-center mb-5 mt-7">
        ***ຂໍ້ມູນກ່ຽວກັບຮ້ານ ດາດ້າແຟຊັ່ນ***
      </h1>
      <h1 style="font-family:Noto Sans Lao;font-weight:bold;font-size:25px" class="ml-7 mb-5 mt-7">*ປະຫວັດຄວາມເປັນມາ</h1>
      <h1 id="csss" class="ml-7 mr-7">
        .....ຮ້ານດາດ້າແຟຊັ່ນ ສ້າງຕັ້ງຂື້ນ ແລະ ເລີ່ມເປີດບໍລິການໃນວັນທີ 24 ເດືອນ
        ກຸມພາ ປີ 2019. ໂດຍແມ່ນທ່ານ ນາງ ຮົ້ວຢ່າງເປັນເຈົ້າຂອງຮ້ານທັງເປັນຜູ້ບໍລິຫານ
        ແລະ ຈັດການທຸກຢ່າງພາຍໃນຮ້ານ. ການເປີດບໍລິການແມ່ນທຸກໆມື້ເລີ່ມແຕ່ເວລາ 8
        ໂມງເຊົ້າ ຫາ 5 ໂມງແລງ.
        ຮ້ານດາດ້າແຟຊັ່ນເປັນຮ້ານຊ່າງຕັດ-ຂາຍເສື້ອຜ້າມີທັງຂາຍສົ່ງ ແລະ ຂາຍຍ່ອຍ
        ສາມາດຂາຍສີນຄ້າແບບອອຟລາຍ(ໜ້າຮ້ານ) ແລະ ຂາຍອອນລາຍໂດຍຜ່ານແວບໄຊ໌
        ດາດ້າແຟຊັ່ນ.... ສິນຄ້າພາຍໃນຮ້ານມີຫຼາຍປະເພດຄື: ຊຸດມົ້ງ, ຊຸດແຟຊັ່ນທົ່ວໄປ,
        ເສື້ອ, ໂສ້ງ, ສີ້ນ ແລະ ອື່ນໆ. ຮ້ານດາດ້າແຟຊັ່ນ ຕັ້ງຢູ່ບ້ານ ຫົວຂົວ, ເມືອງ
        ໄຊເສດຖາ, ນະຄອນຫລວງວຽງຈັນ.
      </h1>
          <h1 style="font-family:Noto Sans Lao;font-weight:bold;font-size:25px" class="ml-7 mb-5 mt-10">*ທີ່ຕັ້ງ</h1>
      <h1 id="csss" class="ml-7 mr-7 mb-10">
        ......ຮ້ານດາດ້າແຟຊັ່ນ ຕັ້ງຢູ່ບ້ານ ຫົວຂົວ, ເມືອງ ໄຊເສດຖາ,
        ນະຄອນຫລວງວຽງຈັນ.
      </h1>
      <v-row justify="center">
        <v-card elevation="2" height="500" width="500" class="mb-15 mt-10">
          <v-carousel
            cycle
            height="500"
            hide-delimiter-background
            show-arrows-on-hover
            :show-arrows="false"
          >
            <v-carousel-item v-for="img in items" :src="img.src" :key="img.id">
            </v-carousel-item>
          </v-carousel>
        </v-card>
        <h2 style="font-family:Noto Sans Lao;font-weight:bold;font-size:25px;margin-top:80px;margin-left:40px">ເຈົ້າຂອງຮ້ານ:</h2>
         <h2 style="font-family:Noto Sans Lao;margin-top:80px;margin-left:20px">ນາງ ຮົ້ວຢ່າງ</h2>
      </v-row>
      <v-divider class="mb-9"></v-divider>
      <v-row justify="start">
        <v-col cols="12" sm="8" md="6" lg="4">
          <h1 class="ml-10" style="font-family:Noto Sans Lao;font-weight:bold;font-size:25px">*ສິນຄ້າວັດຖຸດິບ</h1>
          <v-card
            elevation="2"
            height="500"
            width="450"
            class="mb-15 mt-10 ml-10"
          >
            <v-carousel
              cycle
              height="500"
              hide-delimiter-background
              show-arrows-on-hover
              :show-arrows="false"
            >
              <v-carousel-item
                v-for="img in cloths"
                :src="img.src"
                :key="img.id"
              >
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
        <v-col>
          <h1 class="ml-6" style="font-family:Noto Sans Lao;font-weight:bold;font-size:25px">*ສິນຄ້າ</h1>
          <v-card
            elevation="2"
            height="500"
            width="450"
            class="mb-15 mt-10 ml-5"
          >
            <v-carousel
              cycle
              height="100%"
              hide-delimiter-background
              show-arrows-on-hover
              :show-arrows="false"
            >
              <v-carousel-item
                v-for="img in Fashion"
                :src="img.src"
                :key="img.id"
              >
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
        <v-col>
          <h1 class="ml-4" style="font-family:Noto Sans Lao;font-weight:bold;font-size:25px">*ຕົວແບບ</h1>
          <v-card elevation="2" height="500" width="450" class="mb-2 mt-10">
            <v-carousel
              cycle
              height="500"
              hide-delimiter-background
              show-arrows-on-hover
              :show-arrows="false"
            >
              <v-carousel-item
                v-for="img in People"
                :src="img.src"
                :key="img.id"
              >
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
      </v-row>
      <h1 style="font-family:Noto Sans Lao;font-weight:bold;font-size:25px" class="ml-7 mb-5 mt-10">*ແຜນທີ່ຮ້ານ</h1>
      <p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3795.0696860721323!2d102.64797601483455!3d17.97549458772867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x312467ba1e3f5e7f%3A0x95a541852ab6540!2z4LqV4Lqw4Lqr4Lq84Lqy4LqUIOC6q-C6u-C6p-C6guC6u-C6pyAoSHVhIEtodWEgTWFya2V0KQ!5e0!3m2!1sen!2sla!4v1618639727621!5m2!1sen!2sla"
          width="1500"
          height="500"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </p>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        src: require("@/assets/images/Others/houa_yang5.jpg"),
      },
      {
        src: require("@/assets/images/Others/houa_yang3.jpg"),
      },
      {
        src: require("@/assets/images/Others/houa_yang1.jpg"),
      },
      {
        src: require("@/assets/images/Others/houa_yang2.jpg"),
      },
      {
        src: require("@/assets/images/Others/houa_yang.jpg"),
      },
      {
        src: require("@/assets/images/Others/houa_yang4.jpg"),
      },
    ],
    cloths: [
      {
        src: require("@/assets/images/Others/Ntaub.jpg"),
      },
      {
        src: require("@/assets/images/Others/Ntaub1.jpg"),
      },
      {
        src: require("@/assets/images/Others/Ntaub2.jpg"),
      },
      {
        src: require("@/assets/images/Others/Ntaub3.jpg"),
      },
      {
        src: require("@/assets/images/Others/Ntaub4.jpg"),
      },
      {
        src: require("@/assets/images/Others/Ntaub5.jpg"),
      },
      {
        src: require("@/assets/images/Others/Ntaub6.jpg"),
      },
      {
        src: require("@/assets/images/Others/Xov.jpg"),
      },
      {
        src: require("@/assets/images/Others/Xov1.jpg"),
      },
      {
        src: require("@/assets/images/Others/Xov2.jpg"),
      },
    ],
    Fashion: [
      {
        src: require("@/assets/images/Others/Khaub_Ncaws.jpg"),
      },
      {
        src: require("@/assets/images/Others/Khaub_Ncaws1.jpg"),
      },
      {
        src: require("@/assets/images/Others/Khaub_Ncaws1 (4).jpg"),
      },
      {
        src: require("@/assets/images/Others/Khaub_Ncaws1 (3).jpg"),
      },
      {
        src: require("@/assets/images/Others/Khaub_Ncaws1 (2).jpg"),
      },
      {
        src: require("@/assets/images/Others/Khaub_Ncaws1 (1).jpg"),
      },
    ],
      People: [
      {
        src: require("@/assets/images/Others/Neeg.jpg"),
      },
      {
        src: require("@/assets/images/Others/Neeg1.jpg"),
      },
      {
        src: require("@/assets/images/Others/Neeg2.jpg"),
      },
      {
        src: require("@/assets/images/Others/Neeg3.jpg"),
      },
      {
        src: require("@/assets/images/Others/Neeg4.jpg"),
      },
      {
        src: require("@/assets/images/Others/Neeg5.jpg"),
      },
      {
        src: require("@/assets/images/Others/Neeg6.jpg"),
      },
      {
        src: require("@/assets/images/Others/Neeg7.jpg"),
      },
    ],

  }),
};
</script>
<style>
#csss {
  font-size: 25px;
  font-family: "Noto Sans Lao";
  text-align: left;
  margin-left: 7px;
  margin-top: 7px;
  color: black;
  font-weight: normal;
}
</style>