import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import moment from 'moment'
import { header } from '../../config/constant';


Vue.use(Vuex)

const customerModule = {
  namespaced: true,
  state: {
    customerdata: [],
    customermember:[],
    customerdit: [],
    cust_newmember: [],
    cust_onemember:[],
    custMember: {
      cust_id: "",
      register_pay: 0,
      register_date: new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-"),
      update_date: new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-"),
      card_capture: ''
    }
  },
  mutations: {
    setCustomer(state, customer) {
      state.customerdata = customer
    },
    setCustomerMember(state, customer) {
      state.customermember = customer
    },
    setCustomerByone(state, cust) {
      state.customerdit = {
        cus_id: cust.cus_id,
        full_name: cust.full_name,
        gender: cust.gender,
        tel: cust.tel,
        facebook: cust.facebook,
        email: cust.email,
        village: cust.village,
        district: cust.district,
        province: cust.province,
        country: cust.country,
        password: cust.password,
        status: cust.status,
        profile: cust.image,
        register_pay: 0,
        update_date: new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-")
      }
    },
    setCustMember(state, cust) {
      state.custMember = {
        cus_id: cust.cust_id,
        register_pay: cust.register_pay,
        register_date: cust.register_date,
        update_date: new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, "-"),
        card_capture: cust.card_capture
      }
      state.customerdit.register_pay = cust.register_pay;
    },
    setCustnewMember(state, member) {
      state.cust_newmember = member
    },
    setOneCustnewMember(state, member) {
      member.register_date=moment(String(member.register_date)).format('YYYY-MM-DD')
      state.cust_onemember = member
    }
  },

  actions: {
    async getCustomer(context) {
      try {
        var customers=[]
        var response = await axios.get(`http://localhost:2021/api/dadabackend/customer/getall`, {
          headers: header
        })
        for (const key in response.data) {
            const element = response.data[key];
            var list={
              idx:parseInt(key,10)+1,
              cus_id:element.cus_id,
              image:element.image,
              full_name:element.full_name,
              gender:element.gender,
              tel:element.tel,
              facebook:element.facebook,
              email:element.email,
              village:element.village,
              district:element.district,
              province:element.province,
              country:element.country,
              status:element.status
            }
            customers.push(list)
        }
        context.commit('setCustomer', customers)
      } catch (e) {
        console.log(e)
      }
    },
    async getCustomerMember(context) {
      try {
        var response = await axios.get(`http://localhost:2021/api/dadabackend/customer/member/getall`, {
          headers: header
        })
        context.commit('setCustomerMember', response.data)
      } catch (e) {
        console.log(e)
      }
    },
    async getCustomerByone(context, id) {
      try {
        var res = await axios.get(
          `http://localhost:2021/api/dadabackend/customer/getById/${id}`, {
          headers: header
        }
        );
        context.commit('setCustomerByone', res.data[0])
      } catch (e) {
        console.log(e)
      }
    },
    async getCustMember(context, id) {
      try {
        var res = await axios.get(
          `http://localhost:2021/api/dadabackend/customer/member/getById/${id}`, {
          headers: header
        }
        );
        context.commit('setCustMember', res.data[0])
      } catch (e) {
        console.log(e)
      }
    },
    async delCustomer(context, delItem) {
      try {
        await axios.post(`http://localhost:2021/api/dadabackend/customer/delete`, delItem, {
          headers: header
        })
        context.dispatch('getCustomer')
      } catch (e) {
        console.log(e)
      }
    },
    async InsertCustomer(context, insertItem) {
      try {

        await axios.post(
          `http://localhost:2021/api/dadabackend/customer/insert`,
          insertItem, {
          headers: header
        }
        );
        context.dispatch('getCustomer')
      } catch (e) {
        console.log(e)
      }
    },
    async InsertCustMember(context, insertItem) {
      try {
        await axios.post(`http://localhost:2021/api/dadabackend/customer/member/insert`, insertItem, {
          headers: header
        })
      } catch (e) {
        console.log(e)
      }
    },
    async UpdateCustomer(context, updateItem) {
      try {

        await axios.post(
          `http://localhost:2021/api/dadabackend/customer/update`,
          updateItem, {
          headers: header
        }
        );
        context.dispatch('getCustomer')
      } catch (e) {
        console.log(e)
      }
    },
    async UpdateCustMember(context, updatetItem) {
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/customer/member/update`,
          updatetItem, {
          headers: header
        }
        );
        await axios.post(
          `http://localhost:2021/api/dadabackend/customer/update-member`,
          updatetItem, {
          headers: header
        }
        );

        context.dispatch('getCustnewMember')
      } catch (e) {
        console.log(e)
      }
    },
    async DeleteCustMember(context, Item) {
      try {
        await axios.post(
          `http://localhost:2021/api/dadabackend/customer/member/delete`,
          Item, {
          headers: header
        }
        );
      } catch (e) {
        console.log(e)
      }
    },
    
    async getCustnewMember(context) {
      try {
        var member = await axios.get(
          `http://localhost:2021/api/dadabackend/customer/member/getNew`,
          {
            headers: header
          }
        );
        context.commit('setCustnewMember', member.data)
      } catch (e) {
        console.log(e)
      }
    },
    async getOneCustnewMember(context,id) {
      try {
        var member = await axios.get(
          `http://localhost:2021/api/dadabackend/customer/member/getNew/${id}`,
          {
            headers: header
          }
        );
        context.commit('setOneCustnewMember', member.data[0])
      } catch (e) {
        console.log(e)
      }
    },
  },
  getters: {
    customers: state => state.customerdata,
    customermember: state => state.customermember,
    customerdit: state => state.customerdit,
    custMember: state => state.custMember,
    cust_newmember: state => state.cust_newmember,
    cust_onemember: state => state.cust_onemember
  }
}

export default customerModule;

