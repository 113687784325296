<template>
    
    <v-app class="font">
      <div :class="container_transition?'containers sign-in-mode':'containers sign-up-mode'" >
      <div class="forms-containers">
        <div class="signin-signup">
          <form action="" class="sign-in-form">
            <h2 style="font-family:NotoSansLao; font-size:30px" class="mb-7">Log In</h2>
            <div class="input-field">
              <i class="fas fa-user"></i>
              <input type="text" v-model="person.email" placeholder="Email"
               :error-messages="emailErrors"
                    counter
                    required
                    @input="$v.employees.email.$touch()"
                    @blur="$v.employees.email.$touch()" />
            </div>
            <div class="input-field">
              <i class="fas fa-lock"></i>
              <input type="password"  v-model="person.password" placeholder="Password"
              
                    :error-messages="PasswordErrors"
                    required
                    @input="$v.employees.password.$touch()"
                    @blur="$v.employees.password.$touch()" />
            </div>
                <v-row>
            <!-- <input type="submit" class="btn solid" value="SignUp" /> -->
              <v-btn large elevation="3" class="btn solid mr-7 mt-7" color="#FF7043" style="font-size:20px" @click="Cancel">Cancel</v-btn>
            <router-link to="">
             <v-btn large elevation="3" class="btn solid ml-7 mt-7" color="#76FF03" style="font-size:20px; font-weight: bold;" @click="lognin">Log in</v-btn>
            </router-link>
            </v-row>
          </form>
          <form class="sign-up-form">
            <h2 style="font-family:NotoSansLao; font-size:30px" class="mb-7">ເຂົ້າລະບົບ</h2>
            <!-- <div class="input-field">
              <i class="fas fa-user"></i>
              <input type="text" placeholder="User name" />
            </div> -->
            <div class="input-field">
              <i class="fas fa-envelope"></i>
              <input type="text" placeholder="ອີແມວ" v-model="person.email"
               :error-messages="emailErrors"
                    counter
                    required
                    @input="$v.employees.email.$touch()"
                    @blur="$v.employees.email.$touch()"/>
            </div>
            <div class="input-field">
              <i class="fas fa-lock"></i>
              <input type="password"  v-model="person.password" placeholder="ລະຫັດຜ່ານ"
              
                    :error-messages="PasswordErrors"
                    required
                    @input="$v.employees.password.$touch()"
                    @blur="$v.employees.password.$touch()"/>
            </div>
            <v-row class="mb-5">
            <!-- <input type="submit" class="btn solid" value="SignUp" /> -->
              <v-btn large elevation="3" class="btn solid mr-7 mt-7" color="#FF7043" style="font-size:20px" @click="Cancel">ຍົກເລິກ</v-btn>
            <router-link to="">
             <v-btn large elevation="3" class="btn solid ml-7 mt-7" color="#76FF03" style="font-size:20px; font-weight: bold;" @click="lognin">ເຂົ້າລະບົບ</v-btn>
            </router-link>
            </v-row>
          </form>
        </div>
      </div>
      <div class="panels-containers">
        <div class="panel left-panel">
          <div class="content">
            <h3>DaDaFashion Online Service System</h3>
             <v-progress-linear value="100" class="mt-4 mb-7" height="2" color="primary"></v-progress-linear>
            
           
              <v-btn large elevation="10"  border="solid 2px red"
             class="btn transparent" id="sign-up-btn" @click="signup">
              ເຂົ້າລະບົບ
            </v-btn>
          </div>
          <img src="./login.svg" alt="Image dot .svg" class="image" />
        </div>

        <div class="panel right-panel">
          <div class="content">
            <h2>ລະບົບບໍລິການ ການຂາຍເຄື່ອງແຟຊັ່ນອອນລາຍຂອງ</h2>
              <h3 class="mt-5">ຮ້ານ ດາດ້າແຟຊັ່ນ</h3>
            <v-progress-linear value="100" class="mt-4 mb-3" height="2"></v-progress-linear>
            <v-btn large elevation="10"  border="solid 2px red"
             class="btn transparent"  
             id="sign-in-btn" 
             @click="signin">
              Sign in
            </v-btn>
          </div>
          <img src="./programmer.svg" alt="Image dot .svg" class="image" />
        </div>
      </div>
    </div>
    </v-app>
</template>
<script>
// import axios from 'axios'
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email
} from "vuelidate/lib/validators";
import { mapActions } from 'vuex'
export default {
  
  mixins: [validationMixin],

  validations: {
    person: {
      email: { required, email },
      password: { required, minLength: minLength(6), maxLength: maxLength(20) },
    },
    
  },

  data() {
    return {
      container_transition :false,
      translater: false,
      person:{
        email:'',
        password:'',
      },
    };
  },
  computed:{
    
     

     emailErrors() {
      const errors = [];
      if (!this.$v.person.email.$dirty) return errors;
      !this.$v.person.email.email && errors.push("Must be valid e-mail");
      !this.$v.person.email.required && errors.push("E-mail is required");
      return errors;
    },
    PasswordErrors() {
      const errors = [];
      if (!this.$v.person.password.$dirty) return errors;
      !this.$v.person.password.minLength &&
        errors.push("Password must be at less 6 characters short");
      !this.$v.person.password.maxLength &&
        errors.push("Password must be at most 20 characters long");
      !this.$v.person.password.required &&
        errors.push("Password is required.");
      return errors;
    },
  },
  watch:{
// token(val)
  },
  mounted(){
  },
  methods:{
   ...mapActions({
doLogin:'authMod/doLogin',
   }),
    
    signup(){
this.container_transition=false
    },
    signin(){
this.container_transition=true
    },
  async lognin(){
      this.$v.$touch();
       this.doLogin(this.person);
       this.person={
          email:'',
        password:'',
       }
    },
    Cancel(){
      this.person.email=""
       this.person.password=""
    }
  }
};
      
</script>




  <style   scoped  lang="css">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

  .font{
    font-family: 'NotoSansLao';
  }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.containers {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #04befe;
  overflow: hidden;
}

.containers:before {
  content: "";
  position: absolute;
  width: 2000px;
  height: 2000px;
  border-radius: 50%;
  background: linear-gradient(-45deg, #febe04, #3ecf31, #fe04be);
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  z-index: 6;
  transition: 1.8s ease-in-out;
}

.forms-containers {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: 50%;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
  transition: 1s 0.7s ease-in-out;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 5rem;
  overflow: hidden;
  grid-row: 1/2;
  grid-column: 1/2;
  transition: 0.2s 0.7s ease-in-out;
}

form.sign-up-form {
  z-index: 1;
  opacity: 0;
}

form.sign-in-form {
  z-index: 2;
}

.title {
  font-size: 2.2rem;
  color: #3b3737;
  margin-bottom: 10px;
}

.input-field {
  max-width: 380px;
  width: 100%;
  height: 55px;
  background-color: #f0f0ff;
  margin: 10px 0;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  font-size: 1.1rem;
  color: #acccac;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
}
input{
  max-width:95%
}
.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.btn {
  width: 150px;
  height: 49px;
  border: none;
  outline: none;
  border-radius: 49px;
  cursor: pointer;
  background-color: #5995fd;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  transition: 0.5s;
}

.btn:hover {
  background-color: #4d2;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  width: 46px;
  height: 46px;
  border: 1px solid #f0ff0f;
  display: flex;
  margin: 0 0.45rem;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #ffa822;
  font-size: 1.1rem;
  border-radius: 50%;
  transition: 0.3s;
}

.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}

.panels-containers {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #04befe;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 7;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #000000;
  transition: 0.9s 0.6s ease-in-out;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0, 7rem;
}

.btn.transparent {
  margin: 0;
  color: #ffffff;
  background: none;
  border: 2px solid #af4;
  width: 130px;
  height: 42px;
  font-size: 0.8rem;
  font-weight: 600;
}

.image {
  width: 100%;
  transition: 1.1s 0.4s ease-in-out;
}

.right-panel .content,
.right-panel .image {
  transform: translateX(800px);
}

/* Animationn */
.containers.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}
.containers.sign-up-mode .left-panel .image,
.containers.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.containers.sign-up-mode .right-panel .content,
.containers.sign-up-mode .right-panel .image {
  transform: translateX(0px);
}

.containers.sign-up-mode .left-panel {
  pointer-events: none;
}
.containers.sign-up-mode .right-panel {
  pointer-events: all;
}
.containers.sign-up-mode .signin-signup {
  left: 25%;
}
.containers.sign-up-mode form.sign-in-form {
  z-index: 1;
  opacity: 0;
}
.containers.sign-up-mode form.sign-up-form {
  z-index: 2;
  opacity: 1;
}
@media (max-width: 870px) {
  .containers {
    min-height: 800px;
    height: 100vh;
  }
  .containers:before {
    width: 1500px;
    height: 1500px;
    left: 30%;
    bottom: 68%;
    transform: translate(-50%);
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }
  .signin-signup {
    width: 100%;
    left: 50%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }
  .panels-containers {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }
  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
  }
  .panel .content {
    padding-right: 15%;
    transition: 0.9s 0.8s ease-in-out;
  }
  .panel h3 {
    font-size: 1.2rem;
  }
  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }
  .left-panel {
    grid-row: 1/2;
  }
  .right-panel {
    grid-row: 3/4;
  }
  .btn.transparent {
    width: 110px;
    height: 53px;
    font-size: 0.7rem;
  }
  .image {
    width: 200px;
    transition: 0.9s 0.6s ease-in-out;
  }
  .right-panel .content,
  .right-panel .image {
    transform: translateY(300px);
  }
  .containers.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }
  .containers.sign-up-mode .left-panel .image, 
  .containers.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }
  .containers.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0%);
    left: 50%;
  }
}
@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }
  .image {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .containers:before {
    bottom: 72%;
    left: 50%;
  }
  .containers .sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}
</style>