<template>
  <v-app class="font">
    <v-card class="ma-2 elevation-0">
      <v-row>
        <div class="d-flex mt-9">
          <v-avatar size="100" color="#fafafafa" class="pa-2">
            <v-img src="@/assets/images/DaDa_LoGo.png" />
          </v-avatar>
        </div>
        <v-col cols="9">
          <v-row no-gutters class="justify-center mt-5">
            <p style="font-size: 1.2rem">ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</p>
          </v-row>
          <v-row no-gutters class="justify-center">
            <p style="font-size: 1.2rem">
              ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດຖະນາຖາວອນ
            </p>
          </v-row>
          <v-row no-gutters class="justify-center mt-3">
            <p style="font-size: 2rem; text-decoration: bold">ໃບຮັບເງີນ</p>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class>
        <v-col cols="6">
          <p style="font-size: 1.4rem">ຮ້ານ ດາດ້າເເຟຊັນ</p>
          <p>ໂທລະສັບ: 020 92602326</p>

          <p>ອີເມວ: houyang0087@gmail.com</p>
          <p>ຕັ້ງຢູ່: ບ້ານ ຫົວຂົວ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ</p>
        </v-col>
        <v-col cols="6" class="align-end align-content-end">
          <v-row class="justify-end">
            <v-card class="mr-5 elevation-0">
              <p>ບິນເລກທີ່: {{ $route.query.sell_id }}</p>
              <p>ວັນທີ່: {{ bills.sell_date }}</p>
              <p>ເວລາ: {{ bills.sell_time }}</p>
              <p>ລູກຄ້າ: {{ bills.full_name }}</p>
            </v-card>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters class>
        <v-progress-linear value="100" height="1"></v-progress-linear>
      </v-row>
      <v-row no-gutters class>
        <v-card width="100%" class="mt-2 elevation-0">
          <v-data-table
            :headers="headers"
            :items="bills.detail"
            :search="search"
            item-key="id"
            sort-by="idx"
            class="elevation-0"
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr v-ripple="{ class: `white--text` }">
                <td>{{ item.idx }}</td>
                <td>
                  <v-carousel class="carousel"
                    v-if="item.image.length > 0"
                    show-arrows-on-hover
                    hide-delimiters
                    height="100"
                    width="70"
                  >
                    <v-carousel-item v-for="(img, i) in item.image" :key="i">
                      <v-card height="100%" width="100">
                        <v-img :src="fectImg(img.img_url)"></v-img>
                      </v-card>
                    </v-carousel-item>
                  </v-carousel>
                  <v-card v-else height="100" width="100">
                    <v-img
                      src="https://pbs.twimg.com/profile_images/1150780350932684800/LIJ11DWR.jpg"
                    ></v-img>
                  </v-card>
                </td>
                <td>{{ item.pro_name }}</td>
                <td>{{ item.size }}</td>
                <td>
                  {{ String(item.qty).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }}
                  {{ item.unit }}
                </td>
                <td>
                  {{ String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }}
                </td>
                <td>
                  {{
                    String(item.qty * item.price).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1 "
                    )
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-divider class></v-divider>
        </v-card>
      </v-row>
      <v-progress-linear value="100" height="1"></v-progress-linear>
      <v-row no-gutters class="mt-2">
        <v-col cols="4">
          <v-row no-gutters class="justify-center">
            <div>
              <p>ຜູ້ຮັບເງີນ</p>
              <p>{{ bills.emp_name }}</p>
            </div>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row no-gutters class="justify-center">
            <div>
              <p>ຜູ້ຈ່າຍເງີນ</p>
              <p>----------------------</p>
            </div>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row no-gutters class="mr-5 justify-center">
            <div>
              <div>
                <v-row no-gutters class="justify-center">
                  <v-col cols="6">
                    <v-row no-gutters class="justify-end">
                      <p style="margin-top: 10px; margin-right: 10px">
                        ລວມເປັນມູນຄ່າ:
                      </p>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row no-gutters class="justify-start">
                      <p style="margin-top: 10px">
                        {{
                          String(Sum).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                        ກິບ ກິບ
                      </p>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="justify-center">
                  <v-col cols="6">
                    <v-row no-gutters class="justify-end">
                      <p style="margin-right: 10px">ສ່ວນຫຼຸດ:</p>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row no-gutters class="justify-start">
                      <p>{{ bills.discount }} ເປີເຊັນ</p>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="justify-center">
                  <v-col cols="6">
                    <v-row no-gutters class="justify-end">
                      <p style="margin-right: 10px">ຊໍາລະເປັນມູນຄ່າ:</p>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row no-gutters class="justify-start">
                      <p>
                        {{
                          String(SumPay).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                        }}
                        ກິບ
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <v-card
                height="250"
                width="250"
                img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3_BhEiXXdZ_uT_di2-3a8WAxCrU5m8Wabfmituko1CduDH3_12BZ0Ydlg2yLOmPPdlmo&usqp=CAU"
              ></v-card>
             <v-row justify="center" class="mt-3"> <h1>QR Code</h1></v-row>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "ລໍາດັບ", value: "idx" },
        {
          text: "ຮູບ",
          sortable: false,
          value: "image",
        },
        { text: "ລານການສິນຄ້າ", value: "name" },
        { text: "ຂະໜາດ", value: "size" },
        { text: "ຈໍານວນ", value: "qty" },
        { text: "ລາຄາ(ກິບ)", value: "price" },
        { text: "ລາຄາລວມ(ກິບ)", value: "qty*price" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      bills: "saleMod/bills",
    }),

    Sum() {
      var sum = 0;
      if (this.bills.length < 0) return sum;
      for (const key in this.bills.detail) {
        const el = this.bills.detail[key];
        sum += parseInt(el.qty) * parseFloat(el.price);
      }
      return sum;
    },
    SumPay() {
      return this.Sum - (this.Sum * parseFloat(this.bills.discount)) / 100;
    },
  },

  created() {
    this.getBillSale(this.$route.query.sell_id);
  },
  methods: {
    ...mapActions("saleMod", ["getBillSale"]),
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/logo.png");
      }
      return imageUrl;
    },
  },
};
</script>
<style lang="css">
.font {
  font-family: "NotoSansLao";
}
p {
  font-size: 1rem;
}

.carousel{
width: 100px;
}
</style>