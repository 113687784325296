<template>
  <div>
    <v-card
      cols="12"
      sm="4"
      class="mb-7"
      flat
      v-for="(item, index) in Shop_Info"
      :key="index"
    >
      <v-stepper v-model="e1" class="elevation-0">
        <v-stepper-header
          style="font-family: Noto Sans Lao"
          class="elevation-0"
        >
          <v-stepper-step :complete="e1 > 1" step="1" @click="e1 = 1">
            ຂັ້ນຕອນທີ່ 1
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" @click="e1 = 2">
            ຂັ້ນຕອນທີ່ 2
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row justify="center">
              <h1 class="ml-9 mb-10 mt-3" id="myfont">
                *ສະມັກສະມາຊິກ*
              </h1>
            </v-row>
            <v-divider></v-divider>
            <v-row cols="2" sm="6" class="mt-3">
              <v-col lg="6" cols="12" sm="6">
                <h1 class="ml-9" id="myfont">ຊື່ເຕັມ*</h1>
                <v-text-field
                  class="mt-2 mr-9 ml-9"
                  outlined
                  id="textfield"
                  placeholder="Enter Your Full Name"
                  v-model="txtfullname"
                  :error-messages="NameErrors"
                  :counter="100"
                  required
                  @input="$v.txtfullname.$touch()"
                  @blur="$v.txtfullname.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ເພດ*</h1>
                <v-row dense justify="space-around">
                  <v-radio-group
                    required
                    :error-messages="GenderErrors"
                    @change="$v.gender.$touch()"
                    @blur="$v.gender.$touch()"
                    v-model="gender"
                    row
                  >
                    <v-radio
                      label="ຊາຍ/Male"
                      value="ຊາຍ"
                      class="mr-15 radio"
                    ></v-radio>
                    <v-radio
                      label="ຍິງ/Female"
                      value="ຍິງ"
                      class="ml-15 radio"
                    ></v-radio>
                  </v-radio-group>
                </v-row>
                <h1 class="ml-9 mt-7" id="myfont">ເບີໂທຕິດຕໍ່*</h1>
                <v-text-field
                  class="mt-1 mr-9 ml-9"
                  outlined
                  id="textfield"
                  placeholder="Enter Your Phone Number"
                  v-model="txttel"
                  :error-messages="TelErrors"
                  :counter="12"
                  required
                  @keypress="isNumber($event)"
                  @input="$v.txttel.$touch()"
                  @blur="$v.txttel.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ແຟສບຸກ*</h1>
                <v-text-field
                  class="mt-1 mr-9 ml-9"
                  outlined
                  id="textfield"
                  placeholder="Enter Your Facebook"
                  v-model="txtfacebook"
                  :error-messages="FacebookErrors"
                  :counter="100"
                  required
                  @input="$v.txtfacebook.$touch()"
                  @blur="$v.txtfacebook.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ອີແມວ*</h1>
                <v-text-field
                  class="mt-1 mr-9 ml-9"
                  outlined
                  placeholder="Enter Your Email"
                  v-model="txtemail"
                  id="textfield"
                  :error-messages="emailErrors"
                  :counter="100"
                  required
                  @input="$v.txtemail.$touch()"
                  @blur="$v.txtemail.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <h1 class="ml-9" id="myfont">ບ້ານຢູ່ປັດຈຸບັນ*</h1>
                <v-text-field
                  class="mt-1 mr-9 ml-9"
                  outlined
                  placeholder="Enter Your Village"
                  v-model="txtvillage"
                  id="textfield"
                  :error-messages="VillageErrors"
                  :counter="100"
                  required
                  @input="$v.txtvillage.$touch()"
                  @blur="$v.txtvillage.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ເມືອງ*</h1>
                <v-text-field
                  id="textfield"
                  class="mt-1 mr-9 ml-9"
                  outlined
                  placeholder="Enter Your District"
                  v-model="txtdistrict"
                  :error-messages="DistrictErrors"
                  :counter="100"
                  required
                  @input="$v.txtdistrict.$touch()"
                  @blur="$v.txtdistrict.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ແຂວງ*</h1>
                <v-text-field
                  id="textfield"
                  class="mt-1 mr-9 ml-9"
                  outlined
                  placeholder="Enter Your Province"
                  v-model="txtprovince"
                  :error-messages="ProvinceErrors"
                  :counter="100"
                  required
                  @input="$v.txtprovince.$touch()"
                  @blur="$v.txtprovince.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ປະເທດ*</h1>
                <v-select
                  :items="items"
                  outlined
                  id="textfield"
                  placeholder="Select Your Country"
                  class="mt-1 mr-9 ml-9"
                  v-model="country"
                  :error-messages="CountryErrors"
                  required
                  @change="$v.country.$touch()"
                  @blur="$v.country.$touch()"
                ></v-select>
                <h1 class="ml-9" id="myfont">ຕັ້ງລະຫັດຜ່ານ*</h1>
                <v-text-field
                  class="mt-1 mr-9 ml-9"
                  outlined
                  id="textfield"
                  placeholder="Create Password"
                  type="password"
                  v-model="txtpassword"
                  :error-messages="PasswordErrors"
                  counter
                  required
                  @input="$v.txtpassword.$touch()"
                  @blur="$v.txtpassword.$touch()"
                ></v-text-field>
                <h1 class="ml-9" id="myfont">ຢືນຢັນລະຫັດຜ່ານ*</h1>
                <v-text-field
                  class="mt-1 mr-9 ml-9"
                  outlined
                  id="textfield"
                  v-model="comfirmpassword"
                  placeholder="Comfirm Password"
                  type="password"
                  :error-messages="ComfirmPasswordErrors"
                  counter
                  required
                  @input="$v.comfirmpassword.$touch()"
                  @blur="$v.comfirmpassword.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-btn
                class="mt-16 mb-16 mr-10"
                @click="BtnClear"
                id="btntext"
                color="error"
              >
                ຍົກເລິກ
              </v-btn>
              <v-btn
                class="mt-16 mb-16 mr-10"
                color="primary"
                @click="GotoStep2"
                id="btntext"
              >
                ຖັດໄປ
              </v-btn>
            </v-row>
             <v-row justify="center">
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <v-card>
        <v-card-title>
          <span style="font-family:Noto Sans Lao;font-size:25px;font-weight:bold">ຂໍ້ແນະນໍາກ່ຽວກັບການສະມັກເປັນລູກຄ້າທົ່ວໄປ ຫຼື ສະມັກເປັນຕົວແທນກັບຮ້ານດາດ້າແຟຊັ່ນ</span>
        </v-card-title>
        <v-card-text>
        <span style="font-family:Noto Sans Lao;font-size:17px;">*ລູກຄ້າທຸກຄົນຕ້ອງປະຕິບັດຕາມຂັ້ນຕອນດັ່ງລຸ່ມນີ້:</span>
        <h3 style="font-family:Noto Sans Lao;font-size:17px;font-weight:normal">1. ຖ້າລູກຄ້າຕ້ອງການຢາກສັ່ງຕັດເຄື່ອງ ຫຼື ສັ່ງຊື້ສິນຄ້າຈາກທາງຮ້ານດາດ້າແຟຊັ່ນເທົ່ານັ້ນໂດຍທີ່ບໍ່ຕ້ອງ
          ການຢາກສະມັກເປັນຕົວແທນແມ່ນໃຫ້ກົດໃສ່ປຸ່ມ "ສະມັກເປັນລູກຄ້າທົ່ວໄປ" ເພື່ອທີ່ຈະສາມາດສັ່ງຕັດເຄື່ອງ ແລະ ສັ່ງຊື້ສິນຄ້າຈາກທາງຮ້ານ, ແຕ່ຖ້າສະມັກເປັນລູກທົ່ວໄປແມ່ນຈະ
          ບໍ່ໄດ້ຮັບສ່ວນຫຼຸດໃດໆ (ແຕ່ລູກຄ້າກໍສາມາດສະມັກເປັນຕົວແທນຕາມຫຼັງກໍ່ໄດ້ຫຼັງຈາກທີ່ລູກຄ້າສະມັກເປັນລູກຄ້າທົ່ວໄປສໍາເລັດ).</h3>
                <h3 style="font-family:Noto Sans Lao;font-size:17px;font-weight:normal">2. ຖ້າລູກຄ້າຕ້ອງການຢາກສັ່ງຕັດເຄື່ອງ ຫຼື ສັ່ງຊື້ສິນຄ້າຈາກທາງຮ້ານດາດ້າແຟຊັ່ນ
                  ເປັນຈໍານວນຫຼາຍ ຫຼື ຕ້ອງການເປັນຕົວແທນຈໍາໜ່າຍກັບຮ້ານເຮົາແມ່ນໃຫ້ກົດໃສ່ປຸ່ມ "ສະມັກເປັນຕົວແທນ" ແຕ່ການສະມັກເປັນຕົວແທນແມ່ນລູກຄ້າຈະຕ້ອງໄດ້ຈ່າຍຄ່າ
                  ສະມັກຕົວແທນຕາມທີ່ທາງຮ້ານເຮົາໄດ້ກໍານົດໄວ້ ຖ້າລູກຄ້າບໍ່ຈ່າຍຄ່າສະມັກແມ່ນທາງຮ້ານຈະບໍ່ຮັບເປັນຕົວແທນກັບຮ້ານໄດ້, ສໍາລັບການຈ່າຍຄ່າສະມັກຕົວແທນແມ່ນລູກຄ້າສາມາດ
                  ເລຶອກຮູບແບບການຈ່າຍເງີນຕາມຄວາມສະດວກຂອງລູກຄ້າເຊິ່ງທາງຮ້ານກໍ່ໄດ້ກໍານົດຮູບແບບວິທີການຊໍາລະເງີນໃຫ້ກັບລູກຄ້າແລ້ວ ຫຼື ລູກຄ້າສາມາດຕິດຕໍ່ຫາແອັດມິນເພື່ອອະທິບາຍລາຍ
                  ລະອຽດເພີ່ມເຕີມໃຫ້ກໍ່ໄດ້. ເຊິ່ງໃນນີ້ຖ້າລູກຄ້າສະມັກເປັນຕົວແທນກັບຮ້ານເຮົາແລ້ວ
                  ແມ່ນລູກຄ້າຈະໄດ້ຮັບສ່ວນຫຼຸດພິເສດໃນການສັ່ງຕັດເຄື່ອງ ຫຼື ສັ່ງຊື້ສິນຄ້າຈາກທາງຮ້ານເຮົາ, ຍິ່ງໄປກ່ວານັ້ນວັນບຸນປີໃໝ່ ແລະ ວັນສໍາຄັນຕ່າງໆແມ່ນທາງຮ້ານ
                  ກໍ່ຈະເພີ່ມສ່ວນຫຼຸດໃຫ້ກັບບັນດາຕົວແທນທາງຫຼາຍ ພ້ອມນັ້ນທາງຮ້ານເຮົາກໍ່ຍັງມີການຄັດເລຶອກຕົວແທນທີ່ນິຍົມມາບໍລິການກັບຮ້ານເຮົາເພື່ອໃຫ້ໂປຣໂມຊັ່ນໃຫ້ກັບ
                  ຕົວແທນດັ່ງກ່າວອິກດ້ວຍ.
                </h3>
        </v-card-text>
        <v-card-actions>
                   <v-btn
            color="error"
            text
            style="font-family:Noto Sans Lao;font-size:17px;"
            @click="dialog = false"
          >
            ກັບຄືນ
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            style="font-family:Noto Sans Lao;font-size:17px;"
            @click="General_Register"
          >
            ສະມັກເປັນລູກຄ້າທົ່ວໄປ
          </v-btn>
          <v-btn
            color="green darken-2"
            text
            style="font-family:Noto Sans Lao;font-size:17px;"
            @click="GoStep2"
          >
            ສະມັກເປັນຕົວແທນ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-row justify="center">
              <h1 class="ml-9 mt-3 mb-7" id="myfont">*ຈ່າຍຄ່າສະມັກຕົວແທນ*</h1>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="6" md="5">
                <h1 class="ml-7 mt-8" id="myfont">*ໂອນຜ່ານບັນຊີທະນາຄານ</h1>
                <v-img
                  class="ml-9"
                  height="120"
                  width="120"
                  src="@/assets/images/bcelone.png"
                ></v-img>
                <v-row>
                  <h1 class="ml-7 mt-7" id="myfont">ເລກບັນຊີ:</h1>
                  <h1 class="ml-7 mt-7" id="myfont">
                    {{ item.bank_account_no }}
                  </h1>
                </v-row>
                <v-row>
                  <h1 class="ml-7 mt-7" id="myfont">ຊື່ບັນຊີ:</h1>
                  <h1 class="ml-7 mt-7" id="myfont">
                    {{ item.bank_account_name }}
                  </h1>
                </v-row>
                <v-row>
                  <h1 class="ml-7 mt-7" id="myfont">ປະເພດບັນຊີ:</h1>
                  <h1 class="ml-7 mt-7 mb-7" id="myfont">Saving</h1>
                </v-row>
                <h1 class="ml-7" id="myfont">*QR Code</h1>
                <v-img
                  class="ml-9 mb-8"
                  height="300"
                  width="300"
                  src="@/assets/images/houa.png"
                ></v-img>
                         <h2
                  class="ml-5"
                  style="
                    font-size: 25px;
                    font-weight: bold;
                    font-family: NotoSansLao;
                  "
                >
                  *ຊໍາລະຜ່ານ Paypal
                </h2>
                <h1
                  class="ml-5"
                  style="
                    font-size: 45px;
                    font-weight: bold;
                    font-family: NotoSansLao;
                    color: #2962ff;
                  "
                >
                  PayPal
                </h1>
                <v-row>
                  <h1 class="ml-7 mt-7" id="myfont">ອີແມວ:</h1>
                  <h1
                    class="ml-8 mt-7"
                    style="
                      font-size: 25px;
                      font-weight: bold;
                      font-family: NotoSansLao;
                    "
                  >
                    {{ item.paypal_email }}
                  </h1>
                </v-row>
                <v-col class="mt-3">
                  <a
                    class="mt-13"
                    href="https://www.paypal.com/us/signin"
                    target="brank"
                     style="
                      font-size: 20px;
                      font-weight: bold;
                      font-family: NotoSansLao;text-decoration: underline;color:black
                    "
                    >ເຂົ້າສູ່ລະບົບ PayPal</a
                  >
                </v-col>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <h1 class="mt-9 mr-7 ml-9" id="myfont">ຄ່າສະມັກຕົວແທນ*</h1>
                <v-row>
                  <v-text-field
                    id="textfield"
                    class="mt-5 ml-12 mr-5"
                    outlined
         
                    :value="
                      String(item.register_pay).replace(
                        /(.)(?=(\d{3})+$)/g,
                        '$1 '
                      )
                    "
                    readonly
                  ></v-text-field>
                  <h1 class="mt-9 mr-5" id="myfont">ກິບ</h1>
                </v-row>
                <v-divider class="ml-7 mt-15 mb-4"></v-divider>
                <h1 class="ml-9" id="myfont">ຮູບແບບການຈ່າຍເງີນ*</h1>
                <v-row dense justify="start" class="ml-8">
                  <v-radio-group
                    required
                    :error-messages="Pay_MethodError"
                    @change="$v.pay_method.$touch()"
                    @blur="$v.pay_method.$touch()"
                    v-model="pay_method"
                    row
                  >
                    <v-col>
                      <v-radio
                        label="ຈ່າຍຜ່ານ Bcel One"
                        value="ຈ່າຍຜ່ານ Bcel One"
                        class="radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍຜ່ານ Paypal"
                        value="ຈ່າຍຜ່ານ Paypal"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍຜ່ານ Western Union"
                        value="ຈ່າຍຜ່ານ Western Union"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍຜ່ານ MoneyGram"
                        value="ຈ່າຍຜ່ານ MoneyGram"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-radio
                        label="ຈ່າຍຮູບແບບອື່ນໆ"
                        value="ຈ່າຍຮູບແບບອື່ນໆ"
                        class="mt-5 radio"
                      ></v-radio>
                      <v-text-field
                        v-if="pay_method == 'ຈ່າຍຮູບແບບອື່ນໆ'"
                        class="mt-5 mr-10"
                        outlined
                          id="textfield"
                        placeholder="Pay Method"
                        :error-messages="Pay_ByError"
                        @change="$v.Pay_By.$touch()"
                        @blur="$v.Pay_By.$touch()"
                        v-model="Pay_By"
                      ></v-text-field>
                    </v-col>
                  </v-radio-group>
                </v-row>
                <h1 class="mt-9 mr-7 ml-9" id="myfont">
                  ຮູບອ້າງອິງການຈ່າຍເງີນ*
                </h1>
                <input
                  prepend-icon="mdi-camera"
                  type="file"
                  class="file-upload ml-7 mt-5"
                  @change="onFileSelected"
                  show-size
                  label="Image input"
                  required
                />
                <v-divider class="ml-7"></v-divider>
                <v-img
                  class="mt-4"
                  :src="url"
                  v-show="card_capture"
                  max-height="450"
                  aspect-ratio="1.7"
                  contain
                ></v-img>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-btn
                id="btntext"
                class="mt-16 mb-16 mr-10"
                color="error"
                @click="e1 = 1"
              >
                ກັບຄືນ
              </v-btn>
              <v-btn
                id="btntext"
                color="primary"
                @click="Register(item.register_pay)"
                class="mt-16 mb-16 mr-10"
              >
                ລົງທະບຽນ
              </v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import {mapGetters,mapActions} from "vuex";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    txtfullname: { required, maxLength: maxLength(100) },
    gender: { required },
    txttel: { required, maxLength: maxLength(12) },
    txtfacebook: { required, maxLength: maxLength(100) },
    txtemail: { required, email },
    txtvillage: { required, maxLength: maxLength(100) },
    txtdistrict: { required, maxLength: maxLength(100) },
    txtprovince: { required, maxLength: maxLength(100) },
    country: { required },
    txtpassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
    },
    comfirmpassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
    },
    Pay_By: { required },
    pay_method: { required },
  },
  data() {
    return {
      items: ["Laos", "Thailand", "America", "Vietname"],
    dialog: false,
      e1: 1,
      url: null,
      profile: "",
      CustomerStatus: "General",
      //Customer Info
      txtfullname: "",
      gender: "",
      txttel: "",
      txtfacebook: "",
      txtemail: "",
      txtvillage: "",
      txtdistrict: "",
      txtprovince: "",
      country: "",
      txtpassword: "",
      comfirmpassword: "",
      pay_method: "",
      Pay_By: "",
    };
  },
  mounted() {
    this.$store.dispatch("Shop_Info");
    this.$store.dispatch("Customer_List");
    this.getCountry();
  },
  computed: {
    customerInfo() {
      // alert(JSON.stringify(this.$store.state.customerInfo));
      return this.$store.state.customerInfo;
    },
      ...mapGetters({
      Country_Name: "countryMod/Country_Name",
    }),

      Country() {
      //alert(JSON.stringify(this.$store.state.Country));
      return this.$store.state.Country;
    },
    Shop_Info() {
      // alert(this.$store.state.Shop_Info)
      return this.$store.state.Shop_Info;
    },
    Customer_detail() {
      // alert(this.$store.state.Customer_detail)
      return this.$store.state.Customer_detail;
    },

    NameErrors() {
      const errors = [];
      if (!this.$v.txtfullname.$dirty) return errors;
      !this.$v.txtfullname.maxLength &&
        errors.push("Name must be at most 100 characters long");
      !this.$v.txtfullname.required && errors.push("Name is required.");
      return errors;
    },
    GenderErrors() {
      const errors = [];
      if (!this.$v.gender.$dirty) return errors;
      !this.$v.gender.required && errors.push("");
      return errors;
    },
    TelErrors() {
      const errors = [];
      if (!this.$v.txttel.$dirty) return errors;
      !this.$v.txttel.maxLength &&
        errors.push("Tel must be at most 12 characters long");
      !this.$v.txttel.required && errors.push("Tel is required.");
      return errors;
    },
    FacebookErrors() {
      const errors = [];
      if (!this.$v.txtfacebook.$dirty) return errors;
      !this.$v.txtfacebook.maxLength &&
        errors.push("Facebook must be at most 100 characters long");
      !this.$v.txtfacebook.required && errors.push("Facebook is required.");
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.txtemail.$dirty) return errors;
      !this.$v.txtemail.email && errors.push("Must be valid e-mail");
      !this.$v.txtemail.required && errors.push("E-mail is required");

      if (this.txtemail != "") {
        for (const key in this.Customer_detail) {
          const element = this.Customer_detail[key];
          if (this.txtemail == element.email) {
            errors.push("ອີແມວນີ້ຖຶກໃຊ້ແລ້ວ...!");
          }
        }
      }
      return errors;
    },

    VillageErrors() {
      const errors = [];
      if (!this.$v.txtvillage.$dirty) return errors;
      !this.$v.txtvillage.maxLength &&
        errors.push("Village must be at most 100 characters long");
      !this.$v.txtvillage.required && errors.push("Village is required.");
      return errors;
    },

    DistrictErrors() {
      const errors = [];
      if (!this.$v.txtdistrict.$dirty) return errors;
      !this.$v.txtdistrict.maxLength &&
        errors.push("District must be at most 100 characters long");
      !this.$v.txtdistrict.required && errors.push("District is required.");
      return errors;
    },

    ProvinceErrors() {
      const errors = [];
      if (!this.$v.txtprovince.$dirty) return errors;
      !this.$v.txtprovince.maxLength &&
        errors.push("Province must be at most 100 characters long");
      !this.$v.txtprovince.required && errors.push("Province is required.");
      return errors;
    },
    CountryErrors() {
      const errors = [];
      if (!this.$v.country.$dirty) return errors;
      !this.$v.country.required && errors.push("Country is required");
      return errors;
    },
    PasswordErrors() {
      const errors = [];
      if (!this.$v.txtpassword.$dirty) return errors;
      !this.$v.txtpassword.minLength &&
        errors.push("Password must be at less 6 characters short");
      !this.$v.txtpassword.maxLength &&
        errors.push("Password must be at most 20 characters long");
      !this.$v.txtpassword.required && errors.push("Password is required.");
      return errors;
    },
    ComfirmPasswordErrors() {
      const errors = [];
      if (!this.$v.comfirmpassword.$dirty) return errors;
      !this.$v.comfirmpassword.minLength &&
        errors.push("Comfirmpassword must be at less 6 characters short");
      !this.$v.comfirmpassword.maxLength &&
        errors.push("Comfirmpassword must be at most 20 characters long");
      !this.$v.comfirmpassword.required &&
        errors.push("Comfirmpassword is required.");

      if (this.comfirmpassword != this.txtpassword) {
        errors.push("Comfirm Password Again...!");
      }
      return errors;
    },
    Pay_ByError() {
      const errors = [];
      if (!this.$v.Pay_By.$dirty) return errors;
      !this.$v.Pay_By.required && errors.push("Pay Method is required.");
      return errors;
    },
    Pay_MethodError() {
      const errors = [];
      if (!this.$v.pay_method.$dirty) return errors;
      !this.$v.pay_method.required && errors.push("");
      return errors;
    },
  },
  methods: {
    ...mapActions("countryMod", ["getCountry"]),
    isNumber: function ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    Register(register_pay) {
      if (
        this.txtfullname == "" ||
        this.txttel == "" ||
        this.txtfacebook == "" ||
        this.txtemail == "" ||
        this.txtvillage == "" ||
        this.txtdistrict == "" ||
        this.txtprovince == "" ||
        this.txtpassword == "" ||
        this.gender == "" ||
        this.country == ""
      ) {
        alert("ປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ......!");
        return;
      }
      if (this.pay_method == "") {
        this.$v.$touch();
        return;
      }
      if (this.pay_method == "ຈ່າຍຮູບແບບອື່ນໆ" && this.Pay_By == "") {
        this.$v.$touch();
        return;
      }
      if (this.pay_method == "ຈ່າຍຮູບແບບອື່ນໆ") {
        this.pay_method = this.Pay_By;
      }

      var datenow = new Date().toJSON().slice(0, 10).replace(/-/g, "");
      var timenow = new Date()
        .toTimeString()
        .replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1", "")
        .replace(":", "")
        .replace(":", "");
      var cusID = "CUS" + datenow.slice(2, datenow.length) + timenow;
      var RegisterID = cusID;
      this.$store.dispatch("Add_NewCustomer", {
        cus_id: cusID,
        full_name: this.txtfullname,
        gender: this.gender,
        tel: this.txttel,
        facebook: this.txtfacebook,
        email: this.txtemail,
        village: this.txtvillage,
        district: this.txtdistrict,
        province: this.txtprovince,
        country: this.country,
        password: this.txtpassword,
        status: "Confirm",
        image: "",
      });

      this.$store.dispatch("Add_Customer_member", {
        cus_id: cusID,
        register_pay: register_pay,
        register_date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        pay_method: this.pay_method,
        card_capture: this.card_capture,
      });
      this.$store.dispatch("Submit_Comment", {
        cus_id: cusID,
        coment_id: RegisterID,
        customer_content: "ສະມັກເປັນຕົວແທນກັບ ຮ້ານດາດ້າແຟຊັນ....",
      });
      this.$v.$reset();
      this.txtfullname = "";
      this.txttel = "";
      this.txtfacebook = "";
      this.txtemail = "";
      this.txtvillage = "";
      this.txtdistrict = "";
      this.txtprovince = "";
      this.txtpassword = "";
      this.gender = "";
      this.country = "";
      if (this.$store.state.Customer_detail.length > 0) {
        this.$router.push(`/Customer/`);
      }
    },
   General_Register(){   
      var datenow = new Date().toJSON().slice(0, 10).replace(/-/g, "");
      var timenow = new Date()
        .toTimeString()
        .replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1", "")
        .replace(":", "")
        .replace(":", "");
      var cusID = "CUS" + datenow.slice(2, datenow.length) + timenow;
      this.$store.dispatch("Add_NewCustomer", {
        cus_id: cusID,
        full_name: this.txtfullname,
        gender: this.gender,
        tel: this.txttel,
        facebook: this.txtfacebook,
        email: this.txtemail,
        village: this.txtvillage,
        district: this.txtdistrict,
        province: this.txtprovince,
        country: this.country,
        password: this.txtpassword,
        status: "General",
        image: "",
      });
      this.dialog=false
      this.$router.push('/Customer')
   },

    GotoStep2() {
      if (
        this.txtfullname == "" ||
        this.txttel == "" ||
        this.txtfacebook == "" ||
        this.txtemail == "" ||
        this.txtvillage == "" ||
        this.txtdistrict == "" ||
        this.txtprovince == "" ||
        this.txtpassword == "" ||
        this.gender == "" ||
        this.country == ""
      ) {
        this.$v.$touch();
        return;
      }
      if (this.txtemail != "") {
        for (const key in this.Customer_detail) {
          const element = this.Customer_detail[key];
          if (this.txtemail == element.email) {
            return;
          }
        }
      }
      if (this.txtpassword != this.comfirmpassword) {
        return;
      }
     // this.e1 = 2;
     this.dialog=true
    },
    GoStep2(){
       this.e1 = 2;
         this.dialog=false
    },
    onFileSelected(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.url = e.target.result;
      };
      this.card_capture = event.target.files[0].name;
     //  alert(JSON.stringify(this.card_capture));
    },
    BtnClear() {
      this.$v.$reset();
      this.txtfullname = "";
      this.txttel = "";
      this.txtfacebook = "";
      this.txtemail = "";
      this.txtvillage = "";
      this.txtdistrict = "";
      this.txtprovince = "";
      this.txtpassword = "";
      this.gender = "";
      this.country = "";
    },
  },
};
</script>

<style>
#cssstyle {
  font-size: 30px;
  font-family: "Noto Sans Lao";
  text-align: left;
  margin-right: 30px;
  margin-top: 7px;
  color: black;
  font-weight: normal;
}
#myfont {
  font-size: 25px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: black;
}
#dialog {
  font-size: 25px;
  font-family: "Noto Sans Lao";
  font-weight: bold;
  align-content: center;
  margin-left: 135px;
  margin-top: 10px;
  color: black;
}
#btntext {
  font-size: 18px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: white;
}
.radio {
  font-size: 40px;
  font-family: "Noto Sans Lao";
  font-weight: normal;
  color: black;
}
</style>
<style lang="css">
.font {
  font-family: "NotoSansLao";
}
</style>