
// Shop Info
export const Set_ShopInfo = (state, Shop_Info) => {
    state.Shop_Info = Shop_Info
}
// Product List All
export const Set_ProductList = (state, productItem) => {
    state.productList = productItem;
}
//Product New Limit 4********************************************************************************************************************
export const Set_Products = (state, productItem) => {
    state.products = productItem;
}

//Product Select By ID*********************************************************************************************************
export const Set_Product = (state, productItem) => {
    state.product = productItem;
}
//*****************************Top Product limit 4********************************************************************* */
export const SET_TOP_PRODUCT = (state, productItem) => {
    state.TopProduct = productItem;
}
//*************************************************************************************************************************** */

export const Set_category = (state, Category) => {
    state.Category = Category;
}
//**************************************************************************************************************************
export const Add_ToCart = (state, { proID, proName, quantity, price, total, Image }) => {
    let ProductInCart = state.ProductCart.find(item => {
        return item.proID == proID;
    });
    if (ProductInCart) {
        if (ProductInCart.quantity < 1) {
            return;
        }
        ProductInCart.quantity += quantity;
        ProductInCart.total = ProductInCart.quantity * price;
        return;
    }
    state.ProductCart.push({
        proID,
        proName,
        quantity,
        price,
        total,
        Image
    })
}

// export const Set_Cart=(state,Cart_Product_Items)=>{
//     state.ProductCart=Cart_Product_Items;
// }
//*************************************************************************************************************************** */
export const Delete_CurrentItemIncart = (state, proID) => {
    state.ProductCart = state.ProductCart.filter(item => {
        return item.proID !== proID;
    })
}
export const Delete_All_List_ProductCart = (state) => {
    state.ProductCart = []
}

export const Delete_All_OrderCut_Item=(state)=>{
    state.OrderCutCartItem=[]
}
export const Delete_ItemInOrderCut_Cart = (state, { proName, Color, Size, Detail }) => {
    for (const key in state.OrderCutCartItem) {
        const item = state.OrderCutCartItem[key];
        if (item.proName == proName && item.Color == Color && item.Size == Size && item.Detail == Detail) {
            item.Quantity = 0
        }
    }
    state.OrderCutCartItem = state.OrderCutCartItem.filter(item => {
        return item.Quantity != 0;
    })

}

//*************************************************************Customer Log IN************************************ */
export const Set_Customer = (state, customerInfo) => {
    state.customerInfo = customerInfo;
}
//*******************************************************************Customer List***************************** */
export const Set_Customer_List = (state, Customer_detail) => {
    state.Customer_detail = Customer_detail;
}
//**************************************************************Image All******************************************* */
export const Set_ImageAll = (state, imagelist) => {
    state.imagelist = imagelist
}

//*****************************************************OrderCutCart************************************************* */
export const Add_ToOrderCutCart = (state, { proID, proName,Category, Color, Size, Quantity,Unit,Price, Detail, image }) => {
    for (const key in state.OrderCutCartItem) {
        const item = state.OrderCutCartItem[key];
        if (item.proName == proName && item.Color == Color && item.Size == Size && item.Detail == Detail) {
            item.Quantity += Quantity
            return
        }
    }
    state.OrderCutCartItem.push({
        proID,
        proName,
        Category,
        Color,
        Size,
        Quantity,
        Unit,
        Price,
        Detail,
        image
    })
}

//************************************************************Set Country************************************ */
export const Set_Country=(state,Country)=>{
    state.Country=Country;
}
