
<script>
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Pie,
  mixins: [reactiveProp],
  data() {
    return {
      options:{
      maintainAspectRatio:false,
      scales:{
        yAxes:[{
          ticks:{
            bdginAtZero:true,
            useCallback:
              function(value,){
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')
              },
               display: false
          },
          gridLines:{
            display:false
          }
        }],
        xAxes:[{
           gridLines:{
            display:false
          },
           ticks:{
               display: false
           }
        }],
         legend:{
            display:false
          }
      }
      }
    }
  },
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    setTimeout(() => {
      this.renderChart(this.chartData, this.options)
    }, 100);
  }
}
</script>
