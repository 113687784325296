import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { header } from '../../config/constant'
Vue.use(Vuex)

const OrderModule = {
    namespaced: true,
    state: {
      material:[],
      materialEdit:[]
    },
    mutations: {
        setMaterial(state, m) {
            state.material = m
            // alert(JSON.stringify(m))
        },
        setMaterialOne(state, m) {
          state.materialEdit = m
      },
    },

    actions: {
      async getMaterial(context) {
        var data = await axios.get(
          `http://localhost:2021/api/dadabackend/material/getall`,
          {
            headers: header
          }
        );
        var materiallist = []
          for (const i in data.data) {
            const e=data.data[i]
           var material = {
             idx:parseInt(i)+1,
             m_id: e.m_id,
             pro_name: e.pro_name,
              category: e.category,
              color: e.color,
              size: e.size,
              unit: e.unit,
              image: e.image,
              icon:'mdi-plus',
              check:'#0288D1'
            };
          materiallist.push(material);
        }
        context.commit("setMaterial",materiallist)
      },
      async getMaterialOne(context,id) {
        var data = await axios.get(
          `http://localhost:2021/api/dadabackend/material/getById/${id}`,
          {
            headers: header
          }
        );
            const e=data.data[0]
           var material = {
             m_id: id,
             pro_name: e.pro_name,
              category: e.category,
              color: e.color,
              size: e.size,
              unit: e.unit,
              image: e.image,
            };
        context.commit("setMaterialOne",material)
      },
      async InsertMaterial(context,order){
         await axios.post(
           `http://localhost:2021/api/dadabackend/material/insert`,order,{
               headers:header
             }
         );
        context.dispatch("getMaterial")
       },
       async UpdateMaterial(context,order){
          await axios.post(
            `http://localhost:2021/api/dadabackend/material/update`,order,{
                headers:header
              }
          );
          context.dispatch("getMaterial")
        },
      async DeleteMaterial(context,order){
         await axios.post(
           `http://localhost:2021/api/dadabackend/material/delete`,order,{
               headers:header
             }
         );
         context.dispatch("getMaterial")
       },
      
    },
    getters: {
      material: state => state.material,
      materialEdit: state => state.materialEdit
    }
}
export default OrderModule;
