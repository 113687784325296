<template>
  <div
    v-if="
      $store.state.authMod.token.status == 'Admin' ||
        $store.state.authMod.token.status == 'admin'
    "
  >
    <v-row justify="center">
      <v-col cols="12" md="11">
        <form @submit.prevent="submit">
          <v-card elevation="2" style="margin-top:2px; padding-bottom:10px">
            <v-row no-gutters class="justify-center">
              <h2 style="margin-top:30px;">ເພີ່ມຂໍ້ມູນລູກຄ້າ</h2>
            </v-row>
            <v-row no-gutters class="ma-5 justify-space-around">
              <v-col cols="12" md="9">
                <v-card class="mr-4 elevation-0">
                <v-text-field
                    v-model="customers.full_name"
                    :error-messages="NameErrors"
                    :counter="100"
                    label="ຊື່ ແລະ ນານສະກຸນ"
                    required
                    @input="$v.customers.full_name.$touch()"
                    @blur="$v.customers.full_name.$touch()"
                  ></v-text-field>
                  <v-radio-group
                    label="ເພດ"
                    v-model="customers.gender"
                    row
                    required
                    :error-messages="GenderErrors"
                    @change="$v.customers.gender.$touch()"
                    @blur="$v.customers.gender.$touch()"
                  >
                    <v-row class="justify-space-around">
                      <v-radio label="ຊາຍ" value="ຊາຍ"></v-radio>
                      <v-radio label="ຍິງ" value="ຍິງ"></v-radio>
                    </v-row>
                  </v-radio-group>
                  <v-divider style="margin-top:-12px"></v-divider>
                  <v-text-field
                    v-model="customers.tel"
                    :error-messages="TelErrors"
                    :counter="100"
                    label="ເບີ້ໂທລະສັບ"
                    required
                    @input="$v.customers.tel.$touch()"
                    @blur="$v.customers.tel.$touch()"
                    @keypress="number($event)"
                  ></v-text-field>
                  <v-text-field
                    v-model="customers.facebook"
                    :error-messages="FacebookErrors"
                    :counter="100"
                    label="ແຟ໌ສບຸກ"
                    required
                    @input="$v.customers.facebook.$touch()"
                    @blur="$v.customers.facebook.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="customers.email"
                    :error-messages="emailErrors"
                    label="ອີແມວ"
                    counter
                    required
                    @input="$v.customers.email.$touch()"
                    @blur="$v.customers.email.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="customers.village"
                    :error-messages="VillageErrors"
                    :counter="100"
                    label="ບ້ານຢູ່ປັດຈຸບັນ"
                    required
                    @input="$v.customers.village.$touch()"
                    @blur="$v.customers.village.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model="customers.district"
                    :error-messages="DistrictErrors"
                    :counter="100"
                    label="ເມືອງ"
                    required
                    @input="$v.customers.district.$touch()"
                    @blur="$v.customers.district.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model="customers.province"
                    :error-messages="ProvinceErrors"
                    :counter="100"
                    label="ແຂວງ"
                    required
                    @input="$v.customers.province.$touch()"
                    @blur="$v.customers.province.$touch()"
                  ></v-text-field>

                  <v-text-field
                    v-model="customers.country"
                    :error-messages="CountryErrors"
                    :counter="100"
                    label="ປະເທດ"
                    required
                    @input="$v.customers.country.$touch()"
                    @blur="$v.customers.country.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="customers.password"
                    :error-messages="PasswordErrors"
                    label="ລະຫັດຜ່ານ"
                    counter
                    required
                    @input="$v.customers.password.$touch()"
                    @blur="$v.customers.password.$touch()"
                  ></v-text-field>

                  <v-radio-group
                    v-model="customers.status"
                    row
                    label="ສະຖານະ"
                    required
                    :error-messages="StatusErrors"
                    @change="$v.customers.status.$touch()"
                    @blur="$v.customers.status.$touch()"
                  >
                    <v-row class="justify-space-around">
                      <v-radio label="ສະມາຊິກ/ຕົວແທນ" value="Member"></v-radio>

                      <v-radio label="ລູກຄ້າທົ່ວໄປ" value="General"></v-radio>
                    </v-row>
                  </v-radio-group>
                  <v-divider style="margin-top:-10px"></v-divider>
                  <v-text-field
                    v-if="this.customers.status == 'Member'"
                    v-model="customers.register_pay"
                    :error-messages="Register_payErrors"
                    label="ຄ່າສະໝັກເປັນສະມາຊິກ "
                    counter
                    required
                    @input="$v.customers.register_pay.$touch()"
                    @blur="$v.customers.register_pay.$touch()"
                    @keypress="number($event)"
                  ></v-text-field>
                   <h4 class="mt-5" style="font-size: 17px; font-weight: normal;">ເລຶອກຮູບລູກຄ້າ</h4>
                  <input
                    type="file"
                    class="file-upload"
                    @change="onFileSelected"
                    show-size
                    label="ບຣາວຮູບໂປຣໄຟ"
                    :error-messages="ProfileErrors"
                    required
                    @input="$v.customers.profile.$touch()"
                    @click="$v.customers.profile.$touch()"
                    @blur="$v.customers.profile.$touch()"
                  />
                  <v-img
                    :src="url"
                    v-show="customers.profile"
                    max-height="450"
                    aspect-ratio="1.7"
                    contain
                    v-ripple="{ class: `white--text` }"
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-5 ">
              <v-btn @click="back" style="font-size: 20px; font-weight: bold" color="deep-purple lighten-4" rounded class="mt-2">
                <v-icon left large>
                  mdi-arrow-left-circle
                </v-icon>
                ກັບສູ່ໜ້າຂໍ້ມູນລູກຄ້າ
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn @click="clear" style="font-size: 20px; font-weight: bold" color="#FF8A65" rounded class="mt-2 mr-7">
                <v-icon left large>
                  mdi-close-circle
                </v-icon>
                ຍົກເລິກ
              </v-btn>

              <v-btn @click="submit" style="font-size: 20px; font-weight: bold" color="#1DE9B6" rounded class="mt-2 mr-5">
                <v-icon left large>
                  mdi-check-circle
                </v-icon>
                ບັນທຶກ
              </v-btn>
            </v-row>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    customers: {
      full_name: { required, maxLength: maxLength(100) },
      gender: { required },
      tel: { required, maxLength: maxLength(20) },
      facebook: { required, maxLength: maxLength(100) },
      email: { required, email },
      village: { required, maxLength: maxLength(100) },
      district: { required, maxLength: maxLength(100) },
      province: { required, maxLength: maxLength(100) },
      country: { required, maxLength: maxLength(100) },
      register_pay: { required, maxLength: maxLength(100) },
      password: { required, minLength: minLength(6), maxLength: maxLength(20) },
      status: { required },
      profile: { required, maxLength: maxLength(500) }
    },
    checkbox: {
      checked(val) {
        return val;
      }
    }
  },

  data: () => ({
    url: null,
    customers: {
      cus_id: "",
      full_name: "",
      gender: "",
      tel: "",
      facebook: "",
      email: "",
      village: "",
      district: "",
      province: "",
      country: "",
      password: "",
      status: "",
      profile: "",
      register_pay: null,
      register_date: null
    }
  }),

  computed: {
    NameErrors() {
      const errors = [];
      if (!this.$v.customers.full_name.$dirty) return errors;
      !this.$v.customers.full_name.maxLength &&
        errors.push("ກະລຸນາປ້ອນ ຊື່ ແລະ ນານສະກຸນ ບໍຄວນເກີນ 100 ຕົວອັກສອນ");
      !this.$v.customers.full_name.required && errors.push("ຊື່ ແລະ ນານສະກຸນວ່າງໆ.");
      return errors;
    },
    GenderErrors() {
      const errors = [];
      if (!this.$v.customers.gender.$dirty) return errors;
      !this.$v.customers.gender.required &&
        errors.push("ກະລຸນາເພດຕາມຄວາມເປັນຈິງ!");
      return errors;
    },
    TelErrors() {
      const errors = [];
      if (!this.$v.customers.tel.$dirty) return errors;
      !this.$v.customers.tel.maxLength &&
        errors.push("ກະລຸນາປ້ອນເບີ້ໂທບໍຄວນເກີນ 20 ຕົວອັກສອນ");
      !this.$v.customers.tel.required && errors.push("ເບີ້ໂທວ່າງໆ.");
      return errors;
    },
    FacebookErrors() {
      const errors = [];
      if (!this.$v.customers.facebook.$dirty) return errors;
      !this.$v.customers.facebook.maxLength &&
        errors.push("ກະລຸນາປ້ອນຊື່ Facebook ບໍຄວນເກີນ 100 ຕົວອັກສອນ");
      !this.$v.customers.facebook.required &&
        errors.push("Facebook ວ່າງໆ.");
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.customers.email.$dirty) return errors;
      !this.$v.customers.email.email && errors.push("ກະລຸນາປ້ອນ e-mail ໃຫ້ຖຶກຕ້ອງ");
      !this.$v.customers.email.required && errors.push("E-mail ວ່າງໆ.");
      return errors;
    },

    VillageErrors() {
      const errors = [];
      if (!this.$v.customers.village.$dirty) return errors;
      !this.$v.customers.village.maxLength &&
        errors.push("ກະລຸນາປ້ອນບ້ານບໍຄວນເກີນ 100 ຕົວອັກສອນ");
      !this.$v.customers.village.required &&
        errors.push("ບ້ານວ່າງໆ.");
      return errors;
    },

    DistrictErrors() {
      const errors = [];
      if (!this.$v.customers.district.$dirty) return errors;
      !this.$v.customers.district.maxLength &&
        errors.push("ກະລຸນາປ້ອນເມືອງບໍຄວນເກີນ 100 ຕົວອັກສອນ");
      !this.$v.customers.district.required &&
        errors.push("ເມືອງວ່າງໆ.");
      return errors;
    },

    ProvinceErrors() {
      const errors = [];
      if (!this.$v.customers.province.$dirty) return errors;
      !this.$v.customers.province.maxLength &&
        errors.push("ກະລຸນາປ້ອນແຂວງບໍຄວນເກີນ 100 ຕົວອັກສອນ");
      !this.$v.customers.province.required &&
        errors.push("ແຂວງວ່າງໆ.");
      return errors;
    },
    CountryErrors() {
      const errors = [];
      if (!this.$v.customers.country.$dirty) return errors;
      !this.$v.customers.country.maxLength &&
        errors.push("ກະລຸນາປ້ອນປະເທດບໍຄວນເກີນ 100 ຕົວອັກສອນ");
      !this.$v.customers.country.required &&
        errors.push("ປະເທດວ່າງໆ.");
      return errors;
    },
    PasswordErrors() {
      const errors = [];
      if (!this.$v.customers.password.$dirty) return errors;
      !this.$v.customers.password.minLength &&
        errors.push("ກະລຸນາປ້ອນລະຫັດຜ່ານຄວນເກີນ  6 ຕົວອັກສອນ");
      !this.$v.customers.password.maxLength &&
        errors.push("ກະລຸນາປ້ອນລະຫັດຜ່ານບໍ່ຄວນເກີນ 20 ຕົວອັກສອນ");
      !this.$v.customers.password.required &&
        errors.push("Password is required.");
      return errors;
    },
    StatusErrors() {
      const errors = [];
      if (!this.$v.customers.status.$dirty) return errors;
      !this.$v.customers.status.required &&
        errors.push("ກະລຸນາເລຶອກສະຖານະກ່ອນ!");
      return errors;
    },
    Register_payErrors() {
      const errors = [];
      if (!this.$v.customers.register_pay.$dirty) return errors;
      !this.$v.customers.register_pay.maxLength &&
        errors.push("ກະລຸນາປ້ອນຄ່າສະໝັກບໍ່ຄວນເກີນ 100 ຕົວອັກສອນ");
      !this.$v.customers.register_pay.required &&
        errors.push("ຄ່າສະໝັກວ່າງໆ.");
      return errors;
    },
    ProfileErrors() {
      const errors = [];
      if (!this.$v.customers.profile.$dirty) return errors;
      !this.$v.customers.profile.maxLength &&
        errors.push("ກະລຸນາປ້ອນຮູບພາບບໍ່ຄວນເກີນ 500 ຕົວອັກສອນ");

      !this.$v.customers.profile.required &&
        errors.push("Profile is required!");
      return errors;
    }
  },

  methods: {
    ...mapActions("customerMod", ["InsertCustomer", "InsertCustMember"]),
    ...mapActions("commentMod", ["InsertComment"]),
    
    onFileSelected(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = e => {
        this.url = e.target.result;
      };
      this.customers.profile = event.target.files[0].name;
    },
    async submit() {
      this.$v.$touch();
      try {
       
        if (
          this.customers.full_name == "" ||
          this.customers.full_name == null ||
          this.customers.full_name.length > 100
        ) {
          alert("ກະລຸນາກວດໃສ່ ຊື່ ແລະ ນານສະກຸນ ກ່ອນຈ່າ");
          return;
        }
        if (this.customers.gender == "" || this.customers.gender == null) {
          alert("Your Gender ?");
          return;
        }
        if (
          this.customers.tel == "" ||
          this.customers.tel == null ||
          this.customers.tel.length > 20
        ) {
          alert("Your Tel ?");
          return;
        }
        if (
          this.customers.password == "" ||
          this.customers.password == null ||
          this.customers.password.length < 6 ||
          this.customers.password.length > 20
        ) {
          alert("Your Password?");
          return;
        }
        if (this.customers.status == "" || this.customers.status == null) {
          alert("Your Status ?");
          return;
        }
        this.customers.emp_id = this.$store.state.authMod.token.emp_id;
        var date=new Date()
              .toJSON()
              .slice(0, 10)
              .replace(/-/g, "")
        var time=new Date().toTimeString()
              .replace(/.*(\d{2}:\d{2}:\d{2}).*/,"$1","")
              .replace(":","")
              .replace(":","")
        this.customers.cus_id="CUS"+date.slice(2,date.length)+time;
        
        this.InsertCustomer(this.customers);
        if (this.customers.status == "Member") {
          if (
            this.customers.register_pay == "" ||
            this.customers.register_pay == null ||
            this.customers.register_pay <= 0
          ) {
            alert("Your Money pay ?");
            return;
          }
          var custMember = {
            cus_id: this.customers.cus_id,
            emp_id: this.$store.state.authMod.token.emp_id,
            register_pay: this.customers.register_pay,
            register_date: new Date()
              .toJSON()
              .slice(0, 10)
              .replace(/-/g, "-"),
            update_date: new Date()
              .toJSON()
              .slice(0, 10)
              .replace(/-/g, "-"),
            card_capture: null
          };
          var comment={
               emp_id: this.$store.state.authMod.token.emp_id,
            cus_id: this.customers.cus_id,
          }
          this.InsertCustMember(custMember);
          this.InsertComment(comment)
        }
        alert("ບັນທຶກຂໍ້ມູນສໍາເລັດ....")
        this.clear();
      } catch (e) {
        this.errors.push(e);
      }
    },
    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        alert("ເບີໂທລະສັບຕ້ອງເປັນຕົວເລກ (0-9)");
        evt.preventDefault();
      } else {
        return true;
      }
    },

    clear() {
      this.$v.$reset();
      this.customers.cus_id = "";
      this.customers.full_name = "";
      this.customers.email = "";
      this.customers.tel = "";
      this.customers.facebook = "";
      this.customers.gender = "";
      this.customers.password = "";
      this.customers.province = "";
      this.customers.district = "";
      this.customers.village = "";
      this.customers.country = "";
      this.customers.status = "";
      (this.customers.profile = ""), (this.customers.register_pay = null);
      this.url = null;
    },
    back() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.file-upload {
  margin: 7px 0px;
  font-size: 16px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #999;
}
.file-upload:hover {
  border-bottom: 1px solid #555;
}
::-webkit-file-upload-button {
  background: #eeeeee;
  border-radius: 0px 50px 50px 0px;
  padding: 7px;
  width: 180px;
  border: 1px solid #edffdd;
}
::-webkit-file-upload-button:hover {
  background: #dedede;
}
</style>