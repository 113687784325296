<template>
  <div>
    <v-row justify="center" class="mb-3">
      <h1 class="grey lighten-5 mt-2">ຟອມຂາຍສິນຄ້າ</h1>
    </v-row>
    <v-divider></v-divider>
    <v-stepper v-model="e1" class="elevation-0 grey lighten-5">
      <v-stepper-header>
        <v-spacer></v-spacer>
        <template v-for="n in steps">
          <v-stepper-step
            dense
            flat
            :key="`${n}-step`"
            :complete="e1 > n"
            :step="n"
            editable
          >
            <h2 v-if="n == 1">ເລືອກສິນຄ້າ</h2>
            <h2 v-if="n == 2">ສິນຄ້າທີຕ້ອງການ</h2>
          </v-stepper-step>

          <v-divider v-if="n !== steps" :key="n"></v-divider>
        </template>
        <v-spacer></v-spacer>
        <v-btn
          @click="steps2"
          class="ma-5"
          rounded
          outlined
          color="#880E4F"
          style="font-size: 25px"
        >
          <v-avatar left>
            <v-icon large color="#004D40">mdi-cart</v-icon>
          </v-avatar>
          {{ Ordercount }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="Sell_Order"
          class="ma-5"
          rounded
          outlined
          color="#004D40"
          style="font-size: 25px"
        >
          <v-avatar left>
            <v-icon large color="#880E4F">mdi-cart-variant</v-icon>
          </v-avatar>
          {{ cuttingItems.length + confirm_orders.length }}
        </v-btn>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content v-for="n in steps" :key="`${n}-content`" :step="n">
          <!-- __________________________________________________111____________________________________________________________ -->

          <v-card
            v-if="n == 1 || e1 == 1"
            class="grey lighten-5 elevation-2 ma-0"
          >
            <v-row no-gutters class="justify-center">
              <v-spacer></v-spacer>
              <v-text-field
                dense
                outlined
                class="mt-1 mb-3"
                v-model="search"
                append-icon="mdi-magnify"
                label="ຄົ້ນຫາ"
                single-line
                hide-details
                @keyup="Search"
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-row>
            <v-row v-if="showdetail" no-gutters id="show" class="mt-2">
              <v-col cols="12" md="7">
                <v-carousel
                  hide-delimiter-background
                  show-arrows-on-hover
                  v-ripple="{ class: `error--text` }"
                  height="680"
                  v-model="keymodel"
                  class="grey lighten-5"
                >
                  <v-carousel-item v-for="(img, i) in editpro.image" :key="i">
                    <v-card
                      class="mx-auto"
                      height="100%"
                      aspect-ratio="1.7"
                      :img="fectImg(img.img_url)"
                    >
                    </v-card>
                  </v-carousel-item>
                </v-carousel>
                <v-row no-gutters justify="center">
                  <div
                    class="d-flex flex-nowrap ma-3"
                    align="center"
                    v-for="(img, i) in editpro.image"
                    :key="i"
                  >
                    <v-card
                      outlined
                      height="70"
                      width="100"
                      color="#c977ff"
                      v-ripple="{ class: `error--text` }"
                      :img="fectImg(img.img_url)"
                      @click="mykey(i)"
                    >
                      <v-icon color="green" size="70"
                        >mdi-{{ img.class }}</v-icon
                      >
                    </v-card>
                  </div>
                </v-row>
              </v-col>
              <v-col cols="12" md="5">
                <v-card
                  class="grey lighten-5"
                  lass="elevation-0 ma-0"
                  height="750"
                >
                  <v-card-title primary-title class="justify-center">
                    <h2>ລາຍລະອຽດສິນຄ້າ</h2>
                  </v-card-title>
                  <v-card-text>
                    <v-row class="justify-space-between ma-2">
                      <v-col cols="5" align="end"><h2>ລະຫັດສິນຄ້າ :</h2></v-col>
                      <v-col cols="7"
                        ><h1>{{ editpro.id }}</h1></v-col
                      >
                    </v-row>
                    <v-row class="justify-space-between ma-2">
                      <v-col cols="5" align="end"><h2>ຊື່ສິນຄ້າ :</h2></v-col>
                      <v-col cols="7"
                        ><h1>{{ editpro.name }}</h1></v-col
                      >
                    </v-row>
                    <v-row class="justify-space-between ma-2">
                      <v-col cols="5" align="end"><h2>ປະເພດສິນຄ້າ :</h2></v-col>
                      <v-col cols="7"
                        ><h1>{{ editpro.category }}</h1></v-col
                      >
                    </v-row>
                    <v-row class="justify-space-between ma-2">
                      <v-col cols="5" align="end"><h2>ຫົວໜ່ວຍ :</h2></v-col>
                      <v-col cols="7"
                        ><h1>{{ editpro.unit }}</h1></v-col
                      >
                    </v-row>
                    <v-row class="justify-space-between ma-2">
                      <v-col cols="5" align="end"><h2>ສີສິນຄ້າ :</h2></v-col>
                      <v-col cols="7"
                        ><h1>{{ editpro.color }}</h1></v-col
                      >
                    </v-row>
                    <v-row class="justify-space-between ma-2">
                      <v-col cols="5" align="end"><h2>ຂະໜາດ :</h2></v-col>
                      <v-col cols="7"
                        ><h1>{{ editpro.size }}</h1></v-col
                      >
                    </v-row>
                    <v-row class="justify-space-between ma-2">
                      <v-col cols="5" align="end"><h2>ຈໍານວນ :</h2></v-col>
                      <v-col cols="7"
                        ><h1>
                          {{
                            String(editpro.qty).replace(
                              /(.)(?=(\d{3})+$)/g,
                              "$1 "
                            )
                          }}
                        </h1></v-col
                      >
                    </v-row>
                    <v-row class="justify-space-between ma-2">
                      <v-col cols="5" align="end"><h2>ລາຄາ :</h2></v-col>
                      <v-col cols="7"
                        ><h1>
                          {{
                            String(editpro.price).replace(
                              /(.)(?=(\d{3})+$)/g,
                              "$1 "
                            )
                          }}
                          ກິບ
                        </h1></v-col
                      >
                    </v-row>
                    <v-row class="justify-space-between ma-2">
                      <v-col cols="5" align="end"
                        ><h2>ວັນທີ່ນໍາເຂົ້າ :</h2></v-col
                      >
                      <v-col cols="7"
                        ><h1>{{ editpro.create_date }}</h1></v-col
                      >
                    </v-row>
                    <v-row class="justify-space-between ma-2">
                      <v-col cols="5" align="end"><h2>ສະຖານະ :</h2></v-col>
                      <v-col cols="7"
                        ><h1>{{ editpro.status }}</h1></v-col
                      >
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="showdetail = false" icon>
                      <v-icon color="red" large>mdi-close-circle</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field
                      class="mt-5 tcenter"
                      filled
                      dense
                      rounded
                      prepend-inner-icon="mdi-minus"
                      append-icon="mdi-plus"
                      autofocus
                      v-model="editpro.addqty"
                      hint="ຈໍານວນທີ່ຕ້ອງການ"
                      @click:append="Add(pro)"
                      @click:prepend-inner="Minus(editpro)"
                      @keypress="InputQty($event, editpro)"
                      @keyup="ZeroQty(editpro)"
                      v-ripple="{ class: `white--text` }"
                    >
                      <template slot="append">
                        <v-btn
                          style="margin-top: -7px; margin-right: -20px"
                          color="#1B5E20"
                          icon
                          @click="Add(editpro)"
                        >
                          <v-icon large>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <template slot="prepend-inner">
                        <v-btn
                          style="margin-top: -7px; margin-left: -20px"
                          color="#1B5E20"
                          icon
                          @click="Minus(editpro)"
                        >
                          <v-icon large>mdi-minus</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                    <v-btn
                      class="mb-3 ml-2"
                      icon
                      :color="editpro.btncolor"
                      large
                      @click="addItem(editpro)"
                    >
                      <v-icon large>mdi-cart-plus</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <div v-else>
              <v-row>
                <v-col
                  v-for="(pro, idx) in product"
                  :key="idx"
                  class="d-flex child-flex"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  xl="2"
                >
                  <v-hover
                    class="grey lighten-5"
                    v-slot="{ hover }"
                    open-delay="200"
                    v-ripple="{ class: `error--text` }"
                  >
                    <v-card
                      :elevation="hover ? 15 : 5"
                      :class="{ 'on-hover': hover }"
                      style="background: #fa0afa"
                    >
                      <v-carousel
                        cycle
                        height="400"
                        hide-delimiter-background
                        show-arrows-on-hover
                        class="grey lighten-5"
                      >
                        <v-carousel-item v-for="(img, i) in pro.image" :key="i">
                          <v-card
                            class="mx-auto grey lighten-5"
                            @click="editProduct(pro)"
                            height="100%"
                            aspect-ratio="1.7"
                            :img="fectImg(img.img_url)"
                            contain
                            v-ripple="{ class: `error--text` }"
                          >
                          </v-card>
                        </v-carousel-item>
                      </v-carousel>

                      <v-card class="grey lighten-5 px-5 mt-5 elevation-0" flat>
                        <v-row justify="center">
                          <div>ສິນຄ້າ: {{ pro.name }}</div>
                          <v-spacer></v-spacer>
                          <span>
                            {{
                              String(pro.qty).replace(
                                /(.)(?=(\d{3})+$)/g,
                                "$1 "
                              )
                            }}</span
                          >
                        </v-row>
                        <v-row>
                          <div>
                            ລາຄາ:
                            {{
                              String(pro.price).replace(
                                /(.)(?=(\d{3})+$)/g,
                                "$1 "
                              )
                            }}
                            ກິບ
                          </div>
                        </v-row>
                        <v-row no-gutters class="mr-2">
                          <v-col cols="9" class="align-start">
                            <v-text-field
                              class="mt-5 tcenter"
                              filled
                              dense
                              rounded
                              prepend-inner-icon="mdi-minus"
                              append-icon="mdi-plus"
                              autofocus
                              v-model="pro.addqty"
                              :value="pro.addqty"
                              hint="ຈໍານວນທີ່ຕ້ອງການ"
                              @click:append="Add(pro)"
                              @click:prepend-inner="Minus(pro)"
                              @keypress="InputQty($event, pro)"
                              @keyup="ZeroQty(pro)"
                              v-ripple="{ class: `white--text` }"
                            >
                              <template slot="append">
                                <v-btn
                                  style="margin-top: -7px; margin-right: -20px"
                                  color="#1B5E20"
                                  icon
                                  @click="Add(pro)"
                                >
                                  <v-icon large>mdi-plus</v-icon>
                                </v-btn>
                              </template>
                              <template slot="prepend-inner">
                                <v-btn
                                  style="margin-top: -7px; margin-left: -20px"
                                  color="#1B5E20"
                                  icon
                                  @click="Minus(pro)"
                                >
                                  <v-icon large>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                            </v-text-field></v-col
                          >
                          <v-col cols="3" class="align-center">
                            <v-row class="justify-end" no-gutters>
                              <v-btn
                                class="mt-5"
                                icon
                                :color="pro.btncolor"
                                large
                                @click="addItem(pro)"
                              >
                                <v-icon large>mdi-cart-plus</v-icon>
                              </v-btn>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="nextStep(n)"
                style="font-size: 20px; font-weight: bold"
                color="#00E5FF"
                rounded
                class="mt-5"
              >
                ໄປໜ້າກະຕ່າສິນຄ້າ
                <v-icon large right
                  >mdi-arrow-right-bold-hexagon-outline</v-icon
                >
              </v-btn>
            </v-card-actions>
          </v-card>
          <!-- __________________________________________________222____________________________________________________________ -->
          <v-card v-if="n == 2" class="elevation-0 ma-0">
            <v-data-table
              :headers="headers"
              :items="orderpro"
              :search="search"
              item-key="name"
              v-model="selected"
              sort-by="calories"
              class="elevation-0 table"
            >
              <template v-slot:top>
                <div>
                  <v-toolbar flat>
                    <v-toolbar-title>ລາຍການສິນຄ້າ</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider
                    ><v-spacer></v-spacer>
                    <v-text-field
                      v-model="selectedID"
                      append-icon="mdi-magnify"
                      label="ປ້ອນລະຫັດສິນຄ້າ"
                      single-line
                      outlined
                      hide-details
                      dense
                      class="tcenter"
                    ></v-text-field>
                    <v-btn
                      class="ma-2"
                      icon
                      large
                      @click="InpuItem"
                      color="#004D40"
                    >
                      <v-icon large>mdi-cart-plus</v-icon>
                    </v-btn>

                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title
                          style="
                            font-size: 20px;
                            font-weight: bold;
                            font-family: NotoSansLao;
                          "
                          >ທ່ານຕ້ອງການລຶບຂໍ້ມູນລາຍການນີ້ບໍ...?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            text
                            @click="closeDelete"
                            style="font-size: 20px; font-family: NotoSansLao"
                            >ຍົກເລິກ</v-btn
                          >
                          <v-btn
                            color="primary"
                            text
                            @click="deleteItemConfirm"
                            style="font-size: 20px; font-family: NotoSansLao"
                            >ຢຶນຢັນ</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                  <v-progress-linear
                    class="black mt-2"
                    height="2"
                  ></v-progress-linear>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.id }}</td>
                  <td>
                    <div v-if="item.image.length < 1">Duab khaub ncaw</div>
                    <v-carousel
                      v-else
                      show-arrows-on-hover
                      hide-delimiters
                      height="100"
                      width="100"
                    >
                      <v-carousel-item v-for="(img, i) in item.image" :key="i">
                        <v-card
                          class="mx-auto"
                          height="100%"
                          width="100"
                          :img="fectImg(img.img_url)"
                        >
                        </v-card>
                      </v-carousel-item>
                    </v-carousel>
                  </td>
                  <td>{{ item.name }}</td>
                  <td align="center" wrap>
                    <v-text-field
                      class="mt-3 text-center tcenter"
                      filled
                      dense
                      rounded
                      prepend-inner-icon="mdi-minus"
                      append-icon="mdi-plus"
                      autofocus
                      v-model="item.addqty"
                      hint="ຈໍານວນທີ່ຕ້ອງການ"
                      @click:append="Add(item)"
                      @click:prepend-inner="Minus(item)"
                      @keypress="InputQty($event, item)"
                      @keyup="ZeroQty(item)"
                    >
                      <template slot="append">
                        <v-btn
                          style="margin-top: -7px; margin-right: -20px"
                          color="#004D40"
                          icon
                          @click="Add(item)"
                        >
                          <v-icon large>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <template slot="prepend-inner">
                        <v-btn
                          style="margin-top: -7px; margin-left: -20px"
                          color="#004D40"
                          icon
                          @click="Minus(item)"
                        >
                          <v-icon large>mdi-minus</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </td>
                  <td>
                    {{ String(item.price).replace(/(.)(?=(\d{3})+$)/g, "$1 ") }}
                  </td>
                  <td>
                    {{
                      String(
                        parseInt(item.addqty, 10) * parseInt(item.price, 10)
                      ).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
                    }}
                  </td>
                  <td>
                    <v-icon color="red" @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <h2>ບໍ່ມີຂໍ້ມູນ</h2>
              </template>
            </v-data-table>
            <v-card class="elevation-0">
              <v-divider class=""></v-divider>
              <v-row class="justify-center ma-2">
                <v-col cols="12" md="3">
                  <v-card class="elevation-0">
                    <v-row class="justify-center">
                      <h2 class="mt-2">ຂໍ້ມູນລູກຄ້າ</h2>
                    </v-row>
                    <v-row no-gutters class="justify-center">
                      <v-radio-group
                        class="ma-5 justify-center"
                        v-model="row"
                        row
                      >
                        <v-radio
                          label="ເປັນສະມາຊິກ"
                          value="ເປັນສະມາຊິກ"
                        ></v-radio>
                        <v-radio
                          label="ລູກຄ້າທົ່ວໄປ"
                          value="ລູກຄ້າທົ່ວໄປ"
                        ></v-radio>
                      </v-radio-group>
                    </v-row>
                    <v-card class="elevation-0" v-if="row == 'ເປັນສະມາຊິກ'">
                      <v-row no-gutters>
                        <v-text-field
                          v-model="customerInfo.email"
                          placeholder="ອີແມວ"
                          append-icon="mdi-email"
                          dense
                          outlined
                          counter
                          :error-messages="emailErrors"
                          required
                          @input="$v.customerInfo.email.$touch()"
                          @blur="$v.customerInfo.email.$touch()"
                          @keydown="
                            ccheck = false;
                            css = false;
                          "
                        >
                        </v-text-field>
                      </v-row>
                      <v-row no-gutters>
                        <v-text-field
                          v-model="customerInfo.password"
                          type="password"
                          placeholder="ລະຫັດຜ່ານ"
                          append-icon="mdi-key"
                          dense
                          outlined
                          :success-messages="SSMassage"
                          :error-messages="passwordErrors"
                          counter
                          required
                          @input="$v.customerInfo.password.$touch()"
                          @blur="$v.customerInfo.password.$touch()"
                          @keydown="
                            ccheck = false;
                            css = false;
                          "
                        >
                        </v-text-field>
                      </v-row>
                      <v-row class="justify-end">
                        <v-btn
                          style="font-size: 20px; font-weight: bold"
                          color="#FFFFFF"
                          class="mr-5"
                          @click="Cancel"
                        >
                          <v-icon large left>mdi-close</v-icon>
                          ຍົກເລິກ
                        </v-btn>
                        <v-btn
                          style="font-size: 20px; font-weight: bold"
                          color="#FFFFFF"
                          class="mr-4"
                          @click="CustomerSigin"
                        >
                          <v-icon large left>mdi-login</v-icon>
                          ຢຶນຢັນຕົວ
                        </v-btn>
                      </v-row>
                      <v-divider class="mt-3 hidden-md-and-up"></v-divider>
                    </v-card>
                  </v-card>
                </v-col>
                <v-col cols="1" class="hidden-sm-and-down" align="center">
                  <v-divider vertical></v-divider>
                </v-col>
                <v-col cols="12" md="8">
                  <v-card class="elevation-0">
                    <v-row no-gutters class="mt-1">
                      <v-col cols="12" md="5">
                        <v-row no-gutters class="justify-end md-and-down mr-2">
                          <h1>ມູນຄ່າລວມເປັນ:</h1>
                        </v-row></v-col
                      >
                      <v-col cols="10" md="5">
                        <v-row no-gutters class="justify-start">
                          <v-row no-gutters class="justify-start ml-2">
                            <v-text-field
                              outlined
                              readonly
                              style="font-size: 25px; font-weight: bold"
                              :value="
                                String(Sum).replace(/(.)(?=(\d{3})+$)/g, '$1 ')
                              "
                            >
                            </v-text-field>
                          </v-row> </v-row
                      ></v-col>
                      <v-col cols="2"
                        ><v-row no-gutters class="justify-center">
                          <h1>ກິບ</h1></v-row
                        ></v-col
                      >
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" md="5"
                        ><v-row no-gutters class="justify-end mr-2">
                          <h1>ສ່ວນຫຼຸດ:</h1>
                        </v-row>
                      </v-col>
                      <v-col cols="10" md="5"
                        ><v-row no-gutters class="justify-start ml-2">
                          <v-text-field
                            readonly
                            type="number"
                            outlined
                            style="font-size: 25px; font-weight: bold"
                            v-model="percent"
                          >
                          </v-text-field> </v-row
                      ></v-col>
                      <v-col cols="2"
                        ><v-row no-gutters class="justify-center">
                          <h1>ເປີເຊັນ</h1></v-row
                        ></v-col
                      >
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" md="5"
                        ><v-row no-gutters class="justify-end mr-2">
                          <h1>ມູນຄ່າຕ້ອງຊໍາລະ:</h1>
                        </v-row>
                      </v-col>
                      <v-col cols="10" md="5"
                        ><v-row
                          no-gutters
                          class="justify-start ml-2 font-size-30"
                        >
                          <v-text-field
                            readonly
                            outlined
                            style="font-size: 25px; font-weight: bold"
                            :value="
                              String(SumPay).replace(/(.)(?=(\d{3})+$)/g, '$1 ')
                            "
                          >
                          </v-text-field> </v-row
                      ></v-col>
                      <v-col cols="2"
                        ><v-row no-gutters class="justify-center">
                          <h1>ກິບ</h1></v-row
                        ></v-col
                      >
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" md="5"
                        ><v-row no-gutters class="justify-end mr-2">
                          <h1>ຊໍາລະເປັນ:</h1>
                        </v-row>
                      </v-col>
                      <v-col cols="10" md="5"
                        ><v-row no-gutters class="justify-start ml-2">
                          <v-text-field
                            v-model="setMoney"
                            :value="
                              String(setMoney).replace(
                                /(.)(?=(\d{3})+$)/g,
                                '$1 '
                              )
                            "
                            outlined
                            style="font-size: 25px; font-weight: bold"
                            @input="InputMoney"
                            @keypress="number($event)"
                          >
                          </v-text-field> </v-row
                      ></v-col>
                      <v-col cols="2"
                        ><v-row no-gutters class="justify-center">
                          <h1>ກິບ</h1></v-row
                        ></v-col
                      >
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" md="5"
                        ><v-row no-gutters class="justify-end mr-2">
                          <h1>ມູນຄ່າຖອນ:</h1>
                        </v-row>
                      </v-col>
                      <v-col cols="10" md="5"
                        ><v-row no-gutters class="justify-start ml-2">
                          <v-text-field
                            :value="
                              String(getMoney).replace(
                                /(.)(?=(\d{3})+$)/g,
                                '$1 '
                              )
                            "
                            readonly
                            outlined
                            style="font-size: 25px; font-weight: bold"
                          >
                          </v-text-field></v-row
                      ></v-col>
                      <v-col cols="2"
                        ><v-row no-gutters class="justify-center">
                          <h1>ກິບ</h1></v-row
                        ></v-col
                      >
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-card-actions>
              <v-btn
                style="font-size: 20px; font-weight: bold"
                color="#00E5FF"
                rounded
                @click="backStep(n)"
              >
                <v-icon large>mdi-arrow-left-bold-hexagon-outline</v-icon>
                ກັບໄປໜ້າສິນຄ້າ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                @click="Clear"
                style="font-size: 20px; font-weight: bold"
                color="#FF8A65"
                rounded
                class="mr-3"
              >
                <v-icon color="" large>mdi-close-octagon-outline</v-icon>
                ຍົກເລິກທັງໝົດ
              </v-btn>
              <v-btn
                @click="save"
                :disabled="EnableBtnSave"
                style="font-size: 20px; font-weight: bold"
                color="#1DE9B6"
                rounded
              >
                <v-icon color="" large>mdi-printer</v-icon>
                ຢຶນຢັນຂາຍ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import axios from "axios";
export default {
  mixins: [validationMixin],
  validations: {
    customerInfo: {
      id: { required, maxLength: maxLength(100) },
      email: { required, email },
      password: { required, minLength: minLength(6), maxLength: maxLength(20) },
    },
  },
  data() {
    return {
      fullscreen: false,
      e1: 1,
      steps: 2,
      showdetail: false,
      keymodel: "",
      dialog: false,
      search: "",
      dialogDelete: false,
      selected: [],
      headers: [
        { text: "ລະຫັດສິນຄ້າ", value: "id" },
        {
          text: "ຮູບ",
          align: "center",
          sortable: false,
          value: "image",
        },
        { text: "ສິນຄ້າ", value: "name" },
        { text: "ຈໍານວນ", align: "center", value: "addQty" },
        { text: "ລາຄາ", value: "price" },
        { text: "ລາຄາລວມ", value: "addQty*price" },
        { text: "ລົບອອກ", value: "actions", sortable: false },
      ],

      row: "ລູກຄ້າທົ່ວໄປ",

      editpro: [],
      deletepro: [],
      orderpro: [],

      selectedID: "",
      Sumpay: 0,
      percent: 0,
      setMoney: 0,
      getMoney: 0,
      customerInfo: {
        id: "",
        email: "",
        password: "",
      },
      ccheck: false,
      css: false,
      sale: {
        emp_id: "",
        cus_id: "",
        date: null,
        time: null,
        detail: [],
      },
    };
  },
  computed: {
    
    ...mapGetters({
      cuttingItems: "cutMod/cuttingItems",
    }),
    ...mapGetters({
      confirm_orders: "orderMod/confirm_orders",
    }),
    ...mapGetters({
      product: "saleMod/products",
      proid: "saleMod/proid",
      cut_Item: "saleMod/cut_Item",
      order_item: "saleMod/order_item",
    }),

    Sum() {
      var sum = 0;
      for (const key in this.orderpro) {
        if (Object.hasOwnProperty.call(this.orderpro, key)) {
          const el = this.orderpro[key];
          sum += parseInt(el.addqty, 10) * parseFloat(el.price, 10);
        }
      }
      return sum;
    },
       CountItem() {
      var item = 1;
      item=parseInt(this.cut_Item)+parseInt(this.order_item)
      return item;
    },

    SumPay() {
      var sum = 0;
      for (const key in this.orderpro) {
        if (Object.hasOwnProperty.call(this.orderpro, key)) {
          const el = this.orderpro[key];
          sum += parseInt(el.addqty, 10) * parseFloat(el.price, 10);
        }
      }
      return sum - (sum * parseFloat(this.percent)) / 100;
    },
    Ordercount() {
      return this.orderpro.length;
    },
    SSMassage() {
      const ss = [];
      if (this.css) {
        ss.push("Sign In Successfully");
      }
      return ss;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.customerInfo.email.$dirty) return errors;
      !this.$v.customerInfo.email.email && errors.push("Must be valid e-mail");
      !this.$v.customerInfo.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (this.ccheck) {
        errors.push("Email or Password Wrong");
        return errors;
      }
      if (!this.$v.customerInfo.password.$dirty) return errors;
      !this.$v.customerInfo.password.minLength &&
        errors.push("Password must be at less 6 characters short");
      !this.$v.customerInfo.password.maxLength &&
        errors.push("Password must be at most 20 characters long");
      !this.$v.customerInfo.password.required &&
        errors.push("Password is required.");
      return errors;
    },
    EnableBtnSave() {
      if (this.Sum < 1 || this.setMoney < this.SumPay) return true;
      return false;
    },
  },
  watch: {
    keymodel(val) {
      for (const key in this.editpro.image) {
        const element = this.editpro.image[key];
        if (key != val) {
          element.class = "";
        } else {
          element.class = "eye-check";
        }
      }
    },
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
    row(val) {
      var discount = "";
      for (const key in this.$store.state.Shop_Info) {
        const element = this.$store.state.Shop_Info[key];
        discount = element.discount;
      }
      if (val == "ເປັນສະມາຊິກ") return (this.percent = discount);
      this.percent = 0;
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.getProduct();
    // this.getCountOrderCut();
    // this.getCountOrderBuy()
  },
  mounted() {
    this.$store.dispatch("Shop_Info");
    //  this.getProduct();
    this.getConfirmOrder();
    this.getCutting();
  },
  methods: {
    
    ...mapActions("cutMod", [
      "getCutting",
    ]),
    ...mapActions("orderMod", ["getConfirmOrder"]),
    ...mapActions("saleMod", [
      "getProduct",
      "getCountOrderCut",
      "getCountOrderBuy",
      "searchProduct",
      "UpdateProduct",
      "InsertSale",
    ]),
    Search() {
      if (this.search) {
        this.searchProduct(this.search);
      } else {
        this.getProduct();
      }
    },
    fectImg(img) {
      var imageUrl;
      if (img) {
        imageUrl = require("@/assets/images/products/" + img);
      } else {
        imageUrl = require("@/assets/No_Product.png");
      }
      return imageUrl;
    },
    InpuItem() {
      var aqty = 1;
      for (const key in this.orderpro) {
        if (Object.hasOwnProperty.call(this.orderpro, key)) {
          const el = this.orderpro[key];
          if (this.selectedID == el.id) {
            if (parseInt(el.qty, 10) > parseInt(el.addqty, 10)) {
              el.addqty = parseInt(el.addqty, 10) + 1;
            }
            return;
          }
        }
      }
      for (const key in this.product) {
        if (Object.hasOwnProperty.call(this.product, key)) {
          const el = this.product[key];
          if (this.selectedID == el.id) {
            if (parseInt(el.addqty, 10) < 1 && parseInt(el.qty, 10) > 0) {
              el.addqty = aqty;
            }
            if (parseInt(el.addqty, 10) >= parseInt(el.qty, 10)) {
              el.addqty = parseInt(el.qty, 10);
            }
            this.orderpro.push(el);
          }
        }
      }
    },
    Add(pro) {
      if (typeof pro.addqty == "string" && pro.addqty == "") {
        pro.addqty = 1;
        return;
      }
      if (parseInt(pro.qty, 10) <= parseInt(pro.addqty, 10)) {
        pro.btncolor = "error";
        return;
      }
      pro.addqty = parseInt(pro.addqty, 10) + 1;
      if (pro.btncolor != "primary") {
        pro.btncolor = "#1B5E20";
      }
    },
    Minus(pro) {
      if (parseInt(pro.addqty, 10) < 1) return;
      pro.addqty = parseInt(pro.addqty, 10) - 1;
      if (pro.btncolor != "primary") {
        pro.btncolor = "#1B5E20";
      }
    },
    addItem(pro) {
      if (typeof pro.addqty == "string" && pro.addqty == "") {
        return;
      }
      if (parseInt(pro.addqty, 10) < 1) {
        pro.btncolor = "#1B5E20";
        return;
      }
      for (const key in this.orderpro) {
        if (Object.hasOwnProperty.call(this.orderpro, key)) {
          const el = this.orderpro[key];
          if (pro.id == el.id) {
            this.orderpro.splice(key, 1);
          }
        }
      }
      this.orderpro.push(pro);
      pro.btncolor = "primary";
    },
    editProduct(pro) {
      this.showdetail = true;
      this.editpro = pro;
    },
    InputMoney() {
      if (this.setMoney - this.SumPay < 0) {
        this.getMoney = 0;
        return;
      }
      this.getMoney = this.setMoney - this.SumPay;
    },
    Clear() {
      // if (window.confirm("ທ່ານຕ້ອງການຍົກເລິກລາຍການທັງໝົດແທ້ບໍ.....?"))
      (this.e1 = 1),
        (this.row = "ລູກຄ້າທົ່ວໄປ"),
        (this.editpro = []),
        (this.orderpro = []),
        (this.selectedID = ""),
        (this.Sumpay = 0),
        (this.percent = 0),
        (this.setMoney = 0),
        (this.getMoney = 0),
        (this.scc = false),
        (this.ccheck = false),
        (this.showdetail = false),
        (this.customerInfo = {
          id: "",
          email: "",
          password: "",
        });
      this.getProduct();
    },

    toggle() {
      this.$fullscreen.toggle(this.$el.querySelector(".full"), {
        wrap: false,
        callback: this.fullscreenChange,
      });
    },
    steps2() {
      this.e1 = 2;
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    backStep(n) {
      if (n > 1) {
        this.e1 = n - 1;
      }
    },
    mykey(i) {
      this.keymodel = i;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.dialog = true;
      this.deletepro = item;
    },
    deleteItemConfirm() {
      for (const key in this.orderpro) {
        if (Object.hasOwnProperty.call(this.orderpro, key)) {
          const el = this.orderpro[key];
          if (this.deletepro.id == el.id) {
            for (const key in this.product) {
              const pro = this.product[key];
              if (this.deletepro.id == pro.id) {
                pro.addqty = 0;
                pro.btncolor = "#1B5E20";
              }
            }
            this.orderpro.splice(key, 1);
          }
        }
      }
      this.dialogDelete = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        //  alert("Enter Only Number ? (0-9)");
        evt.preventDefault();
      } else {
        return true;
      }
    },

    ZeroQty(pro) {
      if (
        parseInt(pro.addqty, 10) < 1 ||
        (typeof pro.addqty == "string" && pro.addqty.length < 1) ||
        typeof pro.addqty == undefined
      ) {
        pro.addqty = 1;
        pro.btncolor = "#1B5E20";
        return;
      }

      if (parseInt(pro.addqty, 10) >= parseInt(pro.qty, 10)) {
        pro.addqty = parseInt(pro.qty, 10);
        pro.btncolor = "error";
      } else {
        if (pro.btncolor != "primary") {
          pro.btncolor = "#1B5E20";
          pro.addqty = parseInt(pro.addqty, 10);
        }
      }
    },
    InputQty(evt, pro) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        // alert("Enter Only Number ? (0-9)");
        evt.preventDefault();
      } else {
        if (parseInt(pro.addqty, 10) >= parseInt(pro.qty, 10)) {
          pro.addqty = parseInt(pro.qty, 10);
          pro.btncolor = "error";
        } else {
          if (pro.btncolor != "primary") {
            pro.btncolor = "#1B5E20";
            pro.addqty = parseInt(pro.addqty, 10);
          }
        }
        if (
          (typeof pro.addqty == "string" && pro.addqty == "") ||
          typeof pro.addqty == undefined
        ) {
          pro.addqty = 0;
          pro.btncolor = "#1B5E20";
        }
        return true;
      }
    },
    Cancel() {
      this.$v.$reset();
      this.customerInfo.email = "";
      this.customerInfo.password = "";
      this.row = "ລູກຄ້າທົ່ວໄປ";
    },
    CustomerSigin() {
      this.$v.$touch();
      if (
        this.customerInfo.email == "" ||
        this.customerInfo.email == null ||
        this.customerInfo.password == "" ||
        this.customerInfo.password == null
      ) {
        this.ccheck = true;
        this.css = false;
        //  alert("ກະລຸນາເຂົ້າລະບົບກ່ອນ.....");
        return;
      }
      axios
        .get(
          `http://localhost:2021/api/dadabackend/customer/signin/${this.customerInfo.email}/${this.customerInfo.password}`
        )
        .then((response) => {
          if (response.data.length === 0) {
            alert("ອີແມວ ຫຼື ລະຫັດຜ່ານບໍ່ຖຶກຕ້ອງ...!...ກະລຸນາກວດຄືນ");
            this.ccheck = true;
            this.css = false;
            return;
          }
          this.customerInfo.id = response.data[0].cus_id;
          this.ccheck = false;
          this.css = true;
        })
        .catch((error) => {
          this.ccheck = true;
          this.css = false;
          console.log(error);
        });
    },
    async save() {
      this.$v.$touch();
      if (this.Sum < 1) {
        return;
      }
      if (this.setMoney < this.SumPay) {
        return;
      }
      if (this.row != "ເປັນສະມາຊິກ") {
        //  alert("No member");
        this.customerInfo.id = "CUS210815001407";
      } else {
        if (
          this.customerInfo.email == "" ||
          this.customerInfo.email == null ||
          this.customerInfo.password == "" ||
          this.customerInfo.password == null
        ) {
          this.ccheck = true;
          this.css = false;
          alert("ກະລຸນາຢືນຢັນລູກຄ້າກ່ອນ.....");
          return;
        }
        axios
          .get(
            `http://localhost:2021/api/dadabackend/customer/signin/${this.customerInfo.email}/${this.customerInfo.password}`
          )
          .then((response) => {
            if (response.data.length === 0) {
              alert("ອີແມວ ຫຼື ລະຫັດຜ່ານບໍ່ຖຶກຕ້ອງ...!...ກະລຸນາກວດຄືນ");
              this.ccheck = true;
              this.css = false;
              return;
            }
            this.customerInfo.id = response.data[0].cus_id;
            this.ccheck = false;
            this.css = true;
          })
          .catch((error) => {
            this.ccheck = true;
            this.css = false;
            console.log(error);
          });
      }
      var detaillist = [];
      for (const key in this.orderpro) {
        if (parseInt(this.orderpro[key].addqty, 10) > 0) {
          var list = {
            pro_id: this.orderpro[key].id,
            qty: this.orderpro[key].addqty,
            price: this.orderpro[key].price,
            discount: this.percent,
          };
          detaillist.push(list);
        }
      }
      var date = new Date().toJSON().slice(0, 10).replace(/-/g, "");
      var time = new Date()
        .toTimeString()
        .replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1", "")
        .replace(":", "")
        .replace(":", "");
      this.sale = {
        sell_id: "BILL" + date.slice(2, date.length) + time,
        emp_id: this.$store.state.authMod.token.emp_id,
        cus_id: this.customerInfo.id,
        detail: detaillist,
      };
      this.InsertSale(this.sale);
      for (const key in this.orderpro) {
        var prolist = {
          pro_id: this.orderpro[key].id,
          qty: (this.orderpro[key].qty =
            this.orderpro[key].qty - this.orderpro[key].addqty),
          status: this.orderpro[key].status,
        };
        this.UpdateProduct(prolist);
      }
      var data = {
        sell_id: "BILL" + date.slice(2, date.length) + time,
      };
      let routeData = this.$router.resolve({
        name: "bills_sell_product",
        query: data,
      });
      window.open(routeData.href, "_blank");

      this.Clear();
    },
    Sell_Order() {
      if(this.cuttingItems.length + this.confirm_orders.length<=0){
return
      }
      this.$router.push(`/sell_order`);
    },
  },
};
</script>
<style scoped>
.table {
  font-size: 1.875em;
  border-radius: 3px;
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
}
th {
  font-size: 1.875em;
}
.tcenter {
  width: 200px;
  min-width: 100px;
}
.tcenter >>> input {
  text-align: center;
}
</style>