import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

const countryModule = {
  namespaced: true,
  state: {
   // category: [],
    Country_Name:[],
  },
  mutations: {
    // setCategory(state, cate) {
    //   var cateList = []
    //   for (const key in cate) {
    //     var el = {
    //       idx: parseInt(key, 10)+1,
    //       id: cate[key].cate_id,
    //       name: cate[key].cate_name,
    //     }
    //     cateList.push(el)
    //   }
    //   state.category = cateList
    // },
    Set_Country(state, Country_Name) {
      state.Country_Name = Country_Name
    },

  },
  actions: {
    // _______________________________________________________Category_________________________________________________________
    async getCountry(context) {
      var response = await axios.get(`https://restcountries.eu/rest/v2/all`)
      var countrylist=[]
      for (const key in response.data) {
        const element = response.data[key];
        countrylist.push(element.name);
      }
   //   context.commit('setCategory', response.data)
      context.commit('Set_Country',countrylist)
    },
    
  },
  getters: {
   // category: state => state.category,
    Country_Name: state => state.Country_Name,
  }
}

export default countryModule;

